import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    paddingTop: '50px',
    // paddingLeft: '150px',
    paddingBottom: '50px',
    // paddingRight: '150px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '50px',
      // paddingLeft: '20px',
      paddingBottom: '50px',
      // paddingRight: '20px',
      minHeight: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '50px',
      // paddingLeft: '20px',
      paddingBottom: '50px',
      // paddingRight: '20px',
      minHeight: '200px',
    },
  },
  innerContentWrapper: {
    paddingTop: '50px',
    paddingLeft: '150px',
    paddingBottom: '50px',
    paddingRight: '150px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '75px',
      paddingRight: '75px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      // justifyContent: 'flex-end',
      paddingRight: '245px',
      paddingLeft: '150px',
    },
  },
  innerContent: {
    width: 'auto',
    [theme.breakpoints.up('xl')]: {
      width: '500px',
    },
  },
  cardContainer: {
    flexBasis: '50%',
    fontFamily: ' Poppins',
    fontSize: '16px',
    // minHeight: '515px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
  cardTitle: {
    fontFamily: ' Poppins',
    fontSize: '24px',
  },
  // nft card style
  nftCardContainer: {
    display: 'flex',
    background: '#767676',
  },
  nftCardTitle: {
    fontFamily: ' Poppins',
    fontSize: '24px',
    fontWeight: '600px',
    color: '#fff',
  },
  nftCardDesc: {
    fontFamily: ' Poppins',
    fontSize: '16px',
    color: '#fff',
  },
  HowItIsMadeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F5673C',
    position: 'relative',
  },
  flexBasis50: {
    flexBasis: '50%',
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },
  drawingSampleImgContainer: {
    paddingLeft: '100px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  drawingSampleImg: {
    width: '350px',
  },
  howItIsMadeTitle: {
    fontFamily: ' Poppins',
    fontSize: '40px',
    fontWeight: '600px',
    marginBottom: '0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  howItIsMadeDesc: {
    fontFamily: ' Poppins',
    fontSize: '18px',
    color: '#fff',
  },
  underLine: {
    marginLeft: '40px',
  },
  // production component style
  ProductionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  productionImgContainer: {
    paddingLeft: '100px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
    },
  },
  babyImg: {
    width: '450px',
    marginTop: '22px',
    ObjectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  productionTitle: {
    fontFamily: ' Poppins',
    fontSize: '40px',
    fontWeight: '600px',
    marginBottom: '0px',
    paddingTop: '40px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  productionDesc: {
    fontFamily: ' Poppins',
    fontSize: '18px',
    marginBottom: '60px',
  },
  productionLink: {
    fontFamily: ' Poppins',
    fontSize: '18px',
    color: '#ED1C24',
  },
  PCHLogo: {
    position: 'absolute',
    top: '-51px',
  },
}));

export { useStyles };

import React, { useState } from 'react';
import { withStyles, Collapse } from '@material-ui/core';
import clsx from 'clsx';

const styles = theme => ({
  openIcon: {
    width: '19px',
    height: '19px',
    marginRight: '21px',
  },
  question: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '50px',
    cursor: 'pointer',
  },
  answerText: {
    color: '#858282',
    marginLeft: '44px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '32px',
  },
});

function Faq({ question, answer, classes }) {
  const [isOpen, setIsOpen] = useState(false);

  const renderAnswer = answer => {
    if (typeof answer === 'string') {
      return <div className={classes.answerText}>{answer}</div>;
    }

    return (
      <ul className={classes.answerText}>
        {answer.map(text => (
          <li>{text}</li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <div className={classes.question} onClick={() => setIsOpen(!isOpen)}>
        <img src="/img/chicadees/plus.svg" alt="plus" className={classes.openIcon} /> {question}
      </div>
      <Collapse in={isOpen}>{renderAnswer(answer)}</Collapse>
    </div>
  );
}

export default withStyles(styles)(Faq);

import React, { Fragment } from 'react';
import ReactLoading from 'react-loading';
import withStyles from '@material-ui/core/styles/withStyles';
/* info: https://www.npmjs.com/package/react-loading */

const pageStyles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function Page({ classes, containerHeight, containerWidth, height, width, message, type, color }) {
  return (
    <Fragment>
      <div className={classes.container} style={{ height: containerHeight, width: containerWidth }}>
        <div style={{ height, width, margin: 'auto' }}>
          <ReactLoading type={type} color={color} height={'100%'} width={'100%'} />
          <div>{message}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default withStyles(pageStyles)(Page);

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  congratulation: {
    textAlign: 'center',
    fontSize: '28px',
    marginBottom: 24,
  },
  makeNft: {
    height: '57px',
    borderRadius: '10px',
    backgroundColor: '#21D7BB',
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: 'Nunito Sans, sans-serif',
    marginBottom: '16px',
    border: 'none',
    textTransform: 'unset',
    width: '240px'
  },
  makeCollection: {
    height: '57px',
    borderRadius: '10px',
    backgroundColor: '#476DDC',
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: 'Nunito Sans, sans-serif',
    marginBottom: '16px',
    border: 'none',
    textTransform: 'unset',
    width: '240px'
  }
}));

export { useStyles };

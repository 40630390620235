import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sectionContainer: {
    width: '100%',
    backgroundColor: '#DDF4FF',
    background: 'url(/sectionBg.png)',
    backgroundSize: 'cover',
    boxSizing: 'border-box',
    paddingBottom: '80px',
    paddingTop: '60px',
    minHeight: '100vh',
  },
}));

const SectionLayout = props => {
  const classes = useStyles();
  return <div className={classes.sectionContainer}>{props.children}</div>;
};

export default SectionLayout;

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from './cross.svg';
import { useStyles } from './style';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, onClose, children, title }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="lg">
      <div className={classes.modalContainer} data-cy="modal">
        <div className={classes.modalHeader}>
          <button type="button" className={classes.closeModal} onClick={onClose}>
            <img src={CloseIcon} alt="Not found" />
          </button>
        </div>
        {children}
      </div>
    </Dialog>
  );
}

import File from './assets/File.svg';
import Pencil from './assets/Pencil.svg';
import Report from './assets/Report.svg';
import Star from './assets/Star.svg';
export const cardData = [
  {
    id: 1,
    image: Pencil,
    title: 'Hand painted ART',
    description:
      'Each trait has been carefully hand painted by Justin and then scanned into the computer to create the same style as all of Justin’s physical artwork. Every character also bears his signature.',
    backgroundColor: '#FC65AA',
  },
  {
    id: 2,
    image: File,
    title: 'Commercial Rights',
    description:
      'COMMERCIAL RIGHTS for the owner to create, profit and sell merchandise. - Each owner may use these characters in commercial work such as creating stories, making merchandise, and creating physical artwork which can be used personally or sold and profited from. The owner is defined as the current owner as indicated by the blockchain.',
    backgroundColor: '#00B9FC',
  },
  {
    id: 4,
    image: Star,
    title: 'Private Club',
    description:
      'Private discord CLUB for owners - Each owner has access to a special private chat with each other and Justin. Members in the club will also have guaranteed access to exclusive private events such as art gallery exhibits and NFT mixer events such as the J. Pierce launch party in Los Angeles. Our upcoming event will be for the sell out celebration',
    backgroundColor: '#F5683D',
  },
];

import { makeStyles } from '@material-ui/core/styles';
import Tick from './assets/tick.svg';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: 120,
    paddingBottom: 100,
  },
  Header: {
    marginTop: '60px',
    marginBottom: '60px',
    textAlign: 'center',
  },
  itemTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    color: '#323441',
  },
  desc: {
    fontFamily: 'Nunito',
    fonStyle: 'normal',
    fontWeight: ' normal',
    fontSize: '18px',
    textAlign: 'center',
    color: '#748896',
  },
  priceContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  flex32: {
    flexBasis: '32%',
    '@media (max-width: 1060px)': {
      flexBasis: '100%',
    },
  },
  priceSection: {
    background: '#eafffd',
    borderRadius: '20px',
    paddingTop: '10px',
    paddingBottom: '55px',
    paddingLeft: '10px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '12px',
    height: '100%',
  },
  proSection: {
    backgroundColor: '#1E3545',
    color: '#fff',
    marginTop: -20,
    marginBottom: -20,
    height: `calc(100% + 40px)`,
  },
  pricingTopSection: {
    borderRadius: 15,
    backgroundColor: '#FFF',
    paddingTop: 32,
    paddingBottom: 24,
    paddingLeft: 48,
    paddingRight: 48,
  },
  proTopSection: {
    backgroundColor: '#2F4B5E',
    borderRadius: 15,
    paddingTop: 32,
    paddingBottom: 24,
    paddingLeft: 48,
    paddingRight: 48,
  },
  priceTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    textAlign: 'center',
    color: '#21D7BB',
    margin: 0,
  },
  price: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
  },
  priceValue: {
    fontSize: '52px',
  },
  priceDesc: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    textAlign: 'center',
    color: '#748896',
    margin: 0,
    marginTop: 10,
  },
  priceList: {
    paddingLeft: 60,
    paddingRight: 60,
    listStyleImage: `url(${Tick})`,
  },
  priceListItem: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    marginBottom: '30px',
  },
  btnWrapper: {
    textAlign: 'center',
  },
  btn: {
    background: 'transparent',
    border: '1px solid #476DDC',
    borderRadius: '10px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: '#476DDC',
    height: '57px',
    padding: '10px',
    cursor: 'pointer',
  },
  bgDark: {
    background: '#1E3545',
  },
  colorWhite: {
    color: '#FFFFFF',
  },
}));

export { useStyles };

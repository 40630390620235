import React from 'react';
import PageLayout from 'Layout/Layout';
import CitizenzStaking from './CitizenzStaking';
import SectionLayout from 'Layout/SectionLayout';
const HistoryUpdate = () => {
  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <CitizenzStaking />
      </SectionLayout>
    </PageLayout>
  );
};

export default HistoryUpdate;

import * as types from './types';
import firebaseService from 'Services/firebaseService';

/**
 * Update User Data
 */
export function updateUserData({ userId, updateData }) {
  return async dispatch => {
    try {
      await firebaseService.updateUserData({ userId, updateData });
    } catch (error) {
      console.warn('Error checking out : ', error);
      alert('User update error' + error);
    }
  };
}

/**
 * Set User Data
 */
export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: types.SET_USER_DATA,
      payload: user,
    });
  };
}

/**
 * Remove User Data
 */
export function removeUserData() {
  return {
    type: types.REMOVE_USER_DATA,
  };
}

/**
 * Update User Data
 */
export function getUserTransactions() {
  return async dispatch => {
    let userTransactions;
    try {
      userTransactions = await firebaseService.getUserTransactions();
    } catch (error) {
      console.warn('Error checking out : ', error);
      alert('Error getting user transactions' + error);
    }

    dispatch({
      type: types.SET_USER_TRANSACTIONS,
      payload: userTransactions || [],
    });
  };
}

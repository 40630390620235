/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useMemo } from 'react';
import { jsx } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import {
  createStripeCheckoutSessionForNFTTransfer,
  getNftTransferFee,
  transferNftWithCredits,
  fetchUserCredits,
} from '../../Services/api';
import config from 'Config/firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import FormContainer from 'Components/FormInputs/FormContainer';
import TextField from 'Components/FormInputs/TextField';
import { useDispatch } from 'react-redux';
import { closeActiveModal } from 'Store/actions';
import Button from 'Shared/Button';
import * as Yup from 'yup';

function TransferNft({ tokenId, tokenIdHex, contractAddress, tokenName, tokenImages, user, schema, blockchain }) {
  const dispatch = useDispatch();
  const [nftTransferPrice, setNftTransferPrice] = useState(null);
  const [userCredits, setUserCredits] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchNftFee = async blockchain => {
      const nftTransferFee = await getNftTransferFee(blockchain);
      setNftTransferPrice(nftTransferFee);
    };

    fetchNftFee(blockchain);
  }, [blockchain]);

  useEffect(() => {
    const getCredits = async () => {
      const credits = await fetchUserCredits();
      setUserCredits(credits);
    };

    getCredits();
  }, []);

  const sendToStripeCheckout = async address => {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);
    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForNFTTransfer({
        item: {
          tokenId,
          tokenIdHex,
          name: tokenName,
          images: tokenImages,
          toAddress: address,
          contractAddress,
          blockchain,
          schema,
        },
        cancelUrl: `${window.location.href}`,
      });
      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const sendNft = async formData => {
    setSubmitting(true);
    if (blockchain === 'polygon') {
      const payload = {
        tokenId,
        tokenIdHex,
        contractAddress,
        tokenName,
        toAddress: formData.address,
        blockchain,
        schema,
      };
      await transferNftWithCredits(payload);
      dispatch(closeActiveModal(null));
    } else {
      await sendToStripeCheckout(formData.address);
    }
  };

  const isDisableSubmit = useMemo(() => {
    if (submitting) return true;
    if (blockchain === 'polygon') {
      return !userCredits;
    } else {
      return !nftTransferPrice;
    }
  }, [blockchain, nftTransferPrice, userCredits, submitting]);

  const handleClose = () => {
    dispatch(closeActiveModal(null));
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .nullable()
      .required('Ethereum or Polygon address is required')
      .matches(/^0x[a-fA-F0-9]{40}$/, 'Provide a valid address'),
  });

  const initialValues = { address: '' };

  // TODO: convert this into the standard modal form with container instead of dialog here
  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={handleClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h2">Transfer NFT</Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={sendNft} mode="onChange">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ethereum or Polygon Address"
                name="address"
                variant="outlined"
                placeholder="Enter Ethereum or Polygon Address"
              />
            </Grid>

            <Grid item xs={12}>
              <Box mt={3} display="flex" alignItems="center">
                {blockchain === 'polygon' ? (
                  <Box>
                    <Box mr={1} component="b">
                      Your Credits:
                    </Box>
                    {userCredits ? (
                      <Box component="span">{userCredits?.nftCredits}</Box>
                    ) : (
                      <CircularProgress size={16} />
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Box mr={1} component="b">
                      Cost to transfer:
                    </Box>
                    {nftTransferPrice ? <Box component="span">{nftTransferPrice}</Box> : <CircularProgress size={16} />}
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              {blockchain === 'polygon' ? (
                <Box component="small">It is 1 credit per transfer.</Box>
              ) : (
                <Box component="small">Transfer fee is based on a set gas amount and varying Ethereum rates.</Box>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" py={2}>
                <Button background btnLabel="Confirm Send" disabled={isDisableSubmit} />
              </Box>
            </Grid>
          </Grid>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(TransferNft);

import React, { useState, useEffect, useMemo } from 'react';
import { useStyles } from './style';
import Container from 'Components/Container';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import config from 'Config/firebase';
import * as actions from 'Store/actions';
import DropDown from 'Shared/TableDropdown';

const History = ({ user, userTransactions, getUserTransactions }) => {
  const classess = useStyles();
  const options = ['approveAll', 'harvestEggs', 'seed', 'mint', 'transfer', 'sendETH'];
  const statusOption = ['Success', 'Pending', 'Error'];
  const [typeFilter, setTypeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);

  useEffect(() => {
    getUserTransactions();
  }, [getUserTransactions, user]);

  console.log({ user }, { userTransactions });

  const transactions = useMemo(() => {
    let data = userTransactions;

    if (typeFilter.length > 0) {
      // TODO: fix for modified option names like Approve All
      //const filterOptions = typeFilter.map(it => it.toLowerCase());
      data = data.filter(item => typeFilter.includes(item.type));
    }

    if (statusFilter.length > 0) {
      const filterOptions = statusFilter.map(it => it.toLowerCase());
      data = data.filter(item => filterOptions.includes(item.status));
    }

    return data;
  }, [typeFilter, statusFilter, userTransactions]);

  return (
    <div className={classess.historyContainer}>
      <Container>
        <div className={classess.sectionHeader}>
          <h1 className={classess.heading}>History</h1>
          <p className={classess.helperText}>New transactions will take a moment to appear.</p>
        </div>
        <div className={classess.tableContainer}>
          <div className={classess.table}>
            <div className={classess.TableHeaderRow}>
              <span className={classess.tableCell}>
                <DropDown label="Type" options={options} onChange={types => setTypeFilter(types)} />
              </span>
              <span className={classess.tableCell}>Date</span>
              <span className={classess.tableCell}>ID</span>
              <span className={classess.tableCell}>
                <DropDown label="Status" options={statusOption} onChange={statuses => setStatusFilter(statuses)} />
              </span>
            </div>
            {transactions
              ?.sort((firstTx, secondTx) => firstTx.createdAt < secondTx.createdAt)
              ?.map(tx => {
                // const extraInfo = tx.tokenId || tx.itemId || tx.amount;
                const scanAddress = tx.blockchain === 'polygon' ? config.polyscanAddress : config.etherscanAddress;
                let color = '';

                if (tx.status === 'success') {
                  color = '#21D7BB';
                } else if (tx.status === 'pending') {
                  color = '#F2994A';
                } else if (tx.status.includes('error')) {
                  color = '#EB5757';
                }

                return (
                  <div className={classess.row}>
                    <a target="scan" href={`${scanAddress}tx/${tx.txHash}`} className={classess.link}></a>
                    <span className={classess.tableCell}>{tx.type}</span>
                    <span className={classess.tableCell}>
                      {moment(parseInt(tx.createdAt, 10)).format('M/D/YYYY, h:mm A')}
                    </span>
                    <span className={classess.tableCell}>{tx.tokenId || tx.itemId}</span>
                    <span className={classess.tableCell} style={{ color: color }}>
                      {tx.status}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        {/* <div className={classess.pagination}>
          <Pagination count={10} color="secondary" className={classess.root} />
        </div> */}
      </Container>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserTransactions: actions.getUserTransactions,
    },
    dispatch,
  );
}

function mapStateToProps({ userTransactions, user }) {
  return {
    user,
    userTransactions,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(History));

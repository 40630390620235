import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import config from 'Config/firebase';

const instance = axios.create({ baseURL: config.functionsURL });

instance.interceptors.request.use(
  
  async (config) => {
    const user = firebase.auth().currentUser;
    if (user) {
      const authToken = await user.getIdToken();
      config.headers['Authorization'] = `Bearer ${authToken}`;
    }
   
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default instance;

import React from 'react';
import { Field } from 'formik';
import './style.css';

const Input = ({ label, name, placeholder = ' ', error, value, touched, type, ...rest }) => {
  return (
    <div className="inputContainer">
      <div className="inputField">
        <Field
          type={type || 'text'}
          name={name}
          value={value}
          placeholder={placeholder}
          className={`${error && touched && 'inputError'}`}
          {...rest}
        />
        <span>{label}</span>
      </div>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
};
export default Input;

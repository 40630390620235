import React from 'react';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import Pricing from './Pricing';
import { useStyles } from './style';
import NftApi from '../Home/NftApi';

const FAQ = () => {
  const classess = useStyles();

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <div className={classess.Header}>
          <h1 className={classess.itemTitle}>
            Create NFTs. Create smart contracts. Create user wallets. We handle the crypto.
          </h1>
          <span className={classess.desc}>
            For small to medium and enterprise business - <a href="https://api.ownerfy.com/">Read the docs</a> or create
            a <a href="https://staging.ownerfy.io">Amoy testnet account</a>.<br /> We use the Polygon blockchain, the
            fastest, most cost effective platform supported by the largest amount of secondary markets
            <br /> and tools like OpenSea, Metamask, and Trust Wallet and companies like Meta and Disney. Ethereum is
            available to enterprise customers upon request.
          </span>
        </div>
        <Pricing />
        <NftApi />
      </SectionLayout>
    </PageLayout>
  );
};

export default FAQ;

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const InfoRow = ({ label, info, className, isContentStacked = false, isChangedOnSmBreakpoint = true }) => {
  const theme = useTheme();
  const isViewportAboveSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: ${isContentStacked ? 'column' : 'row'};
        justify-content: ${isContentStacked ? 'flex-start' : 'space-between'};
        align-items: ${isContentStacked ? 'flex-start' : 'center'};
        margin-bottom: ${isContentStacked ? '8' : '0'}px;
      `}
    >
      <p
        css={css`
          font-family: 'Montserrat', sans-serif;
          color: #939798;
          font-weight: 500;
          font-size: ${isViewportAboveSm && isChangedOnSmBreakpoint ? '13px' : '11px'};
          letter-spacing: ${isViewportAboveSm && isChangedOnSmBreakpoint ? '0.1px' : '0.08px'};
          line-height: ${isContentStacked ? '17' : '27'}px;
          margin: 0;
          margin-bottom: ${isContentStacked ? '0' : '4'}px;
        `}
      >
        {label}
      </p>

      <p
        css={css`
          font-family: 'Montserrat', sans-serif;
          color: #4d5153;
          font-weight: 500;
          font-size: ${isViewportAboveSm && isChangedOnSmBreakpoint ? '13px' : '11px'};
          letter-spacing: ${isViewportAboveSm && isChangedOnSmBreakpoint ? '0.1px' : '0.08px'};
          line-height: ${isContentStacked ? '17' : '27'}px;
          margin: 0;
          margin-bottom: 4px;
        `}
      >
        {info}
      </p>
    </div>
  );
};

import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Box, Container, IconButton } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import useStyles from './style';
import { ReactComponent as WalkIcon } from '../assets/walk.svg';
import { ReactComponent as LeftArrowIcon } from '../assets/LeftArrow.svg';
import { ReactComponent as RightArrowIcon } from '../assets/RightArrow.svg';
import TimelineImg from '../assets/Timeline.png';
import MapBgImg from '../assets/map-bg.png';
import NFTCardImg from '../assets/NFTCard.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const PrizeSection = () => {
  const classes = useStyles();
  let slider = useRef(null);

  return (
    <Box className={classes.prizeWrapper} pb={10}>
      <Container className={classes.prizeMainSection}>
        <Box display="flex" flexDirection="column">
          <Box className={classes.prizeTimeline}>ETH Bonuses</Box>
          <Box className={classes.prizeNftsSold}>0 TO 10,000 NFTS SOLD</Box>

          <PerfectScrollbar>
            <div className={classes.scrollContainer}>
              <img src={TimelineImg} className={classes.timelineImage} width="100%" alt="Timeline" />
            </div>
          </PerfectScrollbar>

          <Box style={{ overflow: 'hidden' }}>
            <Box className={classes.prizeSliderWrapper}>
              <Slider ref={ref => (slider = ref)} {...sliderSettings}>
                <FreePriceCard />
                <OneEthPriceCard />
                <ThreeEthPriceCard />
                <ClosingCard />
              </Slider>
            </Box>
          </Box>

          <Box className={classes.hideDesktop} display="flex" justifyContent="flex-end" mt={5}>
            <IconButton onClick={() => slider.slickPrev()}>
              <LeftArrowIcon />
            </IconButton>

            <IconButton onClick={() => slider.slickNext()}>
              <RightArrowIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const FreePriceCard = () => {
  const classes = useStyles();

  return (
    <Box>
      <div className={classes.freePriceContent}>
        <img src={NFTCardImg} className={classes.freePriceImage} alt="free-price" />
        <div>Special Background NFT</div>
        <div>(Example)</div>
      </div>
    </Box>
  );
};

const OneEthPriceCard = () => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <div className={classes.oneEthPriceBar}></div>
      <div className={classes.oneEthPriceContent}>
        <div className={classes.oneEthPriceTitle}>
          <WalkIcon height={20} />
          <span style={{ marginLeft: 15 }}>1 ETH PRIZE</span>
        </div>
        <div className={classes.oneEthDescription}>
          <div>11 special backgrounds</div>
          <div>will get 1 ETH bonus</div>
        </div>
      </div>
    </Box>
  );
};

const ThreeEthPriceCard = () => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <div className={classes.threeEthPriceBar}></div>
      <div className={classes.threeEthPriceContent}>
        <div className={classes.threeEthPriceTitle}>3 ETH PER LAST ONE</div>
        <div className={classes.threeEthDescription}>
          <div>At NFT 10,000 that one</div>
          <div>person gets 3 ETH</div>
        </div>
      </div>
    </Box>
  );
};

const ClosingCard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.closingContainer} display="flex" justifyContent="center">
      <img src={MapBgImg} className={classes.closingBackground} alt="Map" />
      <div className={classes.closingDescription}>
        <Box pb={4}>
          <div>Also at 10,000 NFTs</div>
          <div>sold we will have a</div>
          <div>
            <strong>closing party for all</strong>
          </div>
          <div>
            <strong>the NFT Owners.</strong>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default PrizeSection;

/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, useFormState } from 'react-hook-form';
import { Box, Grid, Link, Typography, TextField as MuiTextField } from '@material-ui/core';
import { css, jsx } from '@emotion/react';
import NumberFormat from 'react-number-format';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { setActiveModal, logoutUser } from 'Store/actions';
import FormContainer from 'Components/FormInputs/FormContainer';
import TextField from 'Components/FormInputs/TextField';
import Checkbox from 'Components/FormInputs/Checkbox';

function InPageSignupLogin({ onValidationChange }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const linkStyle = css`
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  `;

  const openLoginModal = () => {
    dispatch(setActiveModal('login'));
  };

  if (!isEmpty(user)) {
    return (
      <Typography
        variant="h6"
        css={css`
          color: #21d7bb;
          font-size: 18px;
          font-weight: 100;
          line-height: 150%;
        `}
      >
        Logged in
        <span css={linkStyle} onClick={() => dispatch(logoutUser({ returnPath: '/' }))}>
          (Log out)
        </span>
      </Typography>
    );
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().nullable().required('Provide your first name'),
    lastName: Yup.string().nullable().required('Provider your last name'),
    email: Yup.string().nullable().required('Provide the email').email('Invalid email'),
    phone: Yup.string().nullable(),
    username: Yup.string().nullable().required('Provide a username').min(5, 'Username too short'),
    password: Yup.string()
      .nullable()
      .required('Password is required')
      .min(8, 'Password must be longer than 8 charactors'),
    confirmPassword: Yup.string()
      .nullable()
      .when('password', {
        is: val => !!(val && val.length > 0),
        then: Yup.string().oneOf([Yup.ref('password')], 'Passwords need to match'),
      }),
    agreeTerms: Yup.boolean().test('check', 'Please agree to the terms of service', async value => !!value),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: user?.email || '',
    phone: '',
    username: user?.username || '',
    password: '',
    confirmPassword: '',
    agreeTerms: '',
  };

  return (
    <FormContainer validation={validationSchema} defaultValues={initialValues} mode="onChange" onSuccess={() => {}}>
      <Box mt={4} mb={2}>
        <Typography variant="h3">
          <Box component="span">Already have an account?</Box>
          <span css={linkStyle} onClick={openLoginModal}>
            (Log in here)
          </span>
        </Typography>
      </Box>

      <FormFields onValidationChange={onValidationChange} />
    </FormContainer>
  );
}

const FormFields = ({ onValidationChange }) => {
  const openTosWindow = () => {
    window.open(`${window.location.origin}/TOS?hasNav=false`, 'tos', 'width=500,height=800');
  };

  const { watch } = useFormContext();
  const { isValid } = useFormState();
  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const email = watch('email');
  const username = watch('username');
  const phone = watch('phone');
  const password = watch('password');
  const agreeTerms = watch('agreeTerms');

  useEffect(() => {
    onValidationChange(isValid, { firstName, lastName, email, password, phone, username });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, firstName, lastName, email, username, phone, password, agreeTerms]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          name="firstName"
          label="First Name"
          variant="outlined"
          placeholder="Enter First Name"
          data-cy="first-name"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          name="lastName"
          label="Last Name"
          variant="outlined"
          placeholder="Enter Last Name"
          data-cy="last-name"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField fullWidth name="email" label="Email" variant="outlined" placeholder="Enter Email" data-cy="email" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="username"
          label="Username"
          variant="outlined"
          placeholder="Enter Username"
          data-cy="username"
        />
      </Grid>

      <Grid item xs={12}>
        <NumberFormat
          customInput={props => (
            <MuiTextField {...props} variant="outlined" fullWidth label="Phone Number (Optional)" />
          )}
          format="###-###-####"
          mask="_"
          fullWidth
          name="phone"
          helperText="If provided text messages will be sent here for security."
          placeholder="Phone Number (Optional)"
          data-cy="pone-number"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          type="password"
          name="password"
          label="Password"
          variant="outlined"
          placeholder="Enter Password"
          data-cy="password"
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          type="password"
          name="confirmPassword"
          label="Confirm"
          variant="outlined"
          placeholder="Enter Password"
          data-cy="repeat-password"
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          name="agreeTerms"
          label={() => (
            <Box>
              <Box component="span" mr={0.5}>
                I have read and agree to the
              </Box>
              <Link onClick={openTosWindow} target={'tos'}>
                terms of service
              </Link>
            </Box>
          )}
          data-cy="tos-checkbox"
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(InPageSignupLogin);

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loading: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#DDF4FF',
  },
  mintNftContainer: {
    position: 'relative',
    backgroundColor: '#DDF4FF',
    paddingBottom: '80px',
  },
  mintNft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '42px',
    '@media (max-width: 768px)': {
      flexWrap:
        'wra                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           p',
    },
  },
  mintNftCol: {
    flexBasis: '48%',
    backgroundColor: '#fff',
    borderRadius: '20px',
    marginBottom: '20px',
    padding: '40px',
    marginRight: '12px',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      flexBasis: '100%',
      padding: '20px',
    },
  },
  mintNftHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    color: '#323441',
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: '900',
    marginTop: '0px',
    marginBottom: '10px',
  },
  inputWrapper: {
    marginTop: '42px',
    marginBottom: '42px',
  },
  helperText: {
    color: '#5B5D68',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '400',
    margin: '0px',
  },
  mintText: {
    color: '#5B5D68',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '400',
    margin: '0px',
    marginBottom: '20px',
  },
  mintLink: {
    color: '#21D7BB',
    fontSize: '18px',
    fontWeight: '100',
    lineHeight: '150%',
    fontFamily: '"Nunito Sans",sans-serif',
  },
  mintNftSection: {
    background: '#E8FFFE',
    marginTop: '60px',
    padding: '20px',
    borderRadius: '10px',
    height: '148px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  mintFeeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '22px',
  },
  mintFeeLable: {
    fontSize: '12px',
    color: '#748896',
    fontFamily: 'Lato',
  },
  minFee: {
    fontSize: '24px',
    color: '#5B5D68',
    fontFamily: 'Lato',
    marginRight: '10px',
  },
  attributesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '32px',
  },
  attributes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexBasis: '48%',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    padding: '10px',
    height: '60px',
    boxSizing: 'border-box',
    marginTop: '0px',
    marginBottom: '12px',
  },
  attributesLable: {
    fontSize: '14px',
    color: '#748896',
    fontFamily: 'Lato',
  },
  addBtn: {
    border: '1px solid #476DDC',
    borderRadius: '10px',
    background: 'transparent',
    width: '146px',
    height: '57px',
    color: '#476DDC',
    cursor: 'pointer',
  },
  crossIcon: {
    cursor: 'pointer',
  },
}));

export { useStyles };

import { combineReducers } from 'redux';
import login from './login.reducer';
import user from './user.reducer';
import userTransactions from './user.transactions.reducer';
import activeModal from './activeModal.reducer';
import item from './item.reducer';
import itemDraft from './itemDraft.reducer';
import checkoutData from './checkout.reducer';
import planData from './planData.reducer';
import productData from './productData.reducer';
import userItems from './userItems.reducer';
import shopifyOrder from './shopifyOrder.reducer';
import registerProductConfig from './registerProductConfig.reducer';
import lootvault from './lootvault.reducer';

const authReducers = combineReducers({
  login,
  user,
  item,
  itemDraft,
  userItems,
  planData,
  productData,
  activeModal,
  checkoutData,
  shopifyOrder,
  registerProductConfig,
  userTransactions,
  lootvault,
});

export default authReducers;

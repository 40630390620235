import * as types from './types';
import firebaseService from 'Services/firebaseService';

/**
 * Temporarily store uploaded images
 */
export function saveImagesLocally({ main, thumbs }) {
  return dispatch => {
    dispatch({
      type: types.SAVE_IMAGES_LOCALLY,
      images: {
        main,
        thumbs,
      },
    });
  };
}

/**
 * Save the item form draft info data
 */
export function saveItemDraftInfo(formData) {
  return dispatch => {
    dispatch({
      type: types.SAVE_ITEM_DRAFT_INFO,
      formData,
    });
  };
}

/**
 * Save the item form draft order data
 */
export function saveItemDraftOrder(orderInformation) {
  return dispatch => {
    dispatch({
      type: types.SAVE_ITEM_DRAFT_ORDER,
      orderInformation,
    });
  };
}

export function updateItem(itemDraft) {
  return async dispatch => {
    let item = Object.assign({}, itemDraft.info);

    try {
      if (itemDraft.info.images && itemDraft.info.images[0] && itemDraft.info.images[0].indexOf('https:') === -1) {
        const imgRes = await firebaseService.uploadImages({
          images: itemDraft.info.images,
          userId: itemDraft.info.ownerId,
        });
        item.images = imgRes;
        const imgThumbsRes = await firebaseService.uploadImages({
          images: itemDraft.info.thumbs,
          userId: itemDraft.info.ownerId,
          isThumbs: true,
        });
        item.thumbs = imgThumbsRes;
      }

      await firebaseService.updateItem(item);
      dispatch({
        type: types.RESET_ITEM_DRAFT,
        payload: null,
      });
      return true;
    } catch (error) {
      console.warn('Error updating item : ', error);
      throw new Error(error);
    }
  };
}

import * as types from './types';
// import config from 'Config/firebase';
import firebaseService from 'Services/firebaseService';
// import { getAssets, formatOpenSeaAssets } from 'Services/openSeaService';
import { formatServerAssets } from 'Helpers/format';
import { getNftsByAddress, getNextPageNftsByAddress } from 'Services/api';

export function getUserItems({ filter }) {
  return async dispatch => {
    try {
      switch (filter) {
        case 'pending': {
          const dbItems = await firebaseService.getUserItems();
          // Only pending because minted will come back from opensea
          const onlyPendingItems = dbItems.filter(item => item.mintStatus === 'pending');

          dispatch({
            type: types.SET_USER_ITEMS,
            payload: {
              nfts: onlyPendingItems,
              total: onlyPendingItems.length,
            },
          });
          break;
        }

        case 'ethereum': {
          const ethereumData = await getNftsByAddress({ type: 'ethereum' });
          const formattedEthereum = formatServerAssets(ethereumData.nfts, 'ethereum');
          const ethereumPageKey = ethereumData.meta.pageKey;
          dispatch({
            type: types.SET_USER_ITEMS,
            payload: {
              nfts: formattedEthereum,
              ethereumPageKey,
              total: ethereumData.meta.total,
            },
          });
          break;
        }

        case 'polygon': {
          const polygonData = await getNftsByAddress({ type: 'polygon' });
          const formattedPolygon = formatServerAssets(polygonData.nfts, 'polygon');
          const polygonPageKey = polygonData.meta.pageKey;
          dispatch({
            type: types.SET_USER_ITEMS,
            payload: {
              nfts: formattedPolygon,
              polygonPageKey,
              total: polygonData.meta.total,
            },
          });
          break;
        }

        default: {
          const dbItems = await firebaseService.getUserItems();
          // Only pending because minted will come back from opensea
          const onlyPendingItems = dbItems.filter(item => item.mintStatus === 'pending');

          const ethereumData = await getNftsByAddress({ type: 'ethereum' });
          const polygonData = await getNftsByAddress({ type: 'polygon' });

          const formattedEthereum = formatServerAssets(ethereumData.nfts, 'ethereum');
          const formattedPolygon = formatServerAssets(polygonData.nfts, 'polygon');

          const ethereumPageKey = ethereumData.meta.pageKey;
          const polygonPageKey = polygonData.meta.pageKey;

          const ethereumTotalCount = ethereumData.meta.total;
          const polygonTotalCount = polygonData.meta.total;

          dispatch({
            type: types.SET_USER_ITEMS,
            payload: {
              nfts: [...onlyPendingItems, ...formattedEthereum, ...formattedPolygon],
              polygonPageKey,
              ethereumPageKey,
              total: onlyPendingItems.length + polygonTotalCount + ethereumTotalCount,
            },
          });
        }
      }

      return true;
    } catch (error) {
      console.warn('Error getting user items : ', error);
    }
  };
}

export function addUserItems({ filter, pageKey }) {
  return async dispatch => {
    const data = await getNextPageNftsByAddress({ type: filter, pageKey });
    const formatted = formatServerAssets(data.nfts, filter);
    const pageNextKey = data.meta.pageKey;
    dispatch({
      type: types.ADD_USER_ITEMS,
      payload: {
        nfts: formatted,
        [filter === 'ethereum' ? 'ethereumPageKey' : 'polygonPageKey']: pageNextKey,
      },
    });
  };
}

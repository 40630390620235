import { useState, useEffect } from 'react';
import { checkCoinBalance } from '../Services/api';

const useCheckCoinBalance = function (coin) {
  const _coin = coin || 'eth';
  const [balance, setBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    const getTokenInformation = async () => {
      setIsLoading(true);
      const coinBalance = await checkCoinBalance(_coin);
      setBalance(Number(coinBalance));
      setIsLoading(false);
    };

    getTokenInformation();
  }, [_coin]);

  return { balance, isLoading };
};

export default useCheckCoinBalance;

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Menu } from 'Components/Menu';
import { useTheme } from '@material-ui/core/styles';
import config from 'Config/firebase';
const bs58 = require('bs58');

export const MoreButton = ({ contractAddress, tokenId, blockchain, itemId }) => {
  const theme = useTheme();
  let _tokenId = tokenId;
  let _blockchain = blockchain || 'ethereum';
  let _openseaUrl = _blockchain === 'ethereum' ? config.openseaURL : config.openseaMaticUrl;

  // OpenSea and raribe use the decimal format and Ownerfy uses b58
  if (itemId) {
    const bytes = bs58.decode(_tokenId);
    const b58Hex = bytes.toString('hex');
    _tokenId = parseInt(b58Hex, 16);
  }
  return (
    <Menu
      menuItems={[
        // {
        //   iconUrl: '/img/rarible-logo.svg',
        //   iconAlt: 'Rarible Logo',
        //   text: 'View on Rarible',
        //   key: 1,
        //   href: `${config.raribleURL}token/${contractAddress}:${_tokenId}`,
        // },
        {
          iconUrl: '/img/opensea-logo.svg',
          iconAlt: 'OpenSea Logo',
          key: 2,
          text: 'View on OpenSea',
          href: `${_openseaUrl}/${contractAddress}/${_tokenId}`,
        },
      ]}
    >
      <img
        src="/img/more.png"
        alt="more button"
        css={css`
          width: 20px;
          margin-left: 10px;
          ${theme.breakpoints.up('sm')} {
            width: 27px;
          }
        `}
      />
    </Menu>
  );
};

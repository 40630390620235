import * as types from './types';
import config from 'Config/firebase';

export function getItem(id) {
  const data = { itemId: id };
  return (dispatch) => {
    dispatch({
      type: types.SET_ITEM_LOADING,
      payload: true,
    });
    fetch(`${config.functionsURL}/getItem`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((item) => {
        const host = window.location.host;
        // Fix for any production tags that go to dev
        if (!item && host.includes('dev')) {
          window.location.replace(`https://ownerfy.com/i/${id}`);
        }
        dispatch({
          type: types.SET_ITEM_DATA,
          payload: item,
        });
        dispatch({
          type: types.SET_ITEM_LOADING,
          payload: false,
        });
      })
      .catch((error) => {
        console.warn('Error getting product : ', error);
        dispatch({
          type: types.RESET_ITEM_DATA,
        });
        dispatch({
          type: types.SET_ITEM_LOADING,
          payload: false,
        });
      });
  };
}

export function resetProduct(id) {
  return (dispatch) => {
    return dispatch({
      type: types.RESET_ITEM_DATA,
      payload: null,
    });
  };
}

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import config from 'Config/firebase';

export const NameAndAddress = ({ name, address, blockchain }) => {
  const getShortAddress = address => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };
  let scanAddress = config.etherscanURL;
  if (blockchain && blockchain.toLowerCase() === 'polygon') scanAddress = config.polyscanAddress;
  return (
    <div>
      {name ? name : ''}{' '}
      <a target="_blank" rel="noopener noreferrer" href={`${scanAddress}address/${address}`}>
        {address && getShortAddress(address)}
      </a>
    </div>
  );
};

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
export const MainContent = ({ animationUrl, imageSrc, className }) => {
  let _animationUrl = animationUrl;
  if (imageSrc && imageSrc.substr(imageSrc.lastIndexOf('.') + 1, 3) === 'mp4') _animationUrl = imageSrc;
  return (
    <div
      className={className}
      css={css`
        & > * {
          max-width: 100%;
          max-height: 700px;
          outline: none;
        }
      `}
    >
      {_animationUrl ? (
        <video id="token-video" controls autoPlay loop muted>
          <source src={_animationUrl} type="video/mp4" />
          Your browser does not support playing videos.
        </video>
      ) : (
        <img src={imageSrc} alt="Token" />
      )}
    </div>
  );
};

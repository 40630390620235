import React from 'react';
import Container from '@material-ui/core/Container';
import * as actions from 'Store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PageLayout from 'Layout/Layout';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import SignUpV3 from 'Pages/SignUp-v3';

const styles = theme => ({
  container: {
    marginTop: 30,
  },
  button: {
    color: '#fff',
    display: 'block',
    textAlign: 'center',
    width: 150,
  },
  link: {
    marginTop: 20,
  },
});

function Signup({ classes }) {
  return (
    <PageLayout hasContainer={false}>
      <Container className={classes.container} component="main" maxWidth="md">
        <SignUpV3 title="Sign Up" embedded={true} />
      </Container>
    </PageLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Signup)));

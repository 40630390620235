import React from 'react';
import PageLayout from 'Layout/Layout';
import History from './History';
import SectionLayout from 'Layout/SectionLayout';
const HistoryUpdate = () => {
  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <History />
      </SectionLayout>
    </PageLayout>
  );
};

export default HistoryUpdate;

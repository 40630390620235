import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Page from 'Components/Page';
import * as actions from 'Store/actions';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import config from 'Config/firebase';
// import * as Regex from '../regex';
import LostItemDetails from 'Components/LostItemDetails';
import NumberFormat from 'react-number-format';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CheckIcon from '@material-ui/icons/Check';
import Loading from 'Components/Loading';
import history from '../history';
import FormContainer from 'Components/FormInputs/FormContainer';
import { Grid, TextField as MUITextField } from '@material-ui/core';
import TextField from 'Components/FormInputs/TextField';
import * as Yup from 'yup';

const pageStyles = theme => ({
  pageWrapper: {
    padding: '5%',
  },
  pageHeader: {
    marginBottom: '55px',
  },
  itemContainer: {
    marginTop: '40px',
  },
  textArea: {
    fontSize: 20,
  },
  contactButton: {
    marginTop: 70,
  },
  exitButtons: {
    marginTop: '40px',
  },
  submitButton: {
    marginTop: 40,
    marginLeft: 0,
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '90px',
  },
  itemName: {
    marginBottom: 20,
  },
  itemNameLink: {
    marginBottom: 20,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  itemOverview: {
    marginBottom: 70,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  itemMainInformation: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '50px',
    },
  },
  itemMessage: {
    marginBottom: 0,
  },
  contactHeader: {
    marginBottom: '15px',
  },
  phoneNumber: {
    fontSize: '14px',
    lineHeight: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '0.5px',
  },
  itemImage: {
    maxHeight: '300px',
    maxWidth: '300px',
    cursor: 'pointer',
  },
  imageAndCodeContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  successMessage: {
    marginTop: '50px',
  },
});

function Item({ classes, getItem, item, id, setActiveModal }) {
  const [showContact, setShowContact] = useState(true);
  const [wasMailSent, setWasMailSent] = useState(false);
  if (item.claimed === false) {
    history.push(`/claim/${item.itemId}`);
  }

  useEffect(() => {
    getItem(id);
  }, [getItem, id]);

  console.log('item is:: ', item);

  //console.log('item', item);
  //When there is no image
  const image = (item.images && item.images[0]?.full) || '';

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').nullable().required('Email is required').typeError('Invalid Email'),
    phone: Yup.string().nullable(),
    message: Yup.string().nullable().required('Message is required').min(10, 'Please leave a more detailed message'),
  });

  const initialValues = {
    email: '',
    phone: '',
    message: '',
  };

  const onSubmit = async ({ email, phone, message }) => {
    const _data = {
      email,
      message,
      phone,
      item,
      ownerId: item.ownerId,
    };

    if (item.phoneActive) {
      try {
        fetch(`${config.functionsURL}/sendText`, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(_data),
        });
      } catch (err) {
        console.warn('Contact text server error: ', err);
        alert('Sending text error');
        return;
      }
    }

    try {
      fetch(`${config.functionsURL}/contactOwner`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(_data),
      });
    } catch (err) {
      console.warn('Contact server error: ', err);
      alert('Sending email error');
      return;
    }

    setWasMailSent(true);
  };

  if (item.loading) {
    return (
      <Loading
        type={'bubbles'}
        color={'#2bc8c5'}
        height={'80px'}
        width={'80px'}
        containerHeight={'70vh'}
        containerWidth={'100%'}
        message={'Loading item...'}
      />
    );
  }

  if (!item.itemId) {
    return (
      <Page wrapperStyles={classes.pageWrapper}>
        <h2>Item ID not found. Please check your Item ID. It's CaSe sensitive.</h2>
        <p>
          <Button
            variant="contained"
            color="primary"
            className={classes.exitButtons}
            onClick={() => setActiveModal('foundItem')}
          >
            Enter a Code
          </Button>
        </p>
        <p>
          <Button
            component={Link}
            className={classes.exitButtons}
            to="/item-review"
            variant="contained"
            color="primary"
          >
            Make a tag!
          </Button>
        </p>
      </Page>
    );
  }

  return (
    <Page wrapperStyles={classes.pageWrapper}>
      <div key={item.itemId} className={classes.itemContainer}>
        <LostItemDetails
          name={item.name}
          itemId={item.itemId}
          message={item.message}
          phone={item.phone}
          imageUrl={image}
          secondaryHeaderContent={<></>}
          isQrVisible={false}
          typeId={item.typeId}
          additionalMainContent={
            <>
              {!showContact ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.contactButton}
                  onClick={() => setShowContact(true)}
                >
                  Contact Owner
                </Button>
              ) : (
                <>
                  {!wasMailSent && (
                    <FormContainer
                      validation={validationSchema}
                      defaultValues={initialValues}
                      mode="onChange"
                      onSuccess={onSubmit}
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Typography variant="body1" className={classes.itemMessage}>
                            Please fill out the form below to contact the owner.
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="email"
                            label="Email to Reach You"
                            variant="outlined"
                            placeholder="Enter Email"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <NumberFormat
                            customInput={MUITextField}
                            format="###-###-####"
                            mask="_"
                            fullWidth
                            label="Phone Number"
                            name="phone"
                            placeholder="Enter Phone Number"
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            multiline
                            label="Message"
                            name="message"
                            variant="outlined"
                            placeholder="Enter Message"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Button type="submit" variant="contained" color="primary">
                            Send
                          </Button>
                        </Grid>
                      </Grid>
                    </FormContainer>
                  )}

                  <Collapse in={wasMailSent}>
                    <Alert
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                      onClose={() => setWasMailSent(false)}
                      className={classes.successMessage}
                    >
                      Your message has been sent. Thank you!
                    </Alert>
                  </Collapse>
                </>
              )}
            </>
          }
        />
      </div>
    </Page>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItem: actions.getItem,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

function mapStateToProps({ item }, ownProps) {
  return {
    id: ownProps.match.params.id,
    item,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles)(Item)));

import React from 'react';
import Container from 'Components/Container';
import { useStyles } from './styles.js';
import Grid from './assets/Grid.svg';
const NftTraits = () => {
  const classes = useStyles();
  return (
    <div className={`${classes.nftCardContainer} ${classes.contentWrapper}`}>
      <Container>
        <img src={Grid} alt="Not found" />
        <h1 className={classes.nftCardTitle}>NFT Traits</h1>
        <p className={classes.nftCardDesc}>
          Huge variety of randomly distributed rare and super rare NFT TRAITS - There are over 100 individual traits
          with millions of possible combinations. Great care has been taken to make sure some of the combinations are
          much more exclusive and some will appear more frequently. Everyone who mints has an equal chance of getting a
          combination of very rare or more common traits. Some traits will appear less than 1% of the time!
        </p>
      </Container>
    </div>
  );
};

export default NftTraits;

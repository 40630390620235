import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import { closeActiveModal } from 'Store/actions';
import ModalWrap from 'Shared/Modal';
import FoundItem from 'Components/ModalContent/FoundItem';
import SellNft from 'Components/ModalContent/SellNft';
import TransferNft from 'Components/ModalContent/TransferNft';
import LayEggs from 'Components/ModalContent/LayEggs';
import Community from 'Components/ModalContent/Community';
import SendEthereum from 'Components/ModalContent/SendEthereum';
import LoginV2 from 'Pages/Login-v2';
import SignUpV3 from 'Pages/SignUp-v3';
import ResetPassword from 'Pages/ResetPassword';
import ShowAddress from 'Components/ModalContent/ShowAddress';

const Modal = ({ fullScreen }) => {
  const dispatch = useDispatch();
  const activeModal = useSelector(state => state.activeModal);

  const getModalContents = () => {
    switch (activeModal.name) {
      case 'signup':
        return <SignUpV3 {...activeModal.props} title="Sign up" />;
      case 'login':
        return <LoginV2 {...activeModal.props} title="Login" />;
      case 'resetPassword':
        return <ResetPassword onClose={onModalClose} {...activeModal.props} />;
      case 'showAddress':
        return <ShowAddress onClose={onModalClose} {...activeModal.props} />;
      case 'foundItem':
        return <FoundItem {...activeModal.props} />;
      case 'sellNft':
        return <SellNft {...activeModal.props} />;
      case 'transferNft':
        return <TransferNft {...activeModal.props} />;
      case 'sendEthereum':
        return <SendEthereum {...activeModal.props} />;
      case 'layEggs':
        return <LayEggs {...activeModal.props} />;
      case 'community':
        return <Community {...activeModal.props} />;
      default:
        break;
    }
  };

  const onModalClose = () => {
    dispatch(closeActiveModal());
  };

  return (
    <ModalWrap open={activeModal.name !== 'none'} onClose={onModalClose}>
      {getModalContents()}
    </ModalWrap>
  );
};

export default withMobileDialog()(Modal);

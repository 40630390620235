import React from 'react';
import PageLayout from 'Layout/Layout';
import BuyNftSuccess from './BuyNftSuccess';
const BuyNFtSuccessPage = () => {
  return (
    <PageLayout hasContainer={false}>
      <BuyNftSuccess />
    </PageLayout>
  );
};

export default BuyNFtSuccessPage;

import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import ZombieImg1 from '../assets/zombie-1.png';
import ZombieImg2 from '../assets/zombie-2.png';
import ZombieImg3 from '../assets/zombie-3.png';
import ZombieImg4 from '../assets/zombie-4.png';
import ZombieImg5 from '../assets/zombie-5.png';
import useStyles from './style';

const ZombiesSection = () => {
  const classes = useStyles();

  return (
    <Box px={2} className={classes.zombieWrapper}>
      <Container>
        <Grid container className={classes.zombieGrid} spacing={2} display="flex" justifyContent="center">
          <Grid item xs={12} md="auto">
            <img src={ZombieImg1} alt="Zombie" width="100%" />
          </Grid>
          <Grid item xs={12} md="auto">
            <img src={ZombieImg2} alt="Zombie" width="100%" />
          </Grid>
          <Grid item xs={12} md="auto">
            <img src={ZombieImg3} alt="Zombie" width="100%" />
          </Grid>
          <Grid item xs={12} md="auto">
            <img src={ZombieImg4} alt="Zombie" width="100%" />
          </Grid>
          <Grid item xs={12} md="auto">
            <img src={ZombieImg5} alt="Zombie" width="100%" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ZombiesSection;

import React from 'react';
import { useStyles } from './styles.js';
import { Link } from 'react-router-dom';
import Container from 'Components/Container';
import Baby from './assets/baby.svg';
import PCHLogo from './assets/PCHLogo.svg';
const ProductionSection = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.ProductionContainer} ${classes.contentWrapper} `}>
      <Container>
        <div className={`${classes.ProductionContainer}`}>
          <div className={classes.flexBasis50}>
            <img src={PCHLogo} alt="PCH Logo" className={classes.PCHLogo} />
            <h3 className={classes.productionTitle}>We are donating 5% to the children's hospital.</h3>
            <p className={classes.productionDesc}>
              At Phoenix Children’s, we see a future in which we make premier pediatric care accessible to every child
              who needs it—and we need your help to get there. It costs nearly $2.7 million per day to operate Phoenix
              Children’s.
            </p>
            <Link to="/" className={classes.productionLink}>
              Learn more about hospital
            </Link>
          </div>
          <div className={`${classes.flexBasis50} ${classes.productionImgContainer}`}>
            <img src={Baby} alt="Not found" className={classes.babyImg} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ProductionSection;

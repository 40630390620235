// Modal for showing the user's ethereum address

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import ModalContent from '../ModalContent';
import { withStyles } from '@material-ui/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Snackbar } from '@mui/material';

const styles = theme => ({
  mainContainer: {
    marginBottom: 20,
    textAlign: 'center',
  },
  qrCode: {
    marginTop: 20,
  },
});

function ShowAddress({ classes }) {
  const user = useSelector(state => state.user);
  const [copyOpen, setCopyOpen] = useState(false);

  const handleCopy = () => {
    setCopyOpen(true);
    navigator.clipboard.writeText(user?.ethereumAddress || user?.wallets[0].pub);
  };

  return (
    <ModalContent
      title="Receive"
      formContents={
        <div className={classes.mainContainer}>
          Receive Ethereum or Polygon digital assets (MSTs / NFTs) to this address
          <br />
          {user?.ethereumAddress || user?.wallets[0].pub}
          <IconButton onClick={handleCopy} color="primary">
            <ContentCopyIcon />
          </IconButton>
          <Snackbar
            message="Copied to clibboard"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={2000}
            onClose={() => handleCopy(false)}
            open={copyOpen}
          />
          <br />
          <QRCode value={user?.ethereumAddress || user?.wallets[0].pub} id="QRCode" className={classes.qrCode} />
        </div>
      }
    />
  );
}

export default withStyles(styles)(ShowAddress);

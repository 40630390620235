import React from 'react';
import { Grid } from '@material-ui/core';
import Container from 'Components/Container';
import Logo from './assets/logo.svg';
import TwitterIcon from '@material-ui/icons/Twitter';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Link } from 'react-router-dom';
import { useStyles } from './style';

const Footer = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <footer className={classes.footerContainer}>
      <Container>
        <Grid container alignItems="flex-start" className={classes.footer}>
          <Grid item xs={12} sm={6} lg={2}>
            <img src={Logo} alt="logo" />
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <ul className={classes.linkWrapper}>
              <li>
                <Link to="/privacy-policy" className={classes.link}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/tos" className={classes.link}>
                  TOS
                </Link>
              </li>
              <li>
                <Link to="/contact" className={classes.link}>
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/nft-standards" className={classes.link}>
                  NFT Standards
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <ul className={classes.linkWrapper}>
              <li>
                <Link to="/faq" className={classes.link}>
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/signup" className={classes.link}>
                  Sign up
                </Link>
              </li>
              <li>
                <Link to="/api-signup" className={classes.link}>
                  API
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    window.location.href = ' https://ownerfy.readme.io/';
                  }}
                  className={classes.link}
                >
                  Documentation
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} lg={2}>
            <ul className={classes.linkWrapper}>
              <li>
                <Link to="/my-collection" className={classes.link}>
                  My Collection
                </Link>
              </li>
              <li>
                <Link to="/settings" className={classes.link}>
                  Settings
                </Link>
              </li>
              <li>
                <Link to="/history" className={classes.link}>
                  History
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.iconsContainer}>
            <a href="https://twitter.com/ownerfy" target="new">
              <TwitterIcon style={{ fill: '#fff', marginRight: '3px' }} />
            </a>
            <a href="https://facebook.com/ownerfy.official" target="new">
              <FacebookIcon style={{ fill: '#fff', marginRight: '3px' }} />
            </a>
            {/* <YouTubeIcon style={{ fill: '#fff', marginRight: '3px' }} /> */}
            <a href="https://instagram.com/ownerfy.official" target="new">
              <InstagramIcon style={{ fill: '#fff', marginRight: '3px' }} />
            </a>
            <a href="https://www.linkedin.com/company/ownerfy/" target="new">
              <LinkedInIcon style={{ fill: '#fff', marginRight: '3px' }} />
            </a>
            {/* <MailOutlineIcon style={{ fill: '#fff', marginRight: '3px' }} /> */}
            <p className={classes.iconsHelperText}>Join the community of #ownerfy</p>
            <img
              onClick={() => window.logBadgeClick()}
              id="badge-button"
              style={{ width: 240, height: 53 }}
              src="https://static.alchemyapi.io/images/marketing/badge.png"
              alt="Alchemy Supercharged"
              className={classes.alchemyBadge}
            />
          </Grid>
        </Grid>
      </Container>
      <div className={classes.timeContainer}>
        <Container>
          <p className={classes.copyRightText}>Copyright © {currentYear} Ownerfy</p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useCallback } from 'react';
import { Box, Button } from '@material-ui/core';
import HeroImg from '../assets/hero.png';
import { getZombiesAvailableForFreeMint, generateValidationToken, getFreeZombieMintParams } from 'Services/api';
import { ReactComponent as MetamaskIcon } from '../assets/Metamask.svg';
import { ReactComponent as CreditCardIcon } from '../assets/CreditCard.svg';
import { BigNumber } from '@ethersproject/bignumber';
import { utils, ethers } from 'ethers';
import config from 'Config/firebase';
import zombieFriends from 'ABI/zombieFriends.json';
import useStyles from './style';

const HeroSection = () => {
  const classes = useStyles();
  const [MMAccounts, setMMAccounts] = useState(null);
  const [freeMints, setFreeMints] = useState([]);
  const [loadingMints, setLoadingMints] = useState(false);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [qty, setQty] = useState(0);

  const handleConnectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        if (window.ethereum.networkVersion !== config.ethNetworkCode) {
          alert('You are on the wrong network');
          throw new Error('You must be on Ethereum Main network');
        }
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const from = accounts[0];

        setLoadingMints(true);

        // Get NFTs available for this wallet
        let { nftList: nftsAvailable, qty } = await getZombiesAvailableForFreeMint({
          address: from,
        });

        // console.log('nfts available are: ', nftsAvailable);
        // const totalAvailable = nftsAvailable.reduce(
        //   (previousValue, currentValue) => previousValue + (currentValue.used ? 0 : 1),
        //   0,
        // );

        setTotalAvailable(qty);
        setQty(qty);
        setFreeMints(nftsAvailable);

        setMMAccounts(accounts);
        setLoadingMints(false);
        return accounts;
      } catch (err) {
        console.log(`err`, err);
        alert('connection failed');
      }
    } else {
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  const mint = async () => {
    let _accounts = MMAccounts ? MMAccounts[0] : [];
    try {
      _accounts = await handleConnectMetamask();
    } catch (err) {
      console.log('connect metamask error');
      return;
    }
    //return;
    if (_accounts && _accounts.length) {
      window.gtag('event', 'click_metamask', {
        event_category: 'ecommerce',
        event_label: 'metamask_click',
        value: parseInt(qty, 10),
      });

      let validationToken = await generateValidationToken({
        address: _accounts[0],
      });

      const string = `Ownerfy: ${_accounts[0].toLowerCase()} ${validationToken}`;

      const msg = `0x${Buffer.from(string, 'utf8').toString('hex')}`;
      const signature = await window.ethereum.request({
        method: 'personal_sign',
        params: [msg, _accounts[0]],
      });

      const { _nonce, _qty, _sig } = await getFreeZombieMintParams({
        address: _accounts[0],
        signature,
      });

      console.log('_nonce, _qty, _sig :::: ', _nonce, _qty, _sig, config.zombieContract);

      // const wlc_s = prompt('Enter whitelist code: ', 0);

      // const wlc = parseInt(wlc_s, 10);
      //77718888

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contract = new ethers.Contract(config.zombieContract, zombieFriends.abi, provider);

      const contractWithSigner = contract.connect(signer);

      const price = BigNumber.from('100000000000000000');
      const totalPrice = price.mul(qty);

      // if (qty > 6) {
      //   alert('Max 5 per wallet');
      //   return;
      // }

      try {
        let mintTx = await contractWithSigner.websiteMint(
          utils.hexlify(parseInt(_qty, 10)),
          utils.hexlify(parseInt(_nonce, 10)),
          _sig,
        );
      } catch (err) {
        console.warn('error:: ', err);
        alert(err.message);
      }
    } else {
      handleConnectMetamask();
      alert('Metamask was not connected. Please try again after metamask is connected.');
    }
  };

  return (
    <Box>
      <Box style={{ position: 'relative', overflow: 'hidden' }}>
        <Box className={classes.heroImgWrapper}>
          <img
            className={classes.heroImg}
            src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/collections%2Fzombiefriends%2Fjpzombies_hero.png?alt=media&token=fb5bb945-f862-44da-9993-f67dc6cabe58"
            alt="Zombie"
          />
        </Box>

        <Box px={4} className={classes.heroDescriptionWrapper}>
          <Box className={classes.heroDescription}>10,000 ZOMBIE FRIENDS</Box>

          <Box className={classes.heroCaptions}>
            <div>The first 4,000 will available for free mint </div>
            <div>
              to current{' '}
              <a style={{ textDecoration: 'none' }} href="/jpandfriends">
                J. Pierce &amp; Friends holders
              </a>
            </div>
            <div>
              Minting has started! There is no snapshot.
              <br /> Wallets are checked for JPFs in real time. <br />
              If you cancel a transaction just click mint again and <br />
              it will keep your mint quantity in the transaction.
              <br />
              for a list of unredeemed NFTs look{' '}
              <a target="new" href={`https://ownerfy.com/api/getUnredeemedZombies`}>
                here
              </a>
              .
            </div>
          </Box>
        </Box>
      </Box>

      <Box pb={10} className={classes.heroActionbtns}>
        <Button onClick={handleConnectMetamask} className={classes.checkBtn}>
          <span style={{ marginRight: 16 }}>{loadingMints ? 'Loading...' : 'Check Free Mints Available'}</span>
        </Button>

        {freeMints.map(nft => (
          <div key={nft.tokenId} className={classes.tokens}>
            <span className={classes.tokenId}>You own JPF token ID {nft.tokenId} and it has </span>
            <span className={classes.tokenUsed}>{nft.used ? 'been used already' : 'not been used'}.</span>
          </div>
        ))}

        {freeMints.length > 0 && (
          <span className={classes.tokens}>
            <br />
            You have {totalAvailable} available to mint. Once you click mint they will be marked as redeemed.
          </span>
        )}

        <Button onClick={mint} className={classes.metamaskBtn}>
          <input
            disabled={true}
            value={qty}
            onChange={e => setQty(e.target.value)}
            placeholder="Quantity"
            className={classes.input}
          />
          <span style={{ marginRight: 16 }}>{loadingMints ? 'Loading...' : 'Mint with metamask'}</span>
          <MetamaskIcon width={32} />
        </Button>

        {/* <Button className={classes.creditcardBtn}>
          <span style={{ marginRight: 16 }}>Mint With Credit Card</span>
          <CreditCardIcon width={32} />
        </Button> */}

        {/* <a target="os" href="https://opensea.io/collection/jpierceandfriendsnft">
          <img alt="opensea" src="/img/chicadees/opensea.svg" className={classes.footerLogo} />
        </a> */}
      </Box>
    </Box>
  );
};

export default HeroSection;

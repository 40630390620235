/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core';

function Container({ children, className, isCentered = true }) {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        justify-content: ${isCentered ? 'center' : 'flex-start'};
        align-items: ${isCentered ? 'center' : 'flex-start'};
        height: 100%;
        width: 100%;
        boxsizing: border-box;
      `}
    >
      <div
        className={className}
        css={css`
          width: 100%;
          max-width: 1535px;
          padding-left: 20px;
          padding-right: 20px;
          box-sizing: border-box;
          ${theme.breakpoints.up('sm')} {
            padding-left: 75px;
            padding-right: 75px;
            max-width: 1645px;
          }
          ${theme.breakpoints.up('md')} {
            max-width: 1795px;
            padding-left: 150px;
            padding-right: 150px;
          }
        `}
      >
        {children}
      </div>
    </div>
  );
}

export default Container;

import React from 'react';
import { scroller } from 'react-scroll';
import { Button } from '@material-ui/core';
import { useStyles } from './style';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const navigateTo = href => () => {
  window.location.href = href;
};

const DesktopMenuItemButton = ({ label, href, type, name, orgUrl }) => {
  const classes = useStyles();
  const history = useHistory();

  if (type === 'popup') {
    return (
      <Button
        color="inherit"
        to={'#'}
        component={RouterLink}
        className="customFont"
        onClick={() => {
          window.open(
            href,
            '_blank',
            'resizable=yes, scrollbars=no, titlebar=no, location=no, menubar=no, status=no, toolbar=no, width=550, height=440, top=10, left=10',
          );
        }}
      >
        <span className={classes.navLink}>{label}</span>
      </Button>
    );
  }

  if (type === 'scroll') {
    return (
      <Button
        color="inherit"
        className="customFont"
        onClick={() => {
          history.push(orgUrl);
          setTimeout(() => {
            scroller.scrollTo(name);
          }, 100);
        }}
      >
        <span className={classes.navLink}>{label}</span>
      </Button>
    );
  }

  if (type === 'external') {
    return (
      <Button color="inherit" onClick={navigateTo(href)} className="customFont">
        <span className={classes.navLink}>{label}</span>
      </Button>
    );
  }

  return (
    <Button color="inherit" to={href} component={RouterLink} className="customFont">
      <span className={classes.navLink}>{label}</span>
    </Button>
  );
};

export default DesktopMenuItemButton;

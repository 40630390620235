import React, { useState, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import { closeActiveModal } from 'Store/actions';
import { debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import FormTextField from 'Components/FormTextField';
import ModalContent from '../ModalContent';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { getConversionRate } from 'Helpers/currency';
import CircularProgress from '@material-ui/core/CircularProgress';
import history from '../../history';
import { getRaribleSellFee, setupChargeForRaribleSale, sellOnRaribleDirect } from 'Services/api';
import config from 'Config/firebase';

const currencies = {
  eth: {
    code: 'eth',
    name: 'Ethereum',
    oppositeCode: 'usd',
  },
  usd: {
    code: 'usd',
    name: 'US Dollars',
    oppositeCode: 'eth',
  },
};

const SellNft = ({ tokenId, contractAddress, tokenName, tokenImages, user }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      currency: currencies.eth.code,
    },
  });
  const [nftCurrency, setNftCurrency] = useState(currencies.eth.code);
  const [isGettingConversion, setIsGettingConversion] = useState(false);
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [isGettingRaribleSellFee, setIsGettingRaribleSellFee] = useState(false);
  const [raribleSellFee, setRaribleSellFee] = useState(null);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  let isContractApproved = false;

  if (user && user.raribleApprovedContracts) {
    isContractApproved = user.raribleApprovedContracts[contractAddress];
  }

  const amount = watch('amount');

  const getConvertedAmount = useCallback(async () => {
    const conversionRate = await getConversionRate({
      fromCurrency: nftCurrency,
      toCurrency: currencies[currencies[nftCurrency].oppositeCode].code,
    });

    const rawAmount = conversionRate * amount;
    const roundedUpToTwoDecimals = Math.ceil((rawAmount + Number.EPSILON) * 100) / 100;
    return nftCurrency === currencies.eth.code ? roundedUpToTwoDecimals : rawAmount;
  }, [amount, nftCurrency]);

  useEffect(() => {
    const queryForConvertedAmount = debounce(async () => {
      if (!nftCurrency || !amount) {
        setConvertedAmount(null);
        return;
      }
      setIsGettingConversion(true);
      setTimeout(async () => {
        setConvertedAmount(await getConvertedAmount());
        setIsGettingConversion(false);
      }, 500);
    }, 800);
    queryForConvertedAmount();
  }, [nftCurrency, amount, getConvertedAmount]);

  useEffect(() => {
    const queryForRaribleFee = async () => {
      setIsGettingRaribleSellFee(true);
      const data = await getRaribleSellFee();
      setRaribleSellFee(data);
      setIsGettingRaribleSellFee(false);
    };
    queryForRaribleFee();
  }, []);

  const sellAction = async tokenInfo => {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);
    setSubmitLoading(true);

    if (isContractApproved) {
      try {
        const sellRaribleResult = await sellOnRaribleDirect({
          itemId: tokenId,
          contractAddress,
          ethAmt: nftCurrency === currencies.usd.code ? convertedAmount : amount,
        });

        history.push(`/sellSuccess/${contractAddress}/${tokenId}`);
      } catch (error) {
        alert(`Server error`, error);
      }
    } else {
      try {
        const stripeCheckoutSessionId = await setupChargeForRaribleSale({
          tokenId,
          contractAddress,
          amount: nftCurrency === currencies.usd.code ? convertedAmount : amount,
          cancelUrl: window.location.href,
          tokenName,
          tokenImages,
        });

        const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
        if (result.error) {
          alert(result.error.message);
        }
      } catch (error) {
        alert(`Stripe checkout error`, error);
      }
    }
    dispatch(closeActiveModal());
  };

  const regexNumberWithOneDecimalAndUnlimitedCharactersAfterDecimal = /^\d+?(\.\d+)?$/;
  const regexNumberWithOneDecimalAndTwoCharactersAfterDecimal = /^\d+(\.\d{2})?$/;

  return (
    <ModalContent
      title="Sell Nft"
      buttonText="Sell on Rarible"
      onFormSubmit={handleSubmit(sellAction)}
      isButtonDisabled={!amount || !convertedAmount || !raribleSellFee || isSubmitLoading}
      formContents={
        <>
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            <FormControl
              css={css`
                flex: 1;
              `}
            >
              <InputLabel error={!!errors.assetType}>Currency</InputLabel>
              <Controller
                control={control}
                rules={{ required: 'Currency is required' }}
                name="currency"
                onChange={([event]) => {
                  setNftCurrency(event.target.value);
                  return event;
                }}
                as={
                  <Select>
                    <MenuItem value={currencies.eth.code}>{currencies.eth.name}</MenuItem>
                    <MenuItem value={currencies.usd.code}>{currencies.usd.name}</MenuItem>
                  </Select>
                }
              />
              {errors.assetType && <FormHelperText error>{errors.assetType.message}</FormHelperText>}
            </FormControl>
            <FormTextField
              css={css`
                flex: 1;
              `}
              shrink
              fullWidth
              label="Amount"
              name="amount"
              required
              error={!!errors.amount}
              helperText={errors.amount && errors.amount.message}
              inputRef={register({
                required: 'Amount is required',
                validate: value => {
                  if (
                    nftCurrency === currencies.eth.code &&
                    !regexNumberWithOneDecimalAndUnlimitedCharactersAfterDecimal.test(value)
                  ) {
                    return 'The amount must be a number';
                  }

                  if (
                    nftCurrency === currencies.usd.code &&
                    !regexNumberWithOneDecimalAndTwoCharactersAfterDecimal.test(value)
                  ) {
                    return 'The amount must be a USD currency amount';
                  }

                  return true;
                },
              })}
              placeholder="Enter price amount"
            />
          </div>
          <div>
            <p
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              {currencies[currencies[nftCurrency].oppositeCode].name} amount:{' '}
              {isGettingConversion ? (
                <CircularProgress
                  size={18}
                  css={css`
                    margin-left: 8px;
                  `}
                />
              ) : amount && convertedAmount ? (
                <>
                  {nftCurrency === currencies.usd.code ? '⟠' : '$'}
                  {convertedAmount}
                </>
              ) : (
                `Please add amount in ${currencies[nftCurrency].name}`
              )}
            </p>
            {!isContractApproved && (
              <p
                css={css`
                  display: flex;
                  align-items: center;
                `}
              >
                One Time Rarible Sell fee:{' '}
                {isGettingRaribleSellFee ? (
                  <CircularProgress
                    size={18}
                    css={css`
                      margin-left: 8px;
                    `}
                  />
                ) : (
                  <>${raribleSellFee / 100}</>
                )}
              </p>
            )}
          </div>
        </>
      }
      IconComponent={AttachMoneyIcon}
    />
  );
}

export default withRouter(SellNft);

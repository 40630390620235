import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  pageWrapper: {
    position: 'relative',
  },
  loading: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#DDF4FF',
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: 'blur(50px)',
    borderRadius: '20px',
    boxSizing: 'border-box',
    '@media (max-width: 992px)': {
      flexWrap: 'wrap',
    },
    // padding: '60px',
  },
  flex48: {
    flexBasis: '48%',
    '@media (max-width: 992px)': {
      flexBasis: '100%',
    },
  },
  apiKeyContainer: {
    padding: '60px',
  },
  settingImg: {
    width: '100%',
  },
  apiKeyHeading: {
    fontFamily: 'Lato',
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#323441',
    textAlign: 'center',
  },
  apiDesc: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 'normal',
    color: '#748896',
    textAlign: 'center',
    marginBottom: '32px',
  },
  formContainer: {
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '60px',
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  termsText: {
    color: ' #748896',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontSize: '14px',
  },
  btnWrapper: {
    marginTop: '48px',
  },
  title: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#323441',
  },
}));

export { useStyles };

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import * as actions from 'Store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';

const styles = theme => ({
  header: {
    marginTop: 30,
  },
  selectBox: {
    marginTop: 30,
  },
  textArea: {
    fontSize: 20,
  },
  submit: {
    margin: '20px 20px 20px 0',
  },
  title: {
    fontWeight: 'bold',
  },
});

function PrivacyPolicy({ setActiveModal, classes }) {
  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container component="main" maxWidth="sm">
          <Typography component="h1" variant="h5" className={classes.header}>
            Privacy Policy
          </Typography>

          <p>
            <span>
              Protecting your private information is our priority. This Statement of Privacy applies to Ownerfy.com and
              Ownerfy Inc and governs data collection and usage. For the purposes of this Privacy Policy, unless
              otherwise noted, all references to Ownerfy Inc include Ownerfy.com and Ownerfy. The Ownerfy website is a
              ecommerce and link site. By using the Ownerfy website, you consent to the data practices described in this
              statement.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Collection of your Personal Information</span>
          </p>
          <p>
            <span>
              In order to better provide you with products and services offered on our Site, Ownerfy may collect
              personally identifiable information, such as your:{' '}
            </span>
          </p>

          <p>
            <span>&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First and Last Name </span>
          </p>
          <p>
            <span>&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mailing Address </span>
          </p>
          <p>
            <span>&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E-mail Address </span>
          </p>
          <p>
            <span>&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone Number </span>
          </p>

          <p>
            <span>
              If you purchase Ownerfy&#39;s products and services, we collect billing and credit card information. This
              information is used to complete the purchase transaction.{' '}
            </span>
          </p>

          <p>
            <span>
              Please keep in mind that if you directly disclose personally identifiable information or personally
              sensitive data through Ownerfy&#39;s public message boards, this information may be collected and used by
              others.{' '}
            </span>
          </p>

          <p>
            <span>
              We do not collect any personal information about you unless you voluntarily provide it to us. However, you
              may be required to provide certain personal information to us when you elect to use certain products or
              services available on the Site. These may include: (a) registering for an account on our Site; (b)
              entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special
              offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or
              other payment information when ordering and purchasing products and services on our Site. To wit, we will
              use your information for, but not limited to, communicating with you in relation to services and/or
              products you have requested from us. We also may gather additional personal or non-personal information in
              the future.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Use of your Personal Information </span>
          </p>
          <p>
            <span>
              Ownerfy collects and uses your personal information to operate its website(s) and deliver the services you
              have requested such as sharing photographs and descriptions of photographs.{' '}
            </span>
          </p>

          <p>
            <span>
              Ownerfy may also use your personally identifiable information to inform you of other products or services
              available from Ownerfy and its affiliates.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Sharing Information with Third Parties</span>
          </p>
          <p>
            <span>Ownerfy does not sell, rent or lease its customer lists to third parties. </span>
          </p>

          <p>
            <span>
              Ownerfy may share data with trusted partners to help perform statistical analysis, send you email or
              postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited
              from using your personal information except to provide these services to Ownerfy, and they are required to
              maintain the confidentiality of your information.{' '}
            </span>
          </p>

          <p>
            <span>
              Ownerfy may disclose your personal information, without notice, if required to do so by law or in the good
              faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal
              process served on Ownerfy or the site; (b) protect and defend the rights or property of Ownerfy; and/or
              (c) act under exigent circumstances to protect the personal safety of users of Ownerfy, or the public.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Tracking User Behavior</span>
          </p>
          <p>
            <span>
              Ownerfy may keep track of the websites and pages our users visit within Ownerfy, in order to determine
              what Ownerfy services are the most popular. This data is used to deliver customized content and
              advertising within Ownerfy to customers whose behavior indicates that they are interested in a particular
              subject area.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Automatically Collected Information</span>
          </p>
          <p>
            <span>
              Information about your computer hardware and software may be automatically collected by Ownerfy. This
              information can include: your IP address, browser type, domain names, access times and referring website
              addresses. This information is used for the operation of the service, to maintain quality of the service,
              and to provide general statistics regarding use of the Ownerfy website.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Use of Cookies</span>
          </p>
          <p>
            <span>
              The Ownerfy website may use &quot;cookies&quot; to help you personalize your online experience. A cookie
              is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read
              by a web server in the domain that issued the cookie to you.{' '}
            </span>
          </p>

          <p>
            <span>
              One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose
              of a cookie is to tell the Web server that you have returned to a specific page. For example, if you
              personalize Ownerfy pages, or register with Ownerfy site or services, a cookie helps Ownerfy to recall
              your specific information on subsequent visits. This simplifies the process of recording your personal
              information, such as billing addresses, shipping addresses, and so on. When you return to the same Ownerfy
              website, the information you previously provided can be retrieved, so you can easily use the Ownerfy
              features that you customized.{' '}
            </span>
          </p>

          <p>
            <span>
              You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you
              can usually modify your browser setting to decline cookies if you prefer. If you choose to decline
              cookies, you may not be able to fully experience the interactive features of the Ownerfy services or
              websites you visit.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Links</span>
          </p>
          <p>
            <span>
              This website contains links to other sites. Please be aware that we are not responsible for the content or
              privacy practices of such other sites. We encourage our users to be aware when they leave our site and to
              read the privacy statements of any other site that collects personally identifiable information.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Security of your Personal Information</span>
          </p>
          <p>
            <span>
              Ownerfy secures your personal information from unauthorized access, use, or disclosure. Ownerfy uses the
              following methods for this purpose:{' '}
            </span>
          </p>

          <p>
            <span>&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SSL Protocol </span>
          </p>

          <p>
            <span>
              When personal information (such as a credit card number) is transmitted to other websites, it is protected
              through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.{' '}
            </span>
          </p>

          <p>
            <span>
              We strive to take appropriate security measures to protect against unauthorized access to or alteration of
              your personal information. Unfortunately, no data transmission over the Internet or any wireless network
              can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information,
              you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are
              beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged
              between you and us through this Site cannot be guaranteed.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Data Deletion</span>
          </p>

          <p>
            Deletion by Customer. Ownerfy will enable Customer to delete Customer Data during the Term in a manner
            consistent with the functionality of the Services. Please contact support@ownerfy.com with subject "Delete
            Data". If Customer uses the Services to delete any Customer Data during the Term and that Customer Data
            cannot be recovered by Customer, this use will constitute an Instruction to Ownerfy to delete the relevant
            Customer Data from Ownerfy’s systems in accordance with applicable law. Ownerfy will comply with this
            Instruction as soon as reasonably practicable and within a maximum period of 180 days, unless European Law
            requires storage.
          </p>

          <p>
            Return or Deletion at the end of the Term. If Customer wishes to retain any Customer Data after the end of
            the Term, it may instruct Ownerfy in accordance with Section 9.1 (Access; Rectification; Restricted
            Processing; Portability) to return that data during the Term. Customer instructs Ownerfy to delete all
            remaining Customer Data (including existing copies) from Ownerfy’s systems at the end of the Term in
            accordance with applicable law. After a recovery period of up to 30 days from that date, Ownerfy will comply
            with this Instruction as soon as reasonably practicable and within a maximum period of 180 days, unless
            European Law requires storage.
          </p>

          <p>
            <span className={classes.title}>Data Processing</span>
          </p>

          <p>
            Processor and Controller Responsibilities. If European Data Protection Law applies to the processing of
            Customer Personal Data:
          </p>

          <p>a. the subject matter and details of the processing are described in Appendix 1;</p>

          <p>b. Ownerfy is a processor of that Customer Personal Data under European Data Protection Law;</p>

          <p>
            c. Customer is a controller or processor, as applicable, of that Customer Personal Data under European Data
            Protection Law; and
          </p>

          <p>
            d. each party will comply with the obligations applicable to it under European Data Protection Law with
            respect to the processing of that Customer Personal Data.
          </p>

          <p>
            Processor Customers. If European Data Protection Law applies to the processing of Customer Personal Data and
            Customer is a processor:{' '}
          </p>

          <p>
            a. Customer warrants on an ongoing basis that the relevant controller has authorized: (i) the Instructions,
            (ii) Customer’s appointment of Ownerfy as another processor, and (iii) Ownerfy’s engagement of Subprocessors
            as described in Section 11 (Subprocessors);
          </p>

          <p>
            b. Customer will immediately forward to the relevant controller any notice provided by Ownerfy under
            Sections 5.2.3 (Instruction Notifications), 7.2.1 (Incident Notification), 9.2.1 (Responsibility for
            Requests), 11.4 (Opportunity to Object to Subprocessor Changes) or that refers to any SCCs; and
          </p>

          <p>c. Customer may:</p>

          <p>
            i. request access for the relevant controller to the SOC Reports in accordance with Section 7.5.3(a); and
          </p>

          <p>
            ii. make available to the relevant controller any other information made available by Ownerfy under Sections
            10.5 (Supplementary Measures and Information), 10.7 (Data Center Information) and 11.2 (Information about
            Subprocessors).
          </p>

          <p>
            Responsibilities under Non-European Law. If Non-European Data Protection Law applies to either party’s
            processing of Customer Personal Data, the relevant party will comply with any obligations applicable to it
            under that law with respect to the processing of that Customer Personal Data.
          </p>

          <p>Scope of Processing.</p>

          <p>
            Customer’s Instructions. Customer instructs Ownerfy to process Customer Personal Data only in accordance
            with applicable law: (a) to provide, secure, and monitor the Services and TSS; (b) as further specified via
            Customer’s use of the Services (including the Admin Console and other functionality of the Services) and
            TSS; (c) as documented in the form of the Agreement (including these Terms); and (d) as further documented
            in any other written instructions given by Customer and acknowledged by Ownerfy as constituting instructions
            for purposes of these Terms (collectively, the “Instructions”).
          </p>

          <p>
            Ownerfy’s Compliance with Instructions. Ownerfy will comply with the Instructions unless prohibited by
            European Law.
          </p>

          <p>
            Instruction Notifications. Ownerfy will immediately notify Customer if, in Ownerfy’s opinion: (a) European
            Law prohibits Ownerfy from complying with an Instruction; (b) an Instruction does not comply with European
            Data Protection Law; or (c) Ownerfy is otherwise unable to comply with an Instruction, in each case unless
            such notice is prohibited by European Law. This Section does not reduce either party’s rights and
            obligations elsewhere in the Agreement.{' '}
          </p>

          <p>
            <span className={classes.title}>Children Under Thirteen</span>
          </p>
          <p>
            <span>
              Ownerfy does not knowingly collect personally identifiable information from children under the age of
              thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use
              this website.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>E-mail Communications</span>
          </p>
          <p>
            <span>
              From time to time, Ownerfy may contact you via email for the purpose of providing announcements,
              promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to
              improve our Services, we may receive a notification when you open an email from Ownerfy or click on a link
              therein.{' '}
            </span>
          </p>

          <p>
            <span>
              If you would like to stop receiving marketing or promotional communications via email from Ownerfy, you
              may opt out of such communications by clicking the unsubscribe link at the bottom of the email.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>External Data Storage Sites</span>
          </p>
          <p>
            <span>
              We may store your data on servers provided by third party hosting vendors with whom we have contracted.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Changes to this Statement</span>
          </p>
          <p>
            <span>
              Ownerfy reserves the right to change this Privacy Policy from time to time. We will notify you about
              significant changes in the way we treat personal information by sending a notice to the primary email
              address specified in your account, by placing a prominent notice on our site, and/or by updating any
              privacy information on this page. Your continued use of the Site and/or Services available through this
              Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and
              (b) agreement to abide and be bound by that Policy.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Contact Information</span>
          </p>
          <p>
            <span>
              Ownerfy welcomes your questions or comments regarding this Statement of Privacy. If you believe that
              Ownerfy has not adhered to this Statement, please contact Ownerfy at:{' '}
            </span>
          </p>

          <p>
            <span>Ownerfy Inc </span>
          </p>
          <p>
            <span>2633 Lincoln Blvd. Suite 710 </span>
          </p>
          <p>
            <span>Santa Monica, California 90405 </span>
          </p>

          <p>
            <span>Email Address: </span>
          </p>
          <p>
            <span>support@ownerfy.com </span>
          </p>

          <p>
            <span>Effective as of February 14, 2020 </span>
          </p>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: actions.submitLoginWithFireBase,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(PrivacyPolicy)));

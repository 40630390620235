import React, { useMemo, useState } from 'react';
import { Avatar, Button, Box, Divider, Grid } from '@material-ui/core';
import Container from 'Components/Container';
import Highlight from 'react-highlight';
import { useStyles } from './style';
import 'highlight.js/styles/vs2015.css';

const OPTIONS = [
  'No crypto or blockchain development experience necessary',
  'Create custodial user wallets. Mint to them. Transfer between them.',
  'No need to purchase crypto currency',
  'Create smart contracts from user wallets for proper attribution',
  'Create NFTs on Polygon, the compatible and cost effective blockchain, supported by OpenSea, Meta, Reddit, Instagram and many more.',
];

const mintNftCode = `curl --location --request POST 'https://ownerfy.com/api/v1/nft/create'
--data-raw '{
    "contract": "0x6aC3beCE6DA0af3E30b1af95F0B5f67140b7353B",
    "nfts": [
      {
        "name": "First Asset Name",
        "description": "description of my asset",
        "image": "https://examplehost.com/assetimage.jpg"
      },
      {
        "name": "Second Asset Name",
        "description": "description of my asset",
        "image": "https://examplehost.com/assetimage.jpg"
      },
    ]
}'`;

const deployContractCode = `curl --location --request POST 'https://ownerfy.com/api/v1/collection/create' \
--data-raw '{
    "name": "A Collection of Assets",
    "contractType": "unlimited_v1"
}'`;

const getNftCode = `curl --location --request GET 'https://ownerfy.com/api/v1/nft/list/ownerfyUserId/JbjLtR6wDmldHVUOXOev'`;

const transferNftCode = `curl --location --request POST 'https://ownerfy.com/api/v1/nft/transfer'
--data-raw '{
    "nfts": [
        {
          "contract": "0x6aC3beCE6DA0af3E30b1af95F0B5f67140b7353B",
          "blockchainTokenId": "1234567",
          "toAddress": "0xc0faB703eBB1EF59a015b921D04232bEcb1Bc5B1"
        }
    ]
}'`;

const TABS = [
  {
    id: 1,
    label: 'Mint NFT',
    code: mintNftCode,
  },
  {
    id: 2,
    label: 'Deploy Smart Contracts',
    code: deployContractCode,
  },
  {
    id: 3,
    label: 'Get NFT Data',
    code: getNftCode,
  },
  {
    id: 4,
    label: 'Transfer NFT',
    code: transferNftCode,
  },
];

const NftApi = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(TABS[0].id);

  const codeSnap = useMemo(() => {
    const tab = TABS.find(item => item.id === activeTab);
    return tab.code;
  }, [activeTab]);

  return (
    <Box name="nft-api" className={classes.nftApiContainer}>
      <Container>
        <Box display="flex" justifyContent="center" style={{ marginBottom: 48 }}>
          <div className={classes.sectionHeading}>Bulk NFT Minting and Wallet Management</div>
        </Box>

        <Box display="flex" justifyContent="center" style={{ marginBottom: 48 }}>
          <Box p={1} className={classes.tabContainer}>
            <Grid container spacing={4} justifyContent="center">
              {TABS.map((tab, index) => (
                <Grid key={index} item xs={12} md="auto">
                  <Button
                    fullWidth
                    color="primary"
                    style={{ borderRadius: activeTab === tab.id ? 10 : 0 }}
                    variant={activeTab === tab.id ? 'contained' : 'text'}
                    onClick={() => setActiveTab(tab?.id)}
                    className={activeTab === tab.id ? classes.activeTab : classes.inactiveTab}
                  >
                    {tab.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Grid className={classes.tabContents} container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box>
              <ul className={classes.wizardlist}>
                {OPTIONS.map((option, index) => (
                  <li key={index} className={classes.wizardlistItem}>
                    {option}
                  </li>
                ))}
              </ul>
            </Box>

            <Box className={classes.actionBtns}>
              <Button href="https://ownerfy.readme.io/reference/api-endpoints" className={classes.outlinedBtn}>
                Documentation
              </Button>
              <Button href="/api-pricing" className={classes.containedBtn}>
                Get Started
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box style={{ backgroundColor: '#1E3545', borderRadius: 10, overflow: 'hidden' }}>
              <Box display="flex" spacing={1} py={2} px={3}>
                <Avatar style={{ backgroundColor: '#FF6056', width: 14, height: 14, marginRight: 8 }}>{''}</Avatar>
                <Avatar style={{ backgroundColor: '#FDBC2C', width: 14, height: 14, marginRight: 8 }}>{''}</Avatar>
                <Avatar style={{ backgroundColor: '#26C93E', width: 14, height: 14 }}>{''}</Avatar>
              </Box>
              <Divider style={{ backgroundColor: '#4567DE' }} />

              <Box className={classes.highlight}>
                <Highlight language="powershell">{codeSnap}</Highlight>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NftApi;

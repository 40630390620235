import React, { useCallback, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { MenuProps, useStyles } from './style';

const DropDown = ({ label, options, onChange }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected = options.length > 0 && selected.length === options.length;
  const upArrow = (
    <div className={classes.arrow}>
      <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.96094 5L5.96094 1L9.96094 5"
          stroke="#21D7BB"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
  const downArrow = (
    <div className={classes.arrow}>
      <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.96094 1L5.96094 5L1.96094 0.999999"
          stroke="#21D7BB"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
  const handleChange = event => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const handleClose = useCallback(() => {
    if (onChange) {
      onChange(selected);
    }
  }, [selected, onChange]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="mutiple-select-label">{label}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={selected => selected.join(', ')}
        MenuProps={MenuProps}
        IconComponent={() => downArrow}
        onClose={handleClose}
        // IconComponent={() => (isAllSelected ? upArrow : downArrow)}
      >
        {/* for future referance */}
        {/* <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={selected.length > 0 && selected.length < options.length}
            />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
        </MenuItem> */}
        {options.map(option => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} color="primary" />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;

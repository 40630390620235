import React from 'react';
import Container from 'Components/Container';
import { useStyles } from './styles.js';
import { cardData } from './dummyData.js';
const FeatureSection = props => {
  return (
    <>
      {cardData.map(item => {
        return <Card data={item} />;
      })}
    </>
  );
};

export default FeatureSection;

export const Card = props => {
  const classes = useStyles(props);
  const { data } = props;
  const justifyContent = data.id % 2 === 0 ? 'flex-start' : 'flex-end';
  return (
    <div className={`${classes.cardContainer}`} style={{ backgroundColor: `${data.backgroundColor}` }}>
      {/* <Container> */}
      <div className={classes.innerContentWrapper} style={{ justifyContent: `${justifyContent}` }}>
        <div className={classes.innerContent}>
          <img src={data.image} alt=" not found" />
          <h3 className={classes.cardTitle}>{data.title}</h3>
          <p>{data.description}</p>
        </div>
      </div>
      {/* </Container> */}
    </div>
  );
};

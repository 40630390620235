import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  footerContainer: {
    backgroundColor: '#16252F',
    paddingTop: '65px',
    overflow: 'hidden',
    '@media (max-width: 992px)': {
      paddingLeft: '22px',
    },
  },
  footer: {
    paddingBottom: '65px',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    padding: '0px',
    margin: '0px',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: '10px',
  },
  iconsHelperText: {
    fontFamily: 'Lato',
    fontSize: '14px',
    color: '#25DEAF',
  },
  timeContainer: {
    borderTop: '1px solid #505658',
  },
  copyRightText: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    color: ' #868889',
  },
  iconsContainer: {
    marginTop: '0px',
    '@media (max-width: 1290px)': {
      marginTop: '20px',
    },
  },
}));

export { useStyles };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textarea: ({ error }) => ({
    marginTop: 0,
    width: '100%',
    '& label': {
      fontSize: 16
    },
    '&.MuiFormControl-root label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 11px) scale(0.75)'
    },
    '& legend': {
      display: 'none'
    },
    '& textarea': {
      marginTop: 8,
      fontSize: 14
    },
    '& fieldset': {
      borderColor: `${error ? '#f44336' : '#0000003b'} !important`
    },
    '& label.Mui-focused': {
      color: '#748896 !important'
    },
    '& .Mui-focused fieldset': {
      borderColor: `${error ? '#f44336' : '#6b6b6b'} !important`,
      border: 'solid 1px !important'
    }
  })
}));

export default useStyles;

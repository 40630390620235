import * as Actions from '../actions';

const initialState = {
  error: {
    username: null,
    password: null,
  },
};

const login = function (state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN_ERROR: {
      return {
        success: false,
        error: action.payload,
      };
    }
    case Actions.PASSWORD_RESET: {
      return {
        success: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default login;

import * as types from '../actions/types';

const INITIAL_STATE = {
  nfts: [],
  polygonPageKey: null,
  ethereumPageKey: null,
  total: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_USER_ITEMS:
      return {
        ...state,
        ...action.payload,
      };
    case types.ADD_USER_ITEMS:
      return {
        ...state,
        nfts: [...state, action.payload.nfts],
        polygonPageKey: action.payload.polygonPageKey,
        ethereumPageKey: action.payload.openseaPageKey,
      };
    case types.DELETE_ITEM:
      return state.filter((items) => {
        return items.itemId !== action.payload;
      });
    default:
    return state;
  }
};
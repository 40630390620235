import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  claimModalContainer: {
    padding: '30px 27px 27px',
  },
  claimModalTitle: {
    marginBottom: 24,
    marginTop: 0,
    padding: 0,
    textAlign: 'center',
    fontWeight: 700,
    fontFamily: 'Lato',
    fontSize: 24,
  },
  claimModalImage: {
    marginBottom: 24,
    borderRadius: 12,
    width: 311,
    objectFit: 'cover',
  },
  claimModalButton: {
    marginBottom: 12,
    width: '100%',
    fontSize: 18,
    fontWeight: 700,
  },
  claimWarning: {
    color: '#FF0000',
  },
  claimModalNotification: {
    marginBottom: 16,
    padding: '4px 15px',
    borderRadius: 35,
    backgroundColor: '#DDF4FF',
    fontFamily: 'Nunito',
    fontSize: 18,
    color: '#476DDC',
  },
  claimModalStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    fontFamily: 'Nunito',
  },
  claimedStatus: {
    color: '#21D7BB',
  },
  availableStatus: {
    color: '#476DDC',
  },
}));

export { useStyles };

import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import BroughtImg1 from '../assets/Brought-1.png';
import BroughtImg2 from '../assets/Brought-2.png';
import BroughtImg3 from '../assets/Brought-3.png';
import BroughtImg4 from '../assets/Brought-4.png';
import BroughtImg5 from '../assets/Brought-5.png';
import useStyles from './style';

const IMAGES = [
  BroughtImg1,
  BroughtImg2,
  BroughtImg3,
  BroughtImg4,
  BroughtImg5
];

const BroughtSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.broughtWrapper}>
      <Container>
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={5}>
            <Box mb={4} className={classes.broughtTitle}>
              BROUGHT TO YOU FROM THE ARTIST WHO CREATED THE SOLD OUT J. PIERCE & FRIENDS COLLECTION!
            </Box>
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="center">
          <Grid
            container
            item
            xs={12}
            md={10}
            className={classes.broughtImages}
            spacing={3}
            display="flex"
            justifyContent="center"
          >
            {IMAGES.map((image, index) => (
              <Grid key={index} item xs={6} md="auto">
                <img src={image} alt="Brought" width="100%" />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="center">
          <Grid item xs={10} md={5}>
            <Box mt={4} className={classes.broughtDescription}>
              Each J. Pierce & Friends holder can mint one Zombie Friends Free!
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BroughtSection;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  resetPassContainer: {
    marginTop: '22px',
    maxWidth: '450px',
  },
  link: {
    color: '#476DDC',
    textDecoration: 'none',
    fontSize: '18px',
    fontFamily: 'Lato',
    marginBottom: '22px',
    outline: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  resetPassLabel: {
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '150%',
    color: '#748896',
    margin: '30px 0',
  },
  resetBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '22px',
    marginBottom: '22px',
  },
  resetHelperText: {
    color: '#748896',
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: '400',
    marginTop: '32px',
  },
  successMsg: {
    marginTop: '12px',
    fontFamily: ' Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    color: '#21D7BB',
    marginLeft: '12px',
  },
}));

export { useStyles };

import React from 'react';
import { Input, withStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  input: {
    borderRadius: 8,
    padding: '14px 18px',
    height: multiline => (multiline ? 76 : 50),
    backgroundColor: '#F3F3F3',
    color: '#142B3A',
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 400,

    '&:placeholder': {
      color: '#828282',
    },

    '& > textarea': {
      height: '100% !important',
    },
  },
});

export function FormInput({ label, name, placeholder, className: wrapperStyle, multiline, value, onChange, ...rest }) {
  const classes = useStyles(!!multiline);

  return (
    <Input
      label={label}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      multiline={!!multiline}
      className={clsx(classes.input, wrapperStyle)}
      variant="filled"
      fullWidth
      disableUnderline
      {...rest}
    />
  );
}

import React from 'react';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import FaqItem from './FaqItem';
import { useStyles } from './style';

const data = [
  {
    id: 2,
    title: 'How do I connect to MetaMask?',
    content:
      "Get your private key from the settings and import it into MetaMask (or other 3rd party software).  MetaMask is a desktop Chrome Extension. There is also a mobile version. If you've never used MetaMask allow it to generate any seed phrase for you. You will be using your imported wallet instead of the generated wallet. Here is a detailed explanation of importing a key. Click on your account icon and then click import. Paste in your private key from Ownerfy app. Then switch to that wallet in MetaMask. Then visit Rarible.com or OpenSea.io and click connect your wallet on MetaMask. This way you can have more fine grained control over your NFT sales while maintaining a higher quality NFT including blockchain hashes and proof of existence.",
  },
  {
    id: 3,
    title: 'How do I see my items on OpenSea?',
    content:
      'There are 2 ways. You can install metamask or another web3 compliant wallet and then import your Ownerfy private key from FAQ question 2. Then you can navigate to OpenSea and see your items. The second way is to transfer your item to an OpenSea connected metamask account by click transfer in the dropdown men in your collection.',
  },
  {
    id: 4,
    title: 'How do I transfer an item?',
    content:
      'Go to My Collection in the top right dropdown menu. Then click on the dropdown menu of the item you wish to transfer. Click on transfer and a dialog will open. Ethereum items are transferred via creditcard and polygon items are transferred with credits.',
  },
  {
    id: 4,
    title: 'How I claim a collection on OpenSea?',
    content:
      'After you have imported your private key into Metamask as seen in question 2 you must switch to that wallet on Metamask so it is currently your active wallet. Then visit the collection page on OpenSea while you are using Metamask with the Ownerfy imported wallet. You can find the collection page by clicking the item on Ownerfy then clicking the drop down menu while on the dedicated item page. Then click the OpenSea link. Then click the collection name on the OpenSea NFT page. An edit, pencil, icon will ne displayed in the banner area of the collection page. Click it and you can set all the details including royalties of your collection. The settings on OpenSea only apple to OpenSea.',
  },
  {
    id: 5,
    title: 'How do I sell an item?',
    content:
      'Import your item to Metamask as explained in FAQ question 2. From there you can navigate to a marketplace like OpenSea.io or Rarible.com. Go to your account page on that site, select your item and place it for sale. You may need to purchase crypto currency to do this.',
  },
];
const FAQ = () => {
  const classess = useStyles();

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <div className={classess.faqHeader}>
          <h1 className={classess.itemTitle}>Frequently Asked Questions</h1>
          <span className={classess.itemDesc}>
            Also check out the <a href="http://docs.ownerfy.com/">docs for a deeper dive</a>
          </span>
        </div>
        {data.map(item => {
          return <FaqItem key={item.id} title={item.title} content={item.content} />;
        })}
      </SectionLayout>
    </PageLayout>
  );
};

export default FAQ;

/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from 'Config/firebase';
import { TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import Container from 'Components/Container';
import { Grid } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import GlobalButton from 'Shared/Button';
import { css, jsx } from '@emotion/react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as actions from 'Store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Loading from 'Components/Loading';
import Link from '@material-ui/core/Link';
import { claimPremadeNft, getClaimOrder, getAlchemyNftMetadata } from '../Services/api';
import InPageSignupLogin from 'Components/InPageSignupLogin';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

function Claim({ marketOrderId, user, registerWithFirebase, history }) {
  const theme = useTheme();
  const [wasClaimed, setWasClaimed] = useState(false);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [claimOrder, setClaimOrder] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sendToAddress, setSendToAddress] = useState(null);
  const [radioValue, setRadioValue] = useState('ownerfy');
  const [sendToError, setSendToError] = useState(false);
  const [emailChecked, setEmailChecked] = React.useState(true);
  const [isSignupLoginFlowValid, setIsSignupLoginFlowValid] = useState(false);
  const [userSignupValues, setUserSignupValues] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      const _claimOrder = await getClaimOrder({ marketOrderId });
      setClaimOrder(_claimOrder);
      const { contract, tokenId } = _claimOrder;
      const _tokenInfo = await getAlchemyNftMetadata({ contract, tokenId });
      setTokenInfo(_tokenInfo);

      const claimed = Number(_tokenInfo.unclaimed) > 0;

      setWasClaimed(claimed);
      setIsLoading(false);
    })();
  }, [setTokenInfo, marketOrderId]);

  const registerUser = async ({ firstName, lastName, email, password, phone }) => {
    const model = {
      firstName: user?.firstName || firstName,
      lastName: user?.lastName || lastName,
      email: user?.email || email,
      password: user?.password || password,
      phone: user?.phone || phone,
    };
    try {
      const registrationRequest = await registerWithFirebase(model);
      if (registrationRequest.status === false) {
        setIsLoading(false);
        if (registrationRequest.payload.includes('auth/email-already-in-use')) {
          setErrorMessage(
            `This email is in use. Please login. If you forgot your password can reset it from the login window.`,
          );
          throw new Error(`This email is in use`);
        }
        setErrorMessage(`Error creating user: ${registrationRequest.payload}`);
        throw new Error(`Error creating user: ${registrationRequest.payload}`);
      }

      return registrationRequest.payload;
    } catch (err) {
      throw err;
    }
  };

  const handleConnectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setSendToAddress(accounts[0]);

        return accounts;
      } catch (err) {
        console.log(`err`, err);
        alert('connection failed');
      }
    } else {
      alert('Web3 wallet not available from your browser.');
    }
  };

  const handleCheckChange = event => {
    setEmailChecked(event.target.checked);
  };

  const onSubmit = async () => {
    let { firstName, lastName, email, password, phone } = userSignupValues;
    setIsLoading(true);
    let data;
    if (radioValue === 'other') {
      if (/^0x[a-fA-F0-9]{40}$/.test(sendToAddress) !== true) {
        setSendToError(true);
        setIsLoading(false);
        return;
      }
    }

    try {
      const _user = user || (await registerUser({ firstName, lastName, email, password, phone }));
      if (!_user?.wallets) {
        setErrorMessage('Error creating user');
        throw new Error('Error creating user');
      }
      const _toAddress = radioValue === 'ownerfy' ? _user.wallets[0].pub : sendToAddress;

      data = await claimPremadeNft({ toAddress: _toAddress, marketOrderId: marketOrderId, email: _user.email });
      if (data === 'Already redeemed') {
        setIsLoading(false);
        setWasClaimed(true);

        return;
      }
      if (data === false) {
        setIsLoading(false);
        setWasClaimed(true);
        return;
      }
    } catch (err) {
      console.log('Claim error is : ', err);
      setIsLoading(false);
      setIsError(true);
      return;
    }

    history.push(
      `/claim/${marketOrderId}/success?thankYouLink=${encodeURIComponent(claimOrder.thankYouLink)}&contractAddress=${
        claimOrder.contract
      }&tokenId=${claimOrder.tokenId}&isExternalWallet=${radioValue === 'other'}&externalAddress=${sendToAddress}`,
    );
  };

  const onSignupLoginValidationChange = (isValid, formValues) => {
    setIsSignupLoginFlowValid(isValid);
    setUserSignupValues(formValues);
  };

  const handleRadioChange = event => {
    setRadioValue(event.target.value);
  };

  if (wasClaimed) {
    return (
      <PageLayout hasContainer={false}>
        <SectionLayout>
          <Container>
            <div style={{ marginTop: 50 }}>
              <h1>No more available or you have already claimed this NFT</h1>
              <p>{claimOrder?.tokenId}</p>
              <p>
                If this is a new item or seems incorrect please contact{' '}
                <a href="mailto:support@ownerfy.com">support@ownerfy.com</a>.
              </p>
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  css={css`
                    margin-top: 30px;
                    color: #ffffff;
                    &:hover {
                      text-decoration: none;
                    }
                  `}
                  component={Link}
                  href={`/token/${claimOrder?.contract}/${claimOrder?.tokenId}/${tokenInfo.blockchain}`}
                >
                  View NFT
                </Button>
              </div>
            </div>
          </Container>
        </SectionLayout>
      </PageLayout>
    );
  }

  if (isError) {
    return (
      <PageLayout hasContainer={false}>
        <SectionLayout>
          <Container>
            <div style={{ marginTop: 50 }}>
              <h1>Status Message:</h1>
              <p>
                {errorMessage || (
                  <span>
                    The claim order ID, {marketOrderId} may not exist anymore or there was a connection error.
                  </span>
                )}
              </p>

              <p>
                If this seems incorrect please contact <a href="mailto:support@ownerfy.com">support@ownerfy.com</a>.
              </p>
            </div>
          </Container>
        </SectionLayout>
      </PageLayout>
    );
  }

  const getLoadingMessage = () => {
    if (!user) {
      return 'Loading...';
    }

    if (wasClaimed) {
      return 'Loading...';
    }

    return 'Loading...';
  };

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          {isLoading ? (
            <Loading
              type={'bubbles'}
              color={'#2bc8c5'}
              height={'80px'}
              width={'80px'}
              containerHeight={'40vh'}
              containerWidth={'100%'}
              message={getLoadingMessage()}
            />
          ) : (
            <>
              <div
                css={css`
                  margin-top: 30px;
                `}
              >
                <Typography
                  variant="h3"
                  css={css`
                    padding-bottom: 30px;
                  `}
                >
                  Claiming: <br />
                  {tokenInfo?.name || tokenInfo?.title}
                </Typography>

                {tokenInfo?.metadata?.image && (
                  <img
                    src={tokenInfo?.metadata?.image}
                    alt={tokenInfo?.name || tokenInfo?.title}
                    css={css`
                      max-height: 90vw;
                      max-width: 90vw;
                      padding-bottom: 30px;
                      ${theme.breakpoints.up('md')} {
                        padding-bottom: 30px;
                        max-height: 30vw;
                        max-width: 50vw;
                      }
                    `}
                  />
                )}

                <ReactMarkdown remarkPlugins={[remarkGfm]}>{tokenInfo?.description}</ReactMarkdown>
              </div>

              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Select a Wallet</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={radioValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="ownerfy"
                    control={<Radio />}
                    label="Ownerfy secure web3 wallet (We'll create one if you don't have one.)"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other Polygon compatible wallet (Like Metamask)"
                  />
                </RadioGroup>
              </FormControl>

              <Grid
                item
                xs={12}
                md={6}
                css={css`
                  margin-top: 20px;
                `}
              >
                <InPageSignupLogin onValidationChange={onSignupLoginValidationChange} />
              </Grid>

              {radioValue === 'other' && (
                <div
                  css={css`
                    margin-top: 10px;
                  `}
                >
                  <GlobalButton
                    css={css`
                      margin-bottom: 16px;
                      margin-top: 30px;
                    `}
                    btnLabel="Connect"
                    background
                    className="button-small"
                    handleClick={handleConnectMetamask}
                  />
                  <Typography variant="body1">or paste in your address manually</Typography>
                  <TextField
                    sx={{ width: '41ch' }}
                    placeholder="0x..."
                    helperText="example: 0xf234567890123456789012345678901234567890"
                    error={sendToError}
                    value={sendToAddress}
                    onChange={e => setSendToAddress(e.target.value)}
                  />
                </div>
              )}

              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked checked={emailChecked} onChange={handleCheckChange} />}
                  label="Share my email with the creator of this NFT"
                />
              </FormGroup>

              <GlobalButton
                css={css`
                  margin-bottom: 16px;
                  margin-top: 30px;
                `}
                disabled={!(user?.uid || isSignupLoginFlowValid)}
                btnLabel="Claim!"
                background
                handleClick={() => onSubmit()}
              />
              <p>Token ID: {tokenInfo?.id?.tokenId}</p>
            </>
          )}
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerWithFirebase: actions.registerWithFirebase,
    },
    dispatch,
  );
}

function mapStateToProps({ user, item }, ownProps) {
  return {
    user,
    item,
    marketOrderId: ownProps.match.params.marketOrderId,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Claim));

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  switchButtonWrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: '#EFEFEF',
    width: 145,
    height: 47,
    borderRadius: 6,
    padding: 6,
  },
  status: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 68,
    height: 36,
    borderRadius: 6,
    fontSize: 16,
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#ABABAB',
    cursor: 'pointer',
  },
  activeStatus: {
    color: '#FFF',
  },
  onActiveStatus: {
    background: 'linear-gradient(248deg, #24E5AD 0.9%, #4567DE 112.34%)',
  },
  offActiveStatus: {
    backgroundColor: '#cdcdcd',
  },
  onStatus: {
    left: 6,
    top: 6,
  },
  offStatus: {
    right: 6,
    top: 6,
  },
}));

export { useStyles };

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty } from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';

const pageStyles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#1B1C1D',
    borderRadius: 0,
  },
  toolbar: {
    display: 'flex',
  },
  flex: {
    flex: 1,
  },
  fullPreviewImg: {
    width: '100%',
  },
  filename: {
    fontSize: '15px',
    fontWeight: '500',
    fontFamily: 'Montserrat',
  },
  dialog: { borderRadius: 0 },
});

const ImageModal = ({ classes, fullScreen, modalImageInformation = {}, onClickCloseModal, ...props }) => {
  return (
    <Dialog
      className={classes.dialog}
      fullScreen={fullScreen}
      open={!isEmpty(modalImageInformation)}
      onClose={onClickCloseModal}
      {...props}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar} variant="dense">
          <div className={classes.flex}>
            {modalImageInformation.fileName && (
              <Typography variant="h6" color="inherit" className={classes.filename}>
                {modalImageInformation.fileName}
              </Typography>
            )}
          </div>
          <IconButton color="inherit" onClick={onClickCloseModal} size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {modalImageInformation.videoURL ? (
        <video id="token-video" controls className={classes.tokenImage} autoPlay={true} muted>
          <source src={modalImageInformation.videoURL} type="video/mp4" />
          Your browser does not support playing videos.
        </video>
      ) : (
        <img src={modalImageInformation.imgSrc} alt="full upload preview" className={classes.fullPreviewImg} />
      )}
    </Dialog>
  );
};

export default withStyles(pageStyles)(withMobileDialog()(ImageModal));

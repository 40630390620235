import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  settingContainer: {
    backgroundColor: '#DDF4FF',
    paddingBottom: '80px',
  },
  setting: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .MuiOutlinedInput-notchedOutline': {
      padding: '8px 20px',
      border: 'none',
    },
    '& .MuiSelect-select:focus': {
      background: 'transparent',
    },
  },
  flex48: {
    flexBasis: '48%',
    '@media (max-width: 960px)': {
      flexBasis: '100%',
    },
  },
  addButton: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    width: 83,
    padding: '14px 24px',
    textTransform: 'none',
    border: 'none',
    borderRadius: 10,
    height: 55,
    backgroundColor: '#476DDC',
    color: '#FFF !important',
    '&:hover': {
      backgroundColor: '#476DDC !important',
    },
  },
  removeButton: {
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    width: 83,
    padding: '14px 24px',
    marginLeft: '24px',
    textTransform: 'none',
    border: '1px solid #ABABAB !important',
    borderRadius: 10,
    height: 55,
    backgroundColor: '#fff',
    color: '#ABABAB !important',
    '&:hover': {
      backgroundColor: '#fff !important',
    },
  },
  nftMailCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  settingInputCard: {
    backgroundColor: '#FFF',
    borderRadius: '20px',
    marginTop: '60px',
    marginBottom: '33px',
    padding: '50px',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      padding: '20px',
    },
  },
  modalDesc: {
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: '400',
    color: '#748896',
    marginBottom: '22px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '150px',
  },
  settingContent: {
    // width: '430px',
    // '@media screen and (max-width: 568px)': {
    //   width: '85%',
    // },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '545px',
    '@media screen and (max-width: 960px)': {
      minHeight: 'auto',
    },
  },

  settingHeader: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
  },
  profileTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: '#1F2029',
    margin: '0px',
  },
  profileDesc: {
    fontSize: '16px',
    fontFamily: 'Lato',
    color: '#748896',
  },
  settingCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '30px',
    '@media screen and (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  settingCard: {
    flexBasis: '28%',
    backgroundColor: '#fff',
    padding: '30px 20px',
    borderRadius: '20px',
    height: '220px',
    marginBottom: '12px',
    marginRight: '12px',
    marginLeft: '12px',
    '@media screen and (max-width: 768px)': {
      flexBasis: '100%',
    },
  },
  cardTitle: {
    color: '#323441',
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: '600',
    marginBottom: '20px',
  },
  cardDesc: {
    color: '#748896',
    fontFamily: 'Lato',
    fontSize: '14px',
    marginBottom: '20px',
    wordBreak: 'break-word',
  },
  cardLink: {
    fontSize: '16px',
    fontFamily: 'Lato',
    background: 'linear-gradient(to right,#4567DE 0%, #24E5AD   30%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    color: 'transparent',
    cursor: 'pointer',
  },
  cardItem: {
    display: 'flex',
    alignItems: 'center',
  },
  cardInput: {
    marginRight: '22px',
  },
  placeHolder: {
    pointerEvents: 'none',
    fontFamily: 'Lato',
    color: '#748896',
    fontSize: '16px',
  },
  input: {
    border: '1.8px solid #e0e0e0',
    borderRadius: '10px',
    height: '66px',
    color: '#000',
    fontFamily: ' Lato',
    boxSizing: 'border-box',
    fontSize: '14px',
  },
  contentLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  circularProgress: {
    minHeight: '400px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  successMsg: {
    fontFamily: ' Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    color: '#21D7BB',
    marginLeft: '12px',
  },
  padding35: {
    padding: '35px',
  },
  switchButton: {
    marginTop: 12,
  },
  balanceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '20px',
    borderBottom: ' 1px solid #E0E0E0',
  },
  balance: {
    fontFamily: ' Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color: ' #748896',
    marginLeft: '10px',
    marginTop: 0,
    marginBottom: 0,
  },
  balanceValue: {
    color: '#323441',
    fontSize: '44px',
  },
  nftMailValue: {
    fontSize: '18px',
    fontFamily: 'Lato',
    color: '#748896',
  },
  balanceInline: {
    display: 'flex',
    alignItems: 'center',
  },
  addresses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btnCretits: {
    width: '100%',
    marginTop: '18px',
  },
  btnDelete: {
    margin: 'auto',
    color: '#ABABAB',
    fontFamily: 'Nunito',
    fontSize: '18px',
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

export { useStyles };

import React, { useState, useEffect } from 'react';
import config from 'Config/firebase';
import axios from 'axios';
import { utils, ethers } from 'ethers';
import { BigNumber } from '@ethersproject/bignumber';
import Page from 'Components/Page';
import { withStyles } from '@material-ui/core';
import Container from 'Components/Container';
import jpf from 'ABI/jpf.json';
import { Helmet } from 'react-helmet';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import FeatureSection from './FeatureSection';
import NftTraits from './NftTraits';
import HowItIsMade from './HowItIsMade';
import ProductionSection from './ProductionSection';
import Merchandise from './Merchandise';
const styles = theme => ({
  page: {
    backgroundColor: '#3CBAE8',
    color: '#000',
    paddingBottom: 0,
    position: 'relative',
  },
  mainContent: {
    position: 'relative',
    zIndex: 1,
  },
  input: {
    height: '32px',
    width: '47px',
    marginLeft: 13,
    border: '2px solid #050403',
    overflow: 'hidden',
    '& input': {
      color: '#050403',
      fontFamily: 'Poppins',
      fontSize: '18px',
      textAlign: 'center !important',
      backgroundColor: '#FFFFFF',
    },
  },
  leftPattern: {
    height: '625px',
    width: '197px',
    position: 'absolute',
    bottom: '345px',
    left: 0,
    zIndex: 0,
  },
  rightPattern: {
    height: '625px',
    width: '197px',
    position: 'absolute',
    top: '106px',
    right: 0,
    zIndex: 0,
  },
  header: {
    height: 60,
    width: '100%',
    backgroundColor: '#E40D69',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
      height: 'auto',
    },
  },
  headerInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 30px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: '15px',
      alignItems: 'center',
    },
  },
  headerTitle: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '39px',
  },
  connect: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '24px',
  },
  cashCowsLogo: {
    fontFamily: '"Montserrat", sans-serif',
    color: '#097EA6',
    fontSize: '40px',
    fontWeight: '800',
    letterSpacing: '0',
    lineHeight: '48px',
    margin: 0,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 28,
    width: '100%',
    position: 'relative',
  },
  jpAndFriendsLogo: {
    width: '100%',
    maxWidth: 488,
  },
  hospitalBadge: {
    position: 'absolute',
    right: '0',
    width: 135,
    [theme.breakpoints.down(1172)]: {
      display: 'none',
    },
  },
  hospitalBadgeSmallViewports: {
    width: 135,
    display: 'none',
    margin: '10px 0 20px 0',
    [theme.breakpoints.down(1172)]: {
      display: 'block',
    },
  },
  jpAndFriendsLineup: {
    width: '100%',
    maxWidth: 899,
    marginBottom: 46,
  },
  logoBox: { textAlign: 'left' },
  logoImage: { height: '56px', width: '266px' },
  logoText: {
    color: '#07435A',
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '46px',
  },
  button: {
    padding: '22px 30px 22px 30px',
    height: '69px',
    border: '4px solid #050403',
    backgroundColor: '#FFE60F',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    textDecoration: 'none !important',
    outline: 'none',
    cursor: 'pointer',
    color: '#050403',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '27px',
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
    width: '100%',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#000',
      borderColor: '#FFE60F',
      color: '#FFE60F',
    },
  },
  clearButton: {
    backgroundColor: 'transparent',
    color: '#097EA6',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  },
  info: {
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '35px',
    textAlign: 'center',
    marginBottom: 10,
  },
  info2: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '27px',
    textAlign: 'center',
  },
  infoLink: {
    textDecoration: 'underline',
    color: 'inherit',
  },
  discordBox: {
    padding: '10px 30px 10px 30px',
    height: '70px',
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#1b1a1a',
    },
  },
  metaMaskLogo: {
    width: 34,
  },
  discordLogo: {
    width: 31,
  },
  headerButtonsBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '12px',
    },
  },
  discordLogoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  discordText: {
    color: '#fff',
    textTransform: 'uppercase',
    display: 'block',
    lineHeight: '51px',
  },
  heroBox: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 28,
  },
  heroImage: { maxWidth: '450px', width: '100%' },
  alert: {
    padding: '20px 0',
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '19px',
    backgroundColor: 'rgba(255,255,255,0.5)',
    margin: '30px 0',
  },

  title1: {
    marginTop: '60px',
    color: '#097EA6',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '31px',
    fontWeight: '800',
    letterSpacing: '0',
    lineHeight: '43px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  subtitle: {
    color: '#1B8302',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '27px',
    textAlign: 'center',
    marginTop: 30,
  },
  title2: {
    margin: '20px 0px 20px 0',
    color: '#FF4DFF',
    fontFamily: 'Poppins',
    fontSize: '40px',
    letterSpacing: 0,
    lineHeight: '60px',
    fontWeight: 500,
    textShadow: '1px 1px 7px #FF4DFF, 1px 0px 1px #ffffff',
  },
  title3: {
    margin: '20px 0px 70px 0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  countdownContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  countdownHeader: {
    marginBottom: 25,
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: '26px',
    letterSpacing: '0',
    lineHeight: '39px',
  },
  countDown: {
    display: 'flex',
    gap: '45px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexWrap: 'wrap',
      maxWidth: '250px',
      gap: '25px',
    },
  },
  countDownGroup: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '70px',
    },
  },
  number: {
    color: '#FFFFFF',
    fontFamily: '"Saira Condensed"',
    fontSize: '60px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 1,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '60px',
    },
  },
  timeSpan: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '25px',
    textAlign: 'center',
  },

  metaButton: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
    padding: '16px 54px',
    marginBottom: 20,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },
  buyNowHeader: {
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '39px',
    marginBottom: 6,
    marginTop: 20,
  },

  littleButton: {
    backgroundColor: '#097EA6',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
    padding: '12px 24px',
    marginBottom: 10,
    marginRight: 5,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: '#097EA6',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },

  remindMeButton: {
    marginTop: 30,
    backgroundColor: '#fff',
  },
  mintQueue: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 50,
  },
  toggleQueue: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 14,
    cursor: 'pointer',
  },
  featuresSection: {
    backgroundColor: '#F5673C',
  },
  features: {
    display: 'flex',
    gap: '75px',
    alignItems: 'center',
    padding: '0 110px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 70px',
    },
    [theme.breakpoints.down('xs')]: {
      gap: '35px',
      padding: 0,
    },
  },
  featureList: {
    marginTop: 33,
    marginBottom: 0,
    listStyleType: 'none',
    padding: 0,
    listStyleImage: "url('/img/jpandfriends/dot.svg')",
  },
  featureTitle: {
    color: '#050403',
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      fontSize: '45px',
      lineHeight: '60px',
    },
  },
  underline: {
    height: '8.03px',
    width: '174.76px',
    display: 'block',
    transform: 'scaleX(1.1)',
  },
  feature: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '18px',
    letterSpacing: '0',
    lineHeight: '40px',
    marginBottom: '10px',
    paddingLeft: '12px',
    marginLeft: '30px',
  },
  featureContent: {
    top: '-4px',
    position: 'relative',
  },
  emphasisFeature: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  drawingSamplesContainer: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1.5,
  },
  drawingSamples: {
    maxWidth: 513,
    width: '100%',
  },
  collectionSamples: {
    display: 'flex',
    gap: '30px',
    '& div': {
      maxHeight: '229px',
      flexGrow: 1,
    },
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      '& div': {
        maxHeight: 'none',
        flexGrow: 1,
        width: '45%',
      },
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'no-wrap',
      '& div': {
        flexGrow: 1,
        width: '100%',
      },
    },
  },
  description: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '30px',
    marginBottom: 55,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  descriptionText: {
    flex: 2,
    margin: 0,
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '34px',
    '& a': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  secondDescriptionText: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  justinPierceLink: {
    color: '#FD2424 !important',
    fontWeight: 'bold !important',
    textDecoration: 'none !important',
  },
  descriptionTextRed: {
    color: '#FD2424',
  },
  descriptionTextPurple: {
    color: '#9A24FD',
  },
  descriptionTextBlue: {
    color: '#2452FC',
  },
  descriptionTextPink: {
    color: '#F91979',
  },
  jimmyKimmelLink: {
    color: '#079C35 !important',
    textDecoration: 'none !important',
  },
  descriptionTextGreen: {
    color: '#079C35',
  },
  faceIllustration: {
    height: '223px',
    width: '165.39px',
    alignSelf: 'center',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: '40px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '60px',
    textAlign: 'center',
    marginBottom: '42px',
  },
  imageRow: {
    display: 'flex',
    gap: '50px',
    '& div': {
      flexGrow: 1,
    },
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      gap: '20px',
      flexDirection: 'column',
    },
  },
  firstImageRow: {
    marginBottom: '50px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '30px',
    },
  },
  hatchIncubation: {
    backgroundColor: '#01202A',
    padding: '80px 0',
    marginBottom: '80px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
    },
  },
  hatchIncubationTitle: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    marginBottom: '70px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '45px',
      lineHeight: '65px',
      marginBottom: '30px',
    },
  },
  hatchEvents: {
    display: 'flex',
    gap: '30px',
    flexWrap: 'wrap',
    marginBottom: '66px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  hatchGroup: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  hatchEvent: {
    maxWidth: '190px',
  },
  hatchEventPercentage: {
    color: '#D29F04',
    fontFamily: '"Saira Condensed"',
    fontSize: '60px',
    fontWeight: '600',
    letterSpacing: '0',
    padding: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  hatchEventText: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '32px',
    textAlign: 'center',
  },
  hatchPrice: {
    width: '100%',
    textAlign: 'center',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '32px',
  },
  yourCollection: { width: '100%', backgroundColor: 'transparent', padding: '0px 0' },
  yourCowBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: '5px',
    overflowX: 'visible',
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
  },
  cowControls: { textAlign: 'center' },

  theCollectionLogo: {
    color: '#00FF00',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    margin: 0,
    textAlign: 'center',
    marginBottom: 65,
    textShadow: '1px 1px 10px #00ff00, 1px 0px 1px #ffffff',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },

  theCollectionCowBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    gap: '5px',
    overflowX: 'hidden',
    paddingBottom: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
  },
  theCollectionCow: {
    height: '255px',
    width: '255px',
    [theme.breakpoints.down('xs')]: {
      height: '255px',
      width: '255px',
    },
  },
  theTeamBox: {
    width: '100%',
    padding: '60px 0',
    backgroundColor: '#050403',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  theTeamLogo: {
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '72px',
    textAlign: 'center',
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },
  teams: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
      padding: '0px 60px',
    },
  },
  team: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  teamLogo: {
    width: 200,
  },
  noDecoration: {
    textDecoration: 'none !important',
  },
  teamDescription: {
    maxWidth: 400,
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '26px',
    textAlign: 'center',
  },
  footer: { backgroundColor: '#201F1F', padding: 50 },
  footerInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
  },
  footerLogos: { display: 'flex', gap: '20px', justifyContent: 'center' },
  footerLogo: {
    marginTop: 10,
    height: '48px',
    width: '48px',
    padding: '10px',
    color: '#097EA6',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#097EA6',
    },
  },
  alchemyBadge: {
    cursor: 'pointer',
  },
  footerSmartContract: {
    color: '#616161',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '21px',
    textAlign: 'center',
    width: '100%',
    marginTop: '15px',
  },
  cowsBg: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  topSection: {
    paddingBottom: '41px',
  },
  countdownArea: {
    marginBottom: 26,
  },
  calloutBox: {
    maxWidth: '450px',
    padding: '20px 0',
    paddingBottom: 0,
    //backgroundColor: 'rgba(9,126,166,0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  calloutBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  mintQtyLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 18,
    justifyContent: 'center',
  },
  qtyTitle: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '27px',
  },
  qtyLabel: {
    color: '#050403',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '21px',
    textAlign: 'center',
    display: 'block',
  },
  saleBox: {
    width: '100%',
    maxWidth: '850px',
  },
  mintOptions: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 24,
    marginTop: 32,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: 44,
    },
  },
  mintOption: {
    flex: 1,
  },
  mintInstructions: {
    textAlign: 'left',
    margin: 0,
    marginBottom: 16,
  },
  mintDisclaimer: {
    color: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '14px',
    textAlign: 'center',
    marginTop: 14,
  },
  section: {
    padding: '31px 0',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: '62px 0',
    },
  },
  sectionHeader: {
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '72px',
    marginBottom: 40,
    textAlign: 'center',
  },
  cowsLineUp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
    flexWrap: 'wrap',
  },
  collectionCow: {},
  transparentButton: {
    color: '#fff',
    border: '2px solid white',
    fontFamily: 'Poppins',
    fontWeight: '500',
    lineHeight: '35px',
    backgroundColor: 'transparent',
    display: 'flex',
    gap: '13px',
    padding: '3px 14px',
    alignItems: 'center',
    borderRadius: '4px',
    fontSize: '14px',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'all 500ms cubic-bezier(0.4, 0, 0.2, 1)',
    '& img': {
      width: '26px',
    },
    '&:hover': {
      backgroundColor: '#e54288',
    },
  },
  connectedContainer: {
    cursor: 'auto !important',
    wordBreak: 'break-word',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '25px',
    },
  },
  smartContractText: {
    color: '#3CBAE8',
  },
});

function padLeadingZeros(num, size) {
  var s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}

function JpAndFriends({ setActiveModal, classes }) {
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [hoursRemaining, setHoursRemaining] = useState(null);
  const [minutesRemaining, setMinutesRemaining] = useState(null);
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  const [MMAccounts, setMMAccounts] = useState(null);
  const [userCows, setUserCows] = useState([]);
  const [milkBalances, setCowBalances] = useState(null);
  const [qty, setQty] = useState(1);
  const [isQueueOpen, setQueueOpen] = useState(false);
  const [saleOn, setSaleOn] = useState(true);
  const [mintQueue, setMintQueue] = useState([]);
  const [intervalState, setIntervalState] = useState([]);
  const [cowTotal, setCowTotal] = useState(0);

  useEffect(() => {
    axios.get(`${config.functionsURL}/getTotalMintedJPF`).then(res => {
      const countTo = res.data.amount;
      const easeOutQuad = t => t * (2 - t);
      const frameDuration = 2000 / 60;
      let frame = 0;
      const totalFrames = Math.round(2000 / frameDuration);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        setCowTotal(Math.ceil(countTo * progress));

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    });
  }, [setCowTotal]);

  const connectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        setMMAccounts(accounts);
        return accounts;
      } catch (err) {
        console.log(`err`, err);
        alert('connection failed');
      }
    } else {
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  const mint = async () => {
    console.log('starting mint');
    let _accounts = MMAccounts ? MMAccounts[0] : [];
    try {
      _accounts = await connectMetamask();
    } catch (err) {
      console.log('connect metamask error');
      return;
    }
    //return;
    if (_accounts && _accounts.length) {
      window.gtag('event', 'click_metamask', {
        event_category: 'ecommerce',
        event_label: 'metamask_click',
        value: parseInt(qty, 10),
      });

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contract = new ethers.Contract(config.jpfContract, jpf.abi, provider);

      const contractWithSigner = contract.connect(signer);

      const price = BigNumber.from('37000000000000000');
      const totalPrice = price.mul(qty);

      if (qty > 12) {
        alert('Max 12 per wallet');
        return;
      }

      let token = 4000;

      // try {
      //   const res = await axios.get(`${config.functionsURL}/checkWhitelist/${MMAccounts[0]}`);

      //   token = res.data.wlc;
      // } catch (err) {
      //   alert('Not on whitelist');
      //   console.log('whitelist token', err);
      // }

      // let signature;
      // try {
      //   const msg = `0x${Buffer.from('ownerfy', 'utf8').toString('hex')}`;
      //   console.log('msg is :: ', msg);
      //   signature = await window.ethereum.request({
      //     method: 'personal_sign',
      //     params: [MMAccounts[0], msg],
      //   });
      //   //console.log('account is :: ', MMAccounts[0]);
      //   //params: [msg, from, 'Example password'],
      //   console.log('sign:: ', signature, "signing with : ', ", MMAccounts[0]);
      // } catch (err) {
      //   console.error('signature error:::: ', err);
      // }

      try {
        let mintTx = await contractWithSigner.mint(
          utils.hexlify(parseInt(qty, 10)),
          _accounts[0],
          utils.hexlify(parseInt(token, 10)),
          {
            value: totalPrice.toHexString(),
          },
        );
      } catch (err) {
        console.warn('error:: ', err);
        alert(err.message);
      }
    } else {
      connectMetamask();
      alert('Metamask was not connected. Please try again after metamask is connected.');
    }
  };

  const openRemindModal = () => {
    window.open(
      'https://cdn.forms-content.sg-form.com/ac3f5bfe-30ac-11ec-a30f-4e53a58ddc0e',
      '_blank',
      'resizable=yes, scrollbars=no, titlebar=no, location=no, menubar=no, status=no, toolbar=no, width=550, height=440, top=10, left=10',
    );
  };

  const getCountdown = () => {
    const deadline = new Date('2021-11-13T03:00:00.000Z');
    const now = new Date();
    const time = deadline - now;
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  // loop to countdown every 1 second
  setInterval(function () {
    const { days, hours, minutes, seconds } = getCountdown();
    setDaysRemaining(days);
    setHoursRemaining(hours);
    setMinutesRemaining(minutes);
    setSecondsRemaining(seconds);
  }, 1000);

  return (
    <Page hasNav={false} wrapperStyles={classes.page} hasContainer={false} FooterComponent={() => null}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>J. Pierce &amp; Friends Hand Painted NFT Collection</title>
        <description>4000 Hand Painted and Signed NFT Characters</description>
        <meta property="og:title" content="J. Pierce and Friends Hand Painted NFT Collection" />
        <meta property="og:description" content="4000 Hand Painted and Signed NFT Characters" />
        <meta property="og:image" content="https://ownerfy.com/img/jpandfriends/friends.png" />
      </Helmet>
      <div className={classes.mainContent}>
        <div className={classes.topSection}>
          <div className={classes.header}>
            <Container>
              <div className={classes.headerInnerContainer}>
                <div className={classes.headerTitle}>An NFT Collection</div>
                <div className={classes.headerButtonsBox}>
                  {saleOn && (
                    <div>
                      {!MMAccounts ? (
                        <button className={classes.transparentButton} onClick={connectMetamask}>
                          Connect to Metamask{' '}
                          <img
                            alt="jpandfriends metamask"
                            className={classes.metaMaskLogo}
                            src="/img/jpandfriends/metamask.svg"
                          />
                        </button>
                      ) : (
                        <div className={clsx(classes.transparentButton, classes.connectedContainer)}>
                          Connected to: {MMAccounts[0]}{' '}
                          <img
                            alt="jpandfriends metamask"
                            className={classes.metaMaskLogo}
                            src="/img/jpandfriends/metamask.svg"
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <a
                    href="https://discord.gg/FnSW6HPZwR"
                    className={classes.transparentButton}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Discord{' '}
                    <img
                      alt="jpandfriends discord"
                      className={classes.discordLogo}
                      src="/img/jpandfriends/discord.svg"
                    />
                  </a>
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div className={classes.heroBox}>
              <div className={classes.logoContainer}>
                <img alt="jpandfriends logo" className={classes.jpAndFriendsLogo} src="/img/jpandfriends/logo.png" />
                <img
                  alt="phoenix childrens hospital badge"
                  className={classes.hospitalBadge}
                  src="/img/jpandfriends/phoenix-hospital-badge.png"
                />
              </div>
              <img
                alt="jpandfriends lineup"
                className={classes.jpAndFriendsLineup}
                src="/img/jpandfriends/friends.png"
              />

              <div className={classes.info}>4000 Limited Edition Hand Painted and Signed NFTs</div>

              <img
                alt="phoenix childrens hospital badge"
                className={classes.hospitalBadgeSmallViewports}
                src="/img/jpandfriends/phoenix-hospital-badge.png"
              />

              <div className={classes.info2}>Mint Price: 0.037 ETH</div>

              <div className={classes.calloutBoxContainer}>
                <div className={classes.calloutBox}>
                  {!saleOn &&
                    (daysRemaining > 0 || hoursRemaining > 0 || minutesRemaining > 0 || secondsRemaining > 0) && (
                      <div className={classes.countdownArea}>
                        <div className={classes.countdownHeader}>DROPS IN</div>
                        <div className={classes.countdownContainer}>
                          <div className={classes.countDown}>
                            <div className={classes.countDownGroup}>
                              <span className={classes.number}>{padLeadingZeros(daysRemaining, 2)}</span>
                              <span className={classes.timeSpan}>DAYS</span>
                            </div>
                            <div className={classes.countDownGroup}>
                              <span className={classes.number}>{padLeadingZeros(hoursRemaining, 2)}</span>
                              <span className={classes.timeSpan}>HOURS</span>
                            </div>
                            <div className={classes.countDownGroup}>
                              <span className={classes.number}>{padLeadingZeros(minutesRemaining, 2)}</span>
                              <span className={classes.timeSpan}>MINUTES</span>
                            </div>
                            <div className={classes.countDownGroup}>
                              <span className={classes.number}>{padLeadingZeros(secondsRemaining, 2)}</span>
                              <span className={classes.timeSpan}>SECONDS</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {!saleOn && (
                    <button className={clsx(classes.button)} onClick={openRemindModal}>
                      Get Notified
                    </button>
                  )}
                </div>
                {saleOn && (
                  <div className={classes.saleBox}>
                    {/* <div className={classes.buyNowHeader}>Sale Live!</div> */}

                    <div>
                      {/* <div className={classes.mintQtyLine}>
                        <div className={classes.qtyTitle}>Choose Quantity:</div>{' '}
                        <Input
                          value={qty}
                          id="qty"
                          type="number"
                          min="1"
                          max="1000"
                          onChange={e => setQty(e.target.value)}
                          className={classes.input}
                        />
                      </div> */}
                      {/* <p className={classes.qtyLabel}>Max 12 per wallet address</p>

                      <div className={classes.mintOptions}>
                        <div className={classes.mintOption}>
                          <p className={classes.mintInstructions}>
                            Click "Connect to Metamask" button in top right to connect or click "Mint with Metamask" and
                            click "Ok" to connect to Metamask before minting.
                          </p>
                          <button className={classes.button} onClick={() => mint()}>
                            Mint with Metamask <img src="/img/jpandfriends/metamask.svg" alt="metamask" />
                          </button>
                        </div>

                        <div className={classes.mintOption}>
                          <p className={classes.mintInstructions}>
                            Click "Mint with credit card" and proceed to mint page to enter quantity and review fees.
                            Then proceed to checkout page to complete purchase.
                          </p>
                          <button
                            className={classes.button}
                            onClick={() => (window.location.href = './jpandfriends/mint')}
                          >
                            Mint with Credit Card <img src="/img/jpandfriends/cc.svg" alt="credit card" />
                          </button>
                        </div>
                      </div> */}

                      <div className={classes.mintDisclaimer}>{cowTotal} sold of 4000</div>

                      <a target="os" href="https://opensea.io/collection/jpierceandfriendsnft">
                        <img alt="opensea" src="/img/chicadees/opensea.svg" className={classes.footerLogo} />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
        <Merchandise />
        <div className={classes.section} style={{ backgroundColor: '#fff', paddingBottom: 50 }}>
          <Container>
            <div className={classes.aboutContainer}>
              <div className={classes.sectionTitle} style={{ color: '#FD2424' }}>
                About the Artist
              </div>
              <div className={classes.description}>
                <p className={classes.descriptionText}>
                  Renowned American modern artist,{' '}
                  <a
                    className={classes.justinPierceLink}
                    href="https://www.artbyjpierce.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Justin Pierce
                  </a>{' '}
                  has found a passion in fine arts, product design and custom urban murals to touch people and their{' '}
                  <span className={classes.descriptionTextRed}>emotions</span>. <br />
                  His use of <span className={classes.descriptionTextPurple}>colors</span> and{' '}
                  <span className={classes.descriptionTextBlue}>patterns</span> makes his work unique and attractive to
                  both - the young and the young at heart.
                </p>
                <img
                  src="/img/jpandfriends/jp-face-illustration.png"
                  alt="jp illustrated face"
                  className={classes.faceIllustration}
                />
                <p className={clsx(classes.descriptionText, classes.secondDescriptionText)}>
                  <span className={classes.descriptionTextPink}>J.Pierce</span> is inspired by legendary artist: Warhol,
                  Seuss, Haring and Picasso. He’s always discovering new insights and artistic visions from art
                  galleries, books, and pop culture in general. <br />
                  Justin is known for his many{' '}
                  <a href="https://www.instagram.com/iamjpierce/" target="_blank" rel="noopener noreferrer">
                    colorful paintings
                  </a>
                  ,{' '}
                  <a href="https://www.instagram.com/iamjpierce/" target="_blank" rel="noopener noreferrer">
                    clothes
                  </a>
                  ,{' '}
                  <a href="https://www.instagram.com/iamjpierce/" target="_blank" rel="noopener noreferrer">
                    shoes
                  </a>
                  ,{' '}
                  <a href="https://www.instagram.com/iamjpierce/" target="_blank" rel="noopener noreferrer">
                    car wraps
                  </a>
                  , and{' '}
                  <a href="https://www.instagram.com/iamjpierce/" target="_blank" rel="noopener noreferrer">
                    murals
                  </a>
                  . He's been featured in <span className={classes.descriptionTextOrange}>national news</span>, and the{' '}
                  <a
                    className={classes.jimmyKimmelLink}
                    href="https://twitter.com/iamjpierce/status/1253881031607779328"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jimmy Kimmel Show
                  </a>
                  .
                </p>
              </div>
              <div className={clsx(classes.imageRow, classes.firstImageRow)}>
                <div>
                  <img src="/img/jpandfriends/jp-pose.png" alt="jp posing in front of shoe collection" />
                </div>

                <div>
                  <img src="/img/jpandfriends/jp-drawing.png" alt="jp drawing mural" />
                </div>

                <div>
                  <img src="/img/jpandfriends/shoes.png" alt="Hand drawn shoes" />
                </div>
              </div>
              <div className={classes.imageRow} style={{ marginBottom: 0 }}>
                <div>
                  <img src="/img/jpandfriends/la-mural.png" alt="Jp los angeles mural" />
                </div>
                <div>
                  <img src="/img/jpandfriends/jp-plane.png" alt="jp posing with hand drawn bike" />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div style={{ background: 'white', display: 'flex', flexWrap: 'wrap' }}>
          <FeatureSection />
        </div>
        <div style={{ background: 'white' }}>
          <NftTraits />
        </div>
        <div className={clsx(classes.section)} style={{ backgroundColor: '#383738', padding: '97px 0' }}>
          <Container>
            <div className={classes.collectionSamples}>
              <div>
                <img src="/img/jpandfriends/j-pierce-character-001.png" alt="jp & friends sample" />
              </div>
              <div>
                <img src="/img/jpandfriends/j-pierce-character-002.png" alt="jp & friends sample" />
              </div>
              <div>
                <img src="/img/jpandfriends/j-pierce-character-003.png" alt="jp & friends sample" />
              </div>
              <div>
                <img src="/img/jpandfriends/j-pierce-character-004.png" alt="jp & friends sample" />
              </div>
              <div>
                <img src="/img/jpandfriends/j-pierce-character-005.png" alt="jp & friends sample" />
              </div>
            </div>
          </Container>
        </div>
        <div style={{ background: 'white' }}>
          <HowItIsMade />
        </div>
        <div style={{ background: 'white' }}>
          <ProductionSection />
        </div>
        <div className={classes.section} style={{ backgroundColor: '#050403' }}>
          <Container>
            <div className={classes.sectionTitle} style={{ color: '#E40D69' }}>
              In Production With
            </div>
            <div className={classes.team}>
              <a href="/">
                <img src="/img/chicadees/ownerfy-logo.png" className={classes.teamLogo} />
              </a>
              <p className={classes.teamDescription}>
                Ownerfy is an early innovator in NFT technology that helps artists and businesses create higher quality
                NFTs and smart contracts that meet emerging standards and the demands of enterprise business.
              </p>
            </div>
          </Container>
        </div>

        <div className={classes.footer}>
          <div className={classes.footerInnerContainer}>
            <img
              onClick={window.logBadgeClick}
              id="badge-button"
              style={{ width: 240, height: 53 }}
              src="https://static.alchemyapi.io/images/marketing/badge.png"
              alt="Alchemy Supercharged"
              className={classes.alchemyBadge}
            />

            {saleOn && (
              <a
                style={{ textDecoration: 'none' }}
                href="https://etherscan.io/address/0x633cfA9ac099D18C1B33736892427f0a7c1d120a"
                target="es"
              >
                <p className={classes.footerSmartContract}>
                  <span className={classes.smartContractText}>Smart Contract:</span>
                  <br /> 0x633cfA9ac099D18C1B33736892427f0a7c1d120a
                </p>
              </a>
            )}

            <div className={classes.footerLogos}>
              <a href="https://twitter.com/jpandfriendsnft" target="disc">
                <img src="/img/chicadees/twitter.svg" className={classes.footerLogo} />
              </a>
              {saleOn && (
                <a target="os" href="https://opensea.io/collection/jpierceandfriendsnft">
                  <img alt="opensea" src="/img/chicadees/opensea.svg" className={classes.footerLogo} />
                </a>
              )}
              {/*
              <a target="rar" href="https://rarible.com/collection/0xb352131fe48571b7661390e65be4f12119e9686f">
                <img src="/img/rarible-logo-white.svg" className={classes.footerLogo} />
              </a> */}
              <a href="https://discord.gg/FnSW6HPZwR" target="disc">
                <img src="/img/chicadees/discord.svg" className={classes.footerLogo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withStyles(styles)(JpAndFriends);

import React, { forwardRef, useEffect, useState } from 'react';
import { TextField, Dialog, Slide } from '@material-ui/core';
import { createClaimOrder, getERC1155Quantity, getClaimOrder, fetchUserCredits } from 'Services/api';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loading from 'Components/Loading';
import GlobalButton from 'Shared/Button';

import { useStyles } from './style';

const CssDialog = withStyles({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 20,
    },
  },
})(Dialog);

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      marginBottom: 16,
      borderRadius: 10,
    },
  },
})(TextField);

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// TODO: both claim modals need to be put into the modal system
function ClaimModal({ open, onClose, onGenerate, user, tokenId, contract, tokenName }) {
  const classes = useStyles();
  const [totalOwned, setTotalOwned] = useState(1);
  const [thankYouLink, setThankYouLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [userCredits, setUserCredits] = useState(0);
  const [claimed, setClaimed] = useState(0);
  const [previousAllowed, setPreviousAllowed] = useState(0);
  const [emailChecked, setEmailChecked] = React.useState(true);

  const handleGenerate = async () => {
    if (userCredits < 1 || quantity > totalOwned) {
      alert('Not enough credits or enough tokens');
      return;
    }
    // query backend to create claim order
    const marketOrderId = await createClaimOrder({
      tokenId,
      ownerAddress: user?.ethereumAddress,
      thankYouLink,
      contract,
      quantity,
      emailChecked,
      tokenName,
    });

    const link = `http://${window.location.hostname}/claim/${marketOrderId}`;
    setPreviousAllowed(quantity);
    setLoading(true);
    onClose();
    onGenerate(link);
  };

  const handleCheckChange = event => {
    setEmailChecked(event.target.checked);
  };

  useEffect(() => {
    if (tokenId) {
      setLoading(true);
      (async () => {
        if (!user || (user && Object.keys(user).length === 0)) return;

        const ownerAddress = user?.ethereumAddress || user?.wallets[0].pub;
        let _totalOwned = await getERC1155Quantity({ tokenId, ownerAddress, contract });

        setTotalOwned(_totalOwned);

        let _previousClaimOrder = await getClaimOrder({ tokenId, contract, ownerId: user?.uid });

        let _userCreditsData = await fetchUserCredits();

        let _userCredits = _userCreditsData?.nftCredits || 0;

        setUserCredits(_userCredits);

        const _previousAllowed = _previousClaimOrder?.allowed || 0;
        const _claimed = _previousClaimOrder?.claimed || 0;
        setClaimed(_claimed);
        setQuantity(_previousAllowed);
        if (_previousClaimOrder.thankYouLink) setThankYouLink(_previousClaimOrder.thankYouLink);
        setPreviousAllowed(_previousAllowed);
        setLoading(false);
      })(setLoading);
    }
  }, [tokenId, user, contract, setTotalOwned, setPreviousAllowed, setQuantity]);

  return (
    <CssDialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="lg">
      <div className={classes.claimModalContainer}>
        <h1 className={classes.claimModalTitle}>Make Claimable</h1>

        <h2>{tokenName} </h2>

        {loading && (
          <Loading
            type={'bubbles'}
            color={'#2bc8c5'}
            height={'80px'}
            width={'80px'}
            containerHeight={'40vh'}
            containerWidth={'100%'}
            message="Loading"
          />
        )}

        {!loading && (
          <div>
            <CssTextField
              label="Total Allowed Quantity"
              variant="outlined"
              value={quantity}
              onChange={e => setQuantity(e.target.value)}
              InputProps={{ classes: classes.claimModalInput }}
              fullWidth
            />
          </div>
        )}
        <p>
          Credits: {userCredits} (<a href="/buy-credits">Buy more</a>)
          <i>
            {userCredits < quantity - claimed && (
              <span className={classes.claimWarning}> *You may run out of credits.</span>
            )}
          </i>
        </p>

        {/* Add stats if already claimable -how many you own -how many set for claimable */}

        <div className={classes.claimModalNotification}>
          You will pay 1 credit each time someone claims and NFT.
          <br />
          Only one NFT can be claimed per account or address currently.
        </div>
        <TextField
          sx={{ width: '100ch' }}
          placeholder="http://..."
          helperText="Thank you page link to your site"
          value={thankYouLink}
          onChange={e => setThankYouLink(e.target.value)}
        />
        <p>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked checked={emailChecked} onChange={handleCheckChange} />}
              label="Collect emails"
            />
          </FormGroup>
        </p>

        <GlobalButton
          className={classes.claimModalButton}
          btnLabel="Make Claimable"
          background
          handleClick={handleGenerate}
        />
        <div className={classes.claimModalStatus}>
          <div className={classes.claimedStatus}>Claimed: {claimed}</div>
          <div className={classes.availableStatus}>Current Allowed: {previousAllowed}</div>
          <div className={classes.availableStatus}>Available: {totalOwned}</div>
        </div>
      </div>
    </CssDialog>
  );
}

export default ClaimModal;

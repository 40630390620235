/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core/styles';
import { TimeToLeave, TitleRounded } from '@material-ui/icons';

function Post({ title, children }) {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        ${theme.breakpoints.up('md')} {
          flex-direction: row;
        }
      `}
    >
      <div
        css={css`
          padding-top: 50px;
          display: flex;
          justify-content: center;
          ${theme.breakpoints.up('md')} {
            flex: 1;
            border-right: 1px solid #ededed;
            align-items: center;
            height: 100vh;
            position: sticky;
            top: 0;
          }
        `}
      >
        <p
          css={css`
            color: #313739;
            margin: 0;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: bold;
            font-size: 50px;
            line-height: 59px;
            text-align: center;
            width: 70%;
            ${theme.breakpoints.up('md')} {
              font-size: 60px;
              line-height: 72px;
              margin-top: -160px;
            }
          `}
        >
          {title}
        </p>
      </div>
      <div
        css={css`
          ${theme.breakpoints.up('md')} {
            flex: 1;
          }
        `}
      >
        <div
          css={css`
            p,
            li {
              line-height: 25px;
            }
            p,
            ul {
              margin-bottom: 30px;
            }
            li {
              margin-bottom: 10px;
            }
            li > ul {
              margin-top: 15px;
            }
            p + ul {
              margin-top: -10px;
            }
            img {
              max-width: 100%;
            }
            a {
              color: #07be64;
              text-decoration: none;
              &:hover {
                color: #0ae679;
              }
            }
            width: 80%;
            padding: 50px 0;
            margin: 0 auto;
            ${theme.breakpoints.up('md')} {
              padding: 100px 0;
              width: 70%;
            }
          `}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Post;

import React, { useState, useEffect } from 'react';
import Container from 'Components/Container';
import SectionLayout from 'Layout/SectionLayout';
import { useStyles } from './style';
import { isNull } from 'lodash';
import config from 'Config/firebase';
import InPageSignupLogin from 'Components/InPageSignupLogin';
import { createStripeCheckoutSessionForCharacterCreation, getCharacter } from 'Services/api';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'Components/Loading';
import { registerWithFirebase } from 'Store/actions';
import { Link } from 'react-router-dom';
import Input from 'Shared/Input';
import Button from 'Shared/Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CardIcon from './assets/card.svg';
import MetaMaskIcon from './assets/metaMask.svg';

const ExternalMint = () => {
  const classess = useStyles();
  const queryParameters = new URLSearchParams(window.location.search);
  const initialQuanity = queryParameters.get('quantity') || 1;

  const [isLoading, setLoading] = useState(null);

  const [userSignupValues, setUserSignupValues] = useState({});
  const [isSignupLoginFlowValid, setIsSignupLoginFlowValid] = useState(false);

  const [purchaseFeeUsd, setPurchaseFeeUsd] = useState();
  const [purchaseFeeEth, setPurchaseFeeEth] = useState();
  const [gasFeeUsd, setGasFeeUsd] = useState();
  const [gasFeeEth, setGasFeeEth] = useState();
  const [totalFeeUsd, setTotalFeeUsd] = useState();
  const [totalFeeEth, setTotalFeeEth] = useState();

  const [characterInfo, setCharacterInfo] = useState(null);

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const [pageQuantity, setPageQuantity] = useState(initialQuanity);
  const collectionId = queryParameters.get('type') || queryParameters.get('collection');

  const calculateFees = async value => {
    console.log('calculating fees for :: ', value);
    setPurchaseFeeUsd((characterInfo.fees.purchaseFeeInUsd * value).toFixed(2));
    setPurchaseFeeEth(characterInfo.fees.purchaseFeeInEth * value);
    setGasFeeUsd((characterInfo.fees.gasFeeInUsd * value).toFixed(2));
    setGasFeeEth(characterInfo.fees.gasFeeInEth * value);
    setTotalFeeUsd((characterInfo.fees.totalFeeInUsd * value).toFixed(2));
    setTotalFeeEth(characterInfo.fees.totalFeeInEth * value);
  };

  useEffect(() => {
    const fetchCharacter = async () => {
      setLoading(true);
      const character = await getCharacter({ id: collectionId });

      setCharacterInfo(character.data);
      setLoading(false);
    };

    fetchCharacter();
  }, [collectionId]);

  useEffect(() => {
    if (characterInfo && characterInfo.fees && characterInfo.fees.purchaseFeeInUsd) {
      calculateFees(pageQuantity);
    }
  }, [calculateFees, pageQuantity]);

  const onSignupLoginValidationChange = (isValid, formValues) => {
    setIsSignupLoginFlowValid(isValid);
    setUserSignupValues(formValues);
  };

  const finalizeSendToStripe = async () => {
    await sendToStripeCheckoutForCharacterCreation({
      id: collectionId,
      quantity: Number(pageQuantity),
    });
  };

  const sendToStripeCheckoutForCharacterCreation = async order => {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);

    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForCharacterCreation({
        order,
        cancelUrl: window.location.href,
      });

      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const registerUser = async ({ firstName, lastName, email, password, phone, username }) => {
    try {
      const registrationRequest = await dispatch(
        registerWithFirebase({
          firstName: user?.firstName || firstName,
          lastName: user?.lastName || lastName,
          username: user?.username || username,
          email: user?.email || email,
          password: user?.password || password,
          phone: user?.phone || phone,
        }),
      );

      console.log('registration request is: ', registrationRequest);

      const userId = registrationRequest.payload.uid;
      return userId;
    } catch (err) {
      console.warn('User Registration error: ', err);
      alert(err);
      return;
    }
  };

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().required('Quantity is required'),
  });

  if (isNull(isLoading)) {
    return null;
  }

  if (isLoading) {
    return (
      <SectionLayout>
        <Loading
          type={'bubbles'}
          color={'#2bc8c5'}
          height={'80px'}
          width={'80px'}
          containerHeight={'40vh'}
          containerWidth={'100%'}
          message={'Loading'}
        />
      </SectionLayout>
    );
  }

  return (
    <SectionLayout hasContainer={true}>
      <Container>
        <div className={classess.mintNft}>
          <div className={classess.mintNftCol}>
            <div className={classess.mintNftHeader}>
              <div>
                <h4 className={classess.heading}>Mint NFT</h4>
                <span className={classess.authLink}>J. Pierce Zombie Friends</span>
              </div>
              <div className={classess.authLink}>
                <InPageSignupLogin onValidationChange={onSignupLoginValidationChange} />
                {/* <Link href="#">
                  <a href="/logout" className={classess.logoutLink}>
                    (Logout)
                  </a>
                </Link> */}
              </div>
            </div>
            {pageQuantity > 0 && (
              <p>
                <strong>Purchase: </strong> ${purchaseFeeUsd} ({purchaseFeeEth.toFixed(3)} ETH)
                <br />
                <strong>Processing (gas) fees: </strong> ${gasFeeUsd} ({gasFeeEth.toFixed(3)} ETH)
                <br />
                <strong>Total: </strong> ${totalFeeUsd} ({totalFeeEth.toFixed(3)} ETH)
              </p>
            )}

            <div className={classess.formContainer}>
              <Formik
                initialValues={{ quantity: initialQuanity }}
                validator={() => ({})}
                onSubmit={async (values, { resetForm }) => {
                  console.log('submitting values and signup values:: ', values, userSignupValues);

                  setLoading(true);
                  let userId = user?.userId;
                  if (!userId) userId = await registerUser(userSignupValues);
                  //  resetForm();
                  if (!values.quantity) {
                    setLoading(false);
                    alert('Must have quantity');

                    return;
                  }

                  if (parseInt(values.quantity, 10) > 21) {
                    setLoading(false);
                    alert('Must be between 1 and 20');
                    return;
                  }

                  await finalizeSendToStripe();
                }}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <Input
                      name="quantity"
                      label="Quantity"
                      error={errors.quantity}
                      value={values.quantity}
                      touched={touched.quantity}
                      onChange={e => {
                        const value = e.target.value;

                        handleChange(e);

                        setPageQuantity(value);
                      }}
                    />
                    <div className={classess.formFooter}>
                      <span className={classess.btnLabel}>Mint With</span>

                      <div className={classess.btnWrappaer}>
                        <button
                          disabled={!(user?.uid || isSignupLoginFlowValid)}
                          type="submit"
                          className={classess.mintBtn}
                        >
                          <img src={CardIcon} alt="Not found" style={{ marginRight: '10px' }} />
                          <span>Credit Card</span>
                        </button>
                        {/* <button className={classess.mintBtn}>
                          <img src={MetaMaskIcon} alt="Not found" style={{ marginRight: '10px' }} />
                          <span>MetaMask</span>
                        </button> */}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </SectionLayout>
  );
};

function mapStateToProps({ user, item }, ownProps) {
  return {
    user,
    item,
    urlCode: ownProps.match.params.code,
  };
}

export default ExternalMint;

import { makeStyles } from '@material-ui/core/styles';
import { BlockOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  modalContainer: {
    backgroundColor: '#fff',
    borderRadius: '20px',
    padding: '20px 40px',
    boxSizing: 'border-box',
    minWidth: '565px',
    '@media (max-width: 768px)': {
      minWidth: '90%',
    },
  },
  modalHeader: {
    width: '100%',
    textAlign: 'right',
  },

  closeModal: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
}));

export { useStyles };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loginContainer: {
    marginTop: '22px',
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '70px',
    '@media (min-width: 768px) and (max-width: 960px)': {
      gap: '40px',
    },
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      gap: '0',
    },
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flex48: {
    flexBasis: '48%',
  },
  modalHeading: {
    fontFamily: 'Lato',
    fontStyle: ' normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '100%',
    color: '#323441',
  },
  link: {
    color: '#21D7BB',
    textDecoration: 'none',
    fontSize: '18px',
    fontFamily: 'Lato',
    marginBottom: '22px',
    outline: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  singUpButton: {},
  loginBtnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '32px 0',
    '@media (max-width: 960px)': {
      flexDirection: 'column',
      gap: '20px',
      textAlign: 'center',
    },
  },
  loginHelperText: {
    color: '#748896',
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: '400',
    '& button': {
      margin: 0,
    },
    '& input[type="checkbox"]': {
      marginRight: '10px',
    },
  },
}));

export { useStyles };

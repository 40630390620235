import * as Actions from '../actions';

const INITIAL_STATE = {
  availablePlans: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_PLAN_DATA:
      return {
        ...state,
        availablePlans: action.plans,
      };
    case Actions.RESET_PLAN_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

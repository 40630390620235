import React from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import PageLayout from 'Layout/Layout';
import Container from 'Components/Container';
import SectionLayout from 'Layout/SectionLayout';
import Button from 'Shared/Button';
import { useStyles } from './style';

const htmlCodes = `<html>
<!-- Hello World in HTML -->
  <head>
    <title>Hello World!</title>
  </head>
  <body>
    Hello World!
  </body>
</html>`;

const CollectionSuccess = () => {
  const classess = useStyles();
  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <div className={classess.successContainer}>
            <div className={classess.successBlock}>
              <h4 className={classess.heading}>Success!</h4>
              <p className={classess.description}>
                Your collection is being deployed. <strong>This can take up to 2 minutes</strong> to complete and then
                you will be able to select it on the mint page dropdown.
              </p>
              <Button
                handleClick={() => (window.location.href = 'mint-nft')}
                btnLabel="Wait 2 minutes then click here to mint!"
                small
                background
              />
            </div>
            {/* <div className={classess.codeBlock}>
              <CopyBlock language="html" text={htmlCodes} codeBlock theme={dracula} showLineNumbers={false} />
            </div> */}
          </div>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
};

export default CollectionSuccess;

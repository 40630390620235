import React from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { ReactComponent as MapBg1Icon } from '../assets/MapBG1.svg';
import { ReactComponent as MapBg2Icon } from '../assets/MapBG2.svg';
import { ReactComponent as MapBg3Icon } from '../assets/MapBG3.svg';
import { ReactComponent as MapBg4Icon } from '../assets/MapBG4.svg';
import { ReactComponent as MapBg5Icon } from '../assets/MapBG5.svg';
import { ReactComponent as MembershipIcon } from '../assets/Membership.svg';
import { ReactComponent as CommercialIcon } from '../assets/Commercial.svg';
import { ReactComponent as OwnerIcon } from '../assets/Owner.svg';
import { ReactComponent as ClubIcon } from '../assets/Club.svg';
import { ReactComponent as TraitIcon } from '../assets/Trait.svg';

import useStyles from './style';

const OptionsSection = () => {
  return (
    <Box px={2} style={{ overflow: 'hidden' }} pb={20}>
      <Container>
        <Grid spacing={10} container>
          <Grid item xs={12} md={6}>
            <MembershipBenefits />
          </Grid>
          <Grid item xs={12} md={6}>
            <CommercialRights />
          </Grid>
          <Grid item xs={12} md={6}>
            <PrivateClub />
          </Grid>
          <Grid item xs={12}>
            <NftTraits />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const MembershipBenefits = () => {
  const classes = useStyles();
  return (
    <Box style={{ position: 'relative' }}>
      <Box className={classes.memberShipWrapper} display="flex" flexDirection="column" alignItems="center">
        <Box style={{ height: 100 }} display="flex" alignItems="flex-end" justifyContent="center" mb={2}>
          <MembershipIcon />
        </Box>
        <Box mb={2}>
          <Typography className={classes.membershipTitle}>MEMBERSHIP BENEFITS</Typography>
        </Box>
        <Box>
          <Typography component="div" className={classes.membershipDescription}>
            <div>
              Owners will receive discounts on entertainment like movies, and restaurants, discounts on hotels, tickets
              to shows, special events and also exclusive access to J. Pierce & Friends events like the J. Pierce SOLD
              OUT afterparty, NFT parties in Puerto Rico, or the Zombie Fighter All Nighter in Las Vegas."
            </div>
          </Typography>
        </Box>
      </Box>

      <MapBg1Icon className={classes.mapBackground} />
    </Box>
  );
};

const CommercialRights = () => {
  const classes = useStyles();
  return (
    <Box style={{ position: 'relative' }}>
      <Box className={classes.commercialWrapper} display="flex" flexDirection="column" alignItems="center">
        <Box mb={2} style={{ height: 100 }} display="flex" alignItems="flex-end" justifyContent="center">
          <CommercialIcon />
        </Box>
        <Box mb={2}>
          <Typography className={classes.commercialTitle}>COMMERCIAL RIGHTS</Typography>
        </Box>
        <Box>
          <Typography className={classes.commercialDescription}>
            COMMERCIAL RIGHTS for the owner to create, profit and sell merchandise. - Each owner may use these
            characters in commercial work such as creating stories, making merchandise, and creating physical artwork
            which can be used personally or sold and profited from. The owner is defined as the current owner as
            indicated by the blockchain.
          </Typography>
        </Box>
      </Box>

      <MapBg2Icon className={classes.mapBackground} />
    </Box>
  );
};

const OwnerDAO = () => {
  const classes = useStyles();
  return (
    <Box style={{ position: 'relative' }}>
      <Box className={classes.ownerDtoWrapper} display="flex" flexDirection="column" alignItems="center">
        <Box mb={2} style={{ height: 100 }} display="flex" alignItems="flex-end" justifyContent="center">
          <OwnerIcon />
        </Box>
        <Box mb={2}>
          <Typography className={classes.ownerDtoTitle}>OWNER DAO</Typography>
        </Box>
        <Box>
          <Typography className={classes.ownerDtoDescription}>
            Ownerfy is creating an Owner DAO to allow all owners of Justin Pierce and other Ownerfy collections to help
            decide crucial decisions for collections. This allows the owners to help make decisions that benefit the
            community of owners in the best way possible. This will include decision on the content of new collections
            and the rules around those collections.
          </Typography>
        </Box>
      </Box>

      <MapBg3Icon className={classes.mapBackground} />
    </Box>
  );
};

const PrivateClub = () => {
  const classes = useStyles();
  return (
    <Box style={{ position: 'relative' }}>
      <Box className={classes.privateClubWrapper} display="flex" flexDirection="column" alignItems="center">
        <Box mb={2} style={{ height: 100 }} display="flex" alignItems="flex-end" justifyContent="center">
          <ClubIcon />
        </Box>
        <Box mb={2}>
          <Typography className={classes.privateClubTitle}>PRIVATE CLUB</Typography>
        </Box>
        <Box>
          <Typography className={classes.privateClubDescription}>
            Private discord CLUB for owners - Each owner has access to a special private chat with each other and
            Justin. Members in the club will also have guranteed access to exclusive private events such as art gallery
            exhibits and NFT mixer events such as the J. Pierce launch party in Los Angeles. Our upcoming event will be
            for the sell out celebration!
          </Typography>
        </Box>
      </Box>

      <MapBg4Icon className={classes.mapBackground} />
    </Box>
  );
};

const NftTraits = () => {
  const classes = useStyles();
  return (
    <Box style={{ position: 'relative' }}>
      <Box className={classes.nftTraitsWrapper} display="flex" flexDirection="column" alignItems="center">
        <Box mb={2} style={{ height: 100 }} display="flex" alignItems="flex-end" justifyContent="center">
          <TraitIcon />
        </Box>
        <Box mb={2}>
          <Typography className={classes.nftTraitsTitle}>NFT TRAITS</Typography>
        </Box>
        <Box>
          <Typography className={classes.nftTraitsDescription}>
            Huge variety of randomly distributed rare and super rare NFT TRAITS - There are over 100 individual traits
            with millions of possible combinations. Great care has been taken to make sure some of the combinations are
            much more exclusive and some will appear more frequently. Everyone who mints has an equal chance of getting
            a combination of very rare or more common traits. Some traits will appear less than 1% of the time!
          </Typography>
        </Box>
      </Box>

      <MapBg5Icon className={classes.mapBackground} />
    </Box>
  );
};

export default OptionsSection;

import { makeStyles } from '@material-ui/core/styles';
// import headerBg from './assets/headerBg.png';
// import footerBg from './assets/footerBg.png';
const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDrawer-paper': {
      border: '1px solid red',
    },
  },
  headerContainer: {
    width: '100%',
    minHeight: '90px',
    '& .MuiDrawer-paper': {
      backgroundColor: 'red',
    },
  },

  header: {
    backgroundColor: '#1d3547 !important',
    height: '90px ',
    zIndex: '1',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
      backgroundColor: '#1d3547 !important',
    },
  },
  backgroundTransparent: {
    backgroundColor: 'transparent !important',
    '@media (max-width: 900px)': {
      paddingLeft: 0,
      backgroundColor: '#1d3547 !important',
    },
  },
  backgroundColor: {
    backgroundColor: '#1d3547',
  },
  logoContainer: {
    width: '200px',
  },
  logo: {
    width: '150px',
  },
  navLink: {
    fontSize: '14px',
    fontFamily: 'Lato',
    fontWeight: '600',
    color: '#F7F9FF',
    display: 'inline-block',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  navContainer: {
    paddingLeft: '50px !important',
    paddingRight: '50px !important',
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mobileViewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexBasis: '100%',
  },
  drawerPaper: {
    paddingTop: '100px',
    backgroundColor: '#1F2029',
    width: '100%',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  outlinedButton: {
    border: '1px solid #30b9cf',
    borderRadius: '10px',
    color: '#30b9cf',
    fontFamily: "'Work Sans Bold', 'Nunito Sans', sans-serif",
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '14px',
    padding: '10px 24px',
    textTransform: 'uppercase',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: '#30b9cf',
      color: '#FFFFFF',
      transition: 'all 0.3s ease',
    },
  },
  dropdownNav: {
    minWidth: 150,
  },
  dropdownMenu: {
    position: 'relative',
    background: '#FFF',
    borderRadius: 8,
    padding: '14px 0',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      margin: 1,
      backgroundColor: '#FFF',
      borderRadius: 8,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      zIndex: -2,
      top: -10,
      left: 18,
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: '10px solid #21E7AF',
    },

    '& > li:hover': {
      background: 'transparent',
    },
  },
  menuItem: {
    color: '#323441',
    fontSize: 18,
    fontWeight: 700,
    fontFamily: 'Nunito',
    cursor: 'pointer',
    textDecoration: 'none',

    '&:hover': {
      background: 'linear-gradient(180deg, #21E7AF 0%, #3291E9 100%);',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },

    '&:active': {
      background: '#21D7BB',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
    },
  },
}));

export { useStyles };

import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { Link, Box, Grid } from '@material-ui/core';
import * as Yup from 'yup';

import { AUTH_MODALS } from 'Constants/modals';
import { setActiveModal, submitLoginWithFireBase, setAuthModal } from 'Store/actions';
import GlobalButton from 'Shared/Button/index';
import FormContainer from 'Components/FormInputs/FormContainer';
import TextField from 'Components/FormInputs/TextField';
import history from '../../history';

import { useStyles } from './style';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string().nullable().email('Invalid email').required('Provide your login email'),
  password: Yup.string().required('Provide the password'),
});

const Login = ({ title, embedded }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [apiError, setApiError] = useState('');

  const formRef = useRef();

  const onSubmit = async values => {
    setApiError('');

    const returnPath = window.location.pathname === '/' ? '/my-collection' : '';
    const result = await dispatch(submitLoginWithFireBase({ ...values, returnPath }));

    if (result?.status) {
      dispatch(setAuthModal(null));
      dispatch(setActiveModal('none'));
    } else {
      setApiError(result?.message);
    }
    if (embedded) history.push('/my-collection');
  };

  const handleForgotPassword = () => {
    dispatch(setActiveModal('resetPassword'));
    dispatch(setAuthModal(null));
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formRef.current.click();
    }
  };

  return (
    <div className={classes.loginContainer} onKeyPress={handleKeyPress}>
      <h1 className={classes.modalHeading}>{title}</h1>

      <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormFields apiError={apiError} />
          </Grid>

          <Grid item xs={12}>
            <Link component="button" variant="body2" className={classes.link} onClick={handleForgotPassword}>
              Forgot password?
            </Link>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <GlobalButton ref={formRef} btnLabel="Login" background data-cy="login-submit-button" />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.loginHelperText}>
              <span>Don’t have an account?</span>
              <button
                className={classes.link}
                data-cy="signup-modal-button"
                onClick={() => dispatch(setAuthModal(AUTH_MODALS.SIGNUP))}
              >
                Sign Up
              </button>
            </div>
          </Grid>
        </Grid>
      </FormContainer>
    </div>
  );
};

const FormFields = ({ apiError }) => {
  const { setError } = useFormContext();

  useEffect(() => {
    if (apiError) {
      setError('password', { type: 'custom', message: apiError });
    }
  }, [apiError, setError]);

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={4}>
        <TextField
          fullWidth
          name="email"
          label="Email"
          variant="outlined"
          placeholder="Enter Email"
          data-cy="login-email-input"
        />
      </Box>

      <Box>
        <TextField
          fullWidth
          name="password"
          label="Password"
          variant="outlined"
          type="password"
          data-cy="login-password-input"
        />
      </Box>
    </Box>
  );
};

export default Login;

import React from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
import Page from 'Components/Page';
import HeaderSection from './sections/HeaderSection';
import HeroSection from './sections/HeroSection';
import LaunchSection from './sections/LaunchSection';
import ArtistSection from './sections/ArtistSection';
import PrizeSection from './sections/PrizeSection';
import OptionSection from './sections/OptionsSection';
import ZombiesSection from './sections/ZombiesSection';
import BroughtSection from './sections/BroughtSection';
import HowItMadeSection from './sections/HowMadeSection';
import BestBuddiesSection from './sections/BestBuddiesSection';
import ProductSection from './sections/ProductSection';
import FooterSection from './sections/FooterSection';
import useStyles from './sections/style';
import './style.css';

const ZombieFriends = () => {
  const classes = useStyles();

  return (
    <Page wrapperStyles={classes.pageWrapper} hasNav={false} hasContainer={false} FooterComponent={() => null}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>J. Pierce &amp; 10,000 Zombie Friends</title>
        <description>The first 4,000 will be given to the to J.Pierce and Friends Owners for Free.</description>
        <meta property="og:title" content="J. Pierce and 10,000 Zombie Friends" />
        <meta
          property="og:description"
          content="The first 4,000 will available for free mint to current J. Pierce &amp; Friends holders"
        />
        <meta property="og:image" content="https://ownerfy.com/img/zombiefriends/friends.png" />
      </Helmet>

      <Box bgcolor="#1A1A1A">
        <HeaderSection />
        <HeroSection />
        <LaunchSection />
        <ArtistSection />
        <PrizeSection />
        <OptionSection />
        <ZombiesSection />
        <BroughtSection />
        <HowItMadeSection />
        <BestBuddiesSection />
        <ProductSection />
        <FooterSection />
      </Box>
    </Page>
  );
};

export default ZombieFriends;

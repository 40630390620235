import React, { useRef } from 'react';
import { ControlledMenu, MenuItem as ControlledMenuItem, useMenuState } from '@szhsin/react-menu';

import DropdownMenuItem from './DropdownMenuItem';
import DesktopMenuItemButton from './DesktopMenuItemButton';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useStyles } from './style';

const DropdownMenu = menuItemProps => {
  const classes = useStyles();
  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });

  return (
    <div style={{ display: 'inline-block' }}>
      <div ref={ref} onMouseEnter={() => toggleMenu(true)}>
        <DesktopMenuItemButton {...menuItemProps} />
      </div>
      <ControlledMenu
        {...menuProps}
        anchorRef={ref}
        onMouseLeave={() => toggleMenu(false)}
        onClose={() => toggleMenu(false)}
        menuClassName={classes.dropdownMenu}
      >
        {menuItemProps.list.map((listItem, listItemIndex) => (
          <ControlledMenuItem key={listItemIndex}>
            <DropdownMenuItem {...listItem} />
          </ControlledMenuItem>
        ))}
      </ControlledMenu>
    </div>
  );
};

export default DropdownMenu;

import React, { forwardRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { Dialog, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import GlobalButton from 'Shared/Button';
import { IconButton, Snackbar } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useStyles } from './style';

const CssDialog = withStyles({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 20,
    },
  },
})(Dialog);

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// TODO: both claim modals need to be put into the modal system
function ClaimInfoModal({ open, onClose, link, tokenId, tokenName }) {
  const classes = useStyles();
  const [copyOpen, setCopyOpen] = useState(false);

  const handleCopy = () => {
    setCopyOpen(true);
    navigator.clipboard.writeText(link);
  };

  const onImageCownload = () => {
    const svg = document.getElementById('QRCode');

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${tokenName}-${tokenId}.png`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <CssDialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="lg">
      <div className={classes.claimInfoModalContainer}>
        <h2>Claim Link</h2>
        <p>{tokenName}</p>
        <p>Direct your users to the link, send in an email, or display the QR code below.</p>
        <p>Users will be given the option to</p>
        <ul>
          <li>Claim to an existing Web3 wallet like Metamask</li>
          <li>To an existing Ownerfy wallet</li>
          <li>Or to make a new wallet</li>
        </ul>
        <p>
          <a href={link}>{link}</a>
          <IconButton onClick={handleCopy} color="primary">
            <ContentCopyIcon />
          </IconButton>
          <Snackbar
            message="Copied to clibboard"
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={2000}
            onClose={() => handleCopy(false)}
            open={copyOpen}
          />
        </p>

        <p>Optionally direct your users to this QR code to claim.</p>
        <QRCode value={link || ''} id="QRCode" />
        <p>
          <a onClick={onImageCownload} href="#this">
            Download QR Code
          </a>
        </p>

        <p>
          <a href={link} target="new">
            <GlobalButton className={classes.claimModalButton} btnLabel="Go to claim page" background />
          </a>
        </p>
      </div>
    </CssDialog>
  );
}

export default ClaimInfoModal;

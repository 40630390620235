import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import ModalContent from '../ModalContent';
import { withStyles } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { createStripeCheckoutSessionForHarvestEggs, getHarvestEggsFee } from '../../Services/api';
import config from 'Config/firebase';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  submit: {
    marginTop: 15,
  },
});

function LayEggs({ tokenId, images, chicInfo, classes }) {
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [harvestEggsPrices, setharvestEggsPrices] = useState(null);

  useEffect(() => {
    const fetchNftFee = async () => {
      const harvestFee = await getHarvestEggsFee();
      setharvestEggsPrices(harvestFee);
    };

    fetchNftFee();
  }, []);

  const sendToStripeCheckout = async harvestAll => {
    setSubmitLoading(true);
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);

    const tokenIds = harvestAll ? chicInfo.chicBalances.map(chic => chic.id) : [tokenId];

    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForHarvestEggs({
        tokenIds,
        images,
        cancelUrl: `${window.location.href}`,
      });
      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  return (
    <ModalContent
      title="Lay Eggs"
      isButtonDisabled={true}
      formContents={
        <>
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <div>
              <p>
                All Chic-A-Dees must be initialized to start laying EGGS. If you havest an un-initialized Chic-A-Dee it
                will automatically be initialized. Any harvest Chic-A-Dee will automatically start laying EGGS
                immediately after harvest. Harvesting a Chic-A-Dee with available EGGS will transfer the EGGS to your
                account. Harvesting EGGS will not be available once the total supply reaches 1 billion.
              </p>
              <p>
                EGGS available for this Chic-A-Dee: {chicInfo.chicBalances.find(chic => chic.id === tokenId).balance}
              </p>
              <p>
                EGGS available from all your Chic-A-Dees:{' '}
                {chicInfo.chicBalances.reduce((previousValue, chic) => {
                  return previousValue + chic.balance;
                }, 0)}
              </p>
              <b>Cost to harvest this Chic-A-Dee:</b>{' '}
              {harvestEggsPrices ? (
                `$${harvestEggsPrices.forOne}`
              ) : (
                <CircularProgress
                  size={16}
                  css={css`
                    margin-left: 5px;
                  `}
                />
              )}
              <br />
              <b>Cost to harvest all Chic-A-Dees:</b>{' '}
              {harvestEggsPrices && chicInfo ? (
                `$${(harvestEggsPrices.forMany * chicInfo.chicBalances.length).toFixed(2)}`
              ) : (
                <CircularProgress
                  size={16}
                  css={css`
                    margin-left: 5px;
                  `}
                />
              )}
            </div>
            <small
              css={css`
                margintop: 15px;
              `}
            >
              Harvest fee is based on a set gas amount and varying Ethereum rates.
            </small>
            <div
              css={css`
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
              `}
            >
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                isButtonDisabled={isSubmitLoading}
                onClick={() => {
                  sendToStripeCheckout(false);
                }}
              >
                Harvest or initialize this Chic-A-Dee
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                isButtonDisabled={isSubmitLoading}
                onClick={() => {
                  sendToStripeCheckout(true);
                }}
              >
                Harvest or initialize all Your Chic-A-Dees
              </Button>

              <p
                css={css`
                  margin-bottom: 0px;
                  padding-bottom: 0px;
                `}
              >
                Your total harvested EGGS: {chicInfo.userBalance}
              </p>
              <p>Total supply of harvested EGGS from all users: {chicInfo.totalSupply}</p>
            </div>
          </div>
        </>
      }
      IconComponent={DoubleArrowIcon}
    />
  );
}

export default withRouter(withStyles(styles)(LayEggs));

import React, { useState, useCallback } from 'react';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as LogoIcon } from '../assets/Logo.svg';
import { ReactComponent as MetamaskIcon } from '../assets/Metamask.svg';
import { ReactComponent as DiscordIcon } from '../assets/Discord.svg';
import { ReactComponent as BurgerIcon } from '../assets/Burger.svg';

import config from 'Config/firebase';
import useStyles from './style';

const HeaderSection = () => {
  const [MMAccounts, setMMAccounts] = useState(null);
  const [qty, setQty] = useState(1);
  const classes = useStyles();

  // const handleConnectMetamask = useCallback(async () => {
  //   if (typeof window.ethereum !== 'undefined' && !MMAccounts) {
  //     try {
  //       const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //       setMMAccounts(accounts);
  //       return accounts;
  //     } catch (err) {
  //       console.log(`err`, err);
  //       alert('connection failed');
  //     }
  //   } else {
  //     alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
  //   }
  // }, [MMAccounts]);

  const handleConnectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        if (window.ethereum.networkVersion !== config.ethNetworkCode) {
          alert('You are on the wrong network');
          throw new Error('You must be on Ethereum Main network');
        }

        setMMAccounts(accounts);
        return accounts;
      } catch (err) {
        console.log(`err`, err);
        alert('connection failed');
      }
    } else {
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  return (
    <Box
      className={classes.navWrapper}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={12.5}
    >
      <a href="/" className={classes.hideMobile}>
        <LogoIcon height={44} />
      </a>

      {/* <IconButton className={classes.hideDesktop}>
        <BurgerIcon width={48} height={48} />
      </IconButton> */}

      <Box display="flex">
        {/* <Button onClick={handleConnectMetamask} variant="outlined" mr={2} className={classes.navBtn}>
          <Typography variant="body1" component="span" className={classes.navBtnLabel}>
            {!MMAccounts ? 'Connect to Metamask' : `Connected to: ${MMAccounts[0]}`}
          </Typography>
          <MetamaskIcon width={32} />
        </Button> */}

        <Button variant="outlined" size="small" className={classes.navBtn}>
          <Typography variant="body1" component="span" className={classes.navBtnLabel}>
            <a className={classes.navLink} href="https://discord.gg/h5rex3zxmf" target="new">
              Discord Community
            </a>
          </Typography>

          <DiscordIcon width={32} />
        </Button>
      </Box>
    </Box>
  );
};

export default HeaderSection;

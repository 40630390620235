import React, { useState, forwardRef, useEffect } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import { useFormik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';

import config from 'Config/firebase';
import axios from 'Services/axios';
import firebaseService from 'Services/firebaseService';
import Button from 'Shared/Button';
import { FormInput } from './FormInput';
import * as Yup from 'yup';
import CloseIcon from './assets/cross.svg';
import CheckIcon from './assets/check.svg';
import { useStyles } from './style';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export function ContactConfirmModal({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="lg">
      <div className={classes.messageConfirmModalContainer}>
        <div>
          <img src={CheckIcon} alt="confirmed" />
        </div>
        <div className={classes.messageConfirmModalText}>
          Thanks! <br />
          Your message is sent!
        </div>
        <div className={classes.messageConfirmModalButton}>
          <Button handleClick={onClose} btnLabel="Got it" background />
        </div>
      </div>
    </Dialog>
  );
}

export function ContactModal({ open, onClose, ethAddr, showConfirm, user, tokenId, contract, tokenName }) {
  const classes = useStyles();

  const [errorMsg, setErrorMsg] = useState('');
  const [disability, setDisable] = useState(false);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);

  const messageScheme = Yup.object().shape({
    message: Yup.string().min(5, 'Message too short').max(1800, 'Message too long').required('Message is required'),
  });

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: messageScheme,
  });

  useEffect(() => {
    setEmail(user?.email);
  }, [user]);

  const handleClick = async () => {
    setDisable(true);

    const validationResult = await axios.post('/checkRecaptcha', { captchaToken });

    if (validationResult?.data?.status !== 'success') {
      setDisable(false);
      return;
    }

    firebaseService
      .isOwnerValid(ethAddr)
      .then(({ data: res }) => {
        if (res.status === 'error') {
          setDisable(false);
          setErrorMsg(res.message);
        } else {
          const { message } = formik.values;

          const body = message;

          firebaseService
            .sendMessageToOwner({ body, ethAddr, tokenId, contract, tokenName })
            .then(({ data: result }) => {
              setDisable(true);
              setCaptchaToken(null);
              setSent(true);
              formik.handleReset();
              showConfirm(true);
              onClose();
            })
            .catch(() => {
              setDisable(false);
              setCaptchaToken(null);
              setErrorMsg('Owner is not currently on file.');
            });
        }
      })
      .catch(() => {
        setDisable(false);
        setErrorMsg('Owner is not currently on file.');
      });
  };

  const verifyCaptcha = res => {
    setCaptchaToken(res);
  };

  const expireCaptcha = () => {
    setCaptchaToken(null);
  };

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition} maxWidth="lg">
      <div className={classes.messageModalContainer}>
        <div className={classes.messageModalHeader}>
          <div>
            Write a message <br />
            to the owner
          </div>
          <button className={classes.closeModalButton} onClick={onClose}>
            <img src={CloseIcon} alt="Not found" />
          </button>
        </div>
        <div className={classes.messageModalBody}>
          {errorMsg && <div className={classes.errorMessage}>{errorMsg}</div>}
          <form onSubmit={formik.handleSubmit}>
            <div className={classes.messageModalDesc}>
              If we have this owner of this NFT on file we will send an email <br />
              to the owner with reply to {email}
            </div>
            <div className={classes.formContainer}>
              <FormInput
                label="message"
                name="message"
                value={formik.values.message}
                placeholder="Write your message here"
                disabled={disability}
                multiline
                onChange={formik.handleChange}
              />
              <div className={classes.captcha}>
                <ReCAPTCHA sitekey={config.captchaSiteKey} onChange={verifyCaptcha} onExpired={expireCaptcha} />
              </div>
            </div>
            <Button
              handleClick={handleClick}
              btnLabel={sent ? 'Sent!' : 'Send'}
              className={classes.formButton}
              disabled={disability}
              background
            />
          </form>
        </div>
      </div>
    </Dialog>
  );
}

/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import config from 'Config/firebase';
import { css, jsx } from '@emotion/react';
import { css as cssString } from '@emotion/css';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NameAndAddress } from 'Components/Tokens/NameAndAddress';
import Page from 'Components/Page';
import CenteredSpinner from 'Components/CenteredSpinner';
import TokensNavbar from 'Components/TokensNavbar';
import Container from 'Components/Container';
import { MainContent } from 'Components/Tokens/MainContent';
import { InfoArea } from 'Components/Tokens/InfoArea';
import { InfoRow } from 'Components/Tokens/InfoRow';
import { Star } from 'Components/Tokens/Star';
import { AuthenticityInfoRow } from 'Components/Tokens/AuthenticityInfoRow';
import useDetectPrint from 'use-detect-print';
import Footer from 'Components/Footer';
import bs58 from 'bs58';

function Certificate({ tokenId, contractAddress }) {
  const theme = useTheme();
  const [tokenInfo, setTokenInfo] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isPrinting = useDetectPrint();

  useEffect(() => {
    if (!tokenInfo) {
      const getTokenInformation = async () => {
        let _tokenId = tokenId;
        let ownerfyResponse;

        if (tokenId.length < 6) {
          //TODO if this is a foreign address this shouldn't be called
          // but for now it's just us
          ownerfyResponse = await axios.get(`${config.functionsURL}/items/${tokenId}`);
        } else {
          const bytes = bs58.decode(tokenId);
          const b58Hex = bytes.toString('hex');
          ownerfyResponse = await axios.get(`${config.ipfsLink}/${b58Hex}`);
          _tokenId = parseInt('0' + b58Hex, 16);
        }

        const OpenseaResponse = await axios.get(`${config.openseaApiUrl}v1/asset/${contractAddress}/${_tokenId}/`);

        const qrCodeImage = `https://us-central1-receiptcoin-receiptchain-de560.cloudfunctions.net/app/generateCheckMarkCode/${encodeURIComponent(
          'nft/' + tokenId,
        )}.png`;

        setTokenInfo({
          ...ownerfyResponse.data,
          owners: OpenseaResponse.data.top_ownerships,
          qrCodeImage,
        });

        setTimeout(function () {
          const videoPlayer = document.getElementById('token-video');
          if (videoPlayer) {
            videoPlayer.play();
          }
        }, 1000);
      };

      getTokenInformation();
    }
  }, [contractAddress, tokenId, tokenInfo]);

  const CertificatePageNavbar = () => (
    <TokensNavbar
      css={css`
        @media print {
          display: none;
        }
      `}
    />
  );

  const CertificatePageFooter = () => (
    <Footer
      css={css`
        @media print {
          display: none;
        }
      `}
    />
  );

  return (
    <Page
      hasContainer={false}
      isContainerPadded={false}
      wrapperStyles={cssString`
        padding-bottom: 0 !important;
      `}
    >
      {tokenInfo ? (
        <div
          css={css`
            padding-bottom: 37px;
            ${theme.breakpoints.up('sm')} {
              padding-bottom: 50px;
            }
            & a {
              color: #30b9cf;
              text-decoration: none;
            }
          `}
        >
          <div
            css={css`
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media screen {
                display: none;
              }
            `}
          >
            <img
              css={css`
                max-width: 130px;
                max-height: 40px;
                height: auto;
                ${theme.breakpoints.up('sm')} {
                  max-width: 218px;
                  max-height: 60px;
                }
              `}
              src="/img/logo-horizontal-2.png"
              alt="logo"
            />
          </div>
          <div>
            <div
              css={css`
                background-color: #fdfdfd;
                border-bottom: 1px solid #ededed;
                padding: 13px 0;
                ${theme.breakpoints.up('sm')} {
                  padding: 20px 0;
                }
              `}
            >
              <Container>
                <Typography
                  variant="h1"
                  css={css`
                    color: #04a542;
                    margin: 0;
                    font-family: 'Work Sans', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    letter-spacing: 0.18px;
                    line-height: 28px;
                    text-align: center;
                    ${theme.breakpoints.up('sm')} {
                      font-size: 34px;
                      letter-spacing: 0.26px;
                      line-height: 40px;
                    }
                  `}
                >
                  NFT Certificate of Authenticity
                </Typography>
              </Container>
            </div>

            <div
              css={css`
                padding: 30px 0;
                ${theme.breakpoints.up('sm')} {
                  padding: 36px 0;
                }
              `}
            >
              <Container isCentered>
                <a href={`/token/${contractAddress}/${tokenId}`}>
                  <Typography
                    variant="h1"
                    css={css`
                      color: #4d5153;
                      margin: 0;
                      font-family: 'Nunito Sans', sans-serif;
                      font-weight: bold;
                      font-size: 20px;
                      letter-spacing: 0.15px;
                      line-height: 27px;
                      text-align: center;
                      ${theme.breakpoints.up('sm')} {
                        font-size: 26px;
                        letter-spacing: 0.2px;
                        line-height: 36px;
                      }
                    `}
                  >
                    {tokenInfo.name}
                  </Typography>
                </a>
                <div
                  css={css`
                    display: flex;
                    margin-top: 10px;
                    max-width: 651px;
                    margin: 0 auto;
                    margin-top: 24px;
                    ${theme.breakpoints.up('sm')} {
                      margin-top: 30px;
                      flex-direction: row;
                    }
                  `}
                >
                  <a href={`/token/${contractAddress}/${tokenId}`}>
                    <MainContent animationUrl={tokenInfo.animation_url} imageSrc={tokenInfo.image} />
                  </a>
                  <img
                    css={css`
                      display: none;
                      ${theme.breakpoints.up('sm')} {
                        display: block;
                        margin-left: 60px;
                      }
                      @media print {
                        display: block;
                        margin-left: 60px;
                      }
                    `}
                    src={tokenInfo.qrCodeImage}
                    alt="qr code"
                    width="157"
                    height="157"
                  />
                </div>
              </Container>
            </div>
          </div>
          <div
            css={css`
              flex: 1;
              width: 100%;
            `}
          >
            <Container>
              <InfoArea
                title="NFT Info"
                css={css`
                  margin: 0 auto;
                  max-width: 651px;
                `}
              >
                <InfoRow label="Token ID" info={tokenId} isContentStacked={isMobile && !isPrinting} />
                <InfoRow label="Blockchain" info="Ethereum" isContentStacked={isMobile && !isPrinting} />

                <InfoRow
                  label="Smart Contract"
                  isContentStacked={isMobile && !isPrinting}
                  info={<NameAndAddress name="Ownerfy" address={contractAddress} />}
                />
                <InfoRow
                  label="Owned by"
                  isContentStacked={isMobile && !isPrinting}
                  info={tokenInfo.owners.map(owner => (
                    <NameAndAddress name="" address={owner.owner.address} />
                  ))}
                />
                <InfoRow
                  label="Created by"
                  isContentStacked={isMobile && !isPrinting}
                  info={<NameAndAddress name={tokenInfo.creator} address={tokenInfo.creatorAddress} />}
                />
                <InfoRow
                  label="Created"
                  isContentStacked={isMobile && !isPrinting}
                  info={moment(parseInt(tokenInfo.createdAt, 10)).format('M/D/YYYY, h:mm A')}
                />
              </InfoArea>

              <InfoArea
                title={
                  <>
                    NFT Authenticity
                    <Star
                      css={css`
                        margin-left: 5px;
                        ${theme.breakpoints.up('sm')} {
                          margin-left: 10px;
                        }
                      `}
                    />
                    <Star />
                    <Star />
                    <Star />
                    <Star />
                  </>
                }
                css={css`
                  margin: 0 auto;
                  margin-top: 30px;
                  max-width: 651px;
                `}
              >
                <AuthenticityInfoRow
                  label="NFT"
                  criterias={[
                    {
                      label: 'token exists on the blockchain',
                      isVerified: true,
                    },
                    {
                      label: 'ERC-1155 standard',
                      isVerified: true,
                    },
                  ]}
                  css={css`
                    margin-top: -12px;
                  `}
                />

                <AuthenticityInfoRow
                  label="Smart Contract"
                  criterias={[
                    {
                      label: 'source code published',
                      isVerified: true,
                    },
                  ]}
                />

                <AuthenticityInfoRow
                  label="Creator"
                  criterias={[
                    {
                      label: 'verified address',
                      isVerified: true,
                    },
                    {
                      label: 'unique creator',
                      isVerified: true,
                    },
                  ]}
                />

                <AuthenticityInfoRow
                  label="Data Integrity"
                  criterias={[
                    {
                      label: 'on-chain image hashing',
                      isVerified: true,
                    },
                    {
                      label: 'off-chain metadata hash (ipfs)',
                      isVerified: false,
                    },
                  ]}
                />

                <AuthenticityInfoRow
                  label="Availability"
                  criterias={[
                    {
                      label: 'links are active',
                      isVerified: true,
                    },
                  ]}
                  css={css`
                    border: none !important;
                    margin-bottom: -14px;
                  `}
                />
              </InfoArea>

              <img
                css={css`
                  margin-top: 48px;
                  ${theme.breakpoints.up('sm')} {
                    display: none;
                  }
                  @media print {
                    display: none;
                  }
                `}
                src={tokenInfo.qrCodeImage}
                alt="qr code"
                width="100%"
                height="auto"
              />
            </Container>
          </div>
        </div>
      ) : (
        <CenteredSpinner
          css={css`
            margin-top: 150px;
          `}
        />
      )}
    </Page>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    tokenId: ownProps.match.params.tokenId,
    contractAddress: ownProps.match.params.contractAddress,
  };
}

export default withRouter(connect(mapStateToProps, null)(Certificate));

import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import PageLayout from 'Layout/Layout';
import GlobalButton from 'Shared/Button';

import GetStartedCard from './GetStartedCard';
import WizardImage from './assets/wizard.png';
import APIImage from './assets/api.png';

const useStyles = makeStyles(theme => ({
  pageContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 32,
    padding: '40px 0 90px',
    background: 'radial-gradient(ellipse at bottom, #7DC0CF, #DDF4FF)',
  },
  cardTitle: {
    marginBottom: 8,
    fontFamily: 'Lato',
    fontWeight: 700,
    fontSize: 36,
    color: '#323441',
  },
  cardDescription: {
    fontFamily: 'Lato',
    fontSizeE: 18,
    color: '#748896',
  },
  cardButton: {
    marginTop: 'auto',
    width: '100%',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    margin: '8px 0',
  },
}));

const GetStarted = () => {
  const history = useHistory();
  const classes = useStyles();

  const navigateTo = pathName => () => {
    history.push(pathName);
  };

  return (
    <PageLayout hasContainer={false} wrapperStyles={classes.pageContent}>
      <GetStartedCard>
        <div className={classes.cardTitle}>Contact us</div>
        <div className={classes.cardDescription}>If you're starting a new project, big or small we can help.</div>
        <div className={classes.imageWrapper}>
          <img src={WizardImage} alt="wizard" />
        </div>
        <GlobalButton
          className={classes.cardButton}
          btnLabel="Contact us today"
          handleClick={navigateTo('/contact')}
          background
        />
      </GetStartedCard>

      <GetStartedCard>
        <div className={classes.cardTitle}>The Api</div>
        <div className={classes.cardDescription}>
          If you are a software developer or a company with software developers the API may be right for you.
        </div>
        <div className={classes.imageWrapper}>
          <img src={APIImage} alt="api" />
        </div>
        <GlobalButton
          className={classes.cardButton}
          btnLabel="Get Started"
          handleClick={navigateTo('/api-pricing')}
          background
        />
      </GetStartedCard>
    </PageLayout>
  );
};

export default GetStarted;

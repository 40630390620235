import React from 'react';
import { TextField, Box } from "@material-ui/core";
import useStyles from './style';

const TextArea = ({
  label,
  name,
  placeholder,
  error,
  value,
  touched,
  onChange,
  ...rest
}) => {
  const classes = useStyles({ error });

  return (
    <Box>
      <TextField
        multiline
        variant="outlined"
        label={label}
        name={name}
        placeholder={placeholder}
        value={value}
        className={classes.textarea}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      {error && touched && <Box className="error">{error}</Box>}
    </Box>
  );
};

export default TextArea;
/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { css, jsx } from '@emotion/react';

import MuiMenu from '@material-ui/core/Menu';
import MuiMenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { useTheme } from '@material-ui/core/styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    paddingTop: 10,
    paddingBottom: 10,
  },
})(props => (
  <MuiMenu
    elevation={0}
    getContentAnchorEl={null}
    autoFocus={false}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    MenuListProps={{
      disablePadding: true,
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover': {
      '& svg, & .MuiTypography-root': {
        color: '#30b9cf',
        transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      backgroundColor: 'transparent',
    },
  },
}))(MuiMenuItem);

const StyledListItemIcon = withStyles(theme => ({
  root: {
    minWidth: 'auto',
    marginRight: 12,
    maxWidth: 27,
  },
}))(ListItemIcon);

const StyledListItemText = withStyles(theme => ({
  primary: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '27px',
    color: '#4D5153',
  },
}))(ListItemText);

export function Menu({ children, menuItems }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItemContent = ({ icon, iconUrl, iconAlt, text, key }) => {
    return (
      <>
        {(icon || iconUrl) && (
          <StyledListItemIcon key={key}>
            {icon || (
              <img
                src={iconUrl}
                alt={iconAlt}
                css={css`
                  width: 100%;
                `}
              />
            )}
          </StyledListItemIcon>
        )}
        <StyledListItemText primary={text} />
      </>
    );
  };

  const renderMenuItem = ({ icon, iconUrl, iconAlt, text, href, onClick, key }) => {
    if (onClick) {
      return (
        <StyledMenuItem key={key} onClick={() => onClick({ closeMenu: handleClose })}>
          {renderMenuItemContent({ icon, iconUrl, iconAlt, text, key })}
        </StyledMenuItem>
      );
    }

    return (
      <StyledMenuItem button component="a" href={href} target="_blank">
        {renderMenuItemContent({ icon, iconUrl, iconAlt, text })}
      </StyledMenuItem>
    );
  };

  return (
    <>
      <span
        css={css`
          cursor: pointer;
        `}
        onClick={handleClick}
      >
        {children}
      </span>
      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map(({ icon, iconUrl, iconAlt, text, href, onClick, key }) =>
          renderMenuItem({ icon, iconUrl, iconAlt, text, href, onClick, key }),
        )}
      </StyledMenu>
    </>
  );
}

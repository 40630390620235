import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: '38px',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: '#1F2029',
    margin: '0px',
    textAlign: 'center',
  },
  tableContainer: {
    marginTop: '22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // maxWidth: '764px',
  },
  table: {
    width: '100%',
    textAlign: 'center',
    borderSpacing: '0 10px',
    borderCollapse: 'separate',
    borderStyle: 'hidden',
  },
  row: {
    background: '#FFFFFF',
    borderRadius: '10px',
    minHeight: '70px',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 20px',
    position: 'relative'
  },
  tableCell: {
    width: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    textAlign: 'center',
    wordBreak: 'break-word',
    fontSize: '14px',
    fontFamily: 'Lato',
  },
  TableHeaderRow: {
    borderRadius: '10px',
    minHeight: '70px',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 20px',
  },
  link: {
    color: '#476DDC'
  },
  linkWithBorder: {
    border: '1px solid #21D7BB',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: '#21D7BB',
    padding: '8px 15px',
    cursor: 'pointer'
  },
  apiKeyBox: {
    padding: '40px 44px',
  },
  apiKeyBoxLabel: {
    fontSize: '18px',
    color: "#323441",
    margin: '0'
  },
  apiKeyContainer: {
    padding: '14px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    width: "626px",
    margin: '14px 0',
  },
  apiKeyValue: {
    fontSize: '14px',
    color: '#748896'
  },
  apiKeyCopyClipboard: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    color: '#476DDC',
    fontSize: '18px',
    cursor: 'pointer'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
export { useStyles };
import React, { useState, useEffect } from 'react';
import { Box, Button } from '@material-ui/core';
import HeroImg from '../assets/hero.png';
import { getZombiesAvailableForFreeMint, generateValidationToken, getFreeZombieMintParams } from 'Services/api';
import { ReactComponent as MetamaskIcon } from '../assets/Metamask.svg';
import { ReactComponent as CreditCardIcon } from '../assets/CreditCard.svg';
import { BigNumber } from '@ethersproject/bignumber';
import { utils, ethers } from 'ethers';
import config from 'Config/firebase';
import axios from 'axios';
import zombieFriends from 'ABI/zombieFriends.json';
import useStyles from './style';

const HeroSection = () => {
  const classes = useStyles();
  const [MMAccounts, setMMAccounts] = useState(null);
  const [freeMints, setFreeMints] = useState([]);
  const [loadingMints, setLoadingMints] = useState(false);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [amountMinted, setAmountMinted] = useState(0);
  const [qty, setQty] = useState(1);

  useEffect(() => {
    axios.get(`${config.functionsURL}/getTotalMintedZombies`).then(res => {
      const countTo = res.data.amount;
      const easeOutQuad = t => t * (2 - t);
      const frameDuration = 2000 / 60;
      let frame = 0;
      const totalFrames = Math.round(2000 / frameDuration);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        setAmountMinted(Math.ceil(countTo * progress));

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    });
  }, [setAmountMinted]);

  const handleConnectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        if (window.ethereum.networkVersion !== config.ethNetworkCode) {
          alert('You are on the wrong network');
          throw new Error('You must be on Ethereum Main network');
        }
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        // const from = accounts[0];

        // setLoadingMints(true);

        // // Get NFTs available for this wallet
        // let { nftList: nftsAvailable, qty } = await getZombiesAvailableForFreeMint({
        //   address: from,
        // });

        // setTotalAvailable(qty);
        // setQty(qty);
        // setFreeMints(nftsAvailable);

        // setMMAccounts(accounts);
        // setLoadingMints(false);
        return accounts;
      } catch (err) {
        console.log(`err`, err);
        alert('connection failed');
      }
    } else {
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  const mint = async () => {
    let _accounts = MMAccounts ? MMAccounts[0] : [];
    try {
      _accounts = await handleConnectMetamask();
    } catch (err) {
      console.log('connect metamask error');
      return;
    }
    //return;
    if (_accounts && _accounts.length) {
      window.gtag('event', 'click_metamask', {
        event_category: 'ecommerce',
        event_label: 'metamask_click',
        value: parseInt(qty, 10),
      });

      setLoadingMints(true);

      // let validationToken = await generateValidationToken({
      //   address: _accounts[0],
      // });

      // const string = `Ownerfy: ${_accounts[0].toLowerCase()} ${validationToken}`;

      // const msg = `0x${Buffer.from(string, 'utf8').toString('hex')}`;
      // const signature = await window.ethereum.request({
      //   method: 'personal_sign',
      //   params: [msg, _accounts[0]],
      // });

      // const { _nonce, _qty, _sig } = await getFreeZombieMintParams({
      //   address: _accounts[0],
      //   signature,
      // });

      // console.log('_nonce, _qty, _sig :::: ', _nonce, _qty, _sig, config.zombieContract);
      //let token;

      // try {

      //   const res = await axios.get(`${config.functionsURL}/checkWhitelist/${_accounts[0]}`);

      //   token = res.data.wlc;
      // } catch (err) {
      //   alert('Not on zombielist');
      //   console.log('zombielist token', err);
      //   return;
      // }

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contract = new ethers.Contract(config.zombieContract, zombieFriends.abi, provider);

      const contractWithSigner = contract.connect(signer);

      const price = BigNumber.from('125000000000000000');
      const totalPrice = price.mul(qty);

      if (qty > 20) {
        alert('Max 20 per zombielist');
        setLoadingMints(false);
        return;
      }

      // when ready switch this out

      try {
        // let mintTx = await contractWithSigner.mint(utils.hexlify(parseInt(qty, 10)), _accounts[0], {
        //   value: totalPrice.toHexString(),
        // });
        let mintTx = await contractWithSigner.zombieListMint(utils.hexlify(parseInt(qty, 10)), 2500, {
          value: totalPrice.toHexString(),
        });
        setLoadingMints(false);
      } catch (err) {
        console.warn('error:: ', err);
        setLoadingMints(false);
        alert(err.message);
      }
    } else {
      handleConnectMetamask();
      alert('Metamask was not connected. Please try again after metamask is connected.');
    }
  };

  return (
    <Box>
      <Box style={{ position: 'relative', overflow: 'hidden' }}>
        <Box className={classes.heroImgWrapper}>
          <img
            className={classes.heroImg}
            src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/collections%2Fzombiefriends%2Fjpzombies_hero.png?alt=media&token=fb5bb945-f862-44da-9993-f67dc6cabe58"
            alt="Zombie"
          />
        </Box>

        <Box px={4} className={classes.heroDescriptionWrapper}>
          <Box className={classes.heroDescription}>10,000 ZOMBIE FRIENDS</Box>

          <Box className={classes.heroCaptions}>
            <div>
              <a style={{ textDecoration: 'none' }} href="/jpandfriends">
                J. Pierce &amp; Friends holders
              </a>
            </div>
            <div>Minting will resume TBA </div>
            {/* <div>Minting has started. The price is 0.125 ETH (~$199). Max 20 per Wallet. </div> */}
          </Box>
        </Box>
      </Box>

      <Box pb={10} className={classes.heroActionbtns}>
        {/* <Button onClick={handleConnectMetamask} className={classes.checkBtn}>
          <span style={{ marginRight: 16 }}>{loadingMints ? 'Loading...' : 'Check Free Mints Available'}</span>
        </Button> */}

        {/* {freeMints.map(nft => (
          <div key={nft.tokenId} className={classes.tokens}>
            <span className={classes.tokenId}>You own JPF token ID {nft.tokenId} and it has </span>
            <span className={classes.tokenUsed}>{nft.used ? 'been used already' : 'not been used'}.</span>
          </div>
        ))} */}

        {/* {freeMints.length > 0 && (
          <span className={classes.tokens}>
            *For security there is only 1 free mint transaction per address so mint them all at once. <br />
            You have {totalAvailable} available to mint. Once you click mint they will be marked as redeemed.
          </span>
        )} */}

        {/* <Box className={classes.heroCaptions}>
          <div>{amountMinted} / 10000 Minted &ndash; Last Mint Receives 3 ETH</div>
        </Box> */}

        {/* <Button
          onClick={() => {
            window.location.href = `/contractmint?collection=zombiefriends&type=zombieFriends&quantity=${qty}`;
          }}
          className={classes.creditcardBtn}
        >
          <span style={{ marginRight: 16 }}>Mint With Credit Card $200</span>
          <CreditCardIcon width={32} />
        </Button>

        <Button
          onClick={() => {
            window.location.href = `/contractmint?collection=zombieFriends&type=nxlZombie&quantity=${qty}`;
          }}
          className={classes.creditcardBtn}
        >
          <span style={{ marginRight: 16 }}>Mint Super Zombie $1000</span>
          <CreditCardIcon width={32} />
        </Button> */}

        {/* <div className={classes.qtyContainer}>
          <label className={classes.qty}>Quantity</label>
          <input value={qty} onChange={e => setQty(e.target.value)} placeholder="Quantity" className={classes.input} />
        </div>

        <Button onClick={mint} className={classes.metamaskBtn}>
          <span style={{ marginRight: 16 }}>{loadingMints ? 'Loading...' : 'Mint with ETH Wallet'}</span>
          <MetamaskIcon width={32} />
        </Button> */}

        <a target="os" href="https://opensea.io/collection/jpiercezombiefriends">
          <img alt="opensea" src="/img/chicadees/opensea.svg" className={classes.footerLogo} />
        </a>
      </Box>
    </Box>
  );
};

export default HeroSection;

import { Checkbox as MUICheckbox, FormControl, FormHelperText, Box } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import { Controller } from 'react-hook-form';

const Checkbox = React.memo(({ name, required, parseError, label, ...rest }) => (
  <Controller
    name={name}
    render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
      // eslint-disable-next-line no-nested-ternary
      const helperText = error
        ? typeof parseError === 'function'
          ? parseError(error)
          : error.message
        : rest.helperText;
      return (
        <FormControl required={required} error={invalid}>
          <Box display="flex" alignItems="center">
            <MUICheckbox
              color="primary"
              style={{
                color: invalid ? red[400] : undefined,
              }}
              value={value}
              checked={!!value}
              onChange={() => {
                onChange(!value);
                // setValue(name, !formValue, { shouldValidate: true })
              }}
              {...rest}
            />

            {label && <Box ml={1}>{label}</Box>}
          </Box>
          {helperText && <FormHelperText error={invalid}>{helperText}</FormHelperText>}
        </FormControl>
      );
    }}
  />
));

export default Checkbox;

/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import config from 'Config/firebase';
import { css, jsx } from '@emotion/react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Page from 'Components/Page';
import * as actions from 'Store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import Loading from 'Components/Loading';
import Link from '@material-ui/core/Link';
import { createStripeCheckoutSessionForNFTCreation, getNftCreationFee } from '../Services/api';
import InPageSignupLogin from 'Components/InPageSignupLogin';

function Register({ urlCode, user, item: tokenInfo, getItem, updateItem, registerWithFirebase, history }) {
  const [nftPrice, setNftPrice] = useState(null);
  const [wasClaimedByDifferentUser, setWasClaimedByDifferentUser] = useState(false);
  const [wasClaimed, setWasClaimed] = useState(false);
  const [wasMintedByCurrentUser, setWasMintedByCurrentUser] = useState(false);
  const [isSignupLoginFlowValid, setIsSignupLoginFlowValid] = useState(false);
  const [userSignupValues, setUserSignupValues] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getItem(urlCode);
  }, [getItem, urlCode]);

  useEffect(() => {
    const fetchNftFee = async () => {
      const nftFee = await getNftCreationFee();
      setNftPrice(nftFee);
    };

    fetchNftFee();
  }, []);

  useEffect(() => {
    if (tokenInfo && tokenInfo.itemId && user) {
      setIsLoading(false);
      setWasClaimedByDifferentUser(tokenInfo.claimed && tokenInfo.ownerId !== user.uid);
      setWasClaimed(tokenInfo.claimed);
      setWasMintedByCurrentUser(tokenInfo.mintStatus === 'complete' && tokenInfo.ownerId === user.uid);
    }
  }, [tokenInfo, user]);

  const sendToStripeCheckout = async () => {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);
    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForNFTCreation({
        item: {
          itemId: urlCode,
          ...tokenInfo,
        },
        to: user.wallets[0].pub,
      });
      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const registerUser = async ({ firstName, lastName, email, password, phone }) => {
    try {
      const registrationRequest = await registerWithFirebase({
        firstName: user?.firstName || firstName,
        lastName: user?.lastName || lastName,
        email: user?.email || email,
        password: user?.password || password,
        phone: user?.phone || phone,
      });

      const userId = registrationRequest.payload.uid;
      return userId;
    } catch (err) {
      console.warn('User Registration error: ', err);
      alert(err);
      return;
    }
  };

  const claimItem = async ({ userId }) => {
    const _item = {
      info: {
        itemId: urlCode,
        ownerId: userId,
        claimed: true,
      },
    };

    return updateItem(_item);
  };

  const onSubmit = async ({ data, shouldBeMinted = false, wasPreviouslyClaimed = false }) => {
    let { firstName, lastName, email, password, phone } = userSignupValues;
    setIsLoading(true);

    if (!wasPreviouslyClaimed) {
      try {
        const userId = user?.uid || (await registerUser({ firstName, lastName, email, password, phone }));
        await claimItem({ userId });
      } catch (err) {
        setIsLoading(false);
        setIsError(true);
        return;
      }
    }

    if (!user || !user.wallets) {
      return;
    }

    if (shouldBeMinted) {
      await sendToStripeCheckout();
      return;
    }

    history.push(`/register/${urlCode}/success`);
  };

  const onSignupLoginValidationChange = (isValid, formValues) => {
    setIsSignupLoginFlowValid(isValid);
    setUserSignupValues(formValues);
  };

  if (wasClaimedByDifferentUser) {
    return (
      <Page>
        <div style={{ marginTop: 50 }}>
          <h1>The item has already been claimed</h1>
          <p>The code {urlCode} has already been claimed by another user.</p>
          <p>
            If this is a new tag or seems incorrect please contact{' '}
            <a href="mailto:support@ownerfy.com">support@ownerfy.com</a>.
          </p>
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              css={css`
                margin-top: 30px;
                color: #ffffff;
                &:hover {
                  text-decoration: none;
                }
              `}
              component={Link}
              href={`/nft/${urlCode}`}
            >
              View Item Information
            </Button>
          </div>
        </div>
      </Page>
    );
  }

  if (wasMintedByCurrentUser) {
    return (
      <Page>
        <div style={{ marginTop: 50 }}>
          <h1>You have already claimed and minted this item.</h1>
          <p>Visit the NFT information page for all the details.</p>
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              css={css`
                margin-top: 30px;
                color: #ffffff;
                &:hover {
                  text-decoration: none;
                }
              `}
              component={Link}
              href={`/nft/${urlCode}`}
            >
              View Item Information
            </Button>
          </div>
        </div>
      </Page>
    );
  }

  if (isError) {
    return (
      <Page>
        <div style={{ marginTop: 50 }}>
          <h1>Incorrect Code</h1>
          <p>The code {urlCode} does not exist or a server error occurred.</p>
          <p>
            Please try again. If this is a new tag or seems incorrect please contact{' '}
            <a href="mailto:support@ownerfy.com">support@ownerfy.com</a>.
          </p>
        </div>
      </Page>
    );
  }

  const getLoadingMessage = () => {
    if (!user) {
      return 'Creating user...';
    }

    if (wasClaimed) {
      return 'Checking out...';
    }

    if (tokenInfo && tokenInfo.itemId && user) {
      return 'Claiming item...';
    }

    return 'Getting item details...';
  };

  return (
    <Page>
      {isLoading ? (
        <Loading
          type={'bubbles'}
          color={'#2bc8c5'}
          height={'80px'}
          width={'80px'}
          containerHeight={'40vh'}
          containerWidth={'100%'}
          message={getLoadingMessage()}
        />
      ) : (
        <>
          {wasClaimed ? (
            <Typography variant="h1">Mint NFT for {tokenInfo?.name ? `(${tokenInfo.name.trim()}) ` : ''}</Typography>
          ) : (
            <Typography variant="h1">Claim {tokenInfo?.name ? `(${tokenInfo.name.trim()}) ` : ''}Ownership</Typography>
          )}

          {tokenInfo?.image && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
            >
              <img
                src={tokenInfo.image}
                alt={tokenInfo.name}
                css={css`
                  max-height: 30vw;
                `}
              />
            </div>
          )}

          <InPageSignupLogin onValidationChange={onSignupLoginValidationChange} />

          <div
            css={css`
              margin-top: 30px;
            `}
          >
            <Typography
              variant="h3"
              css={css`
                margin-bottom: 20px;
              `}
            >
              This record is currently not on the blockchain. Do you want to mint this item as an NFT?
            </Typography>
            {!wasClaimed && (
              <Typography
                variant="body1"
                css={css`
                  margin-bottom: 20px;
                `}
              >
                This record can be claimed on Ownerfy for record-keeping, but it won't be an NFT on the blockchain until
                it is minted.
              </Typography>
            )}

            <Typography variant="body1">Benefits of NFTs:</Typography>
            <ul>
              <li>You get an anti-counterfeit limited edition NFT made just for this exact item</li>
              <li>You can transfer your ownership along with the item to enhance its value</li>
              <li>View and sell your NFT on major markets like OpenSea and Rarible</li>
            </ul>
            {!wasClaimed && (
              <>
                <Typography variant="body1">If you claim without minting:</Typography>
                <ul>
                  <li>Your ownership record will still be preserved by Ownerfy</li>
                  <li>You can mint at a later time</li>
                </ul>
              </>
            )}
          </div>

          <div
            css={css`
              align-self: flex-start;
              margin-top: 40px;
              margin-bottom: 48px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            `}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              css={css`
                margin-bottom: 16px;
                min-width: 305px;
              `}
              disabled={!(user?.uid || isSignupLoginFlowValid)}
              //disabled
              onClick={() => onSubmit({ shouldBeMinted: true, wasPreviouslyClaimed: wasClaimed })}
            >
              {nftPrice ? (wasClaimed ? `Mint NFT for $${nftPrice}` : `Claim & Mint NFT for $${nftPrice}`) : 'Mint NFT'}
            </Button>
            {!wasClaimed && (
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                disabled={!(user?.uid || isSignupLoginFlowValid)}
                css={css`
                  min-width: 305px;
                `}
                onClick={() => onSubmit({ shouldBeMinted: false, wasPreviouslyClaimed: wasClaimed })}
              >
                Claim & Mint later
              </Button>
            )}
          </div>
        </>
      )}
    </Page>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerWithFirebase: actions.registerWithFirebase,
      updateItem: actions.updateItem,
      getItem: actions.getItem,
    },
    dispatch,
  );
}

function mapStateToProps({ user, item }, ownProps) {
  return {
    user,
    item,
    urlCode: ownProps.match.params.code,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import * as actions from 'Store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

const styles = theme => ({
  header: {
    marginTop: 30,
  },
  main: {
    fontSize: 18,
    marginBottom: 70,
  },
  textArea: {
    fontSize: 20,
  },
  submit: {
    margin: '20px 20px 20px 0',
  },
  title: {
    fontWeight: 'bold',
  },
});

function NFTStandards({ setActiveModal, classes }) {
  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container component="main" maxWidth="sm">
          <Typography component="h1" variant="h1" className={classes.header}>
            NFT Community Standards
          </Typography>

          <p>
            While blockchain records such as NFTs are nearly impossible to forge, there are many properties of NFTs that
            easily make them confusing and open for misinterpretation. Without standards, the industry is open to abuse
            of the technology which can lead to confusion and mistrust. In order for the technology to establish its
            usefulness and value, standards have been created by Ownerfy to protect both the creators and consumers of
            NFTs.
          </p>

          <p>
            To collaborate on NFT standards, industry leaders are invited to join with Ownerfy to help build the NFT
            Standards Community.{' '}
          </p>

          <p>
            Ownerfy has identified six common NFT problems, best practices, and questions you should ask before minting
            an NFT.
          </p>

          <Typography id="5" component="h1" variant="h5" className={classes.header}>
            Problem 1. No Publicly Available Proof of Data Integrity
          </Typography>

          <p>
            <strong>Why is this a problem in terms of usefulness and value?</strong>
          </p>

          <p>
            <strong>Creators</strong>: Creators might release an NFT and then the platform changes the metadata url or
            stops serving their content. This would devalue the NFT.
          </p>

          <p>
            <strong>Consumers</strong>: Consumers could buy an NFT and then discover the content has changed a year from
            now. This would devalue the NFT.
          </p>

          <p>
            <strong>What should you do to check to see if this is covered?</strong>
          </p>

          <p>
            <strong>Creators</strong> should check to see that a platform saves metadata such as a digital signature of
            the data (hash) or uses IPFS or both, on the blockchain.
          </p>

          <p>
            <strong>Consumers</strong> should also check to see that a platform saves metadata such as a digital
            signature of the data or uses IPFS or both, on the blockchain.
          </p>

          <p>
            Many NFTs are purchased and there is no proof of the data integrity. Most NFTs today have been created
            according to the ERC-721 and ERC-1155 standards. While outlining the bare minimum functionality of
            non-fungible blockchain tokens, these standards omit essential data necessary to create a link between the
            tokens themselves and the digital art, music, or other property. Without this essential data it is
            impossible to know, without external information, whether one token represents for example a painting, and
            another represents a written document.
          </p>

          <p>
            For the vast majority of NFTs today the external data that defines what the token is supposed to represent
            is saved on the blockchain only as a centralized web link, such as www.mytokensite.com/token-meta-data/10.
            Several problems can occur with this method. The website can go offline. The site owner could stop paying
            their bill or the website could return an error. Another problem is a malicious token site could change the
            token-related data returned from the URL from day to day. Since the data is not on the blockchain there is
            no guarantee the information that defines the NFT remains the same over time. Any NFTs created this inferior
            way would be impossible to rely upon for provenance, legal evidence, or financial purposes.
          </p>

          <p>
            <strong>Best practice:</strong> Hash Data is on the Blockchain To Validate Data Consistency How can you make
            sure?
          </p>

          <p>
            The easiest way to ensure that lined data does not change over time is to make use of the IPFS. IPFS links
            are guaranteed to return the same content regardless of where they are served from, with the exception of
            directory links, which may have modified content. If an IPFS link is used for the metadata then it can be
            assured to not change over time. However, this does not account for the integrity of the image. An image
            link is embedded in the metadata returned by the metadata link. If the image link is a non-IPFS link then
            the image can still be changed over. In order to guarantee the image also does not change, it’s link must
            also be IPFS, or the digital signature of the image (hash) must also be stored on the blockchain. If both
            the metadata is stored as an IPFS link and the image is saved in the blockchain both may be evaluated
            independently, which has greater integrity than using one method alone.
          </p>

          <p>
            <strong>Problems with IPFS.</strong> An IPFS link will stay live and active as long as a server somewhere in
            the world maintains that data. This requires a commitment by a central entity of pinning service such as
            Pinata.cloud. If this data were to stop being served the data would no longer be availabe on IPFS. Anyone
            can choose pick up and serve the data, but if no party is available to serve the data it may be lost
            forever. This is why the absolute safest place to store data, such as an image hash is on the blockchain
            itself.
          </p>

          <Typography id="5" component="h1" variant="h5" className={classes.header}>
            Problem 2. Creators Do Not Use Addresses Consistently
          </Typography>

          <p>
            <strong>Why is this a problem?</strong>
          </p>

          <p>
            <strong>Creators:</strong> If creators don’t use the same address their customers will be confused and
            counterfeiters will be able to more easily forge NFTs in their name.
          </p>

          <p>
            <strong>Consumers:</strong> Consumers can’t be sure they are receiving work from the same creators.
          </p>

          <p>
            <strong>What should you do to check to see if this is covered?</strong>
          </p>

          <p>
            <strong>Creators</strong> should try to use the same Ethereum or other platform address on multiple
            platforms.
          </p>

          <p>
            <strong>Consumers</strong> should check that a platform has verified a creator or should check against a 3rd
            party list of known creator addresses. The list currently doesn’t exist.{' '}
          </p>

          <p>
            For each NFT there is one source address that, according to the specification, is considered to be the
            creator of the NFT. Up until now there has been a disparate effort to link creator Ethereum addresses to the
            creators they are intended to belong to. Because of this flaw one creator can release NFTs under several
            Ethereum addresses and it is up to the community to divine whether NFTs both in fact belong to the same
            creator. There is no reliable source of information to describe which addresses belong to which creators.
          </p>

          <p>
            To further compound this problem sometimes we have publishers who release several artists from the same
            address. In this situation there is no link to the original creator and it’s up to the community to keep
            track, “as common knowledge”, which publisher addresses their content creators tend to use. This also opens
            the door for another kind of hack, which is the ‘sleep mint’. A sleep mint is a minting that appears to come
            from an artist but was actually minted by another party.
          </p>

          <p>
            <strong>Best Practice:</strong> Creators Use The Same Addresses to Create NFTs{' '}
          </p>

          <p>
            Platforms should maintain lists of creators and if possible cross check those lists between each other. This
            will make it easier to identify creators falsely claiming ownership. Creators should release content
            consistently using as few publicly known blockchain addresses as possible and also try to avoid publishers
            that do not mint their work on to a sole dedicated address. If multiple platforms are used it’s best to try
            and use the same address across each platform.
          </p>

          <Typography id="5" component="h1" variant="h5" className={classes.header}>
            Problem 3. False Provenance
          </Typography>

          <p>
            <strong>Why is this a problem?</strong>
          </p>
          <p>
            <strong>Creators</strong> can be easily copied.
          </p>

          <p>
            <strong>Consumers</strong> could buy NFTs from a creator claiming to be someone else, making the NFT worth
            less or nothing.
          </p>

          <p>
            <strong>What should you do to check to see if this is covered?</strong>
          </p>

          <p>
            Creators should try to be added to a list of verified creators either on a platform or by a 3rd party
            service.{' '}
          </p>

          <p>
            Consumers should check that an NFT was created by an official address or creator that is verified by a
            platform.
          </p>

          <p>
            To date there is nothing preventing anyone from uploading any piece of art or source material to the
            internet and creating an NFT that links to it. As such it is up to the community to evaluate source material
            as best as it can and identify when content does not appear to belong to the creator of the NFT. False
            content can lead many to believe that an NFT is theirs to sell or even to confuse the marketplace enough,
            resulting in inability to identify the original source.
          </p>

          <p>
            <strong>Best Practice:</strong> Platform Creators Monitoring the Creators to Screen Out Bad Actors{' '}
          </p>

          <p>
            It’s very difficult to evaluate if a given creator has the rights to publish the content they use for NFTs.
            It’s up to platforms to help the community by indicating known publishers and publishers that may have
            falsely identified themselves in the past.
          </p>

          <Typography id="5" component="h1" variant="h5" className={classes.header}>
            Problem 4. Rug Pulls
          </Typography>

          <p>
            <strong>Why is this a problem?</strong>
          </p>

          <p>
            <strong>Creators</strong> might think that features of their NFT are always stable.{' '}
          </p>

          <p>
            <strong>Consumers</strong> might buy an NFT believing they have full rights and it will not change.
          </p>

          <p>
            <strong>What should you do to check to see if this is covered?</strong>
          </p>

          <p>
            <strong>Creators</strong> should check the smart contract or have it reviewed by an expert to see if the
            URLs can be changed, the contract can be paused or other 'administrative' features exist that they are not
            aware of.{' '}
          </p>

          <p>
            <strong>Purchasers</strong> should check a review of the smart contract.
          </p>

          <p>
            Certain functionality can be built into smart contracts that can freeze transfers, transfer without owner
            consent or change the content of the NFT. Sometimes these functions are necessary during an early sale phase
            to enure there are no problems. Sometimes they are necessary for specific project functionality and made
            known. Sometimes the intention is to release control. Sometimes the controls are nefarious and in these
            instances smart contract code is probably not visible on etherscan.
          </p>

          <p>
            <strong>Best Practice:</strong> Smart contract source code should be published and if administrative rights
            are granted they should be renounced at some point unless it is publicly part of the plan to always be under
            3rd party control.
          </p>

          <p>
            NFT creators should always publish source code and that code should grant ultimate power to the owners of
            the NFTs. Functions like pausing, setBaseUri, and built in transfer approvals create back doors and control
            over nft properties. If these functions are necessary it should be explained or there should be a plan to
            renounce these administrative powers when they are no longer necessary.
          </p>

          <Typography id="5" component="h1" variant="h5" className={classes.header}>
            Problem 5. Poor Source Material - Generations Removed from the Original
          </Typography>

          <p>
            <strong>Why is this a problem?</strong>
          </p>

          <p>
            <strong>Creators</strong> might create an NFT but it is not the quality level the customers need.{' '}
          </p>

          <p>
            <strong>Consumers</strong> might buy an NFT but the quality is not suitable for their needs.
          </p>

          <p>
            <strong>What should you do to check to see if this is covered?</strong>
          </p>

          <p>
            <strong>Creators</strong> - If the NFT is digital media then it should be the highest quality version of
            that media. If the NFT represents something physical then the metadata and description should be the best
            possible image and description.{' '}
          </p>

          <p>
            <strong>Purchasers</strong> should review the quality of the NFT.
          </p>

          <p>
            Depending on what an NFT is intended to represent, the source material may affect its value. If an NFT is
            intended to represent art then the image included should be as close to the original work as is possible. If
            an NFT is intended to represent a physical painting then the included image should be of high enough quality
            to effectively identify that unique painting. If an NFT is intended to represent original music then a
            version of the music that most closely represents the piece should be linked to. Ultimately an NFT
            represents the media linked to by the NFT more so than anything else. A customer can buy an NFT believing it
            represents an original work of art, but if the image is a derivative work and better source material exists
            then an NFT based on the better source material will always be more representative of the source material.
            While the community accepts the most accurate source material as the most authentic NFT, there are no
            accepted standards, leading to confusion from NFT creators and consumers.
          </p>

          <p>
            <strong>Best Practice:</strong> Achieve The Highest Value by Having an NFT Closest to The Original Work
          </p>

          <p>
            NFT creators should use source material that best and most accurately represents the media or subject they
            are attempting to embody. If it is an original digital work then the intended work released by the artist
            should be used as the source material. If it is a physical object then a high-resolution photograph from an
            encompassing angle should be used. If it is a piece of music then best quality recording or original source
            file should be used.
          </p>

          {/* <Typography id="5" component="h1" variant="h5" className={classes.header}>
          Problem 5. Source Material That Does Not Meets the Artist’s Intentions
        </Typography>

        <p>
          <strong>Why is this a problem?</strong>
        </p>
        <p>
          <strong>Creators</strong> might create an NFT that the community values less.
        </p>

        <p>
          <strong>Consumers</strong> might buy an NFT and then another is released that better embodies the work and is
          more valuable.{' '}
        </p>

        <p>
          <strong>What should you do to check to see if this is covered?</strong>
        </p>

        <p>
          <strong>Creators</strong> - If creators are not the original artist they should attempt to embody the wishes
          of the artist.{' '}
        </p>

        <p>
          <strong>Consumers</strong> should make an informed decision based on the knowledge they have of the artist.{' '}
        </p>

        <p>
          <strong>Best Practice:</strong> Create artwork that meets the highest quality rendition of how the artist
          intended the art to be appreciated.
        </p> */}

          <Typography id="5" component="h1" variant="h5" className={classes.header}>
            Problem 6. Lack of Existence of the Token (Lazy Minting)
          </Typography>

          <p>
            <strong>Why is this a problem?</strong>
          </p>

          <p>
            <strong>Creators</strong> might think they have created an NFT but the platform is misleading and it is not
            on the blockchain. The provenance can also be lost if it is on a shared smart contract.{' '}
          </p>

          <p>
            <strong>Consumers</strong> might buy an NFT and believe that the NFT already exists.
          </p>

          <p>
            <strong>What should you do to check to see if this is covered?</strong>
          </p>

          <p>
            <strong>Creators</strong> should check the minting policy of the platform and if they expect it to be on the
            blockchain they should see an etherscan transaction for it.{' '}
          </p>

          <p>
            <strong>Consumers</strong> - After a Consumer buys an NFT they should be able to see an etherscan
            transaction for it.{' '}
          </p>

          <p>
            It’s becoming increasingly common to not identify whether or not an NFT exists on the blockchain. For some
            NFT creators this matter is irrelevant because it only becomes important if the NFT needs to be transferred
            to a buyer. This becomes a problem when creators believe they are documenting the existence of certain
            content on the blockchain, but in fact are not. It is not always made clear by platform providers whether
            these tokens have been minted to the blockchain or not. These tokens can go by various names such as ‘lazy
            minted’ or ‘magic minted’ tokens. Creators and customers may not always understand they are not dealing with
            a blockchain product. A token that is not yet on the blockchain does not have the added authenticity and
            ultimate security the blockchain provides. Also, sometimes the mint is first sent to the buyer and if this
            happens on a shared smart contract the provenance is lost.
          </p>

          <p>
            <strong>Best Practice:</strong>
            It should be made very clear by platform providers when an NFT is created, whether that is at the time of
            sale or some other time. This will avoid confusion from content creators and allow them to better define the
            utility of their NFT collection.
          </p>

          <Typography id="5" component="h1" variant="h5" className={classes.header}>
            Problem 7. Published Source Code and Upgradeable Smart Contracts
          </Typography>

          <p>
            <strong>Why is this a problem?</strong>
          </p>

          <p>
            <strong>Creators</strong> Might think their NFT smart contract will never change and always function the
            same way. If a contract is upgradeable almost everything about the NFT contract may change, including who
            has permission to transfer the token. If there is no source code it's impossible to know if there are secret
            functions or if the contract is upgradebale.{' '}
          </p>

          <p>
            <strong>Consumers</strong> may expect their NFTs to behave the same way indefinitely. If a contract is
            upgraded the basic functionality of the contract including the ability to transfer may change.
          </p>

          <p>
            <strong>What should you do to check to see if this is covered?</strong>
          </p>

          <p>
            <strong>Creators</strong> should check if the source code is published and key words like
            "UpgradeabilityProxy" and "Proxy" are missing from the source code. Visit the contract's page on etherscan
            and check the "contract" tab and then "code" tab. There should be visible source code and not random
            strings.{' '}
            <a href="https://etherscan.io/address/0x22356f291a2a28f038832cef990cd8e9342508a3#code" target="contract">
              Example
            </a>{' '}
          </p>

          <p>
            <strong>Consumers</strong> should check if the source code is published and key words like
            "UpgradeabilityProxy" and "Proxy" are missing from the source code. Visit the contract's page on etherscan
            and check the "contract" tab and then "code" tab. There should be visible source code and not random
            strings.{' '}
            <a href="https://etherscan.io/address/0x22356f291a2a28f038832cef990cd8e9342508a3#code" target="contract">
              Example
            </a>{' '}
          </p>

          <p>
            Without visible source code it's impossible to tell if an NFT conforms to the NFT standards or if there are
            any hidden elements which may affect the way it functions. An upgradeable smart contract makes a smart
            contract even more vulnerable to malicious activity. A central body governs whether the code of the contract
            will change. This central body may change core features of the NFT or even break the NFT contract entirely.
          </p>

          <p>
            <strong>Best Practice:</strong>
            Platforms should avoid using upgradeable smart contracts for NFTs or decentral assets that consumers believe
            are unchanging and safe from any third party or governing body. All source code for NFT smart contracts
            should be published on to etherscan using etherscan verification tools for full transparency using{' '}
            <a href="https://etherscan.io/verifyContract" target="etherscan">
              Etherscan verification tools.
            </a>
          </p>

          <p>
            In order for NFTs to maintain value there must be standards enforced by the community. We are composing
            these standards with the intention of creating a collaboration as a community to build NFT standards we can
            all follow to increase the value and usefulness of NFTs. If you’d like to join us please{' '}
            <a href="/contact/">contact us here</a> with the option to help build our NFT Standards community.{' '}
          </p>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: actions.submitLoginWithFireBase,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(NFTStandards)));

import React from 'react';
import PageLayout from 'Layout/Layout';
import ExternalMint from './ExternalMint';
const HomePage = () => {
  return (
    <PageLayout hasContainer={false}>
      <ExternalMint />
    </PageLayout>
  );
};

export default HomePage;

import React, { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';

import NavBar from 'Components/Navbar/Navbar';
import Footer from 'Components/Footer/index';
import Container from 'Components/Container';
import Modal from 'Components/Modal';

const pageStyles = theme => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  wrapper: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    minHeight: '200px',
    flex: '1',
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

function Page({
  classes,
  children,
  hasContainer = true,
  hasNav = true,
  wrapperStyles,
  pageStyles,
  type,
  NavBarComponent = NavBar,
  FooterComponent = Footer,
}) {
  const hash = window.location.hash;
  const pathname = window.location.pathname;
  const _hasNavBackgroundTransparent = pathname === '/' && window.innerWidth > 1092;

  useEffect(() => {
    const element = document.getElementById(hash.substring(1));
    if (element) {
      setTimeout(() => element.scrollIntoView(true), 10);
    }
  }, [hash]);

  function renderChildren() {
    if (hasContainer) {
      return <Container>{children}</Container>;
    }

    return children;
  }

  return (
    <div className={clsx(classes.page, pageStyles)}>
      {hasNav === true && (
        <>
          <Modal />
          <NavBarComponent type={type} backgroundTransparent={_hasNavBackgroundTransparent} />
        </>
      )}

      <div className={classes.bottomSection}>
        <div className={clsx(classes.wrapper, wrapperStyles)}>{renderChildren()}</div>
        <FooterComponent />
      </div>
    </div>
  );
}

export default withStyles(pageStyles)(Page);

// From: https://github.com/peterpeterparker/web-social-share/blob/main/src/utils/utils.ts
import { createHash } from 'crypto-browserify';

export const isMobile = () => {
  return window?.matchMedia('(any-pointer:coarse)').matches;
};

export const staticOpenNewWindow = urlString => {
  if (window.self !== window.top) {
    window.open(urlString, '_blank');
  } else {
    window.open(urlString, '_self');
  }
};

export const shareEncodedUrl = socialShareUrl => {
  return encodeURIComponent(socialShareUrl || window.location.href);
};

export const getSha256 = async file => {
  // All of these steps are necessary in order
  // to get the hash that matches the server hash
  // The has is computed based on a binary buffer
  // This is the same or similar binary buffer in
  // uint8 format that comes from createReadStream
  // when I file is read from disk. Calculting this here
  // saves some memory space on the server, allows for
  // larger uploads and allows us to compare with a browser tool
  const uncutBase64 = file.base64;
  const blob = await (await fetch(uncutBase64)).blob();
  const buffer = await blob.arrayBuffer();
  const binBuffer = Buffer.from(buffer, 'binary');
  const hash = createHash('sha256');
  hash.update(binBuffer);
  const hashHex = hash.digest('hex');

  return hashHex;

  // NOTE: Base 64 hash can be used as well, but
  // It takes up more space to pull and convert on the server
  // const actualBase64 = uncutBase64.substr(uncutBase64.indexOf(',') + 1);
  // const hash4 = crypto.createHash('sha256');
  // hash4.update(actualBase64);
  // hash4.update(actualBase64);
  // const actualBase64Hash = hash4.digest('hex');
};

import * as Actions from '../actions';

const INITIAL_STATE = {
  sku: '',
  name: '',
  thankYouMessage: 'Thank you!',
  template: 'Registration',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_REGISTRATION_CONFIG:
      console.log('setting registration info to:: ', action.payload);
      return action.payload;
    case Actions.RESET_REGISTRATION_CONFIG:
      return INITIAL_STATE;
    case Actions.UPDATE_REGISTRATION_CONFIG:
      return action.payload;
    default:
      return state;
  }
};

import * as types from './types';

/**
 * Set active modal
 */
export function setActiveModal(name, props = {}) {
  return dispatch => {
    dispatch({
      type: types.SET_ACTIVE_MODAL,
      name,
      props,
    });
  };
}

/**
 * Close active modal
 */
export function closeActiveModal() {
  return dispatch => {
    dispatch({
      type: types.CLOSE_ACTIVE_MODAL,
    });
  };
}

import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { ReactComponent as CertifiedIcon } from '../assets/Certified.svg';
import { ReactComponent as TwitterIcon } from '../assets/Twitter.svg';
import { ReactComponent as DiscordIcon } from '../assets/Discord.svg';
import config from 'Config/firebase';
import useStyles from './style';

const FooterSection = () => {
  const classes = useStyles();

  return (
    <Box px={2} py={3}>
      <Container>
        <Grid container display="flex" alignItems="center" spacing={6}>
          <Grid item xs={12} md={3}>
            <Box className={classes.certifiedWrapper}>
              <CertifiedIcon />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={classes.smartContractWrapper}>
              <Box className={classes.smartContractTitle}>Smart Contract</Box>
              <Box className={classes.smartContractAddress}>
                {' '}
                <a target="new" href={`https://etherscan.io/address/${config.zombieContract}`}>
                  {config.zombieContract}
                </a>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box className={classes.socialWrapper}>
              <Box display="flex" justifyContent="space-bewteen" className={classes.socialIcons}>
                <a href="https://twitter.com/jpandfriendsnft" target="new">
                  <TwitterIcon />
                </a>
                {/* <OpenseaIcon /> */}
                <a className={classes.navLink} href="https://discord.gg/h5rex3zxmf" target="new">
                  {' '}
                  <DiscordIcon />
                </a>
                <a href="https://raritysniper.com/nft-drops-calendar">NFT Drops</a>
                <a href="https://icy.tools/calendar" target="_blank">
                  {' '}
                  <img src="https://cdn.icy.tools/images/icy-tools-dark.svg" alt="as seen on icy.tools" width="100" />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FooterSection;

import React from 'react';

import Banner from 'Shared/Banner';
import PageLayout from 'Layout/Layout';
import history from '../../history';

import HeroSection from './HeroSection';
import NftWizard from './NftWizard';
import NftApi from './NftApi';
import Partners from './Partners';
import WhyNfts from './WhyNfts';
import QRCode from './QRCode';

import './style.css';

const HomePage = () => {
  const navigateToPricingPage = () => {
    // history.push('/create-collection');
  };

  return (
    <>
      {/* <Banner>
        <div className="banner-wrap">
          <span className="banner-text">Give away NFTs </span>
          <span className="banner-link" onClick={navigateToPricingPage}>
            <a href="https://ownerfy.medium.com/democratizing-nfts-and-empowering-creators-with-their-new-claimable-feature-30c8f0b60be9">
              to anyone
            </a>
          </span>
          <span className="banner-text">.</span>
        </div>
      </Banner> */}

      <PageLayout hasContainer={false}>
        <HeroSection />

        <WhyNfts />
        <NftWizard />
        {/* <NftApi /> */}
        <QRCode />
        <Partners />
      </PageLayout>
    </>
  );
};

export default HomePage;

import React, { useState, useEffect } from 'react';
import { isNull } from 'lodash';
import { useStyles } from './style';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import * as actions from 'Store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStripeCheckoutSessionForCharacterCreation, getCharacter, getTotalJPFForAccount } from 'Services/api';
import config from 'Config/firebase';
import PageLayout from 'Layout/Layout';
import Container from 'Components/Container';
import SectionLayout from 'Layout/SectionLayout';
import Loading from 'Components/Loading';
import InPageSignupLogin from 'Components/InPageSignupLogin';
import FormTextField from 'Components/FormTextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Image1 from './assets/image1.png';
import Image2 from './assets/image2.png';
import Image3 from './assets/image3.png';
import Image4 from './assets/image4.png';
import creditCard from './assets/credit-card.svg';
import metamask from './assets/metamask.svg';
import checkmark from './assets/checkmark.svg';

const imageList = [Image1, Image2, Image3, Image4];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MintToCollection = ({ user, registerWithFirebase }) => {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const classess = useStyles();
  const [isLoading, setIsLoading] = useState(null);
  const [isSignupLoginFlowValid, setIsSignupLoginFlowValid] = useState(false);
  const [userSignupValues, setUserSignupValues] = useState({});
  const [characterInfo, setCharacterInfo] = useState(null);
  const [isTransferToOwnerfyWallet, setIsTransferToOwnerfyWallet] = useState(true);
  const [isExternalWalletAddressConfirmationVisible, setIsExternalWalletAddressConfirmationVisible] = useState(false);

  const quantity = Number(watch('quantity'));
  const externalWalletAddress = watch('externalWalletAddress');

  const validationSchema = Yup.object().shape({
    quantity: Yup.string().required('Quantity is required'),
  });

  useEffect(() => {
    const fetchCharacter = async () => {
      setIsLoading(true);
      const character = await getCharacter({ id: 'jpAndFriends' });

      setCharacterInfo(character.data);
      setIsLoading(false);
    };

    fetchCharacter();
  }, []);

  const sendToStripeCheckoutForCharacterCreation = async order => {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);
    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForCharacterCreation({
        order,
        cancelUrl: window.location.href,
      });

      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const registerUser = async ({ firstName, lastName, email, password, phone, username }) => {
    try {
      const registrationRequest = await registerWithFirebase({
        firstName: user?.firstName || firstName,
        lastName: user?.lastName || lastName,
        username: user?.username || username,
        email: user?.email || email,
        password: user?.password || password,
        phone: user?.phone || phone,
      });

      const userId = registrationRequest.payload.uid;
      return userId;
    } catch (err) {
      console.warn('User Registration error: ', err);
      alert(err);
      return;
    }
  };

  const finalizeSendToStripe = async () => {
    await sendToStripeCheckoutForCharacterCreation({
      id: 'jpAndFriends',
      quantity: Number(quantity),
      ...(externalWalletAddress ? { externalWalletAddress: externalWalletAddress.trim() } : {}),
    });
  };

  const onSubmit = async data => {
    if (!isTransferToOwnerfyWallet) {
      setIsExternalWalletAddressConfirmationVisible(true);
      return;
    }

    setIsLoading(true);
    let { firstName, lastName, email, password, phone, username } = userSignupValues;

    if (!user?.uid) {
      await registerUser({ firstName, lastName, email, username, password, phone });
    }

    const mintData = await getTotalJPFForAccount();

    if (parseInt(mintData.totalMinted, 10) + parseInt(data.quantity, 10) > 12) {
      alert('Max is 12 per account');
      return;
    }

    await finalizeSendToStripe();
  };

  const onSignupLoginValidationChange = (isValid, formValues) => {
    setIsSignupLoginFlowValid(isValid);
    setUserSignupValues(formValues);
  };

  function getQuantityHelperText() {
    if (errors.quantity && errors.quantity.message) {
      return errors.quantity.message;
    }

    return `Maximum quantity to mint per account is 12`;
  }

  if (isNull(isLoading)) {
    return null;
  }

  if (isLoading) {
    return (
      <PageLayout hasContainer={false}>
        <SectionLayout>
          <Container>
            <Loading
              type={'bubbles'}
              color={'#2bc8c5'}
              height={'80px'}
              width={'80px'}
              containerHeight={'40vh'}
              containerWidth={'100%'}
              message={'Loading'}
            />
          </Container>
        </SectionLayout>
      </PageLayout>
    );
  }

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <div className={classess.mintToCollectionContainer}>
            <div className={classess.mintToCollectionHeader}>
              <h4 className={classess.heading}>Mint J.Pierce & Friends NFT’s</h4>
              <p className={classess.subHeading}>Hand painted art by renowned American artis Justin Pierce</p>
              <div className={classess.headerContainer}>
                {imageList.map(image => (
                  <img src={image} />
                ))}
              </div>
            </div>
            <div className={classess.inPageSignupLogin}>
              <InPageSignupLogin onValidationChange={onSignupLoginValidationChange} />
            </div>
            <div className={classess.mintToCollectionBody}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={classess.amountSection}>
                  <div className={classess.quantityInputSection}>
                    <p className={classess.quantityLabel}>Amount</p>
                    <FormTextField
                      disableUnderline={true}
                      className={classess.quantityInputSection}
                      label="Quantity"
                      name="quantity"
                      required
                      error={!!errors.quantity}
                      helperText={getQuantityHelperText()}
                      inputRef={register({
                        required: 'Quantity is required',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'The quantity must be a whole number',
                        },
                        validate: {
                          isOverLimit: async value => {
                            if (value > 12) {
                              return 'The maximum amount of characters to mint at one time is 12';
                            }
                          },
                        },
                      })}
                      placeholder="Quantity"
                    />
                  </div>
                  <div className={classess.walletBlock}>
                    <h4 className={classess.walletBlockHeading}>
                      <div className={classess.checkboxContainer}>
                        <input
                          checked={isTransferToOwnerfyWallet}
                          value={isTransferToOwnerfyWallet}
                          type="checkbox"
                          name="wallet"
                          onChange={() => setIsTransferToOwnerfyWallet(!isTransferToOwnerfyWallet)}
                        />
                        {isTransferToOwnerfyWallet && <img src={checkmark} />}
                      </div>
                      Your Ownerfy account wallet
                    </h4>
                    <p className={classess.walletBlockDetail}>*Can be transferred to another wallet later</p>
                    <p className={classess.walletBlockDetail}>**Uncheck to send to another wallet or as a gift</p>
                  </div>
                </div>
                {!isTransferToOwnerfyWallet && (
                  <div>
                    <p className={classess.externalWalletLabel}>External wallet address</p>
                    <FormTextField
                      disableUnderline={true}
                      className={classess.externalWalletInputBox}
                      label="External Wallet Address"
                      name="externalWalletAddress"
                      required
                      error={!!errors.externalWalletAddress}
                      helperText={errors.externalWalletAddress && errors.externalWalletAddress.message}
                      inputRef={register({
                        required: 'The external wallet address is required',
                        pattern: {
                          value: /^0x[a-fA-F0-9]{40}$/,
                          message: 'The external wallet address format is incorrect',
                        },
                      })}
                      placeholder="External wallet address"
                    />
                  </div>
                )}
                <div className={classess.priceSection}>
                  <p className={classess.priceLabel}>Fees</p>
                  {quantity > 0 ? (
                    <div className={classess.priceBody}>
                      <div className={classess.priceLists}>
                        <p className={classess.price}>
                          Purchase:{' '}
                          <span className={classess.priceValue}>
                            ${(characterInfo.fees.purchaseFeeInUsd * quantity).toFixed(2)} (
                            {characterInfo.fees.purchaseFeeInEth * quantity} ETH)
                          </span>
                        </p>
                        <p className={classess.price}>
                          Processing (gas) fees:{' '}
                          <span className={classess.priceValue}>
                            ${(characterInfo.fees.gasFeeInUsd * quantity).toFixed(2)} (
                            {characterInfo.fees.gasFeeInEth * quantity} ETH)
                          </span>
                        </p>
                      </div>
                      <div className={classess.totalPriceBody}>
                        <p className={classess.totalPrice}>
                          Total: ${(characterInfo.fees.totalFeeInUsd * quantity).toFixed(2)}{' '}
                          <span className={classess.priceValue}>
                            ({characterInfo.fees.totalFeeInEth * quantity} ETH)
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={classess.priceBody}>
                      <p className={classess.emptyPrice}>Please enter a quantity.</p>
                    </div>
                  )}
                </div>
                <div className={classess.submitAction}>
                  <p className={classess.actionLabel}>Mint with</p>
                  <div className={classess.actionButtons}>
                    <button type="submit" className={classess.button} disabled={!(user?.uid || isSignupLoginFlowValid)}>
                      <img className={classess.buttonImg} src={creditCard} />
                      <span>Credit Card</span>
                    </button>
                    <button className={classess.button} disabled={!(user?.uid || isSignupLoginFlowValid)}>
                      <img className={classess.buttonImg} src={metamask} />
                      <span>MetaMask</span>
                    </button>
                  </div>
                </div>
              </form>
              <Dialog
                open={isExternalWalletAddressConfirmationVisible}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setIsExternalWalletAddressConfirmationVisible(false)}
              >
                <DialogTitle id="alert-dialog-slide-title" className={classess.dialogTitle}>
                  Confirm external wallet address
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description" className={classess.dialogContent}>
                    Please confirm that this is the correct external wallet address. Do not use an exchange address or
                    it will be lost. This is the address that the NFT will be sent to. If this is incorrect, we can not
                    retrieve the NFT or issue refunds. If you are not sure, we recommend you use the option to mint to
                    your Ownerfy wallet address automatically by checking the <u>Send to Ownerfy Account Wallet</u>{' '}
                    checkbox.
                    <br />
                    <br />
                    <b>External Wallet Address the NFT will be sent to:</b>
                    <br />
                    {externalWalletAddress}
                    <br />
                    <a href={`https://opensea.io/${externalWalletAddress}`} target="_blank" rel="noopener noreferrer">
                      https://opensea.io/{externalWalletAddress}
                    </a>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsExternalWalletAddressConfirmationVisible(false)} color="primary">
                    Go back
                  </Button>
                  <Button
                    onClick={async () => {
                      setIsLoading(true);
                      await finalizeSendToStripe();
                    }}
                    color="primary"
                  >
                    Confirm, let's mint
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerWithFirebase: actions.registerWithFirebase,
    },
    dispatch,
  );
}

function mapStateToProps({ user }, ownProps) {
  return {
    user,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MintToCollection));

import * as Actions from '../actions';

const initialState = null;

const user = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_USER_DATA: {
      return {
        ...action.payload,
      };
    }
    case Actions.REMOVE_USER_DATA: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export default user;

import * as types from '../actions/types';

const INITIAL_STATE = {
  info: {
    itemId: '',
    name: '',
    images: [],
    email: '',
    phone: '',
    message: '',
    thumbs: [],
  },
  order: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SAVE_ITEM_DRAFT_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.formData,
        },
      };
    case types.SAVE_ITEM_DRAFT_ORDER:
      return {
        ...state,
        order: action.orderInformation,
      };
    case types.RESET_ITEM_DRAFT:
      return INITIAL_STATE;
    case types.SAVE_IMAGES_LOCALLY:
      return {
        ...state,
        info: {
          ...state.info,
          images: action.images.main || INITIAL_STATE.images,
          thumbs: action.images.thumbs || INITIAL_STATE.thumbs,
        },
      };
    default:
      return state;
  }
};

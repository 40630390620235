import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Grid, Container } from '@material-ui/core';
import QRCodeImg from './assets/qr-code.png';
import { useStyles } from './style';

const QRCode = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box py={15} style={{ backgroundColor: '#DDF4FF' }}>
      <Container>
        <Box
          style={{ backgroundColor: '#F3F8FF', boxShadow: '0px 41px 83px rgba(57, 132, 184, 0.12)', borderRadius: 10 }}
        >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: '100%', minHeight: 200 }}
              >
                <img src={QRCodeImg} alt="QR Scan" width={328} style={{ position: 'absolute' }} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box className={classes.qrDescription} py={6}>
                <span>Ownerfy uses a </span>
                <b>unique patented </b>
                <span>system for connecting Real World Assets to NFTs</span>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box py={10} display="flex" justifyContent="center">
          <Button className={classes.containedBtn} onClick={() => history.push('/get-started')}>
            Contact us
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default QRCode;

import * as Actions from '../actions';

const INITIAL_STATE = {
  authModal: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_LOOTVAULT_AUTH_MODAL:
      return {
        ...state,
        authModal: action.payload
      }
    default:
      return state
  }
}

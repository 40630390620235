export default {
  howItWorks: {
    label: 'How It Works',
    pathname: '/#how-it-works',
    shouldUseHashLink: true,
  },
  foundSomething: {
    pathname: '/found-something',
    label: 'Find NFT',
  },
  registerYourTag: {
    label: 'Register',
    pathname: '/register',
  },
  buyNow: {
    label: 'Mint',
    pathname: '/',
    external: false,
  },
  dashboard: {
    label: 'My Collection',
    pathname: '/my-collection',
    needsAuth: true,
  },
  faq: {
    label: 'FAQ',
    pathname: '/faq',
    needsAuth: true,
  },
  history: {
    label: 'history',
    pathname: '/history',
    needsAuth: true,
  },
  contact: {
    label: 'Contact',
    pathname: '/contact',
  },
  privacyPolicy: {
    label: 'Privacy Policy',
    pathname: '/privacy-policy',
  },
  tos: {
    label: 'Terms of Use',
    pathname: '/TOS',
  },
  registrationDashboard: {
    label: 'Dashboard',
    pathname: '/product-registration/dashboard',
    needsAuth: true,
  },
  registrationCustomers: {
    label: 'Customers',
    pathname: '/product-registration/customers',
    needsAuth: true,
  },
  registrationAddCode: {
    label: 'Add Code',
    pathname: '/product-registration/add-code',
    needsAuth: true,
  },
  joinDiscord: {
    label: 'Join Us on Discord',
    pathname: 'https://discord.com/invite/GGn3knVaeT',
    external: true,
  },
  downloadNftCreator: {
    label: 'Download NFT Creator',
    pathname: '/post/download-app',
  },
  createNFT: {
    label: 'Make NFTs',
    pathname: '/create-collection',
  },
  subscribe: {
    label: 'Subscribe',
    pathname: '#',
    type: 'subscribe',
  },
  business: {
    label: 'Business Signup',
    pathname: '/contact',
  },
  nftStandards: {
    label: 'NFT Standards',
    pathname: '/nft-standards',
  },
  moreSecureNfts: {
    label: 'Better, More Secure NFTs',
    pathname: '/post/more-secure-nfts',
  },
  // howToMakeNfts: {
  //   label: 'How to Make NFTs',
  //   pathname: 'https://shop.ownerfy.com/pages/how-to-make-nfts',
  //   external: true,
  // },
};

import React from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import PageLayout from 'Layout/Layout';
import Container from 'Components/Container';
import SectionLayout from 'Layout/SectionLayout';
import { ReactComponent as CheckIcon } from './assets/circle-checkmark.svg';
import { useStyles } from './style';

const BuyCreditsSuccess = () => {
  const classes = useStyles();

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Paper style={{ borderRadius: '24px', maxWidth: 400 }}>
              <Box p={4} display="flex" flexDirection="column" alignItems="center">
                <Box mb={2}>
                  <CheckIcon width={80} height={80} />
                </Box>

                <Typography className={classes.congratulation}>
                  <strong>Contratulations, </strong>
                  <span>your purchase was successful!</span>
                </Typography>

                <Button
                  handleClick={() => (window.location.href = '/mint-nft')}
                  variant="contained"
                  className={classes.makeNft}
                  href="/mint-nft"
                >
                  Make NFTs
                </Button>
                <Button
                  handleClick={() => (window.location.href = '/create-collection')}
                  variant="contained"
                  href="/create-collection"
                  className={classes.makeCollection}
                >
                  Make a collection
                </Button>
                <Button
                  handleClick={() => (window.location.href = '/create-collection')}
                  variant="contained"
                  href="/my-collection"
                  className={classes.makeCollection}
                >
                  My Collection / Distribute NFTs
                </Button>
              </Box>
            </Paper>
          </Box>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
};

export default BuyCreditsSuccess;

import React from 'react';
import { useStyles } from './styles.js';
import Container from 'Components/Container';
import DrawingSamples from './assets/DrawingSamples.png';
import UnderLine from './assets/underline.svg';
const HowItIsMade = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.HowItIsMadeContainer} ${classes.contentWrapper} `}>
      <Container>
        <div className={classes.HowItIsMadeContainer}>
          <div className={classes.flexBasis50}>
            <h3 className={classes.howItIsMadeTitle}>How it’s made</h3>
            <img src={UnderLine} className={classes.underLine} alt="Not found" />
            <p className={classes.howItIsMadeDesc}>
              Each individual trait was hand painted and colored by Justin then digitized by our team to most accurately
              represent the look and feel of painted canvas. Traits were then joined with a combination of mathematical
              statistics and hand placement to create the best variety of common, rare, and super rare trait sets. Each
              character has been checked and approved by our team.
            </p>
          </div>
          <div className={`${classes.flexBasis50} ${classes.drawingSampleImgContainer}`}>
            <img src={DrawingSamples} alt="Not found" className={classes.drawingSampleImg} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HowItIsMade;

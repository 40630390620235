import * as Actions from '../actions';

const INITIAL_STATE = [
  {
    key: '',
    label: '',
    numTags: 1,
    dimensions: '',
    price: 0,
    shipping: 0,
    imageUrl: '',
    imageUrl2x: '',
    imageUrl3x: '',
    imageAltText: '',
    checkboxName: '',
    selectName: '',
  },
];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_PRODUCT_DATA:
      return action.payload;
    case Actions.RESET_PRODUCT_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

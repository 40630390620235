/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import withStyles from '@material-ui/core/styles/withStyles';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import Container from 'Components/Container';
import GlobalButton from 'Shared/Button';
import config from 'Config/firebase';

const pageStyles = theme => ({
  contractAddress: {
    fontSize: 12,
  },
});

function ClaimSuccess({ classes }) {
  const queryParameters = new URLSearchParams(window.location.search);
  const thankYouLink = decodeURIComponent(queryParameters.get('thankYouLink'));
  const contractAddress = queryParameters.get('contractAddress');
  const tokenId = queryParameters.get('tokenId');
  const externalAddress = queryParameters.get('externalAddress');
  const _thankYouLink = /javascript/gi.test(thankYouLink) ? 'null' : thankYouLink; // this is string null on purpose
  const isExternalWallet = queryParameters.get('isExternalWallet') === 'true';

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <div style={{ marginTop: 50 }}>
            <h1>Success!</h1>

            {isExternalWallet ? (
              <div>
                <p>
                  <i>
                    External wallets may take up to half an hour to display. Unrecognized tokens are hidden by default
                    in some wallets. Below are instructions to unhide your token.{' '}
                  </i>
                </p>
                <p>
                  <b>External Wallet Instructions:</b>
                </p>
                <ul>
                  <li>
                    <b>Trust Wallet:</b> Trust wallet may take up to a half an hour to display your token. Force close
                    and re-open the app again to force a refresh.
                  </li>
                  <li>
                    <b>Metamask Mobile:</b> Please select "Import NFTs" and add this contract and token ID
                    <br />
                    Contract Address: <b className={classes.contractAddress}>{contractAddress}</b>
                    <br />
                    Token ID: <b>{tokenId}</b>
                  </li>
                  <li>
                    <b>Metamask desktop:</b> Log into OpenSea using this address and view the NFT "hidden" tab to unhide
                    the NFT. Alternatively visit <a href="https://portfolio.metamask.io/">Portfolio.metamask.io</a> and
                    select the NFT tab. Then select "more" and select "Hidden NFTs". From there you can select the dots
                    and unhide the NFT.
                  </li>
                </ul>
                <p>
                  Check your on-chain transaction after several minutes here:{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.contractAddress}
                    href={`${config.polyscanAddress}address/${externalAddress}#tokentxnsErc1155`}
                  >
                    {externalAddress}
                  </a>
                </p>
              </div>
            ) : (
              <div>
                {/* <p>Your NFT {props.match.params.code}, is now registered!</p> */}
                <p>This transaction may take up to 1 minute to finish.</p>
                <p>Visit your collection to view your digital collectible.</p>
                <a href="/my-collection">
                  <GlobalButton
                    css={css`
                      margin-bottom: 16px;
                      margin-top: 30px;
                      padding: 14px;
                    `}
                    btnLabel="Go to my collection"
                    background
                    href="/my-collection"
                  />
                </a>
              </div>
            )}

            {_thankYouLink !== 'null' ? (
              <GlobalButton
                css={css`
                  margin-bottom: 16px;
                  margin-top: 30px;
                  padding: 14px;
                `}
                btnLabel="Back to creator site"
                background
                onClick={() => {
                  window.location.href = _thankYouLink;
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

export default withStyles(pageStyles)(ClaimSuccess);

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { useTheme } from '@material-ui/core/styles';
import Page from 'Components/Page';
import Post from 'Components/Post';

function MoreSecureNfts() {
  const theme = useTheme();
  return (
    <Page hasContainer={false}>
      <Post title="Better, More Secure NFTs">
        <h3>
          Ownerfy Saves Digital Signatures of Images "On-The-Chain" to Prove Scientifically this Exact Image Existed at
          the Time of the Creation of the NFT
        </h3>
        <p>
          Right now NFTs are being sold for 10s of millions of dollars, but what exactly is being sold? What is on the
          blockchain and what is not on the blockchain?
        </p>

        <img src="/img/post/more-secure-nfts/marshmello.jpeg" alt="marshmello" />
        <a href="/token/0x559604845ffc64a05f780ead75249c377b1dae57/492" target="_blank" rel="noopener noreferrer">
          Image Credit: Marshmello by 0x3d9092daa3cf5416d3780d1cda2cb0ef4f3495cb
        </a>

        <p>
          For the vast majority of NFTs marketplaces the only thing that is saved on the blockchain is a token ID and a
          URL to the information about that token. This means for your $4,000 NFT what you purchased was something like
          this <b>tokenID: 400, URL: https://google.databucket/id</b>. and that’s is. If the URL were to die such as
          someone stop paying the bill or worse, someone changed what was at that URL all meaningful information about
          what the token is would be lost or <i>changed</i>.
        </p>

        <p>
          This is actually a flaw in the NFT specification and has been{' '}
          <a href="https://github.com/ethereum/eips/issues/721" target="_blank" rel="noopener noreferrer">
            argued about in much length
          </a>
          . In one camp you have people who believe that some identifying information about an NFT is extremely
          important. On the other you have people who think it would be too expensive to mandate any information.
        </p>

        <img src="/img/post/more-secure-nfts/fingerprint.png" alt="fingerprint" />

        <p>
          How can we combat this? It’s simple we must save a some meaningful data about what the NFT on the blockchain.
          The cheapest way to save meaningful information is to save a hash or{' '}
          <a href="https://en.wikipedia.org/wiki/Hash_function" target="_blank" rel="noopener noreferrer">
            digital signature
          </a>{' '}
          of the data. A hash is a digest that is a boiled down string of characters that gets spit out when you run any
          large file through it. The large file will always produce the same hash. You can’t figure out what the file is
          from the hash, but if you have the original file you can run it through the same hash algorithm and get the
          same digital fingerprint.
        </p>

        <p>
          Most of the big marketplaces or minters do not save any hash or any identifiable information about an NFT so
          what you own is a token with a vague generic URL. At{' '}
          <a href="/post/download-app">Ownerfy we do save this hash</a>. This is essential for proving artwork exists at
          a certain time or that token consistently represents the same thing. It is something that needs to be baked
          into the NFT smart contract that stores the NFTs on the blockchain. We designed our smart contract with that
          that in mind. This means as long as the original image is maintained somewhere, anywhere. It could even be
          saved by the author then it can always be provable that this NFT image has not been changed and existed AS-IS
          at the time of the creation of the NFT.
        </p>

        <img src="/img/post/more-secure-nfts/ipfs.png" alt="ipfs" />

        <p>
          There are other methods to attempt to improve authenticity. One is to use IPFS. IPFS is an alternate internet
          where files are shared based on their hashes. In this case the link is a hash that fetches the text JSON data
          for an NFT, not a hash of the image. So if the IPFS link, which is hosted privately stays active it can be
          proven that the text metadata has not changed. However this metadata would still only include a link to an
          image, which does not prove what the image is or was.
        </p>

        <p>
          Only by saving a hash of the image itself with the token minting on the blockchain can we guarantee that an
          image existed at a certain time and is associated with that NFT.{' '}
          <a href="/" target="_blank" rel="noopener noreferrer">
            Ownerfy is the only platform currently that does this and will continue on to do it
          </a>
          .
        </p>

        <img src="/img/post/more-secure-nfts/ownerfy-badge.png" alt="Ownerfy badge" />

        <p>Ownerfy Saves digital Fingerprints of Artwork to the Blockchain</p>
      </Post>
    </Page>
  );
}

export default MoreSecureNfts;

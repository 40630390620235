/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import bs58 from 'bs58';
import CenteredSpinner from 'Components/CenteredSpinner';
import { InfoRow } from 'Components/Tokens/InfoRow';
import { Star } from 'Components/Tokens/Star';
import config from 'Config/firebase';

function Card({ tokenId, contractAddress }) {
  const [tokenInfo, setTokenInfo] = useState(null);

  useEffect(() => {
    if (!tokenInfo) {
      const getTokenInformation = async () => {
        let _tokenId = tokenId;
        let ownerfyResponse;

        if (tokenId.length < 6) {
          //TODO if this is a foreign address this shouldn't be called
          // but for now it's just us
          ownerfyResponse = await axios.get(`${config.functionsURL}/items/${tokenId}`);
        } else {
          const bytes = bs58.decode(tokenId);
          const b58Hex = bytes.toString('hex');
          ownerfyResponse = await axios.get(`${config.ipfsLink}/${b58Hex}`);
          _tokenId = parseInt('0' + b58Hex, 16);
        }

        const OpenseaResponse = await axios.get(`${config.openseaApiUrl}v1/asset/${contractAddress}/${_tokenId}/`);

        const qrCodeImage = `https://us-central1-receiptcoin-receiptchain-de560.cloudfunctions.net/app/generateCheckMarkCode/${encodeURIComponent(
          'nft/' + tokenId,
        )}.png`;

        setTokenInfo({
          ...ownerfyResponse.data,
          owners: OpenseaResponse.data.top_ownerships,
          qrCodeImage,
        });

        setTimeout(function () {
          const videoPlayer = document.getElementById('token-video');
          if (videoPlayer) {
            videoPlayer.play();
          }
        }, 1000);
      };

      getTokenInformation();
    } else {
      setTimeout(function () {
        window.print();
      }, 1000);
    }
  }, [contractAddress, tokenId, tokenInfo]);

  return (
    <div
      css={css`
        width: 525px;
        height: 288px;
        border: 1px solid #999;
      `}
    >
      {tokenInfo ? (
        <div
          css={css`
            padding: 25px;
            display: flex;
          `}
        >
          <div
            css={css`
              flex: 1;
              margin-right: 25px;
            `}
          >
            <p
              css={css`
                color: #4d5153;
                margin: 0;
                font-family: 'Nunito Sans', sans-serif;
                font-weight: bold;
                font-size: 15px;
                letter-spacing: 0.11px;
                line-height: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #d8d8d8;
              `}
            >
              {tokenInfo.name}
            </p>
            <div
              css={css`
                padding-top: 20px;
              `}
            >
              <p
                css={css`
                  font-family: 'Montserrat', sans-serif;
                  color: #4d5153;
                  font-weight: 500;
                  font-size: 14px;
                  letter-spacing: 0.08px;
                  line-height: 18px;
                  margin: 0;
                  margin-bottom: 5px;
                `}
              >
                NFT Info
              </p>
              <InfoRow label="Token ID" info={tokenId} isChangedOnSmBreakpoint={false} />
              <InfoRow
                label="Blockchain"
                info="Ethereum"
                css={css`
                  margin-top: -4px;
                `}
                isChangedOnSmBreakpoint={false}
              />
              <InfoRow
                label="Smart Contract"
                info="Ownerfy"
                css={css`
                  margin-top: -4px;
                `}
                isChangedOnSmBreakpoint={false}
              />
              <InfoRow
                label="Created By"
                info={
                  <div
                    css={css`
                      text-align: right;
                    `}
                  >
                    {tokenInfo.creator}
                    <br />
                    <span
                      css={css`
                        color: #30b9cf;
                      `}
                    >
                      {tokenInfo.creatorAddress}
                    </span>
                  </div>
                }
                css={css`
                  margin-top: -4px;
                `}
                isChangedOnSmBreakpoint={false}
              />
              <InfoRow
                label="Created"
                info={moment(parseInt(tokenInfo.createdAt, 10)).format('M/D/YYYY, h:mm A')}
                css={css`
                  margin-top: -4px;
                `}
                isChangedOnSmBreakpoint={false}
              />
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            `}
          >
            <img
              src={tokenInfo.qrCodeImage}
              alt="qr code"
              width="107"
              height="107"
              css={css`
                @media print {
                  display: block;
                }
              `}
            />
            <p
              css={css`
              font-family: 'Montserrat', sans-serif;
              color: #4D5153;
              font-weight: 500;
              font-size: 8px;
              letter-spacing: 0.06px;
              line-height: 10px
              margin: 0;
              margin-top: 20px;
              text-align: right;
            `}
            >
              NFT Authenticity
              <div>
                <Star fontSize={15} />
                <Star fontSize={15} />
                <Star fontSize={15} />
                <Star fontSize={15} />
                <Star fontSize={15} />
              </div>
            </p>
          </div>
        </div>
      ) : (
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          `}
        >
          <CenteredSpinner />
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    tokenId: ownProps.match.params.tokenId,
    contractAddress: ownProps.match.params.contractAddress,
  };
}

export default withRouter(connect(mapStateToProps, null)(Card));

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  uploadRoot: {},
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: '100%',
  },
  uploadImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  uploadContainer: {
    marginTop: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
  },
  uploadFileLabel: {
    background: 'linear-gradient(0deg, #476DDC, #476DDC), #DDF4FF',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    width: '190px',
    height: '57px',
    cursor: 'pointer',
    marginRight: '40px',
    fontFamily: 'Lato',
  },
  imgHelperText: {
    fontSize: '12px',
    color: '#748896',
    fontFamily: 'Lato',
    width: '50%',
  },
  uploadedFileLabel: {
    background: '#21D7BB',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    width: '190px',
    height: '57px',
    cursor: 'pointer',
    marginRight: '40px',
    fontFamily: 'Lato',
  },
  uploadedImgHelperText: {
    fontSize: '12px',
    color: '#21D7BB',
    fontFamily: 'Lato',
    width: '50%',
  },
}));

export { useStyles };

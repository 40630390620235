import React, { useState, useEffect } from 'react';
import config from 'Config/firebase';
import axios from 'axios';
import { utils, ethers } from 'ethers';
import { BigNumber } from '@ethersproject/bignumber';
import Page from 'Components/Page';
import { withStyles } from '@material-ui/core';
import Container from 'Components/Container';
import cashCowsABI from 'ABI/cashcows.json';
import ccMilkABI from 'ABI/ccmilk.json';
import eggsABI from 'ABI/eggs_abi.json';
import { Helmet } from 'react-helmet';
import Input from '@material-ui/core/Input';
import { getAssets, formatOpenSeaAssets } from 'Services/openSeaService';
import { formatServerAssets } from 'Helpers/format';
import clsx from 'clsx';

const styles = theme => ({
  page: {
    backgroundColor: '#fff',
    color: '#fff',
    paddingBottom: 0,
    position: 'relative',
  },
  mainContent: {
    position: 'relative',
    zIndex: 1,
  },
  input: {
    height: '35px',
    width: '59px',
    backgroundColor: '#FFFFFF',
    textAlign: 'center !important',
    color: '#097EA6',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '24px',
    marginLeft: 16,
    marginRight: 16,
  },
  leftPattern: {
    height: '625px',
    width: '197px',
    position: 'absolute',
    bottom: '345px',
    left: 0,
    zIndex: 0,
  },
  rightPattern: {
    height: '625px',
    width: '197px',
    position: 'absolute',
    top: '106px',
    right: 0,
    zIndex: 0,
  },
  header: {
    height: 125,
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
      height: 'auto',
    },
  },
  headerInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: '30px',
      alignItems: 'center',
    },
  },
  cashCowsLogo: {
    fontFamily: '"Montserrat", sans-serif',
    color: '#097EA6',
    fontSize: '40px',
    fontWeight: '800',
    letterSpacing: '0',
    lineHeight: '48px',
    margin: 0,
  },
  logoBox: { textAlign: 'left' },
  logoImage: { height: '56px', width: '266px' },
  logoText: {
    color: '#FDFDFE',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '30px',
    margin: 0,
  },
  button: {
    padding: '15px 30px 15px 30px',
    height: '55px',
    border: '1px solid #097EA6',
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    textDecoration: 'none',
    backgroundColor: '#097EA6',
    outline: 'none',
    cursor: 'pointer',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '19px',
    '&:hover': {
      backgroundColor: 'rgb(148 187 227 / 89%)',
    },
  },
  clearButton: {
    backgroundColor: 'transparent',
    color: '#097EA6',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  },
  discordBox: {
    padding: '10px 30px 10px 30px',
    height: '70px',
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#1b1a1a',
    },
  },
  metaMaskLogo: {
    width: 34,
  },
  discordLogo: {
    width: 31,
  },
  headerButtonsBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  discordText: {
    color: '#fff',
    textTransform: 'uppercase',
    display: 'block',
    lineHeight: '51px',
  },
  heroBox: {
    width: '100%',
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 50,
  },
  heroImage: { maxWidth: '450px', width: '100%' },
  alert: {
    padding: '20px 0',
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '19px',
    backgroundColor: 'rgba(255,255,255,0.5)',
    margin: '30px 0',
  },

  title1: {
    marginTop: '60px',
    color: '#097EA6',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '31px',
    fontWeight: '800',
    letterSpacing: '0',
    lineHeight: '43px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  subtitle: {
    color: '#1B8302',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '27px',
    textAlign: 'center',
    marginTop: 30,
  },
  title2: {
    margin: '20px 0px 20px 0',
    color: '#FF4DFF',
    fontFamily: 'Poppins',
    fontSize: '40px',
    letterSpacing: 0,
    lineHeight: '60px',
    fontWeight: 500,
    textShadow: '1px 1px 7px #FF4DFF, 1px 0px 1px #ffffff',
  },
  title3: {
    margin: '20px 0px 70px 0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  countdownContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  countdownHeader: {
    marginBottom: 25,
  },
  countDown: {
    display: 'flex',
    gap: '45px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexWrap: 'wrap',
      maxWidth: '250px',
      gap: '25px',
    },
  },
  countDownGroup: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '70px',
    },
  },
  number: {
    color: '#FFFFFF',
    fontFamily: '"Saira Condensed"',
    fontSize: '56px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '0.7',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '60px',
    },
  },
  timeSpan: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '25px',
    textAlign: 'center',
    marginTop: '15px',
  },

  metaButton: {
    backgroundColor: 'rgba(255,255,255,0.5)',
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
    padding: '16px 54px',
    marginBottom: 20,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },

  littleButton: {
    backgroundColor: '#097EA6',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
    padding: '12px 24px',
    marginBottom: 10,
    marginRight: 5,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: '#097EA6',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },

  remindMeButton: {
    marginTop: 30,
    backgroundColor: '#fff',
  },
  mintQueue: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 50,
  },
  toggleQueue: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 14,
    cursor: 'pointer',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '75px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
    },
  },
  description: {
    flex: 1,
    gap: '100px',
    maxWidth: '585px',
  },
  descriptionText: {
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '34px',
    marginTop: 0,
    marginBottom: 30,
  },
  features: {
    maxWidth: '400px',
  },
  featureList: {
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '40px',
    letterSpacing: '0',
    lineHeight: '60px',
    marginTop: 20,
    listStyleType: 'none',
    padding: 0,
  },
  featureTitle: {
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '35px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '72px',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },
  feature: {
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '36px',
    marginBottom: '20px',
  },
  hatchIncubation: {
    backgroundColor: '#01202A',
    padding: '80px 0',
    marginBottom: '80px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
    },
  },
  hatchIncubationTitle: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    marginBottom: '70px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '45px',
      lineHeight: '65px',
      marginBottom: '30px',
    },
  },
  hatchEvents: {
    display: 'flex',
    gap: '30px',
    flexWrap: 'wrap',
    marginBottom: '66px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  hatchGroup: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  hatchEvent: {
    maxWidth: '190px',
  },
  hatchEventPercentage: {
    color: '#D29F04',
    fontFamily: '"Saira Condensed"',
    fontSize: '60px',
    fontWeight: '600',
    letterSpacing: '0',
    padding: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  hatchEventText: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '32px',
    textAlign: 'center',
  },
  hatchPrice: {
    width: '100%',
    textAlign: 'center',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '32px',
  },
  yourCollection: { width: '100%', backgroundColor: 'transparent', padding: '0px 0' },
  yourCowBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: '5px',
    overflowX: 'visible',
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
  },
  cowControls: { textAlign: 'center' },

  theCollectionLogo: {
    color: '#00FF00',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    margin: 0,
    textAlign: 'center',
    marginBottom: 65,
    textShadow: '1px 1px 10px #00ff00, 1px 0px 1px #ffffff',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },

  theCollectionCowBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    gap: '5px',
    overflowX: 'hidden',
    paddingBottom: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
  },
  theCollectionCow: {
    height: '255px',
    width: '255px',
    [theme.breakpoints.down('xs')]: {
      height: '255px',
      width: '255px',
    },
  },
  theTeamBox: { width: '100%', padding: '90px 0', backgroundColor: '#201F1F' },
  theTeamLogo: {
    color: '#DBB044',
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '72px',
    textAlign: 'center',
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },
  teams: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
      padding: '0px 60px',
    },
  },
  team: { maxWidth: 400, width: '100%', textAlign: 'center' },
  teamLogo: {
    width: 200,
  },
  teamDescription: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '26px',
    textAlign: 'center',
  },
  footer: { backgroundColor: '#fff', padding: 50 },
  footerInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
  },
  footerLogos: { display: 'flex', gap: '20px', justifyContent: 'center' },
  footerLogo: {
    marginTop: 10,
    height: '48px',
    width: '48px',
    padding: '10px',
    color: '#097EA6',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#097EA6',
    },
  },
  alchemyBadge: {
    cursor: 'pointer',
  },
  footerSmartContract: {
    color: '#616161',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '21px',
    textAlign: 'center',
    width: '100%',
    marginTop: '15px',
  },
  cowsBg: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    zIndex: -1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  topSection: {
    position: 'relative',
    background: 'url(/img/cashcows/grass-sky-cows.jpg)',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  calloutBox: {
    maxWidth: '450px',
    padding: '20px 30px',
    backgroundColor: 'rgba(9,126,166,0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  calloutBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  mintQtyLine: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 18,
    justifyContent: 'center',
  },
  qtyTitle: {
    color: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '29px',
  },
  qtyLabel: {
    color: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '17px',
    textAlign: 'center',
  },
  mintButtons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 16,
  },
  mintDisclaimer: {
    color: '#FFFFFF',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '12px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '14px',
    textAlign: 'center',
    marginTop: 14,
  },
  section: {
    padding: '62px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  sectionHeader: {
    color: '#097EA6',
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '72px',
    marginBottom: 40,
    textAlign: 'center',
  },
  cowsLineUp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
    flexWrap: 'wrap',
  },
  collectionCow: {},
});

function padLeadingZeros(num, size) {
  var s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}

function CashCows({ setActiveModal, classes }) {
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [hoursRemaining, setHoursRemaining] = useState(null);
  const [minutesRemaining, setMinutesRemaining] = useState(null);
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  const [MMAccounts, setMMAccounts] = useState(null);
  const [userCows, setUserCows] = useState([]);
  const [milkBalances, setCowBalances] = useState(null);
  const [qty, setQty] = useState(1);
  const [isQueueOpen, setQueueOpen] = useState(false);
  const [saleOn, setSaleOn] = useState(true);
  const [mintQueue, setMintQueue] = useState([]);
  const [intervalState, setIntervalState] = useState([]);
  const [cowTotal, setCowTotal] = useState(0);

  // useEffect(() => {
  //   axios.get(`${config.functionsURL}/getTotalMintedchicadees`).then(res => {
  //     const countTo = res.data.amount - 300;
  //     const easeOutQuad = t => t * (2 - t);
  //     const frameDuration = 2000 / 60;
  //     let frame = 0;
  //     const totalFrames = Math.round(2000 / frameDuration);
  //     const counter = setInterval(() => {
  //       frame++;
  //       const progress = easeOutQuad(frame / totalFrames);
  //       setCowTotal(Math.ceil(countTo * progress));

  //       if (frame === totalFrames) {
  //         clearInterval(counter);
  //       }
  //     }, frameDuration);
  //   });
  // }, [setCowTotal]);

  const connectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log('accounts are:: ', accounts);

        setMMAccounts(accounts);

        try {
          let cows = [];
          let offset = 0;
          let isMore = true;

          // while (isMore) {
          //   const { assets } = await getAssets({
          //     owner: accounts[0],
          //     asset_contract_addresses: config.cashCowsContract.toLowerCase(),
          //     order_by: 'pk',
          //     order_direction: 'asc',
          //     offset,
          //   });
          //   cows = [...cows, ...formatOpenSeaAssets(assets)];
          //   if (assets.length > 49) {
          //     isMore = true;
          //     offset += 50;
          //   } else {
          //     isMore = false;
          //   }
          // }

          let pageKey = 0;
          while (isMore) {
            const { data } = await axios.get(
              `${config.functionsURL}/nft/list/address/${accounts[0]}/ethereum/${pageKey}/${config.cashCowsContract}`,
            );

            console.log('processing pagekey : ', pageKey);

            cows = [...cows, ...formatServerAssets(data)];
            if (data.length === 100) {
              isMore = true;
              pageKey += 1;
            } else {
              isMore = false;
            }
          }

          console.log('cows are : ', cows);

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(config.milkContract, ccMilkABI.abi, provider);
          let balances = new Map();
          for (const cow of cows) {
            const balance = await contract.checkCowBalance(cow.token_id);
            const balFixed = ethers.utils.formatEther(balance.toString());
            balances.set(cow.token_id, balFixed);
          }
          setCowBalances(balances);

          setInterval(async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(config.milkContract, ccMilkABI.abi, provider);
            let balances = new Map();
            for (const cow of cows) {
              const balance = await contract.checkCowBalance(cow.token_id);
              const balFixed = ethers.utils.formatEther(balance.toString());
              balances.set(cow.token_id, balFixed);
            }
            setCowBalances(balances);
          }, 15000);

          setUserCows(cows);
        } catch (err) {
          console.warn('Error getting opensea assets: ', err);
        }
      } catch (err) {
        alert('connection failed');
      }
    } else {
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  const mint = async ({ mintOnly, approveOnly }) => {
    connectMetamask();
    if (typeof window.ethereum !== 'undefined') {
      window.gtag('event', 'click_metamask', {
        event_category: 'ecommerce',
        event_label: 'metamask_click',
        value: parseInt(qty, 10),
      });

      // window.gtag('event', 'begin_checkout', {
      //   send_to: 'AW-643218609/1DU_CPm0y9ABELH52rIC',
      //   value: 15.0,
      //   currency: 'USD',
      //   transaction_id: '',
      // });

      // gtag('event', <action>, {
      //   'event_category': <category>,
      //   'event_label': <label>,
      //   'value': <value>
      // });

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const mintContract = new ethers.Contract(config.cashCowsContract, cashCowsABI.abi, provider);

      const eggsContract = new ethers.Contract(config.chicADeeEggs, eggsABI.abi, provider);

      const eggsContractWithSigner = eggsContract.connect(signer);

      const mintContractWithSigner = mintContract.connect(signer);

      const price = BigNumber.from('25000000000000000000000');
      const totalPrice = price.mul(qty);

      if (approveOnly || (!mintOnly && !approveOnly)) {
        try {
          let approveTx = await eggsContractWithSigner.approve(config.cashCowsContract, totalPrice.toHexString());
        } catch (err) {
          console.warn('approve error:: ', err);
          alert('Approve transaction warning.', err);
          return;
        }
      }

      if (mintOnly || (!mintOnly && !approveOnly)) {
        try {
          let mintTx = await mintContractWithSigner.mint(utils.hexlify(parseInt(qty, 10)));
        } catch (err) {
          console.warn('Mint warning.', err);
          alert('Mint Transaction warning.', err);
          return;
        }
      }
    } else {
      alert('Metamask is not connected. Please connect to Metamask plugin or the credit card option to purchase');
    }
  };

  const milkCows = async cows => {
    connectMetamask();
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contract = new ethers.Contract(config.milkContract, ccMilkABI.abi, provider);

      const contractWithSigner = contract.connect(signer);

      let hexIds = cows.map(cow => utils.hexlify(parseInt(cow.token_id)));

      try {
        let milkTx = await contractWithSigner.milkManyCows(hexIds);
      } catch (err) {
        console.warn('error:: ', err);
        alert(JSON.stringify(err));
      }
    } else {
      alert('Metamask is not connected. Please connect to Metamask plugin or the credit card option to purchase');
    }
  };

  // useEffect(() => {
  //   if (typeof window.ethereum !== 'undefined') {
  //     window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
  //       setMMAccounts(accounts);
  //       console.log('use effect accounts are :: , ', accounts);
  //     });
  //   }
  // }, []);

  const openRemindModal = () => {
    window.open(
      'https://cdn.forms-content.sg-form.com/c7cf39a3-261e-11ec-b596-8efc9884dd50',
      '_blank',
      'resizable=yes, scrollbars=no, titlebar=no, location=no, menubar=no, status=no, toolbar=no, width=550, height=440, top=10, left=10',
    );
  };

  const toggleQueue = async () => {
    let mintQueue = await axios.get(`${config.functionsURL}/getMintQueue`);

    if (!isQueueOpen) {
      setIntervalState(
        setInterval(() => {
          axios.get(`${config.functionsURL}/getMintQueue`).then(mintQueue => {
            setMintQueue(mintQueue.data);
          });
          console.log('polling.. ');
        }, 10000),
      );
    } else {
      clearInterval(intervalState);
      setIntervalState(null);
    }

    setMintQueue(mintQueue.data);
    setQueueOpen(!isQueueOpen);
  };

  const getCountdown = () => {
    const deadline = new Date('2021-10-23T02:00:00.000Z');
    const now = new Date();
    const time = deadline - now;
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  // loop to countdown every 1 second
  setInterval(function () {
    const { days, hours, minutes, seconds } = getCountdown();
    setDaysRemaining(days);
    setHoursRemaining(hours);
    setMinutesRemaining(minutes);
    setSecondsRemaining(seconds);
  }, 1000);

  return (
    <Page hasNav={false} wrapperStyles={classes.page} hasContainer={false} FooterComponent={() => null}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>3,333 Cash Cows Milk Producing NFTs </title>
        <discription>Produce ERC20 CCMILK Tokens, Full Game Integration, Variable Rarity</discription>
        <meta property="og:title" content="3,333 Cash Cows MILK Producing NFTs" />
        <meta property="og:description" content="Produce ERC20 MILK Tokens, Full Game Integration, Variable Rarity" />
        <meta property="og:image" content="https://ownerfy.com/img/cashcows/grass-sky-cows.jpg" />
      </Helmet>
      <div className={classes.mainContent}>
        <div className={classes.topSection}>
          <div className={classes.header}>
            <Container>
              <div className={classes.headerInnerContainer}>
                <div className={classes.logoBox}>
                  <p className={classes.cashCowsLogo}>Cash Cows</p>
                  <p className={classes.logoText}>CCMILK Token Producing NFT Collection</p>
                </div>
                <div className={classes.headerButtonsBox}>
                  {!MMAccounts ? (
                    <button className={clsx(classes.button, classes.clearButton)} onClick={connectMetamask}>
                      <span>Connect to Metamask</span>
                      <img alt="cashcows metamask" className={classes.metaMaskLogo} src="/img/cashcows/metamask.svg" />
                    </button>
                  ) : (
                    <div className={clsx(classes.button, classes.clearButton)}>Connected to: {MMAccounts[0]}</div>
                  )}

                  <a
                    className={clsx(classes.button, classes.clearButton)}
                    href="https://discord.gg/GGn3knVaeT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Discord</span>
                    <img alt="cashcows discord" className={classes.discordLogo} src="/img/cashcows/discord.svg" />
                  </a>
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div className={classes.heroBox}>
              <div className={classes.title1}>Produce MILK with Cash Cows</div>
              <div className={classes.subtitle}>
                3,333 Cash Cows available only with EGGS tokens created by <a href="/chicadees">Chic-A-Dees NFTs</a>
              </div>

              <div className={classes.calloutBoxContainer}>
                <div className={classes.calloutBox}>
                  {/* {(daysRemaining > 0 || hoursRemaining > 0 || minutesRemaining > 0 || secondsRemaining > 0) && (
                    <div>
                      <div className={classes.countdownHeader}>COMING HOME IN</div>
                      <div className={classes.countdownContainer}>
                        <div className={classes.countDown}>
                          <div className={classes.countDownGroup}>
                            <span className={classes.number}>{padLeadingZeros(daysRemaining, 2)}</span>
                            <span className={classes.timeSpan}>DAYS</span>
                          </div>
                          <div className={classes.countDownGroup}>
                            <span className={classes.number}>{padLeadingZeros(hoursRemaining, 2)}</span>
                            <span className={classes.timeSpan}>HOURS</span>
                          </div>
                          <div className={classes.countDownGroup}>
                            <span className={classes.number}>{padLeadingZeros(minutesRemaining, 2)}</span>
                            <span className={classes.timeSpan}>MINUTES</span>
                          </div>
                          <div className={classes.countDownGroup}>
                            <span className={classes.number}>{padLeadingZeros(secondsRemaining, 2)}</span>
                            <span className={classes.timeSpan}>SECONDS</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )} */}

                  {!saleOn && (
                    <button
                      className={clsx(classes.button, classes.clearButton, classes.remindMeButton)}
                      onClick={openRemindModal}
                    >
                      Get Notified
                    </button>
                  )}

                  {saleOn && (
                    <div>
                      <div className={classes.mintQtyLine}>
                        <div className={classes.qtyTitle}>Choose Quantity:</div>{' '}
                        <Input
                          value={qty}
                          id="qty"
                          type="number"
                          min="1"
                          max="1000"
                          onChange={e => setQty(e.target.value)}
                          className={classes.input}
                        />
                        {/* <label className={classes.qtyLabel} for="qty">
                          (6 Maximum)
                        </label> */}
                      </div>
                      <p>Select your quantity then click Step 1. Wait for Step 1 to complete. Then click Step. 2.</p>
                      <div className={classes.mintButtons}>
                        {/* <button className={classes.button} onClick={mint}>
                          Mint with Ethereum (both transactions)
                        </button> */}

                        <button className={classes.button} onClick={() => mint({ approveOnly: true })}>
                          Step 1. Approval Transaction
                        </button>
                        <button className={classes.button} onClick={() => mint({ mintOnly: true })}>
                          Step 2. Mint Transaction
                        </button>
                        {/* <a className={classes.button} href="/cashcows/mint">
                          Mint with Credit Card
                        </a> */}
                      </div>

                      <div className={classes.mintDisclaimer}>*25,000 EGGS tokens per Cash Cow</div>
                      <div className={classes.mintDisclaimer}>*There will be an approval and mint transaction</div>
                      <div className={classes.mintDisclaimer}>
                        *Hint: if you plan on doing multiple mints do one really high approval to avoid multiple
                        approval transactions.
                      </div>
                      <a target="os" href="https://opensea.io/collection/the-cash-cows">
                        <img alt="opensea" src="/img/chicadees/opensea.svg" className={classes.footerLogo} />
                      </a>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className={classes.toggleQueue}>300 Gen 1 Sold Out. {cowTotal} out of 500 Gen 2 Minted</div>
            <div className={classes.toggleQueue}>
              Each 6 purchased in Gen 1 and Gen 2 will get 1 free Legendary Cow-A-Dee sent to them at the end of the
              week
            </div>
            <div className={classes.toggleQueue}>There will be a 2 week pause after Gen 2 is complete</div>
            <div className={classes.toggleQueue}>Refresh page after mint and connect with MetaMask to lay EGGS</div>
            {/* <div className={classes.toggleQueue} onClick={toggleQueue}>
              <u>View Mint Queue</u>
            </div> */}
              {/* <div className={classes.toggleQueue}>Minting takes about 3 minutes</div> */}
              {/* {isQueueOpen && (
                <div className={classes.mintQueue}>
                  {mintQueue.length > 0 ? (
                    mintQueue.map(tx => (
                      <p>
                        To: {tx.toAddress}, Pay Tx: {tx.payTxHash}, Status: {tx.status},{' '}
                        <a href={`/nft/${tx.tokenId}`}>{tx.tokenId}</a>{' '}
                      </p>
                    ))
                  ) : (
                    <p>0</p>
                  )}
                </div>
              )} */}
              {/* <a target="os" href="https://opensea.io/collection/the-cash-cows">
                <img alt="opensea" src="/img/chicadees/opensea.svg" className={classes.footerLogo} />
              </a> */}
              {/* 
            <a target="rar" href="https://rarible.com/collection/0xb352131fe48571b7661390e65be4f12119e9686f">
              <img src="/img/rarible-logo-white.svg" className={classes.footerLogo} />
            </a> */}
              {/* <div className={classes.title3}>Exclusive Early Access Sale</div> */}
            </div>
            {!MMAccounts && (
              <button className={classes.metaButton} onClick={connectMetamask}>
                Connect to MetaMask to Milk Your Cows
              </button>
            )}

            {userCows.length > 0 && (
              <div className={classes.yourCollection}>
                <p className={classes.title1}>Your Cows</p>
                <div className={classes.cowControls}>
                  <p>
                    CCMILK ERC20 token contract must be added to Metamask to see your CCMILK tokens
                    <br />
                    CCMILK Contract: {config.milkContract}
                  </p>
                  <button
                    className={classes.littleButton}
                    onClick={() => {
                      milkCows(userCows);
                    }}
                  >
                    Initialize all
                  </button>
                  <button
                    className={classes.littleButton}
                    onClick={() => {
                      milkCows(userCows);
                    }}
                  >
                    Withdraw All Milk (Un-initialized will be started as well)
                  </button>
                  <p></p>
                </div>
                <div className={classes.yourCowBox}>
                  {userCows.map(cow => (
                    <div className={classes.cowControls}>
                      <a
                        target="newwindow"
                        href={`${config.openseaURL}/assets/${config.cashCowsContract}/${cow.token_id}`}
                      >
                        <img src={cow.image} className={classes.theCollectionCow} />
                      </a>
                      <p>{cow.name}</p>
                      <p>Milk balance: {milkBalances ? milkBalances.get(cow.token_id) : 'fetching..'}</p>
                      <button
                        className={classes.littleButton}
                        onClick={() => {
                          milkCows([cow]);
                        }}
                      >
                        Start Producing
                      </button>
                      <button
                        className={classes.littleButton}
                        onClick={() => {
                          milkCows([cow]);
                        }}
                      >
                        Withdraw Milk (Will continue producing)
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Container>
        </div>

        <div
          className={classes.section}
          style={{ background: 'background: linear-gradient(180deg, #EEFAFE 0%, #D8F4FC 100%)' }}
        >
          <Container>
            <div className={classes.infoContainer}>
              <div className={classes.description}>
                <p className={classes.descriptionText}>
                  <b>Cash Cows</b> is the campanion dairy project to <a href="/chicadees">Chic-A-Dees NFTs</a>. They are
                  a new experimental kind of NFT that generates an ERC20 token.
                </p>
                <p className={classes.descriptionText}>
                  Each cow is uniquely named an equiped with vairous clothes, expressions and buckets. Some of these
                  accessories affect the amount of MILK generated
                </p>
                <p className={classes.descriptionText}>Discover the secret to generating more MILK</p>
                <p className={classes.descriptionText}>
                  Cash Cows must be purchased with Cow-A-Dee EGGS. The starting price will be 25,000 EGGS, but it may go
                  up in time. EGGS may also be{' '}
                  <a href="https://app.uniswap.org/#/pool/135955" target="uniswap">
                    purchased from the community
                  </a>
                  .
                </p>
                <p className={classes.descriptionText}>
                  Your cow comes equiped with strength, dexterity, constitution, intelligence, wisdom, and charisma.
                </p>
              </div>

              <div className={classes.features}>
                <div className={classes.featureTitle}>FEATURES:</div>
                <ul className={classes.featureList}>
                  <li className={classes.feature}>Creation of the MILK Token</li>
                  <li className={classes.feature}>
                    VIP Access to{' '}
                    <a href="/" target="home">
                      Upcoming Collections
                    </a>{' '}
                  </li>
                  <li className={classes.feature}>Private Club Room for Owners</li>
                  <li className={classes.feature}>Common, Uncommon, Special, Rare, and Legendary Traits</li>
                  <li className={classes.feature}>AI Generated Names</li>
                </ul>
              </div>
            </div>
          </Container>
        </div>

        <div
          className={classes.section}
          style={{ background: 'background: linear-gradient(180deg, #EEFAFE 0%, #D8F4FC 100%)' }}
        >
          <div className={classes.sectionHeader}>CASH Cows Collection</div>
          <div className={classes.cowsLineUp}>
            <img src="/img/cashcows/cow1.png" />
            <img src="/img/cashcows/cow2.png" />
            <img src="/img/cashcows/cow3.png" />
            <img src="/img/cashcows/cow4.png" />
          </div>
        </div>

        <div className={classes.theTeamBox}>
          <div className={classes.theTeamLogo}>The Team</div>
          <div className={classes.teams}>
            <div className={classes.team}>
              <a href="/">
                <img src="/img/chicadees/ownerfy-logo.png" className={classes.teamLogo} />
              </a>
              <p className={classes.teamDescription}>
                Ownerfy is an early innovator in NFT technology that helps artists and businesses create higher quality
                NFTs and smart contracts that meet emerging standards and the demands of business.
              </p>
            </div>
            <div className={classes.team}>
              <a href="https://adors.xyz" target="ador">
                <img src="/img/chicadees/ador-logo.png" className={classes.teamLogo} />
              </a>
              <p className={classes.teamDescription}>
                By providing an agnostic approach and DeFi tool, curators, creators and collectors alike can monetize
                their virtual assets 24/7, 365 days a year. By choosing a verified publisher like ADOR creators can now
                have a universal point of authenticity.
              </p>
            </div>
          </div>
        </div>

        <div className={classes.footer}>
          <div className={classes.footerInnerContainer}>
            <img
              onClick={window.logBadgeClick}
              id="badge-button"
              style={{ width: 240, height: 53 }}
              src="https://static.alchemyapi.io/images/marketing/badge.png"
              alt="Alchemy Supercharged"
              className={classes.alchemyBadge}
            />

            <a
              style={{ textDecoration: 'none' }}
              href="https://etherscan.io/address/0x1c2a94ff99221667a4b98b05c6fe876080d749d0"
              target="es"
            >
              <p className={classes.footerSmartContract}>Smart Contract: 0x1c2a94ff99221667a4b98b05c6fe876080d749d0</p>
            </a>

            <div className={classes.footerLogos}>
              <a href="https://twitter.com/cashcowsnfts" target="disc">
                <img src="/img/chicadees/twitter.svg" className={classes.footerLogo} />
              </a>
              <a target="os" href="https://opensea.io/collection/the-cash-cows">
                <img alt="opensea" src="/img/chicadees/opensea.svg" className={classes.footerLogo} />
              </a>
              {/*
              <a target="rar" href="https://rarible.com/collection/0xb352131fe48571b7661390e65be4f12119e9686f">
                <img src="/img/rarible-logo-white.svg" className={classes.footerLogo} />
              </a> */}
              <a href="https://discord.gg/AQvvWamC78" target="disc">
                <img src="/img/chicadees/discord.svg" className={classes.footerLogo} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withStyles(styles)(CashCows);

import React, { useState } from 'react';
import Container from 'Components/Container';
import Down from './assets/down.svg';
import Up from './assets/up.svg';
import { useStyles } from './style';

const FaqItem = ({ title, content }) => {
  const classess = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const toggleItem = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Container>
      <div className={classess.faqItemContainer}>
        <div className={classess.faqItem} onClick={toggleItem}>
          <h4 className={classess.faqTitle}>{title}</h4>
          <img src={`${isOpen ? Up : Down}`} alt="Expand" className={classess.expandIcon} />
        </div>
        {isOpen && <p className={classess.faqDesc}>{content}</p>}
      </div>
    </Container>
  );
};

export default FaqItem;

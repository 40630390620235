import React, { useState } from 'react';
import { useStyles } from './style';
import Upload from '../assets/upload.svg';

const UploadBtn = ({ urls, onChange, error }) => {
  const classes = useStyles();
  const [, setFile] = useState();

  const handleFileInputChange = event => {
    const [file] = event?.target?.files;
    setFile(file);
    onChange(file);
  };

  if (urls?.thumb) {
    return (
      <div className={classes.uploadRoot}>
        {!!urls?.thumb && (
          <div className={classes.imageContainer}>
            <img src={urls?.thumb} className={classes.uploadImage} alt="Uploaded" />
          </div>
        )}

        <div className={classes.uploadContainer}>
          <label htmlFor="uploadFile" className={classes.uploadedFileLabel}>
            <input onChange={handleFileInputChange} type="file" hidden id="uploadFile" />
            <img src={Upload} alt="Cloud" style={{ marginRight: '7px' }} />
            <span>Uploaded</span>
          </label>
          <p className={classes.uploadedImgHelperText}>Successful!</p>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.uploadRoot}>
      <div className={classes.uploadContainer}>
        <label htmlFor="uploadFile" className={classes.uploadFileLabel}>
          <input onChange={handleFileInputChange} type="file" hidden id="uploadFile" />
          <img src={Upload} alt="Cloud" style={{ marginRight: '7px' }} />
          <span>Upload File</span>
        </label>
        <p className={classes.imgHelperText}>
          Any jpg, jpeg, png, bmp image file or animated gif accepted up to 10mb file size. The original size and
          dimensions will be maintained.
        </p>
      </div>
      {!!error && <p className="error">{error}</p>}
    </div>
  );
};

export default UploadBtn;

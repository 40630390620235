import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button as MuiButton,
  Box,
  Grid,
  Menu,
  MenuItem,
  ClickAwayListener,
  Paper,
  Tooltip,
  Typography,
  Dialog,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import config from 'Config/firebase';
import { getUserItems, addUserItems, setActiveModal } from 'Store/actions';
import { getChicInfo } from 'Services/api';
import CloseIcon from '@material-ui/icons/Close';
import PageLayout from 'Layout/Layout';
import { useHistory } from 'react-router-dom';
import SectionLayout from 'Layout/SectionLayout';
import Container from 'Components/Container';
import Loading from 'Components/Loading';
import ClaimModal from './ClaimModal';
import ClaimInfoModal from './ClaimInfoModal';
import Button from 'Shared/Button';
import QrCodeFontIcon from '@mui/icons-material/QrCode';
import AddIcon from '@mui/icons-material/Add';
import PlaceholderImg from './assets/placeholder.png';
import Arrow from './assets/arrow.svg';
import { ReactComponent as DiamondIcon } from './assets/diamond.svg';
import { ReactComponent as PolygonIcon } from './assets/polygon.svg';
import { ReactComponent as QRCodeIcon } from './assets/qrcode.svg';
import { useStyles } from './style';
import axiosPlus from 'Services/axios';

const filterItems = [
  { label: 'All', value: 'all' },
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'Polygon', value: 'polygon' },
  { label: 'Pending', value: 'pending' },
];

const MyCollection = () => {
  const classess = useStyles();
  const dispatch = useDispatch();
  const userItems = useSelector(state => state.userItems);
  const user = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(filterItems[0].value);
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [chicInfo, setChicInfo] = useState(null);
  const [showClaimModal, toggleClaimModal] = useState(false);
  const [showClaimInfoModal, toggleClaimInfoModal] = useState(false);
  const history = useHistory();
  const claimItem = useRef(null);
  const claimContract = useRef(null);
  const claimTokenName = useRef(null);
  const claimInfoLink = useRef(null);

  const list = userItems?.nfts || [];
  const total = userItems?.total || 0;

  // 100 is the default page size by alchemy so if we want
  // less we will need to pass in a page size down all the way down
  // https://docs.alchemy.com/reference/getnfts
  const [pageSize] = useState(100);
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(total / pageSize);

  const fetchUserItems = useCallback(
    async filter => {
      setLoading(true);
      await dispatch(getUserItems({ filter }));
      setLoading(false);
    },
    [dispatch],
  );

  const addNextPageItems = useCallback(async () => {
    setLoading(true);
    switch (filter) {
      case 'ethereum':
        await dispatch(addUserItems({ filter, pageKey: userItems.ethereumPageKey }));
        break;
      case 'polygon':
        await dispatch(addUserItems({ filter, pageKey: userItems.polygonPageKey }));
        break;
      case 'all':
        await dispatch(addUserItems({ filter: 'ethereum', pageKey: userItems.ethereumPageKey }));
        await dispatch(addUserItems({ filter: 'polygon', pageKey: userItems.polygonPageKey }));
        break;
      default:
    }
    setLoading(false);
  }, [dispatch, userItems, filter]);

  const filterLabel = useMemo(() => {
    return filterItems.find(item => item.value === filter)?.label;
  }, [filter]);

  const handleTransfer = useCallback(
    item => {
      dispatch(
        setActiveModal('transferNft', {
          blockchain: item.blockchain,
          tokenId: item.itemId,
          tokenIdHex: item.tokenIdHex,
          contractAddress: item.contract,
          schema: item.schema,
          tokenName: item.name,
          tokenImages: item.images,
          user,
        }),
      );
    },
    [dispatch, user],
  );

  const handleCommunity = useCallback(
    item => {
      dispatch(
        setActiveModal('community', {
          tokenId: item.itemId,
          blockchain: item.blockchain,
          contractAddress: item.contract,
          tokenName: item.name || item.title,
          user: user,
        }),
      );
    },
    [dispatch, user],
  );

  const handleStake = useCallback(
    item => {
      dispatch(
        setActiveModal('sellNft', {
          tokenId: item.itemId,
          contractAddress: item.contract,
          tokenName: item.name,
          tokenImages: item.images,
          user,
        }),
      );
    },
    [dispatch, user],
  );

  const handleInitialize = useCallback(
    item => {
      dispatch(
        setActiveModal('layEggs', {
          tokenId: item.itemId,
          images: item.images,
          chicInfo,
        }),
      );
    },
    [dispatch, chicInfo],
  );

  const handleCancel = item => {
    setFilterAnchor(null);
  };

  useEffect(() => {
    if (!!user) {
      fetchUserItems(filter);
      setPage(1);
    }
  }, [user, filter, fetchUserItems]);

  useEffect(() => {
    if (list?.some(item => item?.asset_contract?.address?.toLowerCase() === config.chicADeeContract)) {
      getChicInfo().then(data => {
        setChicInfo(data);
      });
    }
  }, [list]);

  useEffect(() => {
    if (!!page && !!pageSize && !!total && !!userItems) {
      if (list.length <= page * pageSize && list.length < total) {
        addNextPageItems();
      }
    }
  }, [page, pageSize, total, list, addNextPageItems, userItems]);

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <h1 className={classess.heading}>My Collection</h1>
          {!loading && user && (
            <div className={classess.collectionHeader}>
              <div onClick={e => setFilterAnchor(e.currentTarget)} className={classess.collectionSelect}>
                {filterLabel} <img src={Arrow} alt="Not found" />
              </div>
              <MuiButton
                fullWidth
                className={classess.collectionSelect}
                onClick={() => {
                  dispatch(setActiveModal('showAddress'));
                }}
              >
                Receive <QrCodeFontIcon className={classess.qrCodeFontIcon} />
              </MuiButton>
              <MuiButton fullWidth className={classess.collectionSelect} onClick={() => history.push('/mint-nft')}>
                Mint <AddIcon className={classess.qrCodeFontIcon} />
              </MuiButton>
            </div>
          )}

          <div className={classess.collectionItem}>
            {loading && (
              <Loading
                type={'bubbles'}
                color={'#2bc8c5'}
                height={'80px'}
                width={'80px'}
                containerHeight={'40vh'}
                containerWidth={'100%'}
                message="Loading NFTs..."
              />
            )}
            {!loading && user && (
              <Grid container spacing={4}>
                {list?.slice((page - 1) * pageSize, page * pageSize)?.map((item, index) => (
                  <Grid key={index} item sm={6} md={4} lg={3}>
                    <NFTCard
                      item={item}
                      chicInfo={chicInfo}
                      showMakeClaimableDialog={() => {
                        claimItem.current = item.tokenId;
                        claimContract.current = item.contractAddress;
                        claimTokenName.current = item.name || item.title;
                        toggleClaimModal(true);
                      }}
                      onTransfer={() => handleTransfer(item)}
                      onCommunity={() => handleCommunity(item)}
                      onStake={() => handleStake(item)}
                      onInitialize={() => handleInitialize(item)}
                      onCancel={() => handleCancel(item)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {!loading && !user && (
              <h2>
                <a
                  href="#this"
                  onClick={() => {
                    dispatch(setActiveModal('login'));
                  }}
                >
                  Login
                </a>{' '}
                to see your collection.
              </h2>
            )}
          </div>

          <div className={classess.bottomPaginationContainer}>
            <div className={classess.pagination}>
              <Pagination
                count={pageCount}
                page={page}
                onChange={(event, page) => setPage(page)}
                color="secondary"
                className={classess.root}
              />
            </div>
          </div>

          <Menu
            open={!!filterAnchor}
            anchorEl={filterAnchor}
            onClose={() => setFilterAnchor(null)}
            getContentAnchorEl={null}
            classes={{ list: classess.menuList }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {filterItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  setFilter(item.value);
                  setFilterAnchor(null);
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Container>
      </SectionLayout>
      <ClaimModal
        open={showClaimModal}
        onClose={() => toggleClaimModal(false)}
        onGenerate={link => {
          claimInfoLink.current = link;
          toggleClaimInfoModal(true);
        }}
        tokenId={claimItem.current}
        contract={claimContract.current}
        tokenName={claimTokenName.current}
        user={user}
      />
      <ClaimInfoModal
        link={claimInfoLink.current}
        open={showClaimInfoModal}
        tokenId={claimItem.current}
        tokenName={claimTokenName.current}
        onClose={() => toggleClaimInfoModal(false)}
      />
    </PageLayout>
  );
};

const NFTCard = ({
  item,
  chicInfo,
  onTransfer,
  onStake,
  onInitialize,
  onCancel,
  showMakeClaimableDialog,
  onCommunity,
}) => {
  const classes = useStyles();
  const [actionAnchor, setActionAnchor] = useState(null);
  const [QROpen, setQROpen] = useState(false);
  const [showMetaConfirmModal, setShowMetaConfirmModal] = useState(false);
  const [metaMessage, setMetaMessage] = useState(false);

  const isChicADee = item?.contract === config.chicADeeContract;
  const hasEggs = chicInfo?.chicBalances?.some(chic => chic.id === item.itemId);
  const eggBalance = hasEggs ? chicInfo.chicBalances.find(chic => chic.id === item.itemId).balance : 0;

  const detailLink =
    item?.mintStatus === 'pending'
      ? `nft/${item?.itemId}`
      : `token/${item?.contract}/${item?.itemId}/${item?.blockchain || 'ethereum'}`;

  const handleTransfer = () => {
    setActionAnchor(null);
    onTransfer();
  };

  const handleStake = () => {
    setActionAnchor(null);
    onStake();
  };

  const handleInitialize = () => {
    setActionAnchor(null);
    onInitialize();
  };

  const handleCancel = () => {
    setActionAnchor(null);
    onCancel();
  };

  const handleRefreshMeta = async () => {
    try {
      setActionAnchor(null);
      setShowMetaConfirmModal(true);
      await axiosPlus.get(`/refresh-meta/${item.contract}/${item.blockchain}`);
      setMetaMessage('The metadata for this token is being refreshed. It may take serveral minutes to take an effect.');
    } catch (err) {
      setMetaMessage('Failed to refresh the metadata for this token');
      console.log('Failed to refresh metadata');
    }
  };

  const handleMetaConfirmClose = () => {
    setShowMetaConfirmModal(false);
    setMetaMessage('');
  };

  const downloadQRCode = (imgUrl, name) => {
    fetch(imgUrl, { method: 'GET', headers: {} })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const blobUrl = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const Title = useMemo(() => {
    return (
      <Paper>
        <Box p={2}>
          <img
            src={`${config.functionsURL}/v1/qrcode/ownerfy/${item.contract}/${item.itemId}`}
            width={150}
            height={150}
            alt="QR Code"
          />

          <Box pt={2} sx={{ textAlign: 'center' }}>
            <Box
              onClick={() =>
                downloadQRCode(
                  `${config.functionsURL}/v1/qrcode/ownerfy/${item.contract}/${item.itemId}`,
                  'OwnerfyQRCode.jpg',
                )
              }
              className={classes.qrActionBtn}
              py={0.5}
            >
              Download this QR Code
            </Box>

            <Box
              onClick={() =>
                downloadQRCode(
                  `${config.functionsURL}/v1/qrcode/opensea/${item.contract}/${item.itemId}`,
                  'OpenseaQRCode.jpg',
                )
              }
              className={classes.qrActionBtn}
              py={0.5}
            >
              Download link to OpenSea
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }, [classes.qrActionBtn, item.contract, item.itemId]);

  return (
    <div className={classes.collectionCard}>
      <div className={classes.collectionCardHeader}>
        <a href={detailLink} rel="noopener noreferer">
          <img src={item?.images[0]?.full || PlaceholderImg} alt="Not Found" className={classes.collectionCardImg} />
        </a>

        {/* {item?.mintStatus !== 'pending' && (
          <ClickAwayListener onClickAway={() => setQROpen(false)}>
            <Tooltip
              arrow
              title={Title}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={QROpen}
              onClose={() => setQROpen(false)}
              classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper,
                tooltipArrow: classes.tooltipArrow,
                arrow: classes.tooltipArrow,
              }}
            >
              <QRCodeIcon width={32} onClick={() => setQROpen(prev => !prev)} className={classes.qricon} />
            </Tooltip>
          </ClickAwayListener>
        )} */}
      </div>

      <div className={classes.collectionCardBody}>
        <div>
          <h1 className={classes.cardTitle}>{item.name}</h1>
          <p className={classes.cardDesc}>
            <a href={detailLink} rel="noopener noreferer">
              NFT ID: {item.itemId}
            </a>
          </p>
          {item?.mintStatus === 'pending' && (
            <p className={classes.mintPrint}>
              Mint pending: This item is currently minting. This usually takes up to a minute. After that there will be
              more options available. Please refresh the page in a minute.
            </p>
          )}
        </div>

        <a href={detailLink} rel="noopener noreferer">
          {item?.blockchain === 'polygon' ? <PolygonIcon /> : <DiamondIcon />}
        </a>
      </div>

      <div className={classes.cardFooter}>
        <MuiButton
          fullWidth
          className={classes.chooseAction}
          variant="outlined"
          onClick={e => setActionAnchor(e.currentTarget)}
        >
          Choose an action
        </MuiButton>

        <Menu
          open={!!actionAnchor}
          anchorEl={actionAnchor}
          onClose={() => setActionAnchor(null)}
          getContentAnchorEl={null}
          MenuListProps={{
            style: { width: actionAnchor && actionAnchor.offsetWidth },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {item?.mintStatus === 'complete' && <MenuItem onClick={handleTransfer}>Transfer</MenuItem>}
          {/* {item?.mintStatus === 'complete' && <MenuItem onClick={handleStake}>Stake</MenuItem>} */}
          {isChicADee && chicInfo && chicInfo.chicBalances && (
            <MenuItem onClick={handleInitialize}>Initialize</MenuItem>
          )}
          <MenuItem onClick={handleCancel}>Cancel</MenuItem>
          <MenuItem onClick={handleRefreshMeta}>Metadata Refresh</MenuItem>
          {item?.blockchain === 'polygon' && item.mintStatus !== 'pending' && (
            <MenuItem onClick={showMakeClaimableDialog}>Make Claimable</MenuItem>
          )}

          <MenuItem onClick={onCommunity}>Community</MenuItem>
        </Menu>
      </div>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={showMetaConfirmModal}
        onClose={handleMetaConfirmClose}
        PaperProps={{ style: { borderRadius: 12 } }}
      >
        <Box p={2}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton size="small" onClick={handleMetaConfirmClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {!!metaMessage ? (
            <Box p={2}>
              <Box mb={2} px={4}>
                <Typography style={{ textAlign: 'center', color: 'gray' }} variant="h3">
                  {metaMessage}
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center">
                <Button onClick={handleMetaConfirmClose} background btnLabel="Got it" />
              </Box>
            </Box>
          ) : (
            <Box p={2} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Dialog>
    </div>
  );
};

export default MyCollection;

// From: https://github.com/peterpeterparker/web-social-share/blob/main/src/utils/email.ts

import { isMobile, staticOpenNewWindow } from './utils';

export const shareEmail = async attrs => {
  let urlString = 'mailto:';

  if (attrs.socialShareTo) {
    urlString += encodeURIComponent(attrs.socialShareTo);
  }

  urlString += '?';

  if (attrs.socialShareBody) {
    urlString += 'body=' + encodeURIComponent(attrs.socialShareBody);
  }

  if (attrs.socialShareSubject) {
    urlString += '&subject=' + encodeURIComponent(attrs.socialShareSubject);
  }

  if (attrs.socialShareCc) {
    urlString += '&cc=' + encodeURIComponent(attrs.socialShareCc);
  }

  if (attrs.socialShareBcc) {
    urlString += '&bcc=' + encodeURIComponent(attrs.socialShareBcc);
  }

  if (isMobile()) {
    staticOpenNewWindow(urlString);
  } else {
    window.open(
      urlString,
      'Email',
      'toolbar=0,status=0,resizable=yes,width=' +
        (attrs?.socialSharePopupWidth || 700) +
        ',height=' +
        (attrs?.socialSharePopupHeight || 700) +
        ',top=' +
        (window.innerHeight - attrs?.socialSharePopupHeight) / 2 +
        ',left=' +
        (window.innerWidth - attrs?.socialSharePopupWidth) / 2,
    );
  }
};

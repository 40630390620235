import firebaseService from 'Services/firebaseService';
import history from '../../history';

import * as types from './types';

export function submitLoginWithFireBase({ email, password, returnPath }) {
  return dispatch =>
    firebaseService.auth &&
    firebaseService.auth
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        firebaseService.getUserData(response.user.uid);
      })
      .then(user => {
        if (returnPath) {
          history.push(`${returnPath}`);
        }
        dispatch({
          type: types.SET_USER_DATA,
          payload: user,
        });

        return { status: true };
      })
      .catch(error => {
        dispatch({
          type: types.LOGIN_ERROR,
          payload: error,
        });

        if (error?.code === 'auth/wrong-password') {
          return {
            status: false,
            message: 'Email or Password is wrong',
          };
        } else if (error?.code === 'auth/too-many-requests') {
          return {
            status: false,
            message: 'Too many requests',
          };
        }

        return {
          status: false,
          message: 'Something went wrong.',
        };
      });
}

/**
 * Logout
 */
export function logoutUser({ returnPath }) {
  return dispatch => {
    firebaseService.signOut().then(() => {
      dispatch({
        type: types.USER_LOGGED_OUT,
      });
      dispatch({
        type: types.REMOVE_USER_DATA,
      });

      if (returnPath) {
        history.push(`${returnPath}`);
      }
    });
  };
}

/**
 * Password Reset
 */
export function passwordReset({ email }) {
  return dispatch => {
    firebaseService
      .resetPassword(email)
      .then(res => {
        return dispatch({
          type: types.PASSWORD_RESET,
          payload: res,
        });
      })
      .catch(error => {
        console.warn('Password reset connection error: ', error);
      });
  };
}

export function registerWithFirebase(model) {
  const { email, password, returnPath } = model;
  let userModel;

  return dispatch =>
    firebaseService.auth &&
    firebaseService.auth
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        // we'll have to bring verification back with free version
        // or maybe only for free registration
        //response.user.sendEmailVerification();
        userModel = {
          ...model,
          uid: response.user.uid,
        };

        // Create in DB
        return firebaseService.createUserData(userModel);
      })
      .then(userData => {
        dispatch({
          type: types.SET_ACTIVE_MODAL,
          name: 'none',
        });

        if (returnPath) {
          history.push(`${returnPath}`);
        }

        dispatch({
          type: types.SET_USER_DATA,
          payload: userData,
        });

        return {
          status: true,
          payload: userData,
        };
      })
      .catch(error => {
        // firebase saves human readable message in message

        const _error = error.message || error;
        dispatch({
          type: types.REGISTER_ERROR,
          payload: _error,
        });
        return {
          status: false,
          payload: _error,
        };
      });
}

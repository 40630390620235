import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  successContainer: {
    maxWidth: '565px',
    margin: '0 auto'
  },
  successBlock: {
    background: '#FFFFFF',
    borderRadius: '20px',
    padding: '45px 70px',
    textAlign: 'center',
    fontFamily: 'Lato',
    '@media (max-width: 565px)': {
      padding: '30px'
    }
  },
  heading: {
    fontSize: '36px',
    lineHeight: '120%',
    margin: '0',
    color: '#323441'
  },
  description: {
    fontSize: '18px',
    lineHeight: '150%',
    margin: '20px 0',
    color: '#5B5D68',
    letterSpacing: '0.02em'
  },
  codeBlock: {
    margin: '30px 0',
    '& .lbplan': {
      borderRadius: '20px',
      background: '#1E3545',
      padding: '40px',
      '@media (max-width: 565px)': {
        padding: '30px'
      },
      '& span': {
        borderRadius: '20px !important',
        background: '#1E3545 !important'
      },
      '& button': {
        background: 'transparent',
        border: '0',
        cursor: 'pointer'
      }
    }
  }
}));

export { useStyles };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  loginContainer: {
    marginTop: 22,
    maxWidth: 485,
  },
  link: {
    color: '#21D7BB',
    textDecoration: 'none',
    fontSize: '18px',
    fontFamily: 'Lato',
    outline: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  modalHeading: {
    fontFamily: 'Lato',
    fontStyle: ' normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '100%',
    color: '#323441',
  },
  loginHelperText: {
    color: '#748896',
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: '400',
  },
}));

export { useStyles };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  faqContainer: {
    backgroundColor: '#DDF4FF',
    paddingBottom: '80px',
  },
  faqHeader: {
    marginTop: '60px',
    marginBottom: '60px',
    textAlign: 'center',
  },
  faqItemContainer: {
    position: 'relative',
    '& :before': {
      content: "''",
      background: '-webkit-linear-gradient(left, #4567DE 0%, #24E5AD 100%)',
      display: ' block',
      height: '1.5px',
      width: '100%',
      position: 'absolute',
      top: 10,
    },
  },
  faqItem: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  itemTitle: {
    fontFamily: 'Lato',
    fontSize: '42px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  itemDesc: {
    fontSize: '16px',
    fontFamily: 'Lato',
    background: 'linear-gradient(to right, #24E5AD 2%, #4567DE 100%)',
    backgroundClip: 'text',
    '-webkit-background-clip': 'text',
    color: 'transparent',
    margin: '0px',
  },
  faqTitle: {
    fontFamily: 'Lato',
    fontWeight: 'normal',
    color: '#323441',
    fontSize: '22px',
  },
  faqDesc: {
    fontFamily: 'Lato',
    fontWeight: '400',
    color: '#748896',
    fontSize: '18px',
  },
  expandIcon: {
    width: '20px',
  },
}));

export { useStyles };

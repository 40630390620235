import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#16252F',
      color: '#21D7BB !important',
    },
    '& .MuiPaginationItem-page': {
      border: '1px solid #323441 !important',
    },
  },
  historyContainer: {
    // backgroundColor: '#DDF4FF',
    // paddingBottom: '80px',
    // paddingTop: '60px',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: '38px',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: '#1F2029',
    margin: '0px',
    textAlign: 'center',
  },
  helperText: {
    fontSize: '16px',
    fontFamily: 'Lato',
    color: '#748896',
    margin: '0px',
    width: '60%',
    textAlign: 'center',
  },
  tableContainer: {
    marginTop: '22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // maxWidth: '764px',
  },
  table: {
    width: '100%',
    textAlign: 'center',
    borderSpacing: '0 10px',
    borderCollapse: 'separate',
    borderStyle: 'hidden',
  },
  row: {
    background: '#FFFFFF',
    borderRadius: '10px',
    minHeight: '70px',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 20px',
    position: 'relative'
  },
  link: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%'
  },
  tableCell: {
    width: '200px',
    display: 'inline-block',
    textAlign: 'left',
    wordBreak: 'break-word',
    fontSize: '14px',
    fontFamily: 'Lato',
  },
  pagination: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  TableHeaderRow: {
    borderRadius: '10px',
    minHeight: '70px',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 20px',
  },
}));

export { useStyles };

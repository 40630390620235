import React, { useState, useEffect } from 'react';
import { isNull } from 'lodash';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Page from 'Components/Page';
import { useForm } from 'react-hook-form';
import * as actions from 'Store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import FormTextField from 'Components/FormTextField';
import Loading from 'Components/Loading';
import { createStripeCheckoutSessionForCharacterCreation, getCharacter, getTotalJPFForAccount } from 'Services/api';
import config from 'Config/firebase';
import InPageSignupLogin from 'Components/InPageSignupLogin';
import { Helmet } from 'react-helmet';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const pageStyles = theme => ({
  pageWrapper: {
    paddingLeft: '3%',
    paddingRight: '3%',
  },
  addEditForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionalMessage: {
    fontSize: 14,
  },
  button: {
    alignSelf: 'flex-start',
    marginTop: '40px',
  },
  photoContainer: {
    marginBottom: 20,
  },
  collectionContainer: {
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    width: '100%',
    gap: '5px',
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      overflowX: 'scroll',
    },
  },
  collectionItem: {
    height: '255px',
    width: '255px',
    [theme.breakpoints.down('xs')]: {
      height: '200px',
      width: '200px',
    },
  },
  headline: {
    [theme.breakpoints.down('xs')]: {
      lineHeight: '40px',
    },
  },
  dialogTitle: {
    marginTop: 15,
    fontSize: '17px !important',
  },
  dialogContent: {
    wordBreak: 'break-word',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MintJPandFriends({ classes, user, registerWithFirebase }) {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(null);
  const [isSignupLoginFlowValid, setIsSignupLoginFlowValid] = useState(false);
  const [userSignupValues, setUserSignupValues] = useState({});
  const [characterInfo, setCharacterInfo] = useState(null);
  const [isTransferToOwnerfyWallet, setIsTransferToOwnerfyWallet] = useState(true);
  const [isExternalWalletAddressConfirmationVisible, setIsExternalWalletAddressConfirmationVisible] = useState(false);

  const quantity = Number(watch('quantity'));
  const externalWalletAddress = watch('externalWalletAddress');

  useEffect(() => {
    const fetchCharacter = async () => {
      setIsLoading(true);
      const character = await getCharacter({ id: 'jpAndFriends' });

      setCharacterInfo(character.data);
      setIsLoading(false);
    };

    fetchCharacter();
  }, []);

  const sendToStripeCheckoutForCharacterCreation = async order => {
    // eslint-disable-next-line no-undef
    const stripe = Stripe(config.stripe);
    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForCharacterCreation({
        order,
        cancelUrl: window.location.href,
      });

      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const registerUser = async ({ firstName, lastName, email, password, phone, username }) => {
    try {
      const registrationRequest = await registerWithFirebase({
        firstName: user?.firstName || firstName,
        lastName: user?.lastName || lastName,
        username: user?.username || username,
        email: user?.email || email,
        password: user?.password || password,
        phone: user?.phone || phone,
      });

      const userId = registrationRequest.payload.uid;
      return userId;
    } catch (err) {
      console.warn('User Registration error: ', err);
      alert(err);
      return;
    }
  };

  const finalizeSendToStripe = async () => {
    await sendToStripeCheckoutForCharacterCreation({
      id: 'jpAndFriends',
      quantity: Number(quantity),
      ...(externalWalletAddress ? { externalWalletAddress: externalWalletAddress.trim() } : {}),
    });
  };

  const onSubmit = async data => {
    if (!isTransferToOwnerfyWallet) {
      setIsExternalWalletAddressConfirmationVisible(true);
      return;
    }

    setIsLoading(true);
    let { firstName, lastName, email, password, phone, username } = userSignupValues;

    if (!user?.uid) {
      await registerUser({ firstName, lastName, email, username, password, phone });
    }

    const mintData = await getTotalJPFForAccount();

    if (parseInt(mintData.totalMinted, 10) + parseInt(data.quantity, 10) > 12) {
      alert('Max is 12 per account');
      return;
    }

    await finalizeSendToStripe();
  };

  const onSignupLoginValidationChange = (isValid, formValues) => {
    setIsSignupLoginFlowValid(isValid);
    setUserSignupValues(formValues);
  };

  function getQuantityHelperText() {
    if (errors.quantity && errors.quantity.message) {
      return errors.quantity.message;
    }

    return `Maximum quantity to mint per account is 12`;
  }

  if (isNull(isLoading)) {
    return null;
  }

  if (isLoading) {
    return (
      <Page wrapperStyles={classes.pageWrapper}>
        <Loading
          type={'bubbles'}
          color={'#2bc8c5'}
          height={'80px'}
          width={'80px'}
          containerHeight={'40vh'}
          containerWidth={'100%'}
          message={'Loading'}
        />
      </Page>
    );
  }

  return (
    <Page wrapperStyles={classes.pageWrapper}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>J. Pierce &amp; Friends Hand Painted NFT Collection</title>
        <description>4000 Hand Painted and Signed NFT Characters</description>
        <meta property="og:title" content="J. Pierce and Friends Hand Painted NFT Collection" />
        <meta property="og:description" content="4000 Hand Painted and Signed NFT Characters" />
        <meta property="og:image" content="https://ownerfy.com/img/jpandfriends/friends.png" />
      </Helmet>
      <Typography variant="h1" className={classes.headline}>
        Mint {characterInfo.namePlural}
      </Typography>
      <FormHelperText>{characterInfo.description}</FormHelperText>

      <div className={classes.collectionContainer}>
        <img
          src="/img/jpandfriends/j-pierce-character-001.png"
          alt="jp & friends sample"
          className={classes.collectionItem}
        />
        <img
          src="/img/jpandfriends/j-pierce-character-002.png"
          alt="jp & friends sample"
          className={classes.collectionItem}
        />
        <img
          src="/img/jpandfriends/j-pierce-character-004.png"
          alt="jp & friends sample"
          className={classes.collectionItem}
        />
        <img
          src="/img/jpandfriends/j-pierce-character-005.png"
          alt="jp & friends sample"
          className={classes.collectionItem}
        />
      </div>

      <InPageSignupLogin onValidationChange={onSignupLoginValidationChange} />

      <form onSubmit={handleSubmit(onSubmit)} className={classes.addEditForm}>
        <FormTextField
          fullWidth
          label="Quantity"
          name="quantity"
          required
          error={!!errors.quantity}
          helperText={getQuantityHelperText()}
          inputRef={register({
            required: 'Quantity is required',
            pattern: {
              value: /^[0-9]+$/,
              message: 'The quantity must be a whole number',
            },
            validate: {
              isOverLimit: async value => {
                if (value > 12) {
                  return 'The maximum amount of characters to mint at one time is 12';
                }
              },
            },
          })}
          placeholder="Enter quantity"
        />

        <InputLabel
          style={{
            position: 'relative',
            marginTop: '60px',
            top: '-15px',
          }}
        >
          Uncheck to send to another wallet or as a gift
        </InputLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={isTransferToOwnerfyWallet}
              onChange={() =>
                setIsTransferToOwnerfyWallet(currentIsTransferToOwnerfyWallet => !currentIsTransferToOwnerfyWallet)
              }
              name="isTransferToOwnerfyWallet"
            />
          }
          label={
            <span>
              Your Ownerfy account wallet{' '}
              <span
                style={{
                  fontSize: '12px',
                }}
              >
                (Can be transferred to another wallet later)
              </span>
            </span>
          }
        />

        {!isTransferToOwnerfyWallet && (
          <FormTextField
            fullWidth
            label="External Wallet Address"
            name="externalWalletAddress"
            required
            error={!!errors.externalWalletAddress}
            helperText={errors.externalWalletAddress && errors.externalWalletAddress.message}
            inputRef={register({
              required: 'The external wallet address is required',
              pattern: {
                value: /^0x[a-fA-F0-9]{40}$/,
                message: 'The external wallet address format is incorrect',
              },
            })}
            placeholder="Enter external wallet address"
          />
        )}

        <FormControl>
          <InputLabel
            style={{
              position: 'relative',
              marginTop: '35px',
              top: '-40px',
            }}
          >
            Fees
          </InputLabel>
          <p>
            {quantity > 0 ? (
              <>
                <strong>Purchase: </strong> ${(characterInfo.fees.purchaseFeeInUsd * quantity).toFixed(2)} (
                {characterInfo.fees.purchaseFeeInEth * quantity} ETH)
                <br />
                <strong>Processing (gas) fees: </strong> ${(characterInfo.fees.gasFeeInUsd * quantity).toFixed(2)} (
                {characterInfo.fees.gasFeeInEth * quantity} ETH)
                <br />
                <strong>Total: </strong> ${(characterInfo.fees.totalFeeInUsd * quantity).toFixed(2)} (
                {characterInfo.fees.totalFeeInEth * quantity} ETH)
              </>
            ) : (
              <span>Please enter a quantity.</span>
            )}
          </p>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!(user?.uid || isSignupLoginFlowValid)}
        >
          Mint {quantity > 1 ? characterInfo.namePlural : characterInfo.nameSingular}
        </Button>
      </form>

      <Dialog
        open={isExternalWalletAddressConfirmationVisible}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsExternalWalletAddressConfirmationVisible(false)}
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.dialogTitle}>
          Confirm external wallet address
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className={classes.dialogContent}>
            Please confirm that this is the correct external wallet address. Do not use an exchange address or it will
            be lost. This is the address that the NFT will be sent to. If this is incorrect, we can not retrieve the NFT
            or issue refunds. If you are not sure, we recommend you use the option to mint to your Ownerfy wallet
            address automatically by checking the <u>Send to Ownerfy Account Wallet</u> checkbox.
            <br />
            <br />
            <b>External Wallet Address the NFT will be sent to:</b>
            <br />
            {externalWalletAddress}
            <br />
            <a href={`https://opensea.io/${externalWalletAddress}`} target="_blank" rel="noopener noreferrer">
              https://opensea.io/{externalWalletAddress}
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsExternalWalletAddressConfirmationVisible(false)} color="primary">
            Go back
          </Button>
          <Button
            onClick={async () => {
              setIsLoading(true);
              await finalizeSendToStripe();
            }}
            color="primary"
          >
            Confirm, let's mint
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      registerWithFirebase: actions.registerWithFirebase,
    },
    dispatch,
  );
}

function mapStateToProps({ user }, ownProps) {
  return {
    user,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyles)(MintJPandFriends)));

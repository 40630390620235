import React, { Component } from 'react';
import firebaseService from 'Services/firebaseService';
import * as actions from 'Store/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Auth extends Component {
  /* eslint-disable-next-line no-useless-constructor */
  constructor(props) {
    super(props);

    this.firebaseCheck();
  }

  firebaseCheck = () => {
    firebaseService.init();

    firebaseService.onAuthStateChanged(authUser => {
      if (authUser) {
        firebaseService.getUserData(authUser.uid).then(dbUser => {
          this.props.setUserData(dbUser);
        });
      } else {
        this.props.removeUserData();
      }
    });
  };

  render() {
    const { children } = this.props;

    return <React.Fragment>{children}</React.Fragment>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUserData: actions.setUserData,
      removeUserData: actions.removeUserData,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(Auth);

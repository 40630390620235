import React, { useState } from 'react';
import { Box, Typography, Container, Grid } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import * as actions from 'Store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import config from 'Config/firebase';
import FormContainer from 'Components/FormInputs/FormContainer';
import Select from 'Components/FormInputs/Select';
import TextField from 'Components/FormInputs/TextField';
import Button from 'Shared/Button';
import axios from 'Services/axios';
import * as Yup from 'yup';

const styles = theme => ({
  header: {
    marginTop: 30,
  },
});

function Contact({ setActiveModal, classes }) {
  const [mailSent, setMailSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const selectOptions = [
    { value: 'business', label: 'Making a New NFT Project' },
    { value: 'collab', label: 'New Collaboration' },
    { value: 'customerSupport', label: 'Customer Support' },
    { value: 'NFT API', label: 'NFT API' },
    { value: 'other', label: 'Something Else' },
  ];

  const onSubmit = async data => {
    try {
      const { subject } = data;
      const payload = {
        ...data,
        subject: selectOptions.find(item => item.value === subject),
      };

      const validationResult = await axios.post('/checkRecaptcha', { captchaToken });
      if (validationResult?.data?.status !== 'success') {
        setCaptchaToken(null);
        return;
      }

      fetch(`${config.functionsURL}/contactUs`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
    } catch (err) {
      console.warn('Contact server error: ', err);
      alert('Contact connection error');
      return;
    }

    setMailSent(true);
  };

  const validationSchema = Yup.object().shape({
    subject: Yup.string().nullable().required('This field is required'),
    email: Yup.string().nullable().required('This field is required').email('Provide a valid email address'),
    message: Yup.string().nullable().required('This field is required').min(3, 'Message too short'),
  });

  const initialValues = {
    subject: 'business',
    email: '',
    message: '',
  };

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container component="main" maxWidth="sm">
          <Box mb={4}>
            <Typography component="h1" variant="h5" className={classes.header}>
              Contact (Our normal reply time is one day)
            </Typography>
          </Box>

          {!mailSent ? (
            <FormContainer validation={validationSchema} defaultValues={initialValues} onSuccess={onSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Select options={selectOptions} name="subject" label="Subject" />
                </Grid>

                <Grid item xs={12}>
                  <TextField style={{ width: '100% ' }} name="email" label="Email" placeholder="Enter Email" />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    style={{ width: '100% ' }}
                    name="message"
                    label="Message"
                    multiline
                    placeholder=""
                    minRows={8}
                    maxRows={80}
                  />
                </Grid>

                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey={config.captchaSiteKey}
                    onChange={token => setCaptchaToken(token)}
                    onExpired={() => setCaptchaToken(null)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button background type="submit" disabled={!captchaToken} btnLabel="Submit" />
                </Grid>
              </Grid>
            </FormContainer>
          ) : (
            <div className={classes.header}>
              We will respond as soon as possible. Our normal business hours are 8AM - 6PM Eastern Time Zone
            </div>
          )}
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: actions.submitLoginWithFireBase,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(Contact)));

import React from 'react';
import { Container, Grid, Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: 40,
    lineHeight: '60px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: '#050403',
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '34px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: '#383738',
  },
  imageContainer: {
    width: '100%',
    height: 285,
    paddingBottom: 30,
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  buyBtn: {
    border: '4px solid #000000',
    backgroundColor: '#FFE60F',
    width: '100%',
    height: 56,
    fontSize: 18,
    fontWeight: 600,
  },
}));

const images = [
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/receiptchain-dev.appspot.com/o/images%2F1_.svg?alt=media&token=7d2163f1-6316-48a5-8cd5-e49710f8b6db',
    bgcolor: '#3CBBE8',
    href: 'https://shop.ownerfy.com/products/jp-mohawk-weed',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/receiptchain-dev.appspot.com/o/images%2F2_.svg?alt=media&token=0b247ee5-74b1-437e-a703-b138323c9c9b',
    bgcolor: '#FC65AA',
    href: 'https://shop.ownerfy.com/products/jp-watergun',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/receiptchain-dev.appspot.com/o/images%2F3_.svg?alt=media&token=d3d5e609-5128-4cae-bea2-9efb6456b254',
    bgcolor: '#FBD51C',
    href: 'https://shop.ownerfy.com/products/jp-fire-eyes',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/receiptchain-dev.appspot.com/o/images%2F4_.svg?alt=media&token=37cdfead-0da3-4c54-8e8a-01befcf9473c',
    bgcolor: '#F5683D',
    href: 'https://shop.ownerfy.com/products/jp-and-friends',
  },
  {
    url: 'https://firebasestorage.googleapis.com/v0/b/receiptchain-dev.appspot.com/o/images%2F5_.svg?alt=media&token=44f78ea8-93b3-4d1e-9a4d-168edf1740d4',
    bgcolor: '#E40D6A',
    href: 'https://shop.ownerfy.com/products/jp-gamer',
  },
];

const Merchandise = () => {
  const classes = useStyles();

  return (
    <Box bgcolor="background.paper" py={12}>
      <Container>
        <Box className={classes.titleWrapper} mb={5}>
          <Typography className={classes.title} mb={2} variant="h3">
            Shop J.Pierce & Friends
          </Typography>
          <Typography className={classes.subtitle} variant="h6">
            The merchandise featuring the NFT collection is in.
          </Typography>
        </Box>

        <Grid container>
          {images.map((item, index) => (
            <Grid key={index} item xs={12} md>
              <Box bgcolor={item.bgcolor} className={classes.imageContainer}>
                <img src={item.url} alt="Shirts" />
              </Box>
              <Box px={5} py={3}>
                <Button href={item.href} className={classes.buyBtn}>
                  BUY NOW
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Merchandise;

import { TextField as MUITextField } from '@material-ui/core'
import React from 'react'
import { Controller } from 'react-hook-form'

const TextField = React.memo(
  ({ parseError, type, required, name, label, ...rest }) => (
    <Controller
      name={name}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { invalid, error },
      }) => (
        <MUITextField
          {...rest}
          name={name}
          label={label}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          type={type}
          error={invalid}
          helperText={error ? typeof parseError === 'function' ? parseError(error) : error.message : rest.helperText}
          FormHelperTextProps={{ variant: 'standard' }}
        />
      )}
    />
  )
)

export default TextField

import { useState, useCallback } from 'react';
import { postCompany, fetchCompany, updateCompany } from 'Services/api';

const useCompany = function () {
  const [loading, setLoading] = useState(false);

  const addCompany = async data => {
    setLoading(true);
    const result = await postCompany(data);
    setLoading(false);
    return result;
  };

  const getCompany = useCallback(async () => {
    setLoading(true);
    const result = await fetchCompany();
    setLoading(false);
    return result || {};
  }, []);

  const updateCurrentCompany = async data => {
    setLoading(true);
    const result = await updateCompany(data);
    setLoading(false);
    return result;
  };

  return { loading, addCompany, getCompany, updateCompany: updateCurrentCompany };
};

export default useCompany;

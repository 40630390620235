/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, useEffect, useMemo } from 'react';
import { jsx } from '@emotion/react';
import { withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from 'Components/Loading';
import ModalContent from '../ModalContent';
import { getDataForClaim, getAlchemyNftMetadata, getContractMeta } from '../../Services/api';
import { closeActiveModal } from 'Store/actions';

import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
const { convertArrayToCSV } = require('convert-array-to-csv');

const styles = theme => ({
  mainContainer: {
    marginBottom: 20,
  },
});

function Community({ tokenId, contractAddress, tokenName, user, blockchain, classes }) {
  // const [contractOwners, setContractOwners] = useState([]);
  const dispatch = useDispatch();
  const [tokenInfo, setTokenInfo] = useState(null);
  const [contractMeta, setContractMeta] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    setLoading(true);
    const _getDataForClaim = async (setFileName, setCsvData) => {
      const _claimData = await getDataForClaim({ tokenId, contract: contractAddress });

      setFileName(_claimData.fileName);
      //let csvContent = 'data:text/csv;charset=utf-8,';
      if (_claimData.redemptions && _claimData.redemptions.length > 0) {
        let csvContent = convertArrayToCSV(_claimData.redemptions);
        setCsvData(`data:text/csv;charset=utf-8, ${csvContent}`);
      }
      setLoading(false);
    };
    const _getAlchemyNftMetadata = async getAlchemyNftMetadata => {
      const _tokenInfo = await getAlchemyNftMetadata({
        contract: contractAddress,
        blockchain,
        tokenId,
      });
      setTokenInfo(_tokenInfo);
    };

    const _getContractMeta = async getContractMeta => {
      const _claimOrder = await getContractMeta({ address: contractAddress });
      setContractMeta(_claimOrder);
    };

    _getDataForClaim(setFileName, setCsvData);
    _getAlchemyNftMetadata(getAlchemyNftMetadata);
    _getContractMeta(getContractMeta);
  }, [tokenId, contractAddress, user, blockchain, setFileName, setCsvData]);

  // TODO: convert this into the standard modal form with container instead of dialog here
  return (
    <ModalContent
      title="Community Information"
      formContents={
        <>
          {loading && (
            <Loading
              type={'bubbles'}
              color={'#2bc8c5'}
              height={'80px'}
              width={'80px'}
              containerHeight={'40vh'}
              containerWidth={'100%'}
              message="Loading"
            />
          )}
          {!loading && (
            <Grid container spacing={2} className={classes.mainContainer}>
              <Grid item xs={12}>
                {tokenName}
              </Grid>

              {contractMeta?.website && (
                <Grid item xs={12}>
                  Website: <a href={contractMeta.website}> {contractMeta.website}</a>
                </Grid>
              )}

              {contractMeta?.discord && (
                <Grid item xs={12}>
                  Discord: <a href={contractMeta.discord}> {contractMeta.discord}</a>
                </Grid>
              )}

              {contractMeta?.twitter && (
                <Grid item xs={12}>
                  Twitter: <a href={contractMeta.twitter}> {contractMeta.twitter}</a>
                </Grid>
              )}

              {contractMeta?.tiktok && (
                <Grid item xs={12}>
                  TikTok: <a href={contractMeta.tiktok}> {contractMeta.tiktok}</a>
                </Grid>
              )}

              {contractMeta?.instagram && (
                <Grid item xs={12}>
                  Instagram: <a href={contractMeta.instagram}> {contractMeta.instagram}</a>
                </Grid>
              )}

              {contractMeta?.telegram && (
                <Grid item xs={12}>
                  Telegram: <a href={contractMeta.discord}> {contractMeta.telegram}</a>
                </Grid>
              )}

              {/* <Grid item xs={12}>
         Number of owners of this Collection: {contractOwners.length}
       </Grid> */}
              {tokenInfo && tokenInfo.owners && tokenInfo.owners.length > 0 && (
                <Grid item xs={12}>
                  Number of owners of this token: {tokenInfo.owners.length}
                </Grid>
              )}

              {csvData && (
                <Grid item xs={12}>
                  <a href={csvData} download={`${fileName}.csv`}>
                    Download CSV (Only you can see this)
                  </a>
                </Grid>
              )}
            </Grid>
          )}
        </>
      }
    />
  );
}

export default withRouter(withStyles(styles)(Community));

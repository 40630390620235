import React from 'react';
import { Select as MuiSelect, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { MenuProps, useStyles } from './style';

const SelectField = ({ options, label, name, error, value, touched, onChange }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.formControl}>
      {label && <InputLabel>{label}</InputLabel>}
      <MuiSelect
        IconComponent={DownArrowIcon}
        label={label}
        value={value}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        className={`${error && touched && 'inputError'}`}
        MenuProps={MenuProps}
        renderValue={(value) => {
          const selected = options.find((option) => option.value == value);
          if (selected?.image) {
            return (
              <div className={classes.image}>
                <img src={selected.image} alt="Selected Option" />
              </div>
            )
          }
          if (selected?.label) return selected.label
          return value;
        }}
      >
        {options.map((option, index) => (
          <MenuItem className={classes.menuItem} key={index} value={option.value}>
            {option.image && (
              <div className={classes.image}>
                <img src={option.image} alt="Select Option" />
              </div>
            )}

            <div>{option.label}</div>
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

const DownArrowIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.arrow}>
      <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.96094 5L5.96094 1L9.96094 5"
          stroke="#21D7BB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
};

export default SelectField;

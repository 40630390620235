import * as Actions from '../actions';

const INITIAL_STATE = {
  name: '',
  vendor: '',
  ownerFirstName: '',
  ownerLastName: '',
  ownerCity: '',
  origin_location: {
    city: '',
    name: '',
  },
  images: [
    {
      src: '',
    },
  ],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Actions.SET_ITEM_DATA:
      // If empty don't show last item
      if (!action.payload) return INITIAL_STATE;
      // Also don't bring back any attributes of old products
      return {
        ...action.payload,
      };
    case Actions.RESET_ITEM_DATA:
      return INITIAL_STATE;
    case Actions.SET_ITEM_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import SvgIcon from '@material-ui/core/SvgIcon';

function FooterSocialLink({ svgPath, svgViewBox, IconComponent, href, className, ...props }) {
  const iconStyles = css`
    color: #ffffff;
    margin-right: 10px;
    font-size: 29px;
    transition: color 150ms linear;
    :hover {
      color: #07be64;
    }
  `;

  if (svgPath) {
    return (
      <a target="_blank" rel="noopener noreferrer" href={href}>
        <SvgIcon viewBox={svgViewBox} css={iconStyles} className={className} {...props}>
          {svgPath}
        </SvgIcon>
      </a>
    );
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      <IconComponent css={iconStyles} className={className} {...props} />
    </a>
  );
}

export default FooterSocialLink;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  claimInfoModalContainer: {
    padding: '42px 64px',
  },
  claimInfoModalQRCode: {
    marginBottom: 12,
    padding: 12,
  },
  claimInfoModalDescription: {
    padding: 12,
    textAlign: 'center',
    fontSize: 18,
    fontFamily: 'Nunito',
    color: '#748896',
  },
}));

export { useStyles };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mintToCollectionContainer: {
    maxWidth: '763px',
    minHeight: '1000px',
    borderRadius: '20px',
    backgroundColor: '#ffffff',
    margin: '0 auto',
    padding: '12px',
    fontFamily: 'Lato'
  },
  mintToCollectionHeader: {
    background: '#1E3545',
    borderRadius: '20px',
    padding: '54px 58px'
  },
  heading: {
    fontSize: '36px',
    lineHeight: '120%',
    color: '#ffffff',
    margin: '0'
  },
  subHeading: {
    fontSize: '18px',
    lineHeight: '150%',
    margin: '16px 0',
    letterSpacing: '0.02em',
    color: '#748896'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px'
  },
  inPageSignupLogin: {
    margin: '55px 70px 0'
  },
  logoutLink: {
    color: '#476ddc',
  },
  mintToCollectionBody: {
    margin: '50px 70px'
  },
  amountSection: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  quantityInputSection: {
    width: '100%',
    margin: '10px 0',
    '& .MuiInputLabel-formControl': {
      display: 'none'
    },
    '& .MuiInput-formControl': {
      maxWidth: '210px',
      margin: '0 !important',
      border: '1px solid #E0E0E0',
      boxSizing: 'border-box',
      borderRadius: '10px',
      padding: '10px 20px',
      '&.Mui-error': {
        borderColor: '#f44336',
      }
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
      lineHeight: '150%',
      letterSpacing: '0.03em',
      color: '#BDBDBD',
      margin: '0',
      '&.Mui-error': {
        color: '#f44336',
      }
    }
  },
  quantityLabel: {
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
    color: '#5B5D68',
    margin: '0'
  },
  walletBlock: {
    width: '100%',
    background: '#E8FFFE',
    borderRadius: '10px',
    padding: '22px 33px',
  },
  walletBlockHeading: {
    fontSize: '18px',
    lineHeight: '150%',
    color: '#21D7BB',
    marginTop: '0',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  checkboxContainer: {
    width: '20px',
    height: '20px',
    border: '1px solid #21D7BB',
    boxSizing: 'border-box',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
    '& input': {
      position: 'absolute',
      width: '20px',
      height: '20px',
      left: '0',
      right: '0',
      borderRadius: '10px',
      opacity: '0',
      zIndex: '100',
      cursor: 'pointer'
    },
    '& img': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  walletBlockDetail: {
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.03em',
    color: '#677E7D',
    margin: '0'
  },
  externalWalletLabel: {
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
    color: '#5B5D68',
    margin: '0'
  },
  externalWalletInputBox: {
    width: '100%',
    margin: '10px 0',
    '& .MuiInputLabel-formControl': {
      display: 'none'
    },
    '& .MuiInput-formControl': {
      margin: '0 !important',
      border: '1px solid #E0E0E0',
      boxSizing: 'border-box',
      borderRadius: '10px',
      padding: '10px 20px',
      '&.Mui-error': {
        borderColor: '#f44336',
      }
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
      lineHeight: '150%',
      letterSpacing: '0.03em',
      color: '#BDBDBD',
      margin: '0',
      '&.Mui-error': {
        color: '#f44336',
      }
    }
  },
  priceSection: {
    marginTop: '35px'
  },
  priceLabel: {
    fontSize: '18px',
    lineHeight: '150%',
    color: '#5B5D68',
    margin: '0'
  },
  priceBody: {
    margin: '20px 0',
    background: '#F8FCFF',
    borderRadius: '20px',
    minHeight: '100px'
  },
  priceLists: {
    padding: '25px 30px',
    borderBottom: '1px solid #DBE5EC'
  },
  price: {
    fontSize: '14px',
    lineHeight: '200%',
    letterSpacing: '0.03em',
    color: '#5B5D68'
  },
  priceValue: {
    color: '#21D7BB'
  },
  totalPriceBody: {
    padding: '18px 30px'
  },
  totalPrice: {
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '200%',
    letterSpacing: '0.03em',
    color: '#5B5D68'
  },
  emptyPrice: {
    padding: '20px 30px'
  },
  submitAction: {
    marginTop: '35px 0'
  },
  actionLabel: {
    fontSize: '18px',
    lineHeight: '150%',
    color: '#5B5D68',
    margin: '0'
  },
  actionButtons: {
    display: 'flex',
    marginTop: '20px',
    gap: '22px'
  },
  button: {
    background: 'linear-gradient(0deg, #476DDC, #476DDC), #DDF4FF',
    borderRadius: '10px',
    border: 'none',
    color: '#FCFEFF',
    fontFamily: 'Nunito',
    fontSize: '18px',
    cursor: 'pointer',
    padding: '15px 30px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '&:disabled': {
      background: 'rgba(0, 0, 0, 0.12)',
      cursor: 'none'
    }
  }

}));

export { useStyles };

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import config from 'Config/firebase';
import { css, jsx } from '@emotion/react';
import Button from '@material-ui/core/Button';
import Page from 'Components/Page';
import Link from '@material-ui/core/Link';
const bs58 = require('bs58');

function SellSucceess(props) {
  console.log(`props`, props);
  // const bytes = bs58.decode(props.match.params.code);
  // const b58Hex = bytes.toString('hex');
  // const itemId = parseInt(b58Hex, 16);
  return (
    <Page>
      <div style={{ marginTop: 50 }}>
        <h1>Success!</h1>
        {/* <p>Your NFT {props.match.params.code}, is now registered!</p> */}
        <p>
          If you have never sold anything on Rarible before this may take a few minutes. Follow your transactions below.
          Once your item is sold. <a href="/metamask">Use metamask to retrieve your funds</a>.
        </p>

        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            component={Link}
            target="new"
            href={`${config.raribleURL}token/${props.match.params.contract}:${props.match.params.code}`}
            css={css`
              margin-top: 30px;
              color: #ffffff;
              &:hover {
                text-decoration: none;
              }
            `}
          >
            View on Rarible
          </Button>
        </div>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            component={Link}
            href="/my-collection"
            css={css`
              margin-top: 30px;
              color: #ffffff;
              &:hover {
                text-decoration: none;
              }
            `}
          >
            Back to collection
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default SellSucceess;

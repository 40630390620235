export function formatServerAssets(assets, blockchain) {
  const formattedAssets = assets.map(asset => {
    return {
      ...asset,
      pureDigitalAsset: true,
      blockchain,
      itemType: 'digitalAsset',
      mintStatus: 'complete',
      isOpenSea: false,
      name: asset.name || asset.title,
      itemId: asset.token_id,
      tokenIdHex: asset.token_id_hex,
      image: asset.image,
      contract: asset.contract_address,
      images: [
        {
          thumb: asset.image,
          full: asset.image,
          animation_url: asset._animation_url,
        },
      ],
      description: asset.description,
    };
  });

  return formattedAssets;
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderRadius: 20,
    padding: '36px 35px 30px',
    width: 367,
    minHeight: 560,
    background: '#FFFFFF7F',
    textAlign: 'center',
    backdropFilter: 'blur(50px)',
  },
}));

const GetStartedCard = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.cardWrapper}>{children}</div>;
};

export default GetStartedCard;

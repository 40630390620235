export const RESET_ITEM_DATA = 'RESET_ITEM_DATA';
export const SET_ITEM_DATA = 'SET_ITEM_DATA';
export const SET_ITEM_LOADING = 'SET_ITEM_LOADING';
export const RESET_PLAN_DATA = 'RESET_PLAN_DATA';
export const RESET_PRODUCT_DATA = 'RESET_PRODUCT_DATA';
export const SET_PLAN_DATA = 'SET_PLAN_DATA';
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';
export const SET_USER_TRANSACTIONS = 'SET_USER_TRANSACTIONS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_DATA_LOADING = 'SET_USER_DATA_LOADING';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const SHOW_REGISTRATION = 'SHOW_REGISTRATION';
export const GET_SQUAD_DETAILS = 'GET_SQUAD_DETAILS';
export const SAVE_IMAGES_LOCALLY = 'SAVE_IMAGES_LOCALLY';
export const SAVE_ITEM_DRAFT_INFO = 'SAVE_ITEM_DRAFT_INFO';
export const SAVE_ITEM_DRAFT_ORDER = 'SAVE_ITEM_DRAFT_ORDER';
export const RESET_ITEM_DRAFT = 'RSET_ITEM_DRAFT';
export const CHECKOUT_COMPLETE = 'CHECKOUT_COMPLETE';
export const CHECKOUT_PROCESSING = 'CHECKOUT_PROCESSING';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';
export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';
export const CLOSE_ACTIVE_MODAL = 'CLOSE_ACTIVE_MODAL';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const SET_USER_ITEMS = 'SET_USER_ITEMS';
export const ADD_USER_ITEMS = 'ADD_USER_ITEMS';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SET_SHOPIFYORDER_DATA = 'SET_SHOPIFYORDER_DATA';

export const UPDATE_REGISTRATION_CONFIG = 'UPDATE_REGISTRATION_CONFIG';
export const SET_REGISTRATION_CONFIG = 'SET_REGISTRATION_CONFIG';
export const RESET_REGISTRATION_CONFIG = 'RESET_REGISTRATION_CONFIG';

export const SET_LOOTVAULT_AUTH_MODAL = 'SET_LOOTVAULT_AUTH_MODAL';

import React, { useState } from 'react';
import { useStyles } from './style';
import { data } from './data.js';
import ReactLoading from 'react-loading';
import { generateValidationToken, getStakingStatus, startExternalStaking } from 'Services/api';
import config from 'Config/firebase';

const History = () => {
  const classess = useStyles();

  const [MMAccounts, setMMAccounts] = useState(null);
  const [signature, setSignature] = useState(null);
  const [getNFTsLoading, setGetNFTsLoading] = useState(false);
  const [stakeAllLoading, setStakeAllLoading] = useState(false);
  const [nfts, setNfts] = useState([]);
  const [timeoutState, setTimeoutState] = useState();
  const [totalBalance, setTotalBalance] = useState();
  const [refreshStaking, setRefreshStaking] = useState(true);

  const getRate = tokenId => {
    const ones = [34, 75, 143, 203, 517, 912, 1349, 1975, 2026, 2189, 2469, 2936, 3388, 3762, 3833, 4285, 4794, 4794];
    if (ones.some(one => one === tokenId)) return 2;
    if (tokenId) if (parseInt(tokenId, 10) <= 320) return 1.5;
    return 1;
  };

  const setBalances = nfts => {
    const addedBalanceNfts = nfts.map(nft => {
      let withBalance;
      if (nft.started) {
        let timeDiff = 0;
        if (nft.ended) {
          timeDiff = parseInt(nft.ended, 10) - parseInt(nft.started, 10);
        } else {
          timeDiff = Date.now() - parseInt(nft.started, 10);
        }
        // 1 / 345600 ms
        const tokens = (timeDiff / 345600) * getRate(parseInt(nft.tokenId, 10));

        withBalance = {
          ...nft,
          balance: tokens.toFixed(2),
        };
      } else {
        withBalance = {
          ...nft,
          balance: 0,
        };
      }

      return withBalance;
    });

    setTotalBalance(getTotalBalance(addedBalanceNfts));
    setNfts(addedBalanceNfts.sort());
    if (refreshStaking) setTimeoutState(setTimeout(setBalances, 2000, nfts));
  };

  const getTotalBalance = nfts => {
    const total = nfts.reduce((previousValue, currentValue) => {
      return previousValue + parseInt(currentValue.balance, 10);
    }, 0);
    return total;
  };

  const connectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      setGetNFTsLoading(true);
      setRefreshStaking(false);
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        const from = accounts[0];

        setMMAccounts(accounts);

        let validationToken = await generateValidationToken({
          address: from,
        });

        const string = `Ownerfy: ${from.toLowerCase()} ${validationToken}`;

        const msg = `0x${Buffer.from(string, 'utf8').toString('hex')}`;
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [msg, from],
        });

        let _nfts = await getStakingStatus({
          address: from,
          contract: config.citizenzContract,
          blockchain: 'ethereum',
          signature,
        });

        _nfts.sort();

        setNfts(_nfts);
        setRefreshStaking(true);
        setTimeoutState(setTimeout(setBalances, 2000, _nfts));
        setSignature(signature);
        setGetNFTsLoading(false);
      } catch (err) {
        setGetNFTsLoading(false);
        alert('connection failed');
      }
    } else {
      setGetNFTsLoading(false);
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  const startStaking = async ({ stakeAll, tokenId }) => {
    setStakeAllLoading(true);
    setRefreshStaking(false);
    clearTimeout(timeoutState);
    try {
      await startExternalStaking({
        address: MMAccounts[0],
        contract: config.citizenzContract,
        blockchain: 'ethereum',
        signature,
        tokenId,
        stakeAll,
      });

      let _nfts = await getStakingStatus({
        address: MMAccounts[0],
        contract: config.citizenzContract,
        blockchain: 'ethereum',
        signature,
      });

      setTimeoutState(setTimeout(setBalances, 2000, _nfts));
      setNfts(_nfts);
      setStakeAllLoading(false);
      setRefreshStaking(true);
    } catch (err) {
      console.warn('error:: ', err);
      setStakeAllLoading(false);
      alert(JSON.stringify(err));
    }
  };

  return (
    <div className={classess.container}>
      <div className={classess.historyContainer}>
        <div className={classess.sectionHeader}>
          <div className={classess.citizenzContainer}>
            <h1 className={classess.heading}>Citizenz Genesis</h1>
            <div className={classess.badgeContainer}>
              <a target="new" href={`https://etherscan.io/address/${config.citizenzContract}`}>
                <span className={`${classess.badge} ${classess.badgeDarkBlue}`}>CTZN Contract</span>
              </a>
              {/* <span className={`${classess.badge} ${classess.badgeGreenLigh}`}>ERC20</span>
              <span className={`${classess.badge} ${classess.badgeLinear}`}>CTZN Token</span> */}
            </div>
          </div>
          <div className={classess.BalanceContainer}>
            <div>
              <p className={classess.balance}>Total Balance</p>
              <p className={classess.balance}>
                <span className={classess.balanceValue}>{totalBalance}</span> CTZN
              </p>
              {/* <p className={classess.balance}>{value}</p> */}
            </div>
            <div>
              {getNFTsLoading ? (
                <div className={classess.loadContainer}>
                  <div className={classess.loadContainerFix}>
                    <ReactLoading type={'bubbles'} color={'#21D7BB'} height={'10px'} width={'73px'} />
                  </div>
                </div>
              ) : (
                <button className={`${classess.btn} ${classess.btnPrimary}`} onClick={connectMetamask}>
                  {' '}
                  Get Citizenz
                </button>
              )}

              <br />
              {stakeAllLoading && !!nfts.length && (
                <div className={classess.loadContainer}>
                  <div className={classess.loadContainerFix}>
                    <ReactLoading type={'bubbles'} color={'#21D7BB'} height={'10px'} width={'73px'} />
                  </div>
                </div>
              )}
              {!stakeAllLoading && !!nfts.length && (
                <button
                  className={`${classess.btn} ${classess.btnPrimary}`}
                  onClick={() => startStaking({ stakeAll: true })}
                >
                  Stake All
                </button>
              )}
            </div>
          </div>
        </div>
        <div className={classess.tableContainer}>
          <div className={classess.table}>
            <div className={classess.TableHeaderRow}>
              <span className={classess.tableCell}>Item</span>
              <span className={classess.tableCell}>Status</span>
              <span className={classess.tableCell}>Staking Balance</span>
              <span className={classess.tableCell}></span>
            </div>
            {nfts.map((item, index) => {
              return (
                <div className={classess.row}>
                  <div className={classess.tableCell}>
                    <img width="46" height="46" src={item.media1} alt="Not found" />
                    <span className={classess.cellValue}>{item.name}</span>
                  </div>
                  <div className={classess.tableCell}>
                    <span
                      className={`${classess.status} ${
                        item.status === 'staking' ? classess.statusOk : classess.statusPanding
                      }`}
                    >
                      {item.status}
                    </span>
                  </div>
                  <div className={classess.tableCell}>
                    <div>
                      <p className={classess.balance}>
                        <span className={classess.balanceTableValue}>{item.balance}</span> CTZN
                      </p>
                      <p className={classess.balance}>Rate: {item.rate}</p>
                    </div>
                  </div>
                  <div className={classess.tableCell}>
                    {item.status !== 'staking' && item.status !== 'unowned' && !stakeAllLoading && (
                      <button
                        onClick={() => startStaking({ tokenId: item.token_id })}
                        className={`${classess.btn} ${
                          item.active === true ? classess.btnPrimary : classess.btnSecondary
                        }`}
                      >
                        Start staking
                      </button>
                    )}
                    {item.status !== 'staking' && stakeAllLoading && (
                      <div className={classess.loadContainer}>
                        <div className={classess.loadContainerFix}>
                          <ReactLoading type={'bubbles'} color={'#21D7BB'} height={'10px'} width={'73px'} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;

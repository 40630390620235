import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Container } from '@material-ui/core';
import BackgroundImg from '../assets/bg-1.png';
import useStyles from './style';

const LaunchSection = () => {
  const classes = useStyles();
  const [isExpired, setIsExpired] = useState(false);
  const [collectData, setCollectData] = useState([]);

  const openRemindModal = () => {
    window.open(
      'https://cdn.forms-content.sg-form.com/ac3f5bfe-30ac-11ec-a30f-4e53a58ddc0e',
      '_blank',
      'resizable=yes, scrollbars=no, titlebar=no, location=no, menubar=no, status=no, toolbar=no, width=550, height=440, top=10, left=10',
    );
  };

  const tick = () => {
    const { days, hours, minutes, seconds } = getCountdown();

    setCollectData([
      { title: 'Days', value: days },
      { title: 'Hours', value: hours },
      { title: 'Minutes', value: minutes },
      { title: 'Seconds', value: seconds },
    ]);
  };

  const getCountdown = () => {
    const deadline = new Date('2022-07-01T17:00:00.000Z');
    const now = new Date();
    const time = deadline - now;

    if (deadline < now) {
      setIsExpired(true);
    }
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const timer = useMemo(
    () => (isExpired ? '00:00:00:00' : collectData.map(item => item.value).join(':')),
    [collectData, isExpired],
  );

  useEffect(() => {
    const interval = setInterval(() => tick(), 1000);

    return () => clearInterval(interval);
  }, [tick]);

  return (
    <Box className={classes.launchWrapper} py={25}>
      <Container className={classes.launchMainSection}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box className={classes.launchTitle}>
            <div>Next Mint Coming Soon!</div>
          </Box>

          {/* <Box className={classes.launchTimer}>{timer}</Box> */}

          {/* <Button onClick={openRemindModal} className={classes.launchSubscribeBtn}>
            Alert me
          </Button> */}
        </Box>
      </Container>

      <img src={BackgroundImg} className={classes.launchBackground} alt="Launch Background" />
    </Box>
  );
};

export default LaunchSection;

import * as types from '../actions/types';

const INITIAL_STATE = {
  name: 'none',
  props: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_MODAL:
      return {
        name: action.name,
        props: action.props,
      };
    case types.CLOSE_ACTIVE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  creditName: {
    fontSize: '16px',
    padding: '4px 16px',
    borderRadius: '24px',
    backgroundColor: '#c7daf1',
    color: '#6767e7',
    fontFamily: 'Nunito Sans, sans-serif',
    marginBottom: '24px'
  },
  creditCount: {
    fontFamily: 'Nunito Sans, sans-serif',
    color: '#24e5ad',
    fontSize: '56px'
  },
  creditDescription: {
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '18px',
    color: '#748896',
    marginBottom: 32,
  },
  creditButton: {
    height: '57px',
    background: 'linear-gradient(248deg, #24E5AD 0.9%, #4567DE 112.34%)',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    borderRadius: 12,
    textTransform: 'capitalize'
  }
}))

export { useStyles };

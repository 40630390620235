import React from 'react';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

const styles = theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
});

function CenteredSpinner({ classes, className }) {
  return (
    <div className={clsx(classes.loadingContainer, className)}>
      <CircularProgress size={65} />
    </div>
  );
}

export default withStyles(styles)(CenteredSpinner);

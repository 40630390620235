import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import * as actions from 'Store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';

const styles = theme => ({
  header: {
    marginTop: 30,
  },
  selectBox: {
    marginTop: 30,
  },
  textArea: {
    fontSize: 20,
  },
  submit: {
    margin: '20px 20px 20px 0',
  },
  title: {
    fontWeight: 'bold',
  },
});

function TOS({ setActiveModal, classes }) {
  const locationParams = queryString.parse(window.location.search);

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container component="main" maxWidth="sm">
          <Typography component="h1" variant="h5" className={classes.header}>
            Terms and Conditions
          </Typography>

          <p>
            <span className={classes.title}>Agreement between User and Ownerfy.com</span>
          </p>
          <p>
            <span>
              Welcome to Ownerfy.com. The Ownerfy.com website (the &quot;Site&quot;) is comprised of various web pages
              operated by Ownerfy Inc (&quot;Ownerfy&quot;). Ownerfy.com is offered to you conditioned on your
              acceptance without modification of the terms, conditions, and notices contained herein (the
              &quot;Terms&quot;). Your use of Ownerfy.com constitutes your agreement to all such Terms. Please read
              these terms carefully, and keep a copy of them for your reference.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Materials Provided to Ownerfy.com or Posted on Any Ownerfy Web Page</span>
          </p>
          <p>
            <span>
              <b>
                Ownerfy does not claim ownership of the materials you provide to Ownerfy.com (including feedback and
                suggestions) or post, upload, input or submit to any Ownerfy Site or our associated services
                (collectively &quot;Submissions&quot;).
              </b>{' '}
              However, by posting, uploading, inputting, providing or submitting your Submission you are granting
              Ownerfy, our affiliated companies and necessary sublicensees permission to use your Submission in
              connection with the operation of their Internet businesses including, without limitation, the rights to:
              copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat
              your Submission; and to publish your name in connection with your Submission.{' '}
            </span>
          </p>

          {/* <p>
            <span className={classes.title}>Return Policy</span>
          </p>
          <p>
            <span>
              In the case of damaged or defective physical goods returns are accepted within 30 days of original
              purchase. All NFTs generated on Ownerfy mobile app or website are not refundable due to the nature of
              product. A refund will be granted within 5 days of receipt and inspection of the returned product.
              Customers are responsible for shipping fees for returned products. Our return address is:
            </span>
          </p> */}

          <p>
            <span>Ownerfy Inc </span>
          </p>
          <p>
            <span>2633 Lincoln Blvd Suite 710 </span>
          </p>
          <p>
            <span>Santa Monica, California 90405 </span>
          </p>

          <p>
            <span className={classes.title}>Electronic Communications</span>
          </p>
          <p>
            <span>
              Visiting Ownerfy.com or sending emails to Ownerfy constitutes electronic communications. You consent to
              receive electronic communications and you agree that all agreements, notices, disclosures and other
              communications that we provide to you electronically, via email and on the Site, satisfy any legal
              requirement that such communications be in writing.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Your Account</span>
          </p>
          <p>
            <span>
              If you use this site, you are responsible for maintaining the confidentiality of your account and password
              and for restricting access to your computer, and you agree to accept responsibility for all activities
              that occur under your account or password. You may not assign or otherwise transfer your account to any
              other person or entity. You acknowledge that Ownerfy is not responsible for third party access to your
              account that results from theft or misappropriation of your account. Ownerfy and its associates reserve
              the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole
              discretion.{' '}
            </span>
          </p>
          <p>
            <span className={classes.title}>Data Deletion</span>
          </p>

          <p>
            Deletion by Customer. Ownerfy will enable Customer to delete Customer Data during the Term in a manner
            consistent with the functionality of the Services. Please contact support@ownerfy.com with subject "Delete
            Data". If Customer uses the Services to delete any Customer Data during the Term and that Customer Data
            cannot be recovered by Customer, this use will constitute an Instruction to Ownerfy to delete the relevant
            Customer Data from Ownerfy’s systems in accordance with applicable law. Ownerfy will comply with this
            Instruction as soon as reasonably practicable and within a maximum period of 180 days, unless European Law
            requires storage.
          </p>

          <p>
            Return or Deletion at the end of the Term. If Customer wishes to retain any Customer Data after the end of
            the Term, it may instruct Ownerfy in accordance with Section 9.1 (Access; Rectification; Restricted
            Processing; Portability) to return that data during the Term. Customer instructs Ownerfy to delete all
            remaining Customer Data (including existing copies) from Ownerfy’s systems at the end of the Term in
            accordance with applicable law. After a recovery period of up to 30 days from that date, Ownerfy will comply
            with this Instruction as soon as reasonably practicable and within a maximum period of 180 days, unless
            European Law requires storage.
          </p>

          <p>
            <span className={classes.title}>Data Processing</span>
          </p>

          <p>
            Processor and Controller Responsibilities. If European Data Protection Law applies to the processing of
            Customer Personal Data:
          </p>

          <p>a. the subject matter and details of the processing are described in Appendix 1;</p>

          <p>b. Ownerfy is a processor of that Customer Personal Data under European Data Protection Law;</p>

          <p>
            c. Customer is a controller or processor, as applicable, of that Customer Personal Data under European Data
            Protection Law; and
          </p>

          <p>
            d. each party will comply with the obligations applicable to it under European Data Protection Law with
            respect to the processing of that Customer Personal Data.
          </p>

          <p>
            Processor Customers. If European Data Protection Law applies to the processing of Customer Personal Data and
            Customer is a processor:{' '}
          </p>

          <p>
            a. Customer warrants on an ongoing basis that the relevant controller has authorized: (i) the Instructions,
            (ii) Customer’s appointment of Ownerfy as another processor, and (iii) Ownerfy’s engagement of Subprocessors
            as described in Section 11 (Subprocessors);
          </p>

          <p>
            b. Customer will immediately forward to the relevant controller any notice provided by Ownerfy under
            Sections 5.2.3 (Instruction Notifications), 7.2.1 (Incident Notification), 9.2.1 (Responsibility for
            Requests), 11.4 (Opportunity to Object to Subprocessor Changes) or that refers to any SCCs; and
          </p>

          <p>c. Customer may:</p>

          <p>
            i. request access for the relevant controller to the SOC Reports in accordance with Section 7.5.3(a); and
          </p>

          <p>
            ii. make available to the relevant controller any other information made available by Ownerfy under Sections
            10.5 (Supplementary Measures and Information), 10.7 (Data Center Information) and 11.2 (Information about
            Subprocessors).
          </p>

          <p>
            Responsibilities under Non-European Law. If Non-European Data Protection Law applies to either party’s
            processing of Customer Personal Data, the relevant party will comply with any obligations applicable to it
            under that law with respect to the processing of that Customer Personal Data.
          </p>

          <p>Scope of Processing.</p>

          <p>
            Customer’s Instructions. Customer instructs Ownerfy to process Customer Personal Data only in accordance
            with applicable law: (a) to provide, secure, and monitor the Services and TSS; (b) as further specified via
            Customer’s use of the Services (including the Admin Console and other functionality of the Services) and
            TSS; (c) as documented in the form of the Agreement (including these Terms); and (d) as further documented
            in any other written instructions given by Customer and acknowledged by Ownerfy as constituting instructions
            for purposes of these Terms (collectively, the “Instructions”).
          </p>

          <p>
            Ownerfy’s Compliance with Instructions. Ownerfy will comply with the Instructions unless prohibited by
            European Law.
          </p>

          <p>
            Instruction Notifications. Ownerfy will immediately notify Customer if, in Ownerfy’s opinion: (a) European
            Law prohibits Ownerfy from complying with an Instruction; (b) an Instruction does not comply with European
            Data Protection Law; or (c) Ownerfy is otherwise unable to comply with an Instruction, in each case unless
            such notice is prohibited by European Law. This Section does not reduce either party’s rights and
            obligations elsewhere in the Agreement.{' '}
          </p>

          <p>
            <span className={classes.title}>Children Under Thirteen</span>
          </p>
          <p>
            <span>
              Ownerfy does not knowingly collect, either online or offline, personal information from persons under the
              age of thirteen. If you are under 18, you may use Ownerfy.com only with permission of a parent or
              guardian.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Links to Third Party Sites/Third Party Services</span>
          </p>
          <p>
            <span>
              Ownerfy.com may contain links to other websites (&quot;Linked Sites&quot;). The Linked Sites are not under
              the control of Ownerfy and Ownerfy is not responsible for the contents of any Linked Site, including
              without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site.
              Ownerfy is providing these links to you only as a convenience, and the inclusion of any link does not
              imply endorsement by Ownerfy of the site or any association with its operators.{' '}
            </span>
          </p>

          <p>
            <span>
              Certain services made available via Ownerfy.com are delivered by third party sites and organizations. By
              using any product, service or functionality originating from the Ownerfy.com domain, you hereby
              acknowledge and consent that Ownerfy may share such information and data with any third party with whom
              Ownerfy has a contractual relationship to provide the requested product, service or functionality on
              behalf of Ownerfy.com users and customers.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>No Unlawful or Prohibited Use/Intellectual Property </span>
          </p>
          <p>
            <span>
              You are granted a non-exclusive, non-transferable, revocable license to access and use Ownerfy.com
              strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to
              Ownerfy that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You
              may not use the Site in any manner which could damage, disable, overburden, or impair the Site or
              interfere with any other party&#39;s use and enjoyment of the Site. You may not obtain or attempt to
              obtain any materials or information through any means not intentionally made available or provided for
              through the Site.{' '}
            </span>
          </p>

          <p>
            <span>
              All content included as part of the Service, such as text, graphics, logos, images, as well as the
              compilation thereof, and any software used on the Site, is the property of Ownerfy or its suppliers and
              protected by copyright and other laws that protect intellectual property and proprietary rights. You agree
              to observe and abide by all copyright and other proprietary notices, legends or other restrictions
              contained in any such content and will not make any changes thereto.{' '}
            </span>
          </p>

          <p>
            <span>
              You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
              derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
              Ownerfy content is not for resale. Your use of the Site does not entitle you to make any unauthorized use
              of any protected content, and in particular you will not delete or alter any proprietary rights or
              attribution notices in any content. You will use protected content solely for your personal use, and will
              make no other use of the content without the express written permission of Ownerfy and the copyright
              owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant
              you any licenses, express or implied, to the intellectual property of Ownerfy or our licensors except as
              expressly authorized by these Terms.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Use of Communication Services</span>
          </p>
          <p>
            <span>
              The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web
              pages, calendars, and/or other message or communication facilities designed to enable you to communicate
              with the public at large or with a group (collectively, &quot;Communication Services&quot;). You agree to
              use the Communication Services only to post, send and receive messages and material that are proper and
              related to the particular Communication Service.{' '}
            </span>
          </p>

          <p>
            <span>
              By way of example, and not as a limitation, you agree that when using a Communication Service, you will
              not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of
              privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate,
              profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;
              upload files that contain software or other material protected by intellectual property laws (or by rights
              of privacy of publicity) unless you own or control the rights thereto or have received all necessary
              consents; upload files that contain viruses, corrupted files, or any other similar software or programs
              that may damage the operation of another&#39;s computer; advertise or offer to sell or buy any goods or
              services for any business purpose, unless such Communication Service specifically allows such messages;
              conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by
              another user of a Communication Service that you know, or reasonably should know, cannot be legally
              distributed in such manner; falsify or delete any author attributions, legal or other proper notices or
              proprietary designations or labels of the origin or source of software or other material contained in a
              file that is uploaded; restrict or inhibit any other user from using and enjoying the Communication
              Services; violate any code of conduct or other guidelines which may be applicable for any particular
              Communication Service; harvest or otherwise collect information about others, including e-mail addresses,
              without their consent; violate any applicable laws or regulations.{' '}
            </span>
          </p>

          <p>
            <span>
              Ownerfy has no obligation to monitor the Communication Services. However, Ownerfy reserves the right to
              review materials posted to a Communication Service and to remove any materials in its sole discretion.
              Ownerfy reserves the right to terminate your access to any or all of the Communication Services at any
              time without notice for any reason whatsoever.{' '}
            </span>
          </p>

          <p>
            <span>
              Ownerfy reserves the right at all times to disclose any information as necessary to satisfy any applicable
              law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any
              information or materials, in whole or in part, in Ownerfy&#39;s sole discretion.{' '}
            </span>
          </p>

          <p>
            <span>
              Always use caution when giving out any personally identifying information about yourself or your children
              in any Communication Service. Ownerfy does not control or endorse the content, messages or information
              found in any Communication Service and, therefore, Ownerfy specifically disclaims any liability with
              regard to the Communication Services and any actions resulting from your participation in any
              Communication Service. Managers and hosts are not authorized Ownerfy spokespersons, and their views do not
              necessarily reflect those of Ownerfy.{' '}
            </span>
          </p>

          <p>
            <span>
              Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction
              and/or dissemination. You are responsible for adhering to such limitations if you upload the materials.{' '}
            </span>
          </p>

          <p>
            <span>
              No compensation will be paid with respect to the use of your Submission, as provided herein. Ownerfy is
              under no obligation to post or use any Submission you may provide and may remove any Submission at any
              time in Ownerfy&#39;s sole discretion.{' '}
            </span>
          </p>

          <p>
            <span>
              By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that
              you own or otherwise control all of the rights to your Submission as described in this section including,
              without limitation, all the rights necessary for you to provide, post, upload, input or submit the
              Submissions.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Third Party Accounts</span>
          </p>
          <p>
            <span>
              You will be able to connect your Ownerfy account to third party accounts. By connecting your Ownerfy
              account to your third party account, you acknowledge and agree that you are consenting to the continuous
              release of information about you to others (in accordance with your privacy settings on those third party
              sites). If you do not want information about you to be shared in this manner, do not use this feature.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>International Users</span>
          </p>
          <p>
            <span>
              The Service is controlled, operated and administered by Ownerfy from our offices within the USA. If you
              access the Service from a location outside the USA, you are responsible for compliance with all local
              laws. You agree that you will not use the Ownerfy Content accessed through Ownerfy.com in any country or
              in any manner prohibited by any applicable laws, restrictions or regulations.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Indemnification</span>
          </p>
          <p>
            <span>
              You agree to indemnify, defend and hold harmless Ownerfy, its officers, directors, employees, agents and
              third parties, for any losses, costs, liabilities and expenses (including reasonable attorney&#39;s fees)
              relating to or arising out of your use of or inability to use the Site or services, any user postings made
              by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or
              your violation of any applicable laws, rules or regulations. Ownerfy reserves the right, at its own cost,
              to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in
              which event you will fully cooperate with Ownerfy in asserting any available defenses.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Arbitration</span>
          </p>
          <p>
            <span>
              In the event the parties are not able to resolve any dispute between them arising out of or concerning
              these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in
              equity for damages or any other relief, then such dispute shall be resolved only by final and binding
              arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and
              administered by the American Arbitration Association, or a similar arbitration service selected by the
              parties, in a location mutually agreed upon by the parties. The arbitrator&#39;s award shall be final, and
              judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable
              action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing
              party shall be entitled to recover its costs and reasonable attorney&#39;s fees. The parties agree to
              arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a
              result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a
              result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the
              interpretation and enforcement of this provision. The entire dispute, including the scope and
              enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration
              provision shall survive the termination of these Terms and Conditions.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Class Action Waiver</span>
          </p>
          <p>
            <span>
              Any arbitration under these Terms and Conditions will take place on an individual basis; class
              arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY
              MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH&#39;S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A
              PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Ownerfy agree otherwise,
              the arbitrator may not consolidate more than one person&#39;s claims, and may not otherwise preside over
              any form of a representative or class proceeding.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Liability Disclaimer</span>
          </p>
          <p>
            <span>
              THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
              INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. OWNERFY
              INC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.{' '}
            </span>
          </p>

          <p>
            <span>
              OWNERFY INC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
              TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED
              ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
              SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR
              CONDITION OF ANY KIND. OWNERFY INC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
              REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
              WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.{' '}
            </span>
          </p>

          <p>
            <span>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL OWNERFY INC AND/OR ITS SUPPLIERS BE
              LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
              WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN
              ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR
              RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
              PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF
              THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF OWNERFY INC
              OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS
              DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
              LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF
              THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Termination/Access Restriction </span>
          </p>
          <p>
            <span>
              Ownerfy reserves the right, in its sole discretion, to terminate your access to the Site and the related
              services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this
              agreement is governed by the laws of the State of Delaware and you hereby consent to the exclusive
              jurisdiction and venue of courts in Delaware in all disputes arising out of or relating to the use of the
              Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of
              these Terms, including, without limitation, this section.{' '}
            </span>
          </p>

          <p>
            <span>
              You agree that no joint venture, partnership, employment, or agency relationship exists between you and
              Ownerfy as a result of this agreement or use of the Site. Ownerfy&#39;s performance of this agreement is
              subject to existing laws and legal process, and nothing contained in this agreement is in derogation of
              Ownerfy&#39;s right to comply with governmental, court and law enforcement requests or requirements
              relating to your use of the Site or information provided to or gathered by Ownerfy with respect to such
              use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law
              including, but not limited to, the warranty disclaimers and liability limitations set forth above, then
              the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that
              most closely matches the intent of the original provision and the remainder of the agreement shall
              continue in effect.{' '}
            </span>
          </p>

          <p>
            <span>
              Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and
              Ownerfy with respect to the Site and it supersedes all prior or contemporaneous communications and
              proposals, whether electronic, oral or written, between the user and Ownerfy with respect to the Site. A
              printed version of this agreement and of any notice given in electronic form shall be admissible in
              judicial or administrative proceedings based upon or relating to this agreement to the same extent and
              subject to the same conditions as other business documents and records originally generated and maintained
              in printed form. It is the express wish to the parties that this agreement and all related documents be
              written in English.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Changes to Terms</span>
          </p>
          <p>
            <span>
              Ownerfy reserves the right, in its sole discretion, to change the Terms under which Ownerfy.com is
              offered. The most current version of the Terms will supersede all previous versions. Ownerfy encourages
              you to periodically review the Terms to stay informed of our updates.{' '}
            </span>
          </p>

          <p>
            <span className={classes.title}>Contact Us</span>
          </p>
          <p>
            <span>Ownerfy welcomes your questions or comments regarding the Terms: </span>
          </p>

          <p>
            <span>Ownerfy Inc </span>
          </p>
          <p>
            <span>2633 Lincoln Blvd Suite 710 </span>
          </p>
          <p>
            <span>Santa Monica, California 90405 </span>
          </p>

          <p>
            <span>Email Address: </span>
          </p>
          <p>
            <span>support@ownerfy.com </span>
          </p>

          <p>
            <span>Effective as of February 14, 2020 </span>
          </p>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      submitLoginWithFireBase: actions.submitLoginWithFireBase,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(TOS)));

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

const pageStyles = theme => ({
  optionalMessage: {
    fontSize: 18,
  },
  formControl: {
    marginTop: '30px'
  },
  label: {
    paddingLeft: '10px',
    '&.MuiInputLabel-shrink': {
      paddingLeft: '0',
      transform: 'translate(0, -11.5px) scale(0.75)'
    }
  },
  input: {
    border: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '10px 20px',
    marginTop: '0 !important',
    '&.Mui-error': {
      borderColor: '#f44336',
    },
    '& .MuiInput-input:-webkit-autofill': {
      margin: '0'
    }
  },
  helperText: {
    fontSize: '12px',
    lineHeight: '150%',
    letterSpacing: '0.03em',
    color: '#BDBDBD',
    margin: '0',
    '&.Mui-error': {
      color: '#f44336',
    }
  }
});

function FormTextField({ label, helperText, required, classes, className, helperTextComponent, onChange, ...props }) {
  return (
    <FormControl fullWidth={props.fullWidth} className={`${className} ${classes.formControl}`}>
      <InputLabel {...props} className={classes.label}>
        {label} {required && '*'}
      </InputLabel>
      <Input onChange={onChange} {...props} className={classes.input} disableUnderline={true} />
      {helperText && (
        <FormHelperText {...props} className={classes.helperText} component={helperTextComponent}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default withStyles(pageStyles)(FormTextField);

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import Button from '@material-ui/core/Button';
import Page from 'Components/Page';
import Link from '@material-ui/core/Link';

function RegisterSuccess(props) {
  console.log(`props`, props);
  return (
    <Page>
      <div style={{ marginTop: 50 }}>
        <h1>Success!</h1>
        {/* <p>Your NFT {props.match.params.code}, is now registered!</p> */}
        <p>Visit the dashboard to view your token information.</p>
        <p>
          Minting may take up to 2 minutes. After your token is minted it may take a while before 3rd parties like
          OpenSea and Rarible pick it up in their system.
        </p>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          component={Link}
          href="/my-collection"
          css={css`
            margin-top: 30px;
            color: #ffffff;
            &:hover {
              text-decoration: none;
            }
          `}
        >
          Proceed to your collection
        </Button>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            component={Link}
            href="/create-nft"
            css={css`
              margin-top: 30px;
              color: #ffffff;
              &:hover {
                text-decoration: none;
              }
            `}
          >
            Mint Another NFT
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default RegisterSuccess;

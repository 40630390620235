import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core'
import PageLayout from 'Layout/Layout';
import Container from 'Components/Container';
import SectionLayout from 'Layout/SectionLayout';

import FormContainer from 'Components/FormInputs/FormContainer';
import TextField from 'Components/FormInputs/TextField';
import Select from 'Components/FormInputs/Select';
import Button from 'Shared/Button';
import * as Yup from 'yup';

const SELECT_OPTIONS = [
  { value: 'polygon', label: 'Polygon' },
  { value: 'ethereum', label: 'Ethereum' }
]

const NftLookup = () => {
  const history = useHistory()
  const validationSchema = Yup.object().shape({
    tokenId: Yup.number().typeError('Only Accept Numbers').nullable().required('This field is required'),
    contractAddress: Yup.string().nullable().required('This field is required'),
    blockchain: Yup.string().nullable().required('This field is required')
  })

  const initialValues = {
    tokenId: '',
    contractAddress: ''
  }

  const handleSubmit = (values) => {
    const { blockchain, contractAddress, tokenId } = values
    history.push(`/token/${contractAddress}/${tokenId}/${blockchain}`)
  }

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <Box container display="flex" justifyContent="center">
            <Box
              bgcolor="background.default"
              p={6}
              borderRadius={16}
              style={{ maxWidth: 544, boxSizing: 'border-box' }}
            >
              <Box mb={3}>
                <Typography variant="h1" style={{ lineHeight: '30px', marginTop: 0 }}>
                  NFT Lookup
                </Typography>
              </Box>

              <Box mb={3}>
                <Typography variant="body1">
                  <div>
                    <span>Look up a token to send an </span>
                    <b>email or verification </b>
                  </div>
                  <div>code to the owner</div>
                </Typography>
              </Box>

              <Box mb={4}>
                <Typography variant="body1" component="span">
                  If you'd like to register your addresses so you can verify your NFTs and receive email
                </Typography>
                <Typography variant="body1" color="primary" component="span">
                  {` sign up `}
                </Typography>
                <Typography variant="body1" component="span">
                  and navigate to 
                </Typography>
                <Typography variant="body1" color="primary" component="span">
                  {` settings.`}
                </Typography>
                <Typography variant="body1" component="div">
                  There you can register addresses you own.
                </Typography>
              </Box>

              <FormContainer
                validation={validationSchema}
                defaultValues={initialValues}
                mode="onChange"
                onSuccess={handleSubmit}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="tokenId"
                      label="Token ID"
                      variant="outlined"
                      placeholder="Token ID"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name="contractAddress"
                      label="Smart Contract Address"
                      variant="outlined"
                      placeholder="Smart Contract Address"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      options={SELECT_OPTIONS}
                      name="blockchain"
                      label="Blockchain"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button background btnLabel="Submit" />
                  </Grid>
                </Grid>
              </FormContainer>
            </Box>
          </Box>
        </Container>
      </SectionLayout>
    </PageLayout>
  )
}

export default NftLookup

import React, { useState } from 'react';
import Container from 'Components/Container';
import SectionLayout from 'Layout/SectionLayout';
import { useStyles } from './style';
import Input from 'Shared/Input';
import Button from 'Shared/Button';
import SuccessIcon from './assets/success.svg';

const ExternalMint = () => {
  const classess = useStyles();
  return (
    <SectionLayout>
      <Container>
        <div className={classess.mintNft}>
          <div className={classess.mintNftCol}>
            <img src={SuccessIcon} alt="success" />
            <p className={classess.cardText}>
              <span className={classess.fontBold}>Congratulations,</span> your purchase was successful! Please allow up
              to 2 minutes to mint and your NFT will be visible in your collection.
            </p>
            <div className={classess.btnContainer}>
              <button onClick={() => (window.location.href = '/mint-nft')} className={classess.mintBtn}>
                Make NFTs
              </button>
              <button onClick={() => (window.location.href = '/my-collection')} className={classess.mintBtn}>
                My collection
              </button>
              <button onClick={() => (window.location.href = '/create-collection')} className={classess.mintBtn}>
                Make a collection
              </button>
            </div>
          </div>
        </div>
      </Container>
    </SectionLayout>
  );
};

export default ExternalMint;

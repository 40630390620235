import React from 'react';
import PageLayout from 'Layout/Layout';
import Mint from './Mint';
const HomePage = () => {
  return (
    <PageLayout hasContainer={false}>
      <Mint />
    </PageLayout>
  );
};

export default HomePage;

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, MenuItem } from '@material-ui/core';

const navigateTo = href => () => {
  window.location.href = href;
};

const MobileMenuItemButton = ({ type, label, href, list, onClick, ...rest }) => {
  const menuTitle = href ? (
    type === 'external' ? (
      <MenuItem onClick={navigateTo(href)}>{label}</MenuItem>
    ) : (
      <Link
        component={RouterLink}
        to={href}
        color="inherit"
        style={{ textDecoration: 'none', display: 'block' }}
        key={label}
        className="customFont"
        {...rest}
      >
        <MenuItem>{label}</MenuItem>
      </Link>
    )
  ) : (
    <MenuItem onClick={() => onClick()} {...rest}>
      {label}
    </MenuItem>
  );

  return (
    <>
      {menuTitle}
      {!!list && list.map((listItem, listItemIndex) => <MobileMenuItemButton {...listItem} key={listItemIndex} />)}
    </>
  );
};

export default MobileMenuItemButton;

import React from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import Routes from './Routes';
import 'Styles/global.scss';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  palette: {
    primary: {
      main: '#30b9cf',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#58595B',
    },
    light: {
      main: '#fff',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    // fontFamily: ['"Roboto"', '"Lato"', 'sans-serif'].join(','),
    fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
    fontSize: 16,
    color: '#4a4a4a',
    body1: {
      fontSize: 16,
    },
    h1: {
      fontSize: 30,
      lineHeight: '55px',
      fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
      fontWeight: 700,
      marginTop: 40,
      marginBottom: 0,
      [breakpoints.down('sm')]: {
        fontSize: '25px',
        marginTop: 30,
      },
    },
    h2: {
      fontSize: 24,
      lineHeight: '55px',
      fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
      fontWeight: 700,
    },
    h3: {
      fontSize: 20,
      lineHeight: '32px',
      fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
      fontWeight: 700,
    },
    h4: {
      fontSize: 14,
      lineHeight: '32px',
      fontFamily: ['"Nunito Sans"', 'sans-serif'].join(','),
      fontWeight: 700,
    },
    h5: {
      fontSize: 20,
      lineHeight: '15px',
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      lineHeight: '15px',
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 600,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        '& .MuiSvgIcon-root': {
          width: '25px',
          height: 'auto',
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Work Sans',
        fontWeight: '700',
        fontSize: '14px',
        padding: '17px 48px',
        letterSpacing: '0.11px',
        lineHeight: '15px',
        borderRadius: '3px',
        textTransform: 'uppercase',
        textDecoration: 'none',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        boxShadow: 'none',
        height: '51px',
      },
      contained: {
        backgroundColor: '#30b9cf',
        border: '1px solid #30b9cf',
        color: '#ffffff !important',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#30b9cf !important',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          border: '1px solid rgb(89 88 90 / 12%)',
        },
      },
      outlined: {
        padding: '18px 30px',
        color: '#45A358',
      },
      label: {
        lineHeight: 1,
      },
      sizeSmall: {
        fontSize: 14,
        lineHeight: '14px',
        padding: '8px 22px',
        height: 35,
      },
    },
    MuiAlert: {
      action: {
        alignItems: 'flex-start',
      },
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Routes />
    </MuiThemeProvider>
  );
}

export default App;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#16252F',
      color: '#21D7BB !important',
    },
    '& .MuiPaginationItem-page': {
      border: '1px solid #323441 !important',
    },
  },
  myCollectionContiner: {
    backgroundColor: '#DDF4FF',
    paddingBottom: '80px',
    paddingTop: '60px',
  },
  heading: {
    fontSize: '28px',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: '#1F2029',
    margin: '0px',
    textAlign: 'center',
  },
  collectionHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '25px',
    gap: '25px',
    boxSizing: 'border-box',
  },
  listBtn: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  collectionItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: '40px',
  },
  collectionCard: {
    minHeight: '470px',
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    padding: '28px',
    boxSizing: 'border-box',
    flexBasis: '32%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      flexBasis: '100%',
    },
  },
  collectionCardHeader: {
    width: '100%',
    paddingBottom: '100%',
    overflow: 'hidden',
    borderRadius: '12px',
    position: 'relative',
  },
  collectionCardImg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    objectFit: 'cover',
  },
  collectionCardBody: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontSize: '18px',
    fontFamily: 'Lato',
    color: '#16252F',
  },
  cardDesc: {
    fontSize: '14px',
    fontFamily: 'Lato',
    color: '#21D7BB',
    fontWeight: 'bold',
  },
  mintPrint: {
    fontSize: '9px',
    fontFamily: 'Lato',
    color: '#16252F',
    fontWeight: 'normal',
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottomPaginationContainer: {
    marginTop: '22px',
    display: 'flex',
    justifyContent: 'center',
  },
  collectionSelect: {
    borderRadius: '10px',
    width: '170.07px',
    height: '51.41px',
    textAlign: 'center',
    color: '#343C44',
    lineHeight: '51.41px',
    border: ' solid 1px transparent',
    backgroundImage:
      'linear-gradient(4.62deg, #20212a 83.54%, rgba(32, 33, 42, 0.62) 99.54%), linear-gradient(180deg, #1bebb5 0.9%, #3291e9 72.79%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    boxShadow: '2px 1000px 1px #DDF4FF inset',
    fontSize: '14px',
    fontFamily: 'Lato',
    fontStyle: ' normal',
    fontWeight: 'bold',
    cursor: ' pointer',
    textTransform: 'none',
  },
  qrCodeFontIcon: {
    fontSize: '20px',
    marginLeft: '10px',
  },
  chooseAction: {
    height: '57px',
    borderRadius: '10px',
    border: 'solid 1px #21D7BB',
    textTransform: 'unset',
    fontSize: '18px',
    color: '#21D7BB',
    fontFamily: 'Nunito',
  },
  actionBtn: {
    height: '57px',
    background: 'linear-gradient(248deg, #24E5AD 0.9%, #4567DE 112.34%)',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    marginBottom: 16,
    borderRadius: 12,
    textTransform: 'capitalize',
  },
  cancelBtn: {
    height: '57px',
    backgroundColor: '#748896',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    marginBottom: 16,
    borderRadius: 12,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#748896',
    },
  },
  actionLabel: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#748896',
  },
  qricon: {
    position: 'absolute',
    top: 12,
    right: 12,
    cursor: 'pointer',
  },
  qrActionBtn: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  qrBackBtn: {
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    color: '#748896',
  },
  menuList: {
    width: 172,
  },
  tooltipPopper: {
    pointerEvents: 'auto !important',
  },
  tooltip: {
    padding: 0,
  },
  tooltipArrow: {
    color: '#fff',
  },
}));

export { useStyles };

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import Button from '@material-ui/core/Button';
import Page from 'Components/Page';
import Link from '@material-ui/core/Link';

function Success(props) {
  return (
    <Page>
      <div style={{ marginTop: 50 }}>
        <img
          src="/img/chicadees/chick-a-dee.png"
          css={css`
            max-width: 663px;
            width: 100%;
            margin-bottom: 30px;
          `}
          alt="Chic-A-Dee NFT on nest of eggs"
        />
        <h1>Congratulations!</h1>
        <p>You obtained an exclusive Chic-A-Dee!</p>
        <p>Your Chic-A-Dees(s) are being minted</p>
        <p>Visit the dashboard to view your token information.</p>
        <p>
          Minting may take up to 3 minutes (more during NFT drop event). After your chicadee is minted it may take a
          while before 3rd parties like OpenSea and Rarible pick it up in their system.
        </p>
        <p>
          Visit the FAQ to <a href="/faq">learn more</a> about how to connect your account to marketplaces and DAPPs.{' '}
        </p>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          component={Link}
          href="/my-collection"
          css={css`
            margin-top: 30px;
            color: #ffffff;
            &:hover {
              text-decoration: none;
            }
          `}
        >
          Proceed to your collection
        </Button>
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            component={Link}
            href="/chicadees/mint"
            css={css`
              margin-top: 30px;
              color: #ffffff;
              &:hover {
                text-decoration: none;
              }
            `}
          >
            Mint Another Chicadee
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default Success;

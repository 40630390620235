import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import HowImg from '../assets/HowItMade.jpg';
import useStyles from './style';

const HowItMadeSection = () => {
  const classes = useStyles();

  return (
    <Box px={2} className={classes.madeWrapper}>
      <Container>
        <Grid container display="flex" alignItems="center" spacing={4}>
          <Grid item xs={12} md={8}>
            <Box mb={4} className={classes.madeTitle}>
              HOW IT'S MADE
            </Box>
            <Box className={classes.madeDescription}>
              Each individual trait was hand painted and colored by Justin then digitized by our team to most accurately
              represent the look and feel of painted canvas. Traits were then joined with a combination of mathematical
              statistics and hand placement to create the best variety of common, rare, and super rare trait sets. Each
              character has been checked and approved by our team.
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <img src={HowImg} width="100%" alt="How It's Made" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItMadeSection;

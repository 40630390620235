import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Button } from '@material-ui/core';
import Container from 'Components/Container';
import NftWizardBg from './assets/nft-wizard.png';
import { ReactComponent as WizardIcon1 } from './assets/wizard1.svg';
import { ReactComponent as WizardIcon2 } from './assets/wizard2.svg';
import { ReactComponent as WizardIcon3 } from './assets/wizard3.svg';
import { ReactComponent as WizardIcon4 } from './assets/wizard4.svg';
import { ReactComponent as WizardIcon5 } from './assets/wizard5.svg';
import { ReactComponent as WizardIcon6 } from './assets/wizard6.svg';

import { useStyles } from './style';

const wizardList = [
  {
    id: 2,
    icon: <WizardIcon2 />,
    title: (
      <Box>
        <span>Automatic branded </span>
        <b>smart contract creation </b>
        <span>or every collection</span>
      </Box>
    ),
  },
  {
    id: 3,
    icon: <WizardIcon3 />,
    title: 'A digital fingerprint of your media saves proof of existence directly on the blockchain.',
  },
  {
    id: 4,
    icon: <WizardIcon4 />,
    title: (
      <Box>
        <span>Use Ownerfy under the hood without any branding as a </span>
        <b>whitelabel</b>
      </Box>
    ),
  },
  {
    id: 5,
    icon: <WizardIcon5 />,
    title: (
      <Box>
        <b>QR code generation </b>
        <span>for every NFT to attach physical things, making NFTs useful for product authentication</span>
      </Box>
    ),
  },
  // {
  //   id: 6,
  //   icon: <WizardIcon6 />,
  //   title: <Box>Create claimable NFT QR codes that can be printed on items or claimed through email.</Box>,
  // },
  {
    id: 7,
    icon: <WizardIcon1 />,
    title: (
      <Box>
        <span>Use your own </span>
        <b>credit card processor </b>
        <span>and then let us manage the NFTs</span>
      </Box>
    ),
  },
];
const NftWizard = () => {
  const classess = useStyles();
  const history = useHistory();
  return (
    <section className={classess.wizardContainer}>
      <Container>
        <Box style={{ marginBottom: 44 }}>
          <div className={classess.sectionHeading}>Featuring</div>
        </Box>

        <Grid spacing={4} container>
          <Grid item sm={6}>
            <Box p={4} className={classess.nftImgContainer}>
              <img src={NftWizardBg} style={{ width: '100%', maxWidth: 550, height: '100%' }} alt="wizard background" />
            </Box>
          </Grid>

          <Grid item sm={6}>
            <Box display="flex" flexDirection="column" justifyContent="center" style={{ height: '100%' }}>
              {wizardList.map((item, index) => (
                <Box mt={index !== 0 ? 4 : 0} key={index} display="flex" alignItems="center">
                  <Box display="flex" justifyContent="center" alignItems="center" mr={2}>
                    {item.icon}
                  </Box>
                  <Box style={{ fontSize: 18, lineHeight: '28px', fontFamily: 'Nunito' }}>{item.title}</Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Box mt={6} display="flex" justifyContent="center">
          <Button className={classess.containedBtn} onClick={() => history.push('/get-started')}>
            Get Started now
          </Button>
        </Box>
      </Container>
    </section>
  );
};

export default NftWizard;

import React from 'react';
import { Box } from '@material-ui/core';
import Container from 'Components/Container';
import NFT1 from './assets/why-001.png';
import NFT2 from './assets/why-002.png';
import NFT3 from './assets/why-003.png';
import NFT4 from './assets/why-004.png';
import NFT5 from './assets/why-005.png';
import NFT6 from './assets/why-006.png';
import WhyBG from './assets/why-bg.png';
import { useStyles } from './style';

const dummayData = [
  {
    src: NFT1,
    title: 'Monetize your <b>intellectual property</b> like art, music, and books',
  },
  {
    src: NFT2,
    title: '<b>Dedicated smart contracts for proper provenance and royalties</b>',
  },
  {
    src: NFT3,
    title: '<b>Create verified communities for memberships and clubs</b>',
  },
  {
    src: NFT4,
    title: 'Use NFT marketplaces and airdrops as an additional <b>marketing channel</b>',
  },
  {
    src: NFT5,
    title: 'Authenticate genuine products and <b>prevent counterfeiting</b>',
  },
  {
    src: NFT6,
    title: 'Track the <b>origin</b> and <b>history</b> of ownership of any products',
  },
];
const WhyNfts = () => {
  const classes = useStyles();
  return (
    <div className={classes.nftContainer}>
      <Container>
        <Box style={{ marginBottom: 60 }}>
          <h1 className={classes.sectionHeading}>Create with us</h1>
        </Box>

        <ul className={classes.nfItemContainer}>
          {dummayData.map((item, index) => {
            return (
              <div key={index} className={classes.nftItem}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  p={4}
                  className={classes.whyImgContainer}
                >
                  <img src={item.src} alt="" height={80} className={classes.whyfg} />
                  <img src={WhyBG} className={classes.whybg} alt="background" />
                </Box>

                <Box mt={8} display="flex" justifyContent="center">
                  <div className={classes.nftItemTitle} dangerouslySetInnerHTML={{ __html: item.title }} />
                </Box>
              </div>
            );
          })}
        </ul>
      </Container>
    </div>
  );
};

export default WhyNfts;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  historyContainer: {
    maxWidth: '625px',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: '32px',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: ' #1E3545',
    margin: '0px',
    width: '80%',
  },
  citizenzContainer: {
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  badgeContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },

  badge: {
    display: 'inline-block',
    height: ' 17px',
    borderRadius: '37px',
    color: '#fff',
    fontFamily: 'Lato',
    fontSize: '9px',
    textAlign: 'center',
    lineHeight: '17px',
    marginRight: '17px',
    padding: '2px 8px',
    whiteSpace: 'nowrap',
  },
  badgeDarkBlue: {
    background: '#476DDC',
  },
  badgeGreenLigh: {
    background: '#21D7BB',
  },
  badgeLinear: {
    background: 'linear-gradient(248deg, #24E5AD 0.9%, #4567DE 112.34%)',
  },

  BalanceContainer: {
    padding: '25px',
    background: '#EAF8FF',
    borderRadius: '12px',
    width: '396px',
    height: '120px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btn: {
    borderRadius: '5px',
    height: '41px',
    minWidth: '130px',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#fff',
    outline: 'none',
    border: 'none',
    marginBottom: '10px',
  },
  loadContainer: {
    height: '29px',
    minWidth: '130px',
    position: 'relative',
  },
  loadContainerFix: {
    position: 'absolute',
    left: '24px',
    top: '-11px',
  },
  btnPrimary: {
    background: '#21D7BB',
  },
  btnSecondary: {
    background: '#476DDC',
  },
  balance: {
    fontSize: '12px',
    fontFamily: 'Lato',
    color: '#748896',
    margin: '0px',
  },
  balanceValue: {
    fontSize: '36px',
    color: ' #21D7BB',
  },
  tableContainer: {
    marginTop: '22px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EAF8FF',
    borderRadius: '12px',
    padding: '30px',
    // maxWidth: '764px',
  },
  table: {
    width: '100%',
    textAlign: 'center',
    borderSpacing: '0 10px',
    borderCollapse: 'separate',
    borderStyle: 'hidden',
  },
  row: {
    background: '#FFFFFF',
    borderRadius: '10px',
    minHeight: '70px',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 20px',
  },
  tableCell: {
    width: '200px',
    textAlign: 'center',
    wordBreak: 'break-word',
    fontSize: '14px',
    fontFamily: 'Lato',
    display: 'flex',
    alignItems: 'center',
  },
  cellValue: {
    fontSize: '12px',
    fontFamily: 'Lato',
    color: '#5B5D68',
    margin: '0px',
    marginLeft: '10px',
  },
  status: {
    borderRadius: '5px',
    height: '31px',
    width: '76px',
    textAlign: 'center',
    lineHeight: '31px',
    fontFamily: 'Nunito',
    fontSize: '14px',
    padding: '5px 14px',
    textTransform: 'uppercase',
  },
  statusOk: {
    background: '#E4FFFD',
    color: '#21D7BB',
  },
  statusPanding: {
    background: '#FFE4E4',
    color: '#E96B6B',
  },
  balanceTableValue: {
    fontSize: '24px',
    color: '#1E3545',
  },
  TableHeaderRow: {
    borderRadius: '10px',
    minHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '0px 20px',
  },
}));

export { useStyles };

import uuid from 'uuid';
import axios from 'axios';
import config from 'Config/firebase';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import axiosPlus from './axios';

export async function createStripeCheckoutSessionForContractCreation({
  collectionName,
  cancelUrl,
  successUrl = '',
  blockchain,
}) {
  const stripeCheckoutSessionId = await axiosPlus.post(`/createStripeCheckoutSessionForContractCreation`, {
    contractName: collectionName,
    blockchain,
    cancelUrl,
    successUrl,
  });
  return stripeCheckoutSessionId.data;
}

export async function createStripeCheckoutSessionForNFTCreation({ item, cancelUrl, successUrl = '' }) {
  const stripeCheckoutSessionId = await axiosPlus.post(`/createStripeCheckoutSessionForNFTCreation`, {
    item,
    cancelUrl,
    successUrl,
  });
  return stripeCheckoutSessionId.data;
}

export async function getERC1155Quantity({ tokenId, ownerAddress, contract }) {
  try {
    const result = await axiosPlus.post(`/getERC1155Quantity`, { tokenId, ownerAddress, contract });

    return result.data;
  } catch (error) {
    return 'no-credits';
  }
}

export async function createNFTFromCredit({ item }) {
  try {
    const result = await axiosPlus.post(`/createNFTFromCredit`, { item });
    return result.data;
  } catch (error) {
    // 402 means payment is required
    if (error.response.status === 402 && error.response.data.code === 'no-credits') {
      return 'no-credits';
    }
  }
}

export async function createContractFromCredit(args) {
  try {
    const result = await axiosPlus.post(`/createContractFromCredit`, args);
    return result.data;
  } catch (error) {
    // 402 means payment is required
    if (error.response.status === 402 && error.response.data.code === 'no-credits') {
      return 'no-credits';
    }
  }
}

export async function createClaimOrder(args) {
  try {
    const result = await axiosPlus.post(`/createClaimOrder`, args);
    return result.data;
  } catch (error) {
    // 402 means payment is required
    if (error.response.status === 402 && error.response.data.code === 'no-credits') {
      return 'no-credits';
    }
  }
}

export async function getDataForClaim(params) {
  try {
    const result = await axiosPlus.get(`/getDataForClaim`, { params });
    return result.data;
  } catch (error) {
    return error;
  }
}

export async function getContractMeta(params) {
  try {
    const result = await axiosPlus.get(`/getContractMeta`, { params });
    return result.data;
  } catch (error) {
    return error;
  }
}

export async function createStripeCheckoutSessionForCharacterCreation({ order, cancelUrl }) {
  const stripeCheckoutSessionId = await axiosPlus.post(`/createStripeCheckoutSessionForCharacterCreation`, {
    order,
    cancelUrl,
  });
  return stripeCheckoutSessionId.data;
}

export async function createStripeCheckoutSessionForHarvestEggs({ tokenIds, images, cancelUrl }) {
  const stripeCheckoutSessionId = await axiosPlus.post(`/createStripeCheckoutSessionForHarvestEggs`, {
    tokenIds,
    images,
    cancelUrl,
  });
  return stripeCheckoutSessionId.data;
}

export async function createStripeCheckoutSessionForNFTTransfer({ item, cancelUrl }) {
  const stripeCheckoutSessionId = await axiosPlus.post(`/createStripeCheckoutSessionForNFTTransfer`, {
    item,
    cancelUrl,
  });
  return stripeCheckoutSessionId.data;
}

export async function createStripeCheckoutSessionForBuyCredits(data) {
  const stripeCheckoutData = await axiosPlus.post(`/createStripeCheckoutSessionForCredits`, data);
  return stripeCheckoutData.data;
}

export async function transferNftWithCredits(data) {
  const result = await axiosPlus.post(`/transferNFTFromCredit`, data);
  return result.data;
}

export async function getCreditPlans() {
  try {
    const response = await axiosPlus.get(`/credit-plans`);
    return response.data;
  } catch (error) {
    console.error(`Fetch Credit Plans Failed: `, error);
    return [];
  }
}

export async function getCharacter({ id }) {
  const character = await axiosPlus.get(`/getCharacter/${id}`);
  return character;
}

export async function getTotalJPFForAccount() {
  const stripeCheckoutSessionId = await axiosPlus.post(`/getTotalJPFForAccount`, {});
  return stripeCheckoutSessionId.data;
}

export async function generateValidationToken({ address }) {
  const res = await axiosPlus.post(`/generateValidationToken`, { address });
  return res.data;
}

export async function addWallet(params) {
  const res = await axiosPlus.post(`/addAddress`, params);
  return res.data;
}

export async function removeWallet(params) {
  const res = await axiosPlus.post(`/removeUserAddress`, params);
  return res.data;
}

export async function getWallets() {
  try {
    const response = await axiosPlus.get(`/getUserAddresses`);
    return response.data;
  } catch (error) {
    console.error('Get Credit Failed: ', error);
  }
}

export async function sendEth({ amount, to }) {
  const res = await axiosPlus.post(`/sendEth`, {
    amount,
    to,
  });

  return res.data;
}

export async function getStakingStatus({ address, signature, blockchain, contract }) {
  const res = await axiosPlus.post(`/externalStakingStatus`, {
    address,
    signature,
    blockchain,
    contract,
  });

  return res.data;
}

export async function startExternalStaking({ address, contract, blockchain, signature, tokenId, stakeAll }) {
  const res = await axiosPlus.post(`/startExternalStaking`, {
    address,
    signature,
    blockchain,
    contract,
    tokenId,
    stakeAll,
  });

  return res.data;
}

export async function claimPremadeNft(args) {
  const res = await axiosPlus.post(`/claimPremadeNft`, args);
  return res.data;
}

export async function getClaimOrder(params) {
  const res = await axiosPlus.get(`/getClaimOrder`, { params });

  return res.data;
}

export async function getNftsByAddress({ type }) {
  const res = await axiosPlus.get(`/getNftsByAddress`, { params: { type } });

  return res.data;
}

export async function getNextPageNftsByAddress({ type, pageKey }) {
  const res = await axiosPlus.get(`/getNftsByAddress`, { params: { type, pageKey } });
  return res.data;
}

export async function getAlchemyNftMetadata({ contract, tokenId, blockchain }) {
  const res = await axiosPlus.get(`/getNft/${blockchain}/${contract}/${tokenId}`);
  return res.data;
}

export async function setupChargeForRaribleSale(params) {
  const response = await axiosPlus.post(`/sellOnRaribleStripe`, params);
  return response.data;
}

export async function sellOnRaribleDirect(params) {
  const response = await axiosPlus.post(`/sellonRaribleDirect`, params);
  return response.data;
}

export async function getChicInfo() {
  const response = await axiosPlus.get(`/getChicInfo`);
  return response.data;
}

export async function getRaribleSellFee() {
  const response = await axiosPlus.get(`/getRaribleSellFee`);
  return response.data;
}

export async function getNftCreationFee() {
  const nftFee = await axiosPlus.get(`/getNFTCreationFee`);
  return (nftFee.data / 100).toFixed(2);
}

export async function getContractCreationFee() {
  const contractFee = await axiosPlus.get(`/getContractCreationFee`);
  return (contractFee.data / 100).toFixed(2);
}

export async function getNftTransferFee(blockchain) {
  const nftFee = await axiosPlus.get(`/getNFTTransferFee/${blockchain}`);
  return (nftFee.data / 100).toFixed(2);
}

export async function getHarvestEggsFee() {
  const nftFee = await axiosPlus.get(`/getHarvestEggsFee`);
  return { forOne: (nftFee.data.forOne / 100).toFixed(2), forMany: (nftFee.data.forMany / 100).toFixed(2) };
}

export async function getEthSendFee() {
  const nftFee = await axiosPlus.get(`/getEthSendFee`);
  return nftFee;
}

export async function uploadFileAsync(file) {
  console.log(`file in uploadFileAsync`, file);
  const extension = file.type.split('/')[1];

  const formData = new FormData();
  const uniqueItemId = uuid.v4().replace(/-/g, '');
  const fileName = `items/${uniqueItemId}/${uuid.v4()}.${extension}`;
  console.log(`fileName`, fileName);

  formData.append(fileName, file);

  try {
    const uploadedFileUrls = await axiosPlus.post(`/saveImages`, formData);
    // This sometimes does not throw an error because the backend thinks it uploaded files
    return uploadedFileUrls.data;
  } catch (error) {
    throw new Error('There was an error uploading the image/video. Please try again.');
  }
}

export async function checkCoinBalance() {
  const chain = 'eth';

  try {
    const response = await axiosPlus.get(`/checkCoinBalance/${chain}`);
    return response.data;
  } catch (err) {
    console.warn('Error checking balance: ', err);
    return false;
  }
}

export async function getRaribleSellOrders({ type, maker, itemId, contract }) {
  let response;

  try {
    switch (type) {
      case 'maker':
        console.log(
          'getting maker url: ',
          `${config.raribleProtocolURL}ethereum/order/orders/sell/byMaker?maker=${maker}&sort=LAST_UPDATE`,
        );
        response = await axiosPlus.get(
          `${config.raribleProtocolURL}ethereum/order/orders/sell/byMaker?maker=${maker}&sort=LAST_UPDATE`,
        );
        break;
      case 'item':
        response = await axiosPlus.get(
          `${config.raribleProtocolURL}ethereum/order/orders/sell/byItem?contract=${contract}&tokenId=${itemId}&sort=LAST_UPDATE`,
        );
        break;
      case 'collection':
        response = await axiosPlus.get(
          `${config.raribleProtocolURL}order/orders/sell/byCollection?collection=${contract}&sort=LAST_UPDATE`,
        );
        break;
      default:
        console.warn('unsupported rarible protocol type');
        break;
    }

    return response.data;
  } catch (err) {
    console.warn('Error getting rarible sell orders: ', err);
    return false;
  }
}

export async function postBid(data) {
  try {
    const response = await axiosPlus.post(`/bid`, data);
    return response.data;
  } catch (error) {
    console.error('Post Bid Failed: ', error);
    return false;
  }
}

export async function putBid(data) {
  const { id } = data;
  try {
    const response = await axiosPlus.put(`/bid/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Update Bid Failed: ', error);
  }
}

export async function fetchCompletedBids() {
  try {
    const response = await axiosPlus.get(`/bids/completed`);
    return response.data;
  } catch (error) {
    console.error('Get Completed Bids Failed: ', error);
    return [];
  }
}

export async function fetchCurrentBids() {
  try {
    const response = await axiosPlus.get(`/bids/current`);
    return response.data;
  } catch (error) {
    console.error('Fetch Completed Bids Failed: ', error);
    return [];
  }
}

export async function getAuctionList() {
  try {
    const response = await axiosPlus.get(`/auctions`);
    return response.data;
  } catch (error) {
    console.error('Fetch Auction List Failed: ', error);
    return [];
  }
}

export async function getAuction(id) {
  try {
    const response = await axiosPlus.get(`/auctions/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Fetch Auction ${id} Failed: `, error);
    return [];
  }
}

export async function fetchCompany() {
  try {
    const response = await axiosPlus.get(`${config.functionsURL}/company`);
    return response.data;
  } catch (error) {
    console.error('Fetching company failed: ', error);
    return false;
  }
}

export async function postCompany(data) {
  try {
    const response = await axiosPlus.post(`/company`, data);
    return response.data;
  } catch (error) {
    console.error('Post Company Failed: ', error);
    return false;
  }
}

export async function updateCompany(data) {
  try {
    const response = await axiosPlus.put(`/company`, data);
    return response.data;
  } catch (error) {
    console.error('Update Company Failed: ', error);
  }
}

export async function fetchUserCredits() {
  try {
    const response = await axiosPlus.get(`/user-credits`);
    return response.data;
  } catch (error) {
    console.error('Get Credit Failed: ', error);
  }
}

export async function fetchAPIUsage() {
  try {
    const response = await axiosPlus.get(`/api-usage`);
    return response.data;
  } catch (error) {
    console.error('Fetch API Usage Failed: ', error);
  }
}

export async function refreshAPIKey() {
  try {
    const response = await axiosPlus.get(`/api-refresh-key`);
    return response.data;
  } catch (error) {
    console.error('Refresh API Key Failed: ', error);
  }
}

export async function listCollections() {
  try {
    const response = await axiosPlus.get(`/contract-collection`);
    return response.data;
  } catch (error) {
    console.error('Failed list collection of contracts: ', error);
  }
}

export async function listSubscriptions() {
  try {
    const response = await axiosPlus.get(`/subscriptions`);
    return response.data;
  } catch (error) {
    console.error('Failed list subscriptions: ', error);
  }
}

export async function addSmartContract({ name, minterUserId, isDefault, apiKey }) {
  try {
    const response = await axios.post(
      `${config.functionsURL}/v1/collection/create`,
      { name, minterUserId, isDefault },
      { headers: { Authorization: `Bearer ${apiKey}` } },
    );
    return response.data;
  } catch (error) {
    console.error('Failed to add smart contract: ', error);
  }
}

export async function createStripeCustomerPortalSession() {
  try {
    const response = await axiosPlus.post(`/create-customer-portal-session`, {});
    return response.data;
  } catch (error) {
    console.error('Failed to create customer portal session: ', error);
  }
}

export async function getZombiesAvailableForFreeMint({ address }) {
  try {
    const response = await axiosPlus.post(`/getZombiesAvailableForFreeMint`, { address });
    return response.data;
  } catch (error) {
    console.error('Failed to get zombies available for mint: ', error);
  }
}

export async function getFreeZombieMintParams({ address, signature }) {
  try {
    const response = await axiosPlus.post(`/getFreeZombieMintParams`, { address, signature });
    return response.data;
  } catch (error) {
    console.error('Failed to get zombie mint params: ', error);
  }
}

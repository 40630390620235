import axios from 'axios';

export const getConversionRate = async ({ fromCurrency = 'eth', toCurrency = 'usd' }) => {
  const acceptedCurrencies = ['eth', 'usd'];

  if (!acceptedCurrencies.includes(fromCurrency) || !acceptedCurrencies.includes(toCurrency)) {
    throw new Error('Invalid currency');
  }

  try {
    const response = await axios.get(`https://api.cryptonator.com/api/ticker/${fromCurrency}-${toCurrency}`);

    if (response.status !== 200) {
      throw new Error('Server error');
    }

    const conversionRate = response.data.ticker.price;

    return conversionRate;
  } catch (err) {
    console.warn('error getting conversions : ', err);
    throw err;
  }
};

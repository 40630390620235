import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { utils, ethers } from 'ethers';
import { BigNumber } from '@ethersproject/bignumber';
import { withStyles } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';

import abi from 'ABI/chicadee_mint.json';
import eggsABI from 'ABI/eggs_abi.json';
import config from 'Config/firebase';
import { getAssets, formatOpenSeaAssets } from 'Services/openSeaService';
import Page from 'Components/Page';
import Container from 'Components/Container';
import { formatServerAssets } from 'Helpers/format';

import Faq from './Faq';

const styles = theme => ({
  page: {
    backgroundColor: '#000',
    color: '#fff',
    paddingBottom: 0,
    position: 'relative',
  },
  mainContent: {
    position: 'relative',
    zIndex: 1,
  },
  input: {
    margin: 20,
    paddingLeft: 25,
    width: 60,
    color: '#fff',
    backgroundColor: '#2f2b2b',
    textAlign: 'center !important',
  },
  leftPattern: {
    height: '625px',
    width: '197px',
    position: 'absolute',
    bottom: '345px',
    left: 0,
    zIndex: 0,
  },
  rightPattern: {
    height: '625px',
    width: '197px',
    position: 'absolute',
    top: '106px',
    right: 0,
    zIndex: 0,
  },
  header: {
    marginTop: 30,
    marginBottom: 40,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: '30px',
      alignItems: 'center',
    },
  },
  chicadeesLogo: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    fontSize: '40px',
    color: '#ffffff',
    lineHeight: '60px',
    margin: 0,
    textShadow: '1px 1px 10px #03fc06, 1px 0px 10px #03fc06',
  },
  logoBox: { textAlign: 'left' },
  logoImage: { height: '56px', width: '266px' },
  logoText: { color: '#00FF01', fontFamily: '"Poppins", sans-serif', fontSize: 20, margin: 0 },
  discordBox: {
    padding: '10px 30px 10px 30px',
    height: '70px',
    border: '1px solid white',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#1b1a1a',
    },
  },
  discordText: {
    color: '#fff',
    textTransform: 'uppercase',
    display: 'block',
    lineHeight: '51px',
  },
  heroBox: {
    width: '100%',
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 50,
  },
  heroImage: { maxWidth: '663px', width: '100%' },
  title1: {
    margin: '20px 0px 20px 0',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  title2: {
    margin: '20px 0px 20px 0',
    color: '#FF4DFF',
    fontFamily: 'Poppins',
    fontSize: '40px',
    letterSpacing: 0,
    lineHeight: '60px',
    fontWeight: 500,
    textShadow: '1px 1px 7px #FF4DFF, 1px 0px 1px #ffffff',
  },
  title3: {
    margin: '20px 0px 70px 0',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  countdownContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  countDown: {
    display: 'flex',
    gap: '75px',
    marginBottom: '45px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexWrap: 'wrap',
      maxWidth: '250px',
      gap: '25px',
    },
  },
  countDownGroup: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100px',
    [theme.breakpoints.down('xs')]: {
      width: '70px',
    },
  },
  number: {
    color: '#FFFFFF',
    fontFamily: '"Saira Condensed"',
    fontSize: '100px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: '0.7',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '60px',
    },
  },
  timeSpan: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '25px',
    textAlign: 'center',
    marginTop: '15px',
  },
  button: {
    backgroundColor: '#00FAFF',
    color: '#000000',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '30px',
    textAlign: 'center',
    padding: '16px 54px',
    marginBottom: 20,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: '#5fb2b3',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },

  metaButton: {
    backgroundColor: '#2f2b2b',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '25px',
    textAlign: 'center',
    padding: '16px 54px',
    marginBottom: 20,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: '#2f2b2b',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },

  littleButton: {
    backgroundColor: '#2f2b2b',
    color: '#fff',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '20px',
    textAlign: 'center',
    padding: '12px 24px',
    marginBottom: 10,
    marginRight: 5,
    cursor: 'pointer',
    border: 'none',
    '&:hover': {
      backgroundColor: '#2f2b2b',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 50,
    },
  },

  remindMeButton: {
    marginRight: 24,
  },
  mintQueue: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 50,
  },
  toggleQueue: {
    backgroundColor: 'black',
    color: '#fff',
    fontSize: 14,
    cursor: 'pointer',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 70,
    gap: '75px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
    },
  },
  description: {
    flex: 1,
    gap: '100px',
    maxWidth: '585px',
  },
  descriptionText: {
    color: '#B6B4B4',
    fontFamily: 'Poppins',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '34px',
    marginTop: 0,
    marginBottom: 30,
  },
  features: {
    maxWidth: '400px',
  },
  featureList: {
    color: '#D29F04',
    fontFamily: 'Poppins',
    fontSize: '40px',
    letterSpacing: '0',
    lineHeight: '60px',
    marginTop: 20,
    listStyleType: 'none',
    padding: 0,
  },
  featureTitle: {
    color: '#D29F04',
    fontFamily: 'Poppins',
    fontSize: '40px',
    letterSpacing: '0',
    lineHeight: '60px',
    textShadow: '1px 1px 7px #D29F04, 1px 0px 1px #ffffff',
  },
  feature: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '36px',
    marginBottom: '20px',
  },
  hatchIncubation: {
    backgroundColor: '#01202A',
    padding: '80px 0',
    marginBottom: '80px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0',
    },
  },
  hatchIncubationTitle: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    marginBottom: '70px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '45px',
      lineHeight: '65px',
      marginBottom: '30px',
    },
  },
  hatchEvents: {
    display: 'flex',
    gap: '30px',
    flexWrap: 'wrap',
    marginBottom: '66px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  hatchGroup: {
    display: 'flex',
    gap: '30px',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  hatchEvent: {
    maxWidth: '190px',
  },
  hatchEventPercentage: {
    color: '#D29F04',
    fontFamily: '"Saira Condensed"',
    fontSize: '60px',
    fontWeight: '600',
    letterSpacing: '0',
    padding: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  hatchEventText: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '32px',
    textAlign: 'center',
  },
  hatchPrice: {
    width: '100%',
    textAlign: 'center',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '32px',
  },
  yourCollection: { width: '100%', backgroundColor: '#000', padding: '0px 0' },
  yourChicBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: '5px',
    overflowX: 'visible',
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
  },
  chicControls: { textAlign: 'center' },

  theCollectionLogo: {
    color: '#00FF00',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    margin: 0,
    textAlign: 'center',
    marginBottom: 65,
    textShadow: '1px 1px 10px #00ff00, 1px 0px 1px #ffffff',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },

  theCollectionChicBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    gap: '5px',
    overflowX: 'hidden',
    paddingBottom: 50,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
    },
  },
  theCollectionChic: {
    height: '255px',
    width: '255px',
    [theme.breakpoints.down('xs')]: {
      height: '255px',
      width: '255px',
    },
  },
  theTeamBox: { width: '100%', padding: '90px 0', backgroundColor: '#201F1F', marginTop: '80px' },
  theTeamLogo: {
    color: '#00FAFF',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    textAlign: 'center',
    marginBottom: 50,
    textShadow: '1px 1px 10px #00FAFF, 1px 0px 1px #ffffff',
    [theme.breakpoints.down('xs')]: {
      fontSize: '50px',
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },
  teams: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '50px',
      padding: '0px 60px',
    },
  },
  team: { maxWidth: 400, width: '100%', textAlign: 'center' },
  teamLogo: {
    width: 200,
  },
  teamDescription: {
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '26px',
    textAlign: 'center',
  },
  theFaqBox: {
    width: '100%',
    padding: '90px 0',
    backgroundColor: '#201F1F',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  theFaqLogo: {
    color: '#D29F04',
    fontFamily: 'Poppins',
    fontSize: '60px',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '90px',
    textAlign: 'center',
    marginBottom: 50,
    textShadow: '1px 1px 10px #d8ff00a1, 1px 0px 1px #ffffff',
    [theme.breakpoints.down('xs')]: {
      padding: '0 30px',
      fontSize: '45px',
      lineHeight: '60px',
    },
  },
  faqs: {
    display: 'flex',
    flexDirection: 'column',
    gap: '21px',
    maxWidth: 700,
  },
  footer: { backgroundColor: '#000', padding: 50 },
  footerInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
  },
  footerLogos: { display: 'flex', gap: '20px', justifyContent: 'center' },
  footerLogo: {
    height: '48px',
    width: '48px',
    padding: '10px',
    backgroundColor: '#000000',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#1b1a1a',
    },
  },
  alchemyBadge: {
    cursor: 'pointer',
  },
  footerSmartContract: {
    color: '#616161',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '21px',
    textAlign: 'center',
    width: '100%',
    marginTop: '15px',
  },
});

function padLeadingZeros(num, size) {
  var s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
}

const faqs = [
  {
    question: 'What are Chicadees?',
    answer:
      'Chic-A-Dees are the first NFT project from Ownerfy. Chic-A-Dees are high-quality, 3D gamified IPFS enabled NFTs with exclusive owner benefits.  The total supply is 8,888 Common Chic-A-Dees and 2,223 Legendary Chic-A-Dees, which will be distributed in 4 batches.  Each Chic-A-Dee has unique traits that define its appearance. Buy six and receive a “LEGENDARY CHIC” during Generation 1 and 2. Legendary Chics are rare and produce twice as many EGGS per block.',
  },
  {
    question: 'What sets Chicadees apart from many other NFT collections?',
    answer: [
      'ERC1155 Smart Contract',
      'ERC20 token generation',
      'Chic-A-Dees are generated with up to 6 features with 5 levels of rarity, and a unique name. Legendaries have 2 extra features.',
      'All data stored on the blockchain',
      'IPFS metadata ensures that the chic metadata will not change over time.',
      'Gamified 3D chics with AR/VR compatibility with unique, randomly generated game stats (i.e. Strength, Intelligence, Charisma, etc.',
    ],
  },
  {
    question: 'What are EGGS tokens?',
    answer: [
      'EGGS are ERC20 tokens that can be generated by your chics.  Each chic can lay 1 egg per block or around 6,000 EGGS a day. There will be maximum total supply of 1 billion EGGS that can be collected,',
      'Immediately after a Chic-A-Dee is transferred to your wallet , it can be placed in "egg laying mode" which requires a one-time interaction with the smart contract and a small gas charge.',
      'Once a  Chic-A-Dee is put in egg laying mode, it will lay EGGS automatically until "harvested". It only stops laying eggs for you when a  Chic-A-Dee is transferred to another wallet. You are allowed to harvest one more time after a Chic-A-Dee is transferred even if the new owner puts the chic in laying mode - it sends all the old eggs back to you immediately and the new owner starts at 0.',
      'On September 7th Chic-A-Dees became one of the first projects to actually generate passive tokens as a fair distribution with no advantage or presale.',
      'EGGS have immediate value to Chic-A-Dee holders as Ownerfy launched a companion collection to Chic-A-Dees called Cash Cows.  The only way to mint a Cash Cow is with EGGS.',
    ],
  },
  {
    question: 'What are upcoming companion collections?',
    answer:
      "Ownerfy announced the first Chic-A-Dee companion release on October 5th - Cash Cows. Cash Cows are the first of many companion releases to come in the future. The only way to mint a Cash Cow will be with EGGS.  You will either need to own a Chic-A-Dee that is laying EGGS or you'll need to swap ETH for EGGS on Uniswap. Cash Cows will also be token generating from the time they are minted. They will produce MILK tokens. MILK tokens will be used for further discounts on Ownerfy collections.",
  },
];

function Chicadees({ setActiveModal, classes }) {
  const [daysRemaining, setDaysRemaining] = useState(null);
  const [hoursRemaining, setHoursRemaining] = useState(null);
  const [minutesRemaining, setMinutesRemaining] = useState(null);
  const [secondsRemaining, setSecondsRemaining] = useState(null);
  const [MMAccounts, setMMAccounts] = useState(null);
  const [userChics, setUserChics] = useState([]);
  const [chicBalances, setChicBalances] = useState(null);
  const [qty, setQty] = useState(1);
  const [isQueueOpen, setQueueOpen] = useState(false);
  const [saleOn, setSaleOn] = useState(false);
  const [mintQueue, setMintQueue] = useState([]);
  const [intervalState, setIntervalState] = useState([]);
  const [chicTotal, setChicTotal] = useState(0);

  useEffect(() => {
    axios.get(`${config.functionsURL}/getTotalMintedChicadees`).then(res => {
      const countTo = res.data.amount - 300;
      const easeOutQuad = t => t * (2 - t);
      const frameDuration = 2000 / 60;
      let frame = 0;
      const totalFrames = Math.round(2000 / frameDuration);
      const counter = setInterval(() => {
        frame++;
        const progress = easeOutQuad(frame / totalFrames);
        setChicTotal(Math.ceil(countTo * progress));

        if (frame === totalFrames) {
          clearInterval(counter);
        }
      }, frameDuration);
    });
  }, [setChicTotal]);

  const connectMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        setMMAccounts(accounts);

        try {
          // const { assets } = await getAssets({
          //   owner: accounts[0],
          //   asset_contract_addresses: config.chicADeeContract,
          // });
          // let chicADees = formatOpenSeaAssets(assets);
          let chicADees = [];
          let isMore = true;

          let pageKey = 0;
          while (isMore) {
            const { data } = await axios.get(
              `${config.functionsURL}/nft/list/address/${accounts[0]}/ethereum/${pageKey}/${config.chicADeeContract}`,
            );

            console.log('processing pagekey : ', pageKey);

            chicADees = [...chicADees, ...formatServerAssets(data)];
            if (data.length === 100) {
              isMore = true;
              pageKey += 1;
            } else {
              isMore = false;
            }
          }

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(config.chicADeeEggs, eggsABI.abi, provider);
          let balances = new Map();
          for (const chic of chicADees) {
            const balance = await contract.checkChicBalance(chic.token_id);
            const balFixed = balance.toString().replace('000000000000000000', '');
            balances.set(chic.token_id, balFixed);
          }
          setChicBalances(balances);

          setInterval(async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(config.chicADeeEggs, eggsABI.abi, provider);
            let balances = new Map();
            for (const chic of chicADees) {
              const balance = await contract.checkChicBalance(chic.token_id);
              const balFixed = balance.toString().replace('000000000000000000', '');
              balances.set(chic.token_id, balFixed);
            }
            setChicBalances(balances);
          }, 15000);

          setUserChics(chicADees);
        } catch (err) {
          console.warn('Error getting opensea assets: ', err);
        }
      } catch (err) {
        alert('connection failed');
      }
    } else {
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  const mint = async () => {
    connectMetamask();
    if (typeof window.ethereum !== 'undefined') {
      window.gtag('event', 'click_metamask', {
        event_category: 'ecommerce',
        event_label: 'metamask_click',
        value: parseInt(qty, 10),
      });

      // window.gtag('event', 'begin_checkout', {
      //   send_to: 'AW-643218609/1DU_CPm0y9ABELH52rIC',
      //   value: 15.0,
      //   currency: 'USD',
      //   transaction_id: '',
      // });

      // gtag('event', <action>, {
      //   'event_category': <category>,
      //   'event_label': <label>,
      //   'value': <value>
      // });

      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contract = new ethers.Contract('0x68A42D59E7B314c8655D8fe54756Afa5b22BF150', abi.abi, provider);

      const contractWithSigner = contract.connect(signer);

      const price = BigNumber.from('33000000000000000');
      const totalPrice = price.mul(qty);

      if (qty > 6) {
        alert('Qty too high, please try less than 6 in a batch');
        return;
      }

      try {
        let mintTx = await contractWithSigner.mint(utils.hexlify(parseInt(qty, 10)), {
          value: totalPrice.toHexString(),
        });
      } catch (err) {
        console.log('error:: ', err);
        alert(JSON.stringify(err));
      }
    } else {
      alert('Metamask is not connected. Please connect to Metamask plugin or the credit card option to purchase');
    }
  };

  const harvestEggs = async chics => {
    connectMetamask();
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();

      const contract = new ethers.Contract(config.chicADeeEggs, eggsABI.abi, provider);

      const contractWithSigner = contract.connect(signer);

      let hexIds = chics.map(chic => utils.hexlify(parseInt(chic.token_id)));

      try {
        let harvestTx = await contractWithSigner.harvestEggsFromManyChics(hexIds);
      } catch (err) {
        console.warn('error:: ', err);
        alert(JSON.stringify(err));
      }
    } else {
      alert('Metamask is not connected. Please connect to Metamask plugin or the credit card option to purchase');
    }
  };

  // useEffect(() => {
  //   if (typeof window.ethereum !== 'undefined') {
  //     window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
  //       setMMAccounts(accounts);
  //       console.log('use effect accounts are :: , ', accounts);
  //     });
  //   }
  // }, []);

  const openRemindModal = () => {
    window.open(
      'https://cdn.forms-content.sg-form.com/3f856f0a-056c-11ec-a92a-62d34d8457e6',
      '_blank',
      'resizable=yes, scrollbars=no, titlebar=no, location=no, menubar=no, status=no, toolbar=no, width=550, height=440, top=10, left=10',
    );
  };

  const toggleQueue = async () => {
    let mintQueue = await axios.get(`${config.functionsURL}/getMintQueue`);

    if (!isQueueOpen) {
      setIntervalState(
        setInterval(() => {
          axios.get(`${config.functionsURL}/getMintQueue`).then(mintQueue => {
            setMintQueue(mintQueue.data);
          });
          console.log('polling.. ');
        }, 10000),
      );
    } else {
      clearInterval(intervalState);
      setIntervalState(null);
    }

    setMintQueue(mintQueue.data);
    setQueueOpen(!isQueueOpen);
  };

  const getCountdown = () => {
    const deadline = new Date('2021-09-08T02:00:00.000Z');
    const now = new Date();
    const time = deadline - now;
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };

  // loop to countdown every 1 second
  setInterval(function () {
    const { days, hours, minutes, seconds } = getCountdown();
    setDaysRemaining(days);
    setHoursRemaining(hours);
    setMinutesRemaining(minutes);
    setSecondsRemaining(seconds);
  }, 1000);

  return (
    <Page hasNav={false} wrapperStyles={classes.page} hasContainer={false} FooterComponent={() => null}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>First Ever Chic-A-Dees ERC20 Token Making NFT Sale Early Access</title>
        <discription>6000 EGGS per day, 1 Billion Eggs Total, EGGS Token Harvesting</discription>
        <meta property="og:title" content="First Ever Chic-A-Dees ERC20 Token Making NFT Sale Early Access" />
        <meta property="og:description" content="6000 EGGS per day, 1 Billion Eggs Total, EGGS Token Harvesting" />
        <meta property="og:image" content="https://ownerfy.com/img/chicadees/chick-a-dee.png" />
      </Helmet>
      <img src="/img/chicadees/left-pattern.png" alt="left pattern" className={classes.leftPattern} />
      <img src="/img/chicadees/right-pattern.png" alt="right pattern" className={classes.rightPattern} />
      <div className={classes.mainContent}>
        <Container>
          <div className={classes.header}>
            <div className={classes.logoBox}>
              <p className={classes.chicadeesLogo}>Chic-A-Dees</p>
              <p className={classes.logoText}>Token Generating NFT Collection</p>
            </div>
            {!MMAccounts ? (
              <button className={classes.metaButton} onClick={connectMetamask}>
                Connect to MetaMask
              </button>
            ) : (
              <div>Connected to: {MMAccounts[0]}</div>
            )}

            <a className={classes.discordBox} href="https://discord.gg/GGn3knVaeT">
              <img src="/img/chicadees/discord.svg" alt="discord" />
              <span className={classes.discordText}>Discord</span>
            </a>
          </div>
          <div className={classes.heroBox}>
            {/* <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Yxx2TWBzuaM"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> */}
            <img
              alt="chicadees nfts"
              width="560"
              src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fhomepage%2Fchicadees1.png?alt=media&token=069e8a27-628e-4358-aa1d-b6ecc7cc88af"
            />
            <div className={classes.title1}>
              {/* A limited collection of unique 3D Chics with ownership benefits.
              <br /> */}
              Chic-A-Dees lay the EGGS token. There are 1 Billion EGGS that can be laid.
              <br />
              Each Chic Generates about 6,000 EGGS per day. EGGS can be used to trade for{' '}
              <a href="/cashcows">CASH COWS</a>.
            </div>
            {/* <div className={classes.title2}>HATCHING IN</div>
            {(daysRemaining || hoursRemaining || minutesRemaining || secondsRemaining) && (
              <div className={classes.countdownContainer}>
                <div className={classes.countDown}>
                  <div className={classes.countDownGroup}>
                    <span className={classes.number}>{padLeadingZeros(hoursRemaining, 2)}</span>
                    <span className={classes.timeSpan}>HOURS</span>
                  </div>
                  <div className={classes.countDownGroup}>
                    <span className={classes.number}>{padLeadingZeros(minutesRemaining, 2)}</span>
                    <span className={classes.timeSpan}>MINUTES</span>
                  </div>
                  <div className={classes.countDownGroup}>
                    <span className={classes.number}>{padLeadingZeros(secondsRemaining, 2)}</span>
                    <span className={classes.timeSpan}>SECONDS</span>
                  </div>
                </div>
              </div>
            )} */}
            {!saleOn ? (
              <button className={clsx(classes.button, classes.remindMeButton)} onClick={openRemindModal}>
                Notify me
              </button>
            ) : (
              <div>
                <div>
                  <button className={classes.button} onClick={mint}>
                    Mint with Ethereum (MetaMask)
                  </button>

                  <Input
                    value={qty}
                    id="qty"
                    type="number"
                    min="1"
                    max="6"
                    onChange={e => setQty(e.target.value)}
                    className={classes.input}
                  />
                  <label for="qty">QTY (6 Max)</label>
                </div>
                <a href="/chicadees/mint">
                  <button className={classes.button}>Mint with Credit Card</button>
                </a>
              </div>
            )}
            {/* <div className={classes.toggleQueue}>300 Gen 1 Sold Out! 400 Gen 2 Sold Out.</div> */}
            {/* <div className={classes.toggleQueue}>
              Each 6 purchased in Gen 1 and Gen 2 will get 1 free Legendary Chic-A-Dee sent to them at the end of the
              week
            </div> */}

            <div className={classes.toggleQueue}>Connect with MetaMask to lay EGGS</div>
            {/* <div className={classes.toggleQueue} onClick={toggleQueue}>
              <u>View Mint Queue</u>
            </div> */}

            {isQueueOpen && (
              <div className={classes.mintQueue}>
                {mintQueue.length > 0 ? (
                  mintQueue.map(tx => (
                    <p>
                      To: {tx.toAddress}, Pay Tx: {tx.payTxHash}, Status: {tx.status},{' '}
                      <a href={`/nft/${tx.tokenId}`}>{tx.tokenId}</a>{' '}
                    </p>
                  ))
                ) : (
                  <p>0</p>
                )}
              </div>
            )}
            <a target="os" href="https://opensea.io/collection/chic-a-dees">
              <img src="/img/chicadees/opensea.svg" className={classes.footerLogo} alt="opensea" />
            </a>
            <a target="rar" href="https://rarible.com/collection/0xb352131fe48571b7661390e65be4f12119e9686f">
              <img src="/img/rarible-logo-white.svg" className={classes.footerLogo} alt="rarible-logo" />
            </a>
            {/* <div className={classes.title3}>Exclusive Early Access Sale</div> */}
          </div>
          {!MMAccounts && (
            <button className={classes.metaButton} onClick={connectMetamask}>
              Connect to MetaMask to Manage Your Chics
            </button>
          )}

          {userChics.length > 0 && (
            <div className={classes.yourCollection}>
              <p className={classes.theCollectionLogo}>Your Chic-A-Dees</p>
              <div className={classes.chicControls}>
                <p>
                  EGGS ERC20 token contract must be added to Metamask to see your EGGS
                  <br />
                  EGGS Contract: 0xA16AB7dC3C7dc55aA0a59726144dcc4Bc30822c7
                </p>
                <button
                  className={classes.littleButton}
                  onClick={() => {
                    harvestEggs(userChics);
                  }}
                >
                  Start All Laying
                </button>
                <button
                  className={classes.littleButton}
                  onClick={() => {
                    harvestEggs(userChics);
                  }}
                >
                  Harvest All (Un-initialized will be started as well)
                </button>
                <p></p>
              </div>
              <div className={classes.yourChicBox}>
                {userChics.map(chic => (
                  <div className={classes.chicControls}>
                    <a
                      target="newwindow"
                      href={`https://opensea.io/assets/0xb352131fe48571b7661390e65be4f12119e9686f/${chic.token_id}`}
                    >
                      <img src={chic.image} className={classes.theCollectionChic} alt="chic" />
                    </a>
                    <p>Egg Balance: {chicBalances ? chicBalances.get(chic.token_id) : 'fetching..'}</p>
                    <button
                      className={classes.littleButton}
                      onClick={() => {
                        harvestEggs([chic]);
                      }}
                    >
                      Start Laying
                    </button>
                    <button
                      className={classes.littleButton}
                      onClick={() => {
                        harvestEggs([chic]);
                      }}
                    >
                      Harvest
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={classes.infoContainer}>
            <div className={classes.description}>
              <p className={classes.descriptionText}>
                <b>CHIC-A-DEE</b> is a gamified decentralized experiment. Users will have timed windows of opportunity
                to collect Chic-A-Dees and lay up to 1 Billion EGGS. There is a maxiumum of 8,888 Chics which will be
                distributed in 4 batches.
              </p>
              <p className={classes.descriptionText}>
                Chic-A-Dees are generated with up to 6 features with 5 levels of rarity, and a unique name. Legendaries
                have 2 extra features. Each Chic has unique game stats generated with 3 virtual dice and a bonus
                depending on your generation.
              </p>
              {/* <p className={classes.descriptionText}>
                EGGS are ERC-20 tokens and can be traded with popular tools such as Uniswap and Sushiswap.
              </p> */}
              {/* <p className={classes.descriptionText}>
                We ensure that the chic metadata will not change over time by using "IPFS".{' '}
                <a href="https://www.ipfs.com/" target="ipfs">
                  {' '}
                  IPFS is a decentralized file system.
                </a>
              </p> */}
            </div>

            <div className={classes.features}>
              <div className={classes.featureTitle}>FEATURES:</div>
              <ul className={classes.featureList}>
                <li className={classes.feature}>Laying of the EGGS Token</li>
                <li className={classes.feature}>VIP Access to Upcoming Ownerfy NFT Collections</li>
                <li className={classes.feature}>Private Club Room for Owners</li>
                <li className={classes.feature}>Common, Uncommon, Special, Rare, and Legendary Traits</li>
                {/* <li className={classes.feature}>Randomly Generated Gaming Stats (Strength, Intelligence, Charisma)</li> */}
                <li className={classes.feature}>Unique Names</li>
              </ul>
            </div>
          </div>
        </Container>

        {/* <div className={classes.hatchIncubation}>
          <p className={classes.hatchIncubationTitle}>Roadmap</p>
          <div className={classes.hatchEvents}>
            <div className={classes.hatchGroup}>
              <div className={classes.hatchEvent}>
                <div className={classes.hatchEventPercentage}>1%</div>
                <div className={classes.hatchEventText}>Secret chatroom for Chic-A-Dee owners</div>
              </div>
              <div className={classes.hatchEvent}>
                <div className={classes.hatchEventPercentage}>5%</div>
                <div className={classes.hatchEventText}>Unlock egg laying ability for EGGS ERC20 Token.</div>
              </div>
              <div className={classes.hatchEvent}>
                <div className={classes.hatchEventPercentage}>10%</div>
                <div className={classes.hatchEventText}>
                  33 Legendary “3D” chics random airdrop. MFoundation donation.
                </div>
              </div>
              <div className={classes.hatchEvent}>
                <div className={classes.hatchEventPercentage}>25%</div>
                <div className={classes.hatchEventText}>
                  33 Legendary “3D” chics random airdrop, Ownerfy &amp; partner discounts &amp;
                </div>
              </div>
            </div>
            <div className={classes.hatchGroup}>
              <div className={classes.hatchEvent}>
                <div className={classes.hatchEventPercentage}>50%</div>
                <div className={classes.hatchEventText}>ADOR BETA release</div>
              </div>
              <div className={classes.hatchEvent}>
                <div className={classes.hatchEventPercentage}>100%</div>
                <div className={classes.hatchEventText}>100 Legendary “3D” chics infinite object airdrop</div>
              </div>
            </div>
          </div>
        </div> */}

        <div className={classes.theCollection}>
          <p className={classes.theCollectionLogo}>The Collection</p>

          <div className={classes.theCollectionChicBox}>
            <img src="/img/chicadees/v1.png" className={classes.theCollectionChic} alt="chicadees v1" />
            <img src="/img/chicadees/v2.png" className={classes.theCollectionChic} alt="chicadees v2" />
            <img src="/img/chicadees/v3.png" className={classes.theCollectionChic} alt="chicadees v3" />
            <img src="/img/chicadees/v4.png" className={classes.theCollectionChic} alt="chicadees v4" />
            {/* <img src="/img/chicadees/v5.png" className={classes.theCollectionChic} /> */}
            <img src="/img/chicadees/v6.png" className={classes.theCollectionChic} alt="chicadees v6" />
          </div>
        </div>

        <div className={classes.theCollection}>
          <p className={classes.theCollectionLogo}>Legendaries</p>

          <div className={classes.theCollectionChicBox}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fchicadees%2Fleg1.png?alt=media&token=a7e16c54-e1e9-4376-aa6e-b6e242ca8bea"
              className={classes.theCollectionChic}
              alt="fleg-1"
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fchicadees%2Fleg2.png?alt=media&token=a542ef46-654f-416d-8895-90b8d501ca3c"
              className={classes.theCollectionChic}
              alt="fleg-2"
            />
            {/* <img
              src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fchicadees%2Fleg3.png?alt=media&token=1426824b-9664-4438-8fcb-6ec9778992a4"
              className={classes.theCollectionChic}
            /> */}
            <img
              src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fchicadees%2Fleg4.png?alt=media&token=5f6fec5c-3cb9-4f23-9025-b289405aa6ac"
              className={classes.theCollectionChic}
              alt="fleg-4"
            />
            {/* <img src="/img/chicadees/v5.png" className={classes.theCollectionChic} /> */}
            <img
              src="https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fchicadees%2Fleg5.png?alt=media&token=b1e11cd9-0205-4a0b-95f5-4904d7ea7b4e"
              className={classes.theCollectionChic}
              alt="fleg-5"
            />
          </div>
        </div>

        <div className={classes.theTeamBox}>
          <div className={classes.theTeamLogo}>The Team</div>
          <div className={classes.teams}>
            <div className={classes.team}>
              <a href="/">
                <img src="/img/chicadees/ownerfy-logo.png" className={classes.teamLogo} />
              </a>
              <p className={classes.teamDescription}>
                Ownerfy is an early innovator in NFT technology that helps artists and businesses create higher quality
                NFTs and smart contracts that meet emerging standards and the demands of business.
              </p>
            </div>
            <div className={classes.team}>
              <a href="https://adors.xyz" target="ador">
                <img src="/img/chicadees/ador-logo.png" className={classes.teamLogo} />
              </a>
              <p className={classes.teamDescription}>
                By providing an agnostic approach and DeFi tool, curators, creators and collectors alike can monetize
                their virtual assets 24/7, 365 days a year. By choosing a verified publisher like ADOR creators can now
                have a universal point of authenticity.
              </p>
            </div>
          </div>
        </div>

        <div className={classes.theFaqBox}>
          <div className={classes.theFaqLogo}>FAQ</div>
          <div className={classes.faqs}>
            {faqs.map(({ question, answer }) => {
              return <Faq question={question} answer={answer} />;
            })}
          </div>
        </div>

        <div className={classes.footer}>
          <div className={classes.footerInnerContainer}>
            <img
              onClick={window.logBadgeClick}
              id="badge-button"
              style={{ width: 240, height: 53 }}
              src="https://static.alchemyapi.io/images/marketing/badge.png"
              alt="Alchemy Supercharged"
              className={classes.alchemyBadge}
            />

            <p className={classes.footerSmartContract}>
              <a href="https://etherscan.io/address/0xB352131fE48571B7661390E65BE4F12119e9686f" target="es">
                Smart Contract: 0xB352131fE48571B7661390E65BE4F12119e9686f
              </a>
            </p>

            <div className={classes.footerLogos}>
              <a href="https://twitter.com/ChicADeesNFT" target="disc">
                <img src="/img/chicadees/twitter.svg" className={classes.footerLogo} alt="twitter" />
              </a>
              <a target="os" href="https://opensea.io/collection/chic-a-dees">
                <img src="/img/chicadees/opensea.svg" className={classes.footerLogo} alt="opensea" />
              </a>
              <a target="rar" href="https://rarible.com/collection/0xb352131fe48571b7661390e65be4f12119e9686f">
                <img src="/img/rarible-logo-white.svg" className={classes.footerLogo} alt="rarible-logo" />
              </a>
              <a href="https://discord.gg/GGn3knVaeT" target="disc">
                <img src="/img/chicadees/discord.svg" className={classes.footerLogo} alt="discord" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withStyles(styles)(Chicadees);

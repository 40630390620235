import React, { useState } from 'react';
import clsx from 'clsx';

import { useStyles } from './style';

export default function SwitchButton({ className: wrapperStyle, handler, startValue }) {
  const classes = useStyles();

  const [isOn, toggleOn] = useState(startValue);

  return (
    <div
      className={clsx(wrapperStyle, classes.switchButtonWrapper)}
      onClick={() => {
        handler(isOn);
        toggleOn(!isOn);
      }}
    >
      <div
        className={clsx(classes.status, classes.onStatus, isOn && classes.activeStatus, isOn && classes.onActiveStatus)}
      >
        ON
      </div>
      <div
        className={clsx(
          classes.status,
          classes.offStatus,
          !isOn && classes.activeStatus,
          !isOn && classes.offActiveStatus,
        )}
      >
        OFF
      </div>
    </div>
  );
}

import { makeStyles } from '@material-ui/core/styles';
import FeaturedBg from './assets/feature.png';
import List from './assets/list.svg';
import headerBg from './assets/heroBg.png';

const useStyles = makeStyles(theme => ({
  heroSection: {
    // backgroundColor: '#1F2029',
    // marginTop: '-145px',
    // width: '100%',
    // marginBottom: '150px',
    // '@media (max-width: 992px)': {
    //   background: '#1E3545',
    //   marginTop: '0px',
    // },
    backgroundColor: '#1F2029',
    marginTop: '-145px',
    background: `url(${headerBg}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: '100% 30%',
    '@media (max-width: 992px)': {
      background: '#1E3545',
      marginTop: '0px',
    },
  },
  heroSectionInner: {
    maxWidth: '1200px',
    minHeight: '800px',
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  heroTextSection: {
    paddingLeft: '150px',
    '@media (max-width: 992px)': {
      paddingLeft: '0',
    },
  },
  imgContainer: {
    marginTop: '100px',
    marginBottom: '100px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (max-width: 992px)': {
      marginLeft: '0px',
    },
  },
  ownerfyImg: {
    width: '100%',
    transform: 'scale(1.5)',
    '@media (max-width: 992px)': {
      transform: 'scale(1.3)',
    },
  },

  slider: {
    maxWidth: '350px',
    width: 'auto',
    height: '380px',
    marginBottom: '17px',
    marginLeft: '40px',
    border: '1px solid #286169',
    background: 'linear-gradient(180deg, #1F4F59 0%, rgba(31, 79, 89, 0) 100%)',
    backdropFilter: 'blur(88px)',
    borderRadius: ' 20px',
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '20px',
    boxSizing: 'border-box',
  },
  heading: {
    fontFamily: 'Lato',
    fontSize: '38px',
    color: '#fff',
    lineHeight: '67px',
    '@media (max-width: 960px)': {
      textAlign: 'center',
    },
  },
  heroContent: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '@media (max-width: 992px)': {
      // display: 'none',
    },
  },
  infoSectionContainer: {
    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  InfoContainer: {
    display: 'flex',
    '@media (max-width: 960px)': {
      display: 'none',
    },
  },
  InfoContainerMobile: {
    display: 'none',
    '@media (max-width: 960px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  infoSection: {
    display: 'flex',
    alignItems: 'start',
    flexWrap: 'wrap',
    '@media (max-width: 960px)': {
      justifyContent: 'center',
    },
  },
  infoSectionInner: {
    display: 'flex',
    marginRight: '12px',
    marginTop: '18px',
    width: '245px',
  },
  btnContainer: {
    marginTop: '32px',
  },
  content: {
    marginLeft: '12px',
  },
  contentLabel: {
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '600',
    color: '#fff',
    margin: '0px',
    cursor: 'pointer',
  },
  contentHelperText: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '600',
    color: '#fff',
    margin: '0px',
  },
  // featured section styles
  featureContainer: {
    width: '100%',
    minHeight: '700px',
    background: `url(${FeaturedBg})`,
    backgroundSize: 'cover',
    marginTop: '-160px',
    paddingTop: '100px',
    paddingBottom: '50px',
    position: 'relative',
    '@media (max-width: 992px)': {
      marginTop: '0px',
    },
  },
  sectionTitle: {
    fontFamily: 'Lato',
    fontSize: '32px',
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0,
  },
  sectionHeading: {
    fontFamily: 'Lato',
    fontSize: '48px',
    lineHeight: '72px',
    color: '#1F2029',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  sliderContainer: {
    minHeight: '470px',
    // maxWidth: '500px',
    backgroundColor: '#1E3545',
    borderRadius: '20px',
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // marginLeft: '20px',
    // marginRight: '20px',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      marginLeft: '20px',
      marginRight: '20px',
      padding: '15px',
    },
  },
  sliderContent: {
    // textAlign: 'center',
  },
  sliderBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: '42px',
  },
  sliderTitle: {
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    color: 'white',
  },
  sliderDesc: {
    fontSize: '14px',
    fontFamily: 'Nunito',
    color: '#21D7BB',
  },
  sliderImgWrapper: {
    height: '230px',
  },
  sliderImg: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    objectFit: 'cover',
  },
  type: {
    background: '#4567DE',
    border: '1px solid #72CCEF',
    borderRadius: '88px',
    padding: '12px 10px',
    color: 'white',
    fontFamily: 'Nunito',
    fontSize: '15px',
    cursor: 'pointer',
  },
  featuredProjectType: {
    width: '187px',
    height: '41px',
    background: 'linear-gradient(0deg, #476DDC, #476DDC), #DDF4FF',
    backdropFilter: ' blur(83.533px)',
    border: '1px solid transparent',
    lineHeight: '18px',
    borderRadius: '88px',
    padding: '12px 10px',
    color: 'white',
    fontFamily: 'Nunito',
    fontSize: '15px',
    cursor: 'pointer',
    position: 'absolute',
    top: '-30px',
    right: '20%',
  },
  //wizard
  wizardlist: {
    padding: '0px',
    paddingBottom: 40,
    display: 'inline-grid',
    gridTemplateRows: 'repeat(3, auto)',
    gridAutoFlow: ' column',
    columnGap: '80px',
    gap: '20px',
    listStyleImage: `url(${List})`,
    margin: 0,
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'repeat(1, auto)',
      gridAutoFlow: 'row',
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  wizardlistItem: {
    fontSize: ' 18px',
    fontFamily: 'Nunito',
    marginBottom: '20px',
    color: '#5a97b4',
    paddingLeft: 12,
  },
  wizardContainer: {
    paddingTop: '120px',
    paddingBottom: '120px',
    overflow: 'hidden',
    '@media (max-width: 768px)': {
      paddingTop: '48px',
      paddingBottom: '48px',
    },
  },
  nftImgContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  partnersContainer: {
    background: '#1E3545',
  },
  partners: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '45px',
    marginBottom: '65px',
  },
  partner: {
    marginBottom: '20px',
  },
  // NFT
  nftContainer: {
    background: '#DDF4FF',
    paddingTop: '60px',
    paddingBottom: '60px',
  },
  nfItemContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gap: 40,
    listStyle: 'none',
    padding: '0px',
    '@media screen and (max-width:768px)': {
      padding: '20px',
      gridTemplateColumns: 'repeat(1, auto)',
    },
  },
  nftItem: {
    flexBasis: '30%',
    background: 'linear-gradient(180deg, #1F4F59 0%, #313743 100%)',
    borderRadius: 30,
    padding: 50,
    '@media screen and (max-width:768px)': {
      flexBasis: '100%',
    },
  },
  nftItemTitle: {
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'Nunito',
    color: '#FFFFFF',
    maxWidth: 300,
  },
  highlight: {
    margin: 0,
    padding: 24,
    '& pre': {
      margin: 0,
      padding: 0,
    },
    '& code': {
      backgroundColor: '#1E3545',
      padding: 0,
      whiteSpace: 'pre-wrap',
      fontSize: 14,
    },
  },
  outlinedBtn: {
    borderRadius: 10,
    border: 'solid 1px #5A97B4',
    padding: '15px 53px',
    fontSize: 16,
    fontFamily: 'Nunito Sans',
    textTransform: 'capitalize',
    marginRight: 32,

    '@media (max-width: 768px)': {
      marginRight: 0,
      marginBottom: 24,
    },

    '& .MuiButton-label': {
      background: 'linear-gradient(248deg, #24E5AD 0.9%, #4567DE 112.34%)',
      backgroundClip: 'text',
      '-webkitBackgroundClip': 'text',
      webketTextFillColor: 'transparent',
      textFillColor: 'transparent',
    },
  },
  containedBtn: {
    background: 'linear-gradient(248deg, #24E5AD 0.9%, #4567DE 112.34%)',
    borderRadius: 10,
    padding: '15px 53px',
    color: 'white',
    fontSize: 16,
    fontFamily: 'Nunito Sans',
    textTransform: 'capitalize',
  },
  nftApiContainer: {
    paddingTop: 84,
    paddingBottom: 84,
    backgroundColor: '#DDF4FF',
  },
  tabContainer: {
    backgroundColor: '#1E3545',
    borderRadius: 16,

    '@media (max-width: 768px)': {
      '& .MuiGrid-spacing-xs-4': {
        margin: 0,
        width: '100%',

        '& > .MuiGrid-item': {
          padding: 0,
        },
      },
    },
  },
  actionBtns: {
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  tabContents: {
    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  hideDesktop: {
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  hideMobile: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  whyImgContainer: {
    position: 'relative',
  },
  whyfg: {
    position: 'relative',
    zIndex: 1,
  },
  whybg: {
    position: 'absolute',
    left: '5%',
    right: '5%',
    width: '90%',
  },
  inactiveTab: {
    color: '#6AA1D3 !important',
  },
  qrDescription: {
    fontSize: 18,
    lineHeight: '32px',
    fontFamily: 'Nunito',
    paddingRight: 120,
    '@media (max-width: 768px)': {
      paddingLeft: 48,
      paddingRight: 48,
      paddingTop: 80,
    },
  },
}));

export { useStyles };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiMenu-list': {
      borderRadius: '10px',
      margin: '20px',
      background: '#F5F5F5',
      padding: '0'
    },
  },
  formControl: {
    margin: '0',
    border: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
    height: 60,
    minWidth: 184,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      border: 'none',
    },
    '& .MuiFormLabel-root': {
      top: '6px',
      transform: 'scale(0.7) translateY(-10%) translateX(-8.5px) !important',
      transformOrigin: 'unset',
      pointerEvents: 'none',
      fontFamily: 'Lato',
      fontSize: '16px',
      color: '#748896',
      position: 'absolute',
      paddingLeft: '20px',
      marginBottom: '12px',
      left: 0,
      cursor: 'text',
      lineHeight: '20px'
    },
    '& .MuiInputBase-root': {
      borderRadius: '10px',
      overflow: 'hidden',
      marginTop: 0,
      height: '100%',
    },
    '& .MuiSelect-root': {
      padding: '8px 20px',
      boxSizing: 'border-box',
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      color: 'black !important'
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 28px) scale(1)',
    },
    '& .MuiSelect-selectMenu': {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#476DDC !important',
      zIndex: '100',
      paddingRight: '10px',
      '& .selectedHide': {
        display: 'none'
      }
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
      display: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '25px',
    minWidth: '30px'
  },

  arrow: {
    position: 'absolute',
    top: '30%',
    right: '10px'
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export { useStyles, MenuProps };

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mintNftContainer: {
    backgroundColor: '#DDF4FF',
    paddingBottom: '80px',
  },
  mintNft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '42px',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  mintNftCol: {
    flexBasis: '50%',
    backgroundColor: '#fff',
    borderRadius: '20px',
    marginBottom: '20px',
    padding: '60px',
    marginRight: '12px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      flexBasis: '100%',
      padding: '20px',
    },
  },
  cardText: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '30px',
    color: '#1E3545',
    textAlign: 'center',
  },
  fontBold: {
    fontWeight: 'bolder',
  },
  btnContainer: {
    marginTop: '40px',
  },
  mintBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',
    background: 'linear-gradient(0deg, #476DDC, #476DDC), #DDF4FF',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    width: '230px',
    height: '57px',
    color: '#FCFEFF',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: ' 18px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
}));

export { useStyles };

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useTheme } from '@material-ui/core';
import { Star } from './Star';
import CheckIcon from '@material-ui/icons/Check';

export const AuthenticityInfoRow = ({ label, criterias, className }) => {
  const theme = useTheme();

  return (
    <div
      className={className}
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px dashed #d8d8d8;
        padding: 14px 0;
      `}
    >
      <p
        css={css`
          font-family: 'Montserrat', sans-serif;
          color: #939798;
          font-weight: 500;
          font-size: 11px;
          letter-spacing: 0.08px;
          line-height: 27px;
          display: flex;
          align-items: center;
          margin: 0;
          ${theme.breakpoints.up('sm')} {
            font-size: 13px;
            letter-spacing: 0.1px;
          }
        `}
      >
        <Star />{' '}
        <span
          css={css`
            margin-left: 5px;
          `}
        >
          {label}
        </span>
      </p>
      <div>
        {criterias.map(criteria => (
          <p
            css={css`
              font-family: 'Montserrat', sans-serif;
              color: ${criteria.isVerified ? '#4d5153' : '#939798'};
              font-weight: 500;
              font-size: 11px;
              letter-spacing: 0.08px;
              line-height: 27px;
              text-align: right;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              ${theme.breakpoints.up('sm')} {
                font-size: 13px;
                letter-spacing: 0.1px;
              }
            `}
          >
            {criteria.label}
            <span
              css={css`
                width: 20px;
                line-height: 0;
              `}
            >
              {criteria.isVerified && (
                <CheckIcon
                  css={css`
                    width: 10px;
                    margin-left: 5px;
                  `}
                />
              )}
            </span>
          </p>
        ))}
      </div>
    </div>
  );
};

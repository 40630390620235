import React, { useState } from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ImageModal from 'Components/ImageModal';
import history from '../history';
import config from 'Config/firebase';

const pageStyles = theme => ({
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemNameLink: {
    marginBottom: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  itemOverview: {
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  itemMainInformation: {
    flex: 1,
    paddingRight: '150px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '50px',
      paddingRight: '0',
    },
  },
  itemMessage: {
    marginBottom: 0,
  },
  contactHeader: {
    marginTop: '15px',
    marginBottom: '15px',
    cursor: 'pointer',
  },
  phoneNumber: {
    fontSize: '14px',
    lineHeight: '14px',
    fontFamily: 'Roboto',
    letterSpacing: '0.5px',
  },
  itemImageSmall: {
    maxHeight: '250px',
    maxWidth: '250px',
    cursor: 'pointer',
  },
  itemImageLarge: {
    maxHeight: '500px',
    maxWidth: '280px',
    cursor: 'pointer',
  },
  productImage: {
    margin: 20,
    width: 50,
    height: 50,
  },
  imageAndCodeContainer: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  qrCode: {
    marginLeft: '55px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 30,
      marginBottom: 20,
    },
  },
});

const LostItemDetails = ({
  classes,
  name,
  itemId,
  message,
  phone,
  imageUrl,
  itemOverviewStyles,
  secondaryHeaderContent,
  isQrVisible,
  additionalMainContent,
  productUrl,
}) => {
  const [modalPreviewInformation, setModalPreviewInformation] = useState({});

  function onClickPreviewImage(imgSrc) {
    setModalPreviewInformation({
      imgSrc: imgSrc,
      fileName: null,
    });
  }

  const renderQr = () => {
    if (isQrVisible) {
      return (
        <div className={classes.qrCode}>
          {itemId && <img src={`${config.functionsURL}/generateQR/${itemId}.png`} alt="sample qr code" />}
        </div>
      );
    }

    return false;
  };

  return (
    <div>
      <div className={classes.itemHeader}>
        {/* {name && (
          <Typography
            variant="h3"
            color="primary"
            className={classes.itemNameLink}
            onClick={() => history.push(`/i/${itemId}`)}
          >
            {name}
          </Typography>
        )} */}
        <div>{secondaryHeaderContent}</div>
      </div>
      <div className={clsx(classes.itemOverview, itemOverviewStyles)}>
        <div className={classes.itemMainInformation}>
          {itemId && (
            <Typography variant="h6" className={classes.contactHeader} onClick={() => history.push(`/i/${itemId}`)}>
              Tag ID: {itemId}
            </Typography>
          )}
          {message && (
            <Typography variant="body1" className={classes.itemMessage}>
              {message}
            </Typography>
          )}
          {productUrl && <img src={productUrl} alt="product url" className={classes.productImage} />}
          {additionalMainContent}
        </div>

        <div className={classes.imageAndCodeContainer}>
          <div>
            {/* <Carousel infinite arrows draggable={false} centered>
              {images.map(image => (
                <img className={classes.itemImage} src={image} />
              ))}
            </Carousel> */}
            {imageUrl && (
              <img
                className={isQrVisible ? classes.itemImageSmall : classes.itemImageLarge}
                src={imageUrl}
                alt="upload preview"
                onClick={() => {
                  if (itemId) {
                    history.push(`/i/${itemId}`);
                  } else {
                    onClickPreviewImage(imageUrl);
                  }
                }}
              />
            )}
          </div>
          {renderQr()}
        </div>
      </div>
      <ImageModal
        modalImageInformation={modalPreviewInformation}
        onClickCloseModal={() => setModalPreviewInformation({})}
        maxWidth="lg"
      />
    </div>
  );
};

LostItemDetails.defaultProps = {
  isQrVisible: true,
};

export default withStyles(pageStyles)(LostItemDetails);

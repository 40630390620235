import React from 'react';
import Container from 'Components/Container';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useStyles } from './style';
import { Box, Hidden } from '@material-ui/core';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';

import Right from './assets/right.svg';
import Left from './assets/left.svg';
import Button from '../../Shared/Button/index';

const dummyData = [
  {
    title: 'Emerald Anvil and the Battle for Hada',
    src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fhomepage%2Fbattle-for-hada-home.jpeg?alt=media&token=b8a3c72c-60b1-4b26-b01e-7a45400316a7',
    desc: 'Emerald Anvil is a fantasy storyworld set in the dangerous fairy realm of Hada. Battle for Hada - A storytelling game set in the  Emerald Anvil  Universe',
    type: 'Partner',
    buttonTitle: 'Visit',
    href: 'https://emeraldanvil.com',
  },

  {
    title: 'Physical+Digital Collectibles available at Wallmart',
    src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fhomepage%2Fautotwallmart.jpg?alt=media&token=c86c2d1b-e166-4120-a7e9-8a44e1988ad7',
    desc: 'Walmart and NECA have Collaborated to offer collectibles backed by NFTs and Powered by the Ownerfy API',
    type: 'Partner',
    buttonTitle: 'Visit',
    href: 'https://www.autotvault.com',
  },

  {
    title: '🙇♀ V8art and Ownerfy joined forces to launch I Ching online. 🙇♂',
    src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fhomepage%2Fiching.png?alt=media&token=20316fcc-ec7a-4a9f-9dea-91abb48b66e7',
    desc: 'Whether you are looking for guidance or simply seeking greater clarity about your path in life, our app can help you unveil the future.',
    type: 'Partner',
    buttonTitle: 'Claim your I Ching NFT',
    href: 'https://v8.com.br/iching/',
  },

  // {
  //   title: 'Warpd Global Content Studio and Launchpad',
  //   src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fhomepage%2Fwarpd1.jpg?alt=media&token=97e66ca1-f29c-4155-801f-2453f67aea83',
  //   desc: 'Web3 Launching Platform Warpd Going Live Soon',
  //   type: 'Partner',
  //   buttonTitle: 'Visit',
  //   href: 'https://www.warpd.io/',
  // },
  // {
  //   title: 'J.Pierce Zombie Friends NFTs!',
  //   src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/collections%2Fzombiefriends%2Fjpzombies_hero.png?alt=media&token=fb5bb945-f862-44da-9993-f67dc6cabe58',
  //   desc: '10,000 J. Pierce Zombie Friends NFTs! by Justin Piece with Commerical Rights',
  //   type: 'Minting Soon',
  //   buttonTitle: 'Visit',
  //   href: 'zombiefriends',
  // },

  {
    title: 'J.Pierce & Friends NFTs',
    src: '/img/jpandfriends/friends.png',
    desc: 'Merchandise available! J. Pierce & Friends 4000 hand painted and signed NFTs by Justin Piece with Commerical Rights.',
    type: 'Shop Merch!',
    buttonTitle: 'Sold Out',
    href: 'jpandfriends',
  },
  {
    title: 'Auth0rzd by Blockheads',
    src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fhomepage%2Fbbuddies_cropped.jpg?alt=media&token=dce56507-aca2-4973-b575-5b976f722dc0',
    desc: 'Auth0rzd by Blockheads is a streetwear & NFT project featuring premium SnapBack caps, and Blockhead Buddy, it’s NFT avatar. Blockhead Buddy is limited to 1,000 unique characters, and the NFT and its respective commercial rights are conveyed upon purchase of an Auth0rzd product.',
    type: 'NFT + Hat Combo',
    buttonTitle: 'Buy Now',
    href: 'https://www.authorzd.com',
  },

  // {
  //   title: 'Citizenz',
  //   src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fcitizenz%2Fcitizenz-hero.png?alt=media&token=70080a1b-9ebb-4304-8ed1-ce049af2c0eb',
  //   desc: 'Become part of Citizenz',
  //   type: 'Sold Out',
  //   buttonTitle: 'Learn More',
  //   href: 'http://citizenznft.io',
  // },
  // {
  //   title: 'Cash Cows',
  //   src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fchicadees%2Fcashcows.png?alt=media&token=60ef4aa7-42c9-47de-82a6-b719953749c3',
  //   desc: 'Cash Cows. 3333 Milk Producing NFTs',
  //   type: 'Sold Out',
  //   buttonTitle: 'Learn More',
  //   href: '/cashcows',
  // },
  // {
  //   title: 'Chic-A-Dees',
  //   src: 'https://firebasestorage.googleapis.com/v0/b/receiptcoin-receiptchain-de560.appspot.com/o/website%2Fhomepage%2Fchicadees1.png?alt=media&token=069e8a27-628e-4358-aa1d-b6ecc7cc88af',
  //   desc: ' Chic-A-Dees First NFTs to Generate Passive ERC20 Tokens Gen 1 and 2 SOLD OUT. Gen 3 Coming Soon.',
  //   type: 'Sold Out',
  //   buttonTitle: 'Learn More',
  //   href: 'chicadees',
  // },
];
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="sliderBtnRight">
      <Hidden mdUp>
        <ChevronRightIcon />
      </Hidden>
      <Hidden smDown>
        <img src={Left} alt="right" width="46" />
      </Hidden>
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="sliderBtnLeft">
      <Hidden mdUp>
        <ChevronLeftIcon />
      </Hidden>
      <Hidden smDown>
        <img src={Right} alt="left" width="46" />
      </Hidden>
    </div>
  );
}
const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 12000,
  slidesToShow: 2,
  slidesToScroll: 1,
  nextArrow: <NextArrow onClick={undefined} className={undefined} />,
  prevArrow: <PrevArrow onClick={undefined} className={undefined} />,
  responsive: [
    {
      breakpoint: 1268,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1060,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};
const Featured = () => {
  const classes = useStyles();
  return (
    <div className={classes.featureContainer}>
      <Container>
        <div style={{ maxWidth: '1440px' }}>
          <Box mb={0}>
            <h1 className={classes.sectionTitle}>Featured Partners</h1>
          </Box>
          <Slider {...settings}>
            {dummyData.map((item, index) => (
              <div key={index}>
                <div className={classes.sliderContainer}>
                  <div>
                    <div className={classes.sliderImgWrapper}>
                      <a href={item.href}>
                        <img src={item.src} alt="slider" className={classes.sliderImg} />
                      </a>
                    </div>
                    <div className={classes.sliderContent}>
                      <h1 className={classes.sliderTitle}>{item.title}</h1>
                      <p className={classes.sliderDesc}>{item.desc}</p>
                    </div>
                  </div>
                  <div className={classes.sliderBtn}>
                    <button onClick={() => (window.location.href = item.href)} className={classes.type}>
                      {item.type}
                    </button>
                    <Button
                      handleClick={() => (window.location.href = item.href)}
                      btnLabel={item.buttonTitle}
                      background
                      small
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};
export default Featured;

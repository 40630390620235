import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  mintNftContainer: {
    backgroundColor: '#DDF4FF',
    paddingBottom: '80px',
  },
  mintNft: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '42px',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  mintNftCol: {
    flexBasis: '48%',
    backgroundColor: '#fff',
    borderRadius: '20px',
    marginBottom: '20px',
    padding: '40px',
    marginRight: '12px',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      flexBasis: '100%',
      padding: '20px',
    },
  },
  mintNftHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    color: '#323441',
    fontSize: '24px',
    fontFamily: 'Lato',
    fontWeight: '900',
    marginTop: '0px',
    marginBottom: '10px',
  },
  authLink: {
    color: '#21D7BB',
    fontSize: '18px',
    fontFamily: 'Lato',
  },
  logoutLink: {
    color: '#476ddc',
    marginLeft: 8,
  },
  helperText: {
    color: '#5B5D68',
    fontSize: '16px',
    fontFamily: 'Lato',
    fontWeight: '400',
    margin: '0px',
  },
  selectBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    border: '1.8px solid #e0e0e0',
    boxSizing: 'border-box',
    background: 'transparent',
    borderRadius: '10px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    width: ' 100%',
    height: ' 66px',
    marginBottom: '20px',
    marginTop: '20px',
    padding: '8px 20px',
    cursor: 'pointer',
  },
  selectBoxLabel: {
    color: '#748896',
    fontFamily: 'Lato',
  },
  assetsType: {
    fontSize: '16px',
    color: '#5B5D68',
    fontFamily: 'Lato',
  },
  tokenInput: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    background: ' #F7F9FF',
    borderRadius: '10px',
    width: ' 100%',
    height: ' 66px',
    marginBottom: '20px',
    marginTop: '20px',
    padding: '8px 20px',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  tokenInputTitle: {
    fontFamily: 'Lato',
    color: '#476DDC',
    fontWeight: 'bold',
  },
  tokenInputHelperText: {
    fontSize: '12px',
    color: '#748896',
    fontFamily: 'Lato',
    width: '95%',
    letterSpacing: ' 0.03em',
  },
  mintNftSection: {
    background: '#E8FFFE',
    marginTop: '60px',
    padding: '20px',
    borderRadius: '10px',
    height: '148px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  mintFeeContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  mintFeeContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  mintFeeLable: {
    fontSize: '12px',
    color: '#748896',
    fontFamily: 'Lato',
  },
  minFee: {
    fontSize: '28px',
    color: '#5B5D68',
    fontFamily: 'Lato',
  },
  attributesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '32px',
  },
  attributes: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexBasis: '48%',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    padding: '10px',
    height: '60px',
    boxSizing: 'border-box',
    marginBottom: '22px',
  },
  attributesLable: {
    fontSize: '14px',
    color: '#748896',
    fontFamily: 'Lato',
  },
  addBtn: {
    border: '1px solid #476DDC',
    borderRadius: '10px',
    background: 'transparent',
    width: '146px',
    height: '57px',
    marginTop: '22px',
    color: '#476DDC',
    cursor: 'pointer',
  },
  crossIcon: {
    cursor: 'pointer',
  },
  buyMoreLink: {
    display: 'inline-block',
    borderRadius: '36px',
    border: '1px solid #476DDC',
    width: ' 87.44px',
    height: '25.84px',
    marginLeft: '8px',
    textAlign: 'center',
    lineHeight: '25.84px',
    fontFamily: 'Lato',
    fontsiz: '14px',
    color: '#476DDC',
    textDecoration: 'none',
  },
  dialogContainer: {
    minWidth: 500,
    maxWidth: 800,
  },
  createCollectionTitle: {
    fontSize: 24,
    fontWeight: 900,
    fontFamily: 'Lato'
  },
}));

export { useStyles };

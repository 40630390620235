import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';

import Button from 'Shared/Button/index';
import Container from 'Components/Container';
import Featured from './Featured';

import { useStyles } from './style';

import Ownerfy from './assets/ownerfy.png';
import OwnerfyOrbitBall1 from './assets/ownerfyOrbitBall-1.png';
import OwnerfyOrbitBall2 from './assets/ownerfyOrbitBall-2.png';
import OwnerfyOrbitBall3 from './assets/ownerfyOrbitBall-3.png';
import OwnerfyOrbitBall4 from './assets/ownerfyOrbitBall-4.png';
import OwnerfyOrbitBall5 from './assets/ownerfyOrbitBall-5.png';
import Setting from './assets/setting.svg';
import Wizard from './assets/wizard.svg';

const HeroSection = () => {
  const history = useHistory();
  const classes = useStyles();

  const navigateTo = pathName => () => {
    history.push(pathName);
  };

  const infoSection = () => {
    return (
      <div className={classes.infoSectionContainer}>
        <div className={classes.infoSection}>
          <div className={classes.infoSectionInner}>
            <div>
              <a href="/api-pricing">
                <img src={Wizard} alt="NFT Minting interface" />
                {/* <img src={Setting} alt="NFT API" /> */}
              </a>
            </div>
            <div className={classes.content}>
              <p onClick={() => (window.location.href = '/api-pricing')} className={classes.contentLabel}>
                Custom Solutions
              </p>
              <p onClick={() => (window.location.href = '/api-pricing')} className={classes.contentHelperText}>
                Business integrations
                <br />
                Art collections
                <br />
                Marketing projects <br />
              </p>
            </div>
          </div>
          {/* <div className={classes.infoSectionInner}>
            <div>
              <a href="/create-collection">
                <img src={Wizard} alt="NFT Minting interface" />
              </a>
            </div>
            <div className={classes.content}>
              <p onClick={() => (window.location.href = '/api-pricing')} className={classes.contentLabel}>
                For Developers
              </p>
              <p onClick={() => (window.location.href = '/api-pricing')} className={classes.contentHelperText}>
                Games and apps
                <br />
                Deploy, Mint, Transfer <br />
                with no crypto
                <br />
              </p>
            </div>
          </div> */}
        </div>
        <div className={classes.btnContainer}>
          <Button handleClick={navigateTo('/get-started')} btnLabel="Let's go!" background />
        </div>
      </div>
    );
  };
  return (
    <div className={classes.heroSection}>
      <Container>
        <div className={classes.heroSectionInner}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item md={6} sm={12} className={classes.heroTextSection}>
              <Typography variant="h1" className={classes.heading}>
                Ownership Online
              </Typography>
              <div className={classes.InfoContainer}>{infoSection()}</div>
            </Grid>
            <Grid item md={6} className={classes.imgContainer}>
              <div>
                <img src={Ownerfy} alt="Not found" className={classes.ownerfyImg} />
              </div>

              {[OwnerfyOrbitBall5, OwnerfyOrbitBall4, OwnerfyOrbitBall3, OwnerfyOrbitBall2, OwnerfyOrbitBall1].map(
                (img, imgIndex) => (
                  <div className={`orbit-container orbit-container-${4 - imgIndex}`} key={imgIndex}>
                    <div className={`orbit-rotated-area orbit-rotated-area-${4 - imgIndex}`}>
                      <div className={`orbit-spinning orbit-spinning-${4 - imgIndex}`}>
                        <img src={img} alt={`orbit-img-${4 - imgIndex}`} className="orbit-img" />
                      </div>
                    </div>
                  </div>
                ),
              )}

              {/* <h1>Test</h1> */}
            </Grid>
            <div className={classes.InfoContainerMobile}>{infoSection()}</div>
          </Grid>
        </div>
      </Container>
      <Featured />
    </div>
  );
};

export default HeroSection;

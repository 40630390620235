import BN from 'bn.js';

/**
 * Convert firebase snapshot object to array of the values
 * @param  {[type]} snapshot [description]
 * @return {[type]}          [description]
 */
export const snapshotToValueArray = snapshot => {
  const returnArr = [];

  snapshot.forEach(childSnapshot => {
    var item = childSnapshot.val();
    // useful in getting the orginal key
    item.id = childSnapshot.key;

    returnArr.push(item);
  });

  return returnArr;
};

/**
 * Convert firebase snapshot object to array of the keys
 * @param  {[type]} snapshot [description]
 * @return {[type]}          [description]
 */
export const snapshotToKeyArray = snapshot => {
  const returnArr = [];

  snapshot.forEach(childSnapshot => {
    const item = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};

/**
 * Format params from object into string
 * @param  {[object]} keyValuePairs
 * @return {[string]} urlQueryString
 */
export const objectToQuery = keyValuePairs => {
  let tmpArr = [];

  Object.keys(keyValuePairs).forEach(key => {
    if (
      keyValuePairs[key] !== undefined &&
      keyValuePairs[key] !== null &&
      keyValuePairs[key] !== NaN &&
      keyValuePairs[key] !== ''
    ) {
      tmpArr.push(`${key}=${keyValuePairs[key]}`);
    }
  });

  return tmpArr.join('&');
};

/**
 *
 * @param  {[int]} int [this is the large integer from the blockchain]
 * @param  {[string]} int [type of currency it is]
 * @return {[float]}  [human readable format usually moved up 18 decimals]
 */
export const formatWithDecimal = (num, type) => {
  // The result of this function should never be used in Math
  // This is for display purposes only
  // Todo: This should be expanded to allow different numbers of decimals
  if (type === 'btc') {
    return (parseInt(num, 10) / 100000000).toFixed(4);
  }

  return (parseInt(num, 10) / 1000000000000000000).toFixed(4);
};

/**
 *
 * @param  {[float]} float [user entered format usually moved up 18 decimals]
 * @param  {[type]} type [type of currency]
 * @return {[string]} [this is the large integer from the blockchain]
 */
export const toBaseAmount = (num, type) => {
  // Big number library doesn't allow decimals and there is no reason
  // to allow precision less than 9 at this time since it will never be visible
  // And the user will not be able to check if it worked
  if (String(num).length > 10) {
    throw 'Amount must be less than 10 digits';
  }
  if (type === 'btc') {
    var a = parseFloat(num) * 100000000;
    var b = new BN(a, 10);
    return b.toString(10);
  }
  var a = parseFloat(num) * 1000000000;
  var b = new BN(a, 10);
  var c = new BN('1000000000', 10);
  var res = b.mul(c);
  return res.toString(10);
};

// Human readable mint fee
export const getMintFee = gasPrice => {
  return (100000 * gasPrice) / 1000000000 + 0.0011 + (21000 * gasPrice) / 1000000000;
};

import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'Store/actions';
import Container from 'Components/Container';
import pages from 'Constants/pages';
import clsx from 'clsx';

const menuLinks = [
  pages.createNFT,
  pages.downloadNftCreator,
  pages.moreSecureNfts,
  pages.howToMakeNfts,
  pages.nftStandards,
];
const registrationMenuLinks = [pages.registrationDashboard, pages.registrationCustomers, pages.registrationAddCode];

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  appBar: {
    position: 'relative',
    background: '#ffffff',
    alignItems: 'center',
    boxShadow: 'none',
    borderBottom: '1px solid #ededed',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'initial',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  primaryContent: {
    display: 'flex',
    flex: 1,
  },
  nonMobileButtonsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginRight: '-30px',
  },
  nonMobileUserButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textLink: {
    textTransform: 'initial',
    textDecoration: 'none',
    color: '#313739',
    alignSelf: 'center',
    fontFamily: "'Montserrat', sans-serif !important",
    lineHeight: '13px !important',
    fontSize: '13px !important',
    width: 'auto',
    paddingRight: 10,
    textAlign: 'center',
    fontWeight: '500 !important',
    transition: 'opacity 150ms linear, color 150ms linear, background 150ms linear',
    '&:hover': {
      color: '#07be64',
      background: 'none',
    },
  },
  inline: {
    display: 'inline',
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  },
  logoLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginRight: '30px',
    },
  },
  logoImg: {
    maxWidth: '130px',
    maxHeight: '40px',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '218px',
      maxHeight: '60px',
    },
  },
  productLogo: {
    display: 'inline-block',
    borderLeft: `1px solid ${theme.palette.grey.A100}`,
    marginLeft: 32,
    paddingLeft: 24,
    [theme.breakpoints.up('md')]: {
      paddingTop: '1.5em',
    },
  },
  tagline: {
    display: 'inline-block',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: '0.8em',
    },
  },
  iconButton: {
    color: theme.palette.secondary.main,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      padding: 0,
    },
  },
  tabContainer: {
    marginLeft: 32,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  outlinedButton: {
    color: '#58595B',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '4px',
    border: 'solid 1px #58595B',
    padding: '10px 49px',
    fontFamily: "'Nunito Sans', sans-serif",
    textTransform: 'uppercase',
  },
});

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      menuDrawer: false,
      anchorEl: null,
    };
  }

  openAccountMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  mobileMenuOpen = event => {
    this.setState({ menuDrawer: true });
  };

  mobileMenuClose = event => {
    this.setState({ menuDrawer: false });
  };

  handleLoginModalOpen = () => {
    this.props.setActiveModal('login');
  };

  handleLogout = () => {
    this.props.logoutUser({ returnPath: '/' });
  };

  menuListItems = ({ className, isDrawer = false }) => {
    const { user } = this.props;
    let links = menuLinks;
    if (user?.regPlanId) {
      links = registrationMenuLinks;
    }

    return links.map((item, index) => {
      let conditionalProps = {};
      let LinkComponent = Link;
      // if (item.type === 'modal') {
      //   conditionalProps.onClick = () => {
      //     this.props.setActiveModal(item.name);
      //   };
      // } else {
      //   conditionalProps.to = {
      //     pathname: item.pathname,
      //     //search: this.props.location.search,
      //   };
      // }

      if (item.external) {
        return (
          // <Button {...conditionalProps} key={item.label} className={className} href={item.pathname}>
          //   {item.label}
          // </Button>
          <ListItem button {...conditionalProps} key={item.label} target="new" className={className}>
            <a href={item.pathname} className={className}>
              {item.label}
            </a>
          </ListItem>
        );
      }
    });
  };

  renderDrawer = () => {
    const { user, classes } = this.props;

    return (
      <SwipeableDrawer
        anchor="right"
        open={this.state.menuDrawer}
        onClose={this.mobileMenuClose}
        onOpen={this.mobileMenuOpen}
      >
        <AppBar title="Menu" />
        <List>
          {this.menuListItems({
            isDrawer: true,
            className: classes.textLink,
          })}
        </List>
      </SwipeableDrawer>
    );
  };

  renderMobileMenu = () => (
    <>
      <IconButton
        onClick={this.mobileMenuOpen}
        className={this.props.classes.iconButton}
        color="inherit"
        aria-label="Menu"
      >
        <MenuIcon />
      </IconButton>
      {this.renderDrawer()}
    </>
  );

  renderLoggedInUserButtons = () => {
    const { classes, user } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        {/* <IconButton
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.openAccountMenu}
          color="inherit"
        >
          {profileImage ? (
            <Avatar
              alt="Remy Sharp"
              src={profileImage}
              className={classes.avatar}
            />
          ) : (
            <AccountCircle />
          )}
        </IconButton> */}
        <Button className={classes.outlinedButton} onClick={this.openAccountMenu} variant="outlined">
          Account
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleLogout}>Logout {user.firstName}</MenuItem>
        </Menu>
      </div>
    );
  };

  renderLoggedOutUserButtons = () => {
    const { classes } = this.props;

    return (
      <Button className={classes.outlinedButton} onClick={this.handleLoginModalOpen} variant="outlined">
        Login
      </Button>
    );
  };

  renderNonMobileButtons = () => {
    const { classes } = this.props;
    const links = this.menuListItems({
      className: classes.textLink,
    });

    return <div className={classes.nonMobileButtonsContainer}>{links}</div>;
  };

  render() {
    const { classes, user, className } = this.props;

    return (
      <AppBar position="absolute" color="default" className={clsx(classes.appBar, className)}>
        <Container className={classes.container} hasInnerPadding={true}>
          <div className={classes.primaryContent}>
            <a href="https://ownerfy.com" className={classes.logoLink}>
              <img className={classes.logoImg} src="/img/logo-horizontal-2.png" alt="logo" />
            </a>

            {this.renderNonMobileButtons()}
          </div>
          {/* <div className={classes.nonMobileUserButtons}>
            {user ? this.renderLoggedInUserButtons() : this.renderLoggedOutUserButtons()}
          </div> */}
          <div>{this.renderMobileMenu()}</div>
        </Container>
      </AppBar>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logoutUser: actions.logoutUser,
      setActiveModal: actions.setActiveModal,
    },
    dispatch,
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Topbar)));

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { AUTH_MODALS } from 'Constants/modals';
import { registerWithFirebase, setAuthModal } from 'Store/actions';
import firebaseService from 'Services/firebaseService';
import Input from 'Shared/Input';
import GlobalButton from 'Shared/Button/index';
import Select from 'Shared/SelectField';

import history from '../../history';
import { useStyles } from './style';

const STATES = require('./us-states.json');

const isUsernameDuplicated = value =>
  new Promise((resolve, reject) => {
    firebaseService
      .isUsernameDuplicated(value)
      .then(isUsernameDuplicated => {
        if (isUsernameDuplicated) {
          resolve(false);
        }
        resolve(true);
      })
      .catch(() => resolve(true));
  });

const SignUp = ({ title, embedded }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [customErrors, setCustomErrors] = useState({
    email: '',
  });

  const signupScheme = Yup.object().shape({
    firstName: Yup.string().required('Provide your first name').max(50, 'Invalid first name'),
    lastName: Yup.string().required('Provide your last name').max(50, 'Invalid last name'),
    email: Yup.string().email('Invalid email').required('Provide your email').max(50, 'Invalid email'),
    username: Yup.string()
      .required('Provide your user name')
      .max(40, 'Invalid country')
      .test('checkDuplication', 'Same name exists', isUsernameDuplicated),
    phoneNumber: Yup.string()
      .nullable()
      .matches(/^\d{9,12}$/, 'Invalid Phone Number'),
    addressLine1: Yup.string().nullable().max(30, 'Invalid address 1'),
    addressLine2: Yup.string().nullable().max(30, 'Invalid address 2'),
    city: Yup.string().nullable().max(20, 'Invalid city'),
    zipcode: Yup.string().nullable().max(12, 'Invalid zipcode'),
    state: Yup.string().nullable().max(20, 'Invalid state'),
    country: Yup.string().nullable().max(20, 'Invalid country'),
    password: Yup.string().required('Provide the password').max(50, 'Invalid country'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Repeat the password'),
    terms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });
  return (
    <div className={classes.loginContainer}>
      <h1 className={classes.modalHeading}>{title}</h1>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          username: '',
          phoneNumber: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          zipcode: '',
          state: '',
          country: '',
          password: '',
          passwordConfirmation: '',
          terms: false,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={signupScheme}
        onSubmit={async (values, { resetForm }) => {
          const { passwordConfirmation, terms, ...rest } = values;
          const submitButton = document.getElementById('submitButton');
          submitButton.disabled = true;

          const result = await dispatch(registerWithFirebase(rest));

          if (result.status === false && result.payload && result.payload.includes('auth/email-already-in-use')) {
            setCustomErrors({
              ...customErrors,
              email: 'Email is already in use.',
            });
          } else if (result && result.payload && result.payload.ethereumAddress) {
            dispatch(setAuthModal(null));
            resetForm();
            if (!embedded) history.push('/my-collection');
          } else {
            setCustomErrors({
              ...customErrors,
              formError: 'There was an error please contact support@ownerfy.com',
            });
          }

          submitButton.disabled = false;
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <div className={classes.formContainer}>
              <div className={classes.userMainInfo}>
                <div className={classes.userInfo}>
                  <div className={classes.flex48}>
                    <Input
                      label="First Name *"
                      name="firstName"
                      touched={touched.firstName}
                      error={errors.firstName}
                      value={values.firstName}
                      data-cy="signup-first-name-input"
                    />
                  </div>
                  <div className={classes.flex48}>
                    <Input
                      label="Last Name *"
                      name="lastName"
                      touched={touched.lastName}
                      error={errors.lastName}
                      value={values.lastName}
                      data-cy="signup-last-name-input"
                    />
                  </div>
                </div>

                <Input
                  label="Email"
                  name="email"
                  touched={touched.email}
                  error={errors.email || customErrors.email}
                  value={values.email}
                  data-cy="signup-email-input"
                />

                <Input
                  label="Username *"
                  name="username"
                  touched={touched.username}
                  error={errors.username}
                  value={values.username}
                  data-cy="signup-username-input"
                />

                <div className={classes.userInfo}>
                  <div className={classes.flex48}>
                    <Input
                      label="Password *"
                      name="password"
                      touched={touched.password}
                      error={errors.password}
                      value={values.password}
                      type="password"
                      data-cy="signup-password-input"
                    />
                  </div>
                  <div className={classes.flex48}>
                    <Input
                      label="Repeat Password *"
                      name="passwordConfirmation"
                      touched={touched.passwordConfirmation}
                      error={errors.passwordConfirmation}
                      value={values.passwordConfirmation}
                      type="password"
                      data-cy="signup-repeat-password-input"
                    />
                  </div>
                </div>

                <Input
                  label="Phone Number ex. 12223334444"
                  name="phoneNumber"
                  type="number"
                  touched={touched.phoneNumber}
                  error={errors.phoneNumber}
                  value={values.phoneNumber}
                />
              </div>

              <div className={classes.userAddressInfo}>
                <Input
                  label="Address line 1 (Required for shipments)"
                  name="addressLine1"
                  touched={touched.addressLine1}
                  error={errors.addressLine1}
                  value={values.addressLine1}
                />

                <Input
                  label="Address line 2"
                  name="addressLine2"
                  touched={touched.addressLine2}
                  error={errors.addressLine2}
                  value={values.addressLine2}
                />

                <Input label="City" name="city" touched={touched.city} error={errors.city} value={values.city} />

                <div className={classes.userInfo}>
                  <div className={classes.flex48}>
                    <Input
                      label="Postal/Zipcode"
                      name="zipcode"
                      touched={touched.zipcode}
                      error={errors.zipcode}
                      value={values.zipcode}
                    />
                  </div>

                  <div className={classes.flex48}>
                    {/* <Select
                      label="State"
                      name="state"
                      options={STATES}
                      touched={touched.state}
                      error={errors.state}
                      value={values.state}
                      onChange
                      ={value => setFieldValue('state', value)}
                    /> */}
                    <Input
                      label="State/Region"
                      name="state"
                      touched={touched.state}
                      error={errors.state}
                      value={values.state}
                    />
                  </div>
                </div>

                {/* <Select
                  label="Country"
                  name="country"
                  options={[{ label: 'United States', value: 'us' }]}
                  touched={touched.country}
                  error={errors.country}
                  value={values.country}
                  onChange={value => setFieldValue('country', value)}
                /> */}

                <Input
                  label="Country"
                  name="country"
                  touched={touched.country}
                  error={errors.country}
                  value={values.country}
                  data-cy="signup-country-input"
                />
              </div>
            </div>

            <div className={classes.loginBtnContainer}>
              <div className={classes.loginHelperText}>
                Already have an account?
                <button
                  className={classes.link}
                  type="button"
                  onClick={() => dispatch(setAuthModal(AUTH_MODALS.LOGIN))}
                >
                  Sign in
                </button>
              </div>
              <div className={classes.loginHelperText}>
                <Field checked={values.terms} type="checkbox" name="terms" data-cy="signup-tos-checkbox" />I have read
                and agree to
                <Link to="#" className={classes.link}>
                  {' '}
                  the terms of service
                </Link>
                {errors.terms && touched.terms && <p className="error">{errors.terms}</p>}
              </div>

              <div className={classes.singUpButton}>
                <GlobalButton btnLabel="Sign Up" id="submitButton" background data-cy="signup-submit-button" />
                {customErrors.formError && <p className="error">{customErrors.formError}</p>}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUp;

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import StarIcon from '@material-ui/icons/Star';

export const Star = ({ className, fontSize = 25 }) => {
  return (
    <StarIcon
      className={className}
      css={css`
        color: #fdce02;
      `}
      style={{ fontSize }}
    />
  );
};

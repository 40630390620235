import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import PageLayout from 'Layout/Layout';
import Container from 'Components/Container';
import SectionLayout from 'Layout/SectionLayout';
import Loading from 'Components/Loading';
import { getCreditPlans, createStripeCheckoutSessionForBuyCredits } from 'Services/api';
import { useSelector } from 'react-redux';
import config from 'Config/firebase';
import { useStyles } from './style';

const BuyCredits = () => {
  const user = useSelector(state => state.user);
  const [creditPlans, setCreditPlans] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCreditPlans = async () => {
      setLoading(true);
      const plans = await getCreditPlans();
      setCreditPlans(plans);
      setLoading(false);
    };

    if (user) {
      fetchCreditPlans();
    }
  }, [user]);

  const handleBuyCredit = useCallback(
    async item => {
      if (!user) return;
      try {
        setLoading(true);
        // eslint-disable-next-line no-undef
        const stripe = Stripe(config.stripe);
        const stripeCheckoutSessionId = await createStripeCheckoutSessionForBuyCredits({
          creditPlanId: item.id,
          cancelUrl: window.location.href,
          successUrl: `${window.location.href}-success`,
        });

        const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
        if (result.error) alert(result.error.message);
        setLoading(false);
      } catch (error) {
        console.log('error: ', error);
        setLoading(false);
      }
    },
    [user],
  );

  if (loading || !creditPlans) {
    return (
      <PageLayout hasContainer={false}>
        <SectionLayout>
          <Container>
            <Loading
              type={'bubbles'}
              color={'#2bc8c5'}
              height={'80px'}
              width={'80px'}
              containerHeight={'40vh'}
              containerWidth={'100%'}
              message="Loading..."
            />
          </Container>
        </SectionLayout>
      </PageLayout>
    );
  }

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <Box style={{ textAlign: 'center', paddingBottom: 32 }}>
            <Typography style={{ fontFamily: 'Lato', color: '#323441' }} variant="h1">
              Buy NFT Credits
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {creditPlans.map((item, index) => (
              <BuyCard key={index} item={item} onBuy={() => handleBuyCredit(item)} />
            ))}
          </Grid>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
};

const BuyCard = ({ item, onBuy }) => {
  const classes = useStyles();

  return (
    <Grid item md={4} xs={12}>
      <Paper style={{ borderRadius: '24px' }}>
        <Box p={4} display="flex" flexDirection="column" alignItems="center">
          <Box className={classes.creditName}>{item.name}</Box>
          <Box className={classes.creditCount}>{item.nftCredits}</Box>
          <Box className={classes.creditDescription}>Create or send {item.nftCredits} NFTs</Box>
          <Button onClick={onBuy} fullWidth className={classes.creditButton}>
            ${item.price}
          </Button>
        </Box>
      </Paper>
    </Grid>
  );
};

export default BuyCredits;

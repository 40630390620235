import React from 'react';
import { Box } from '@material-ui/core';
import Container from 'Components/Container';
import OpenSea from './assets/openSea.svg';
import Rarible from './assets/rarible.svg';
import Alchemy from './assets/alchemy.svg';
import Polygon from './assets/polygon.svg';
import Ethereum from './assets/ethereum.svg';
import Stripe from './assets/stripe.svg';
import { useStyles } from './style';
const Partners = () => {
  const classes = useStyles();
  return (
    <section className={classes.partnersContainer}>
      <Container>
        <Box pt={5}>
          {/* <h1 className={classes.sectionTitle}>Partners</h1> */}
          <div className={classes.partners}>
            <div className={classes.partner}>
              <img src={OpenSea} alt="Not found" />
            </div>
            <div className={classes.partner}>
              <img src={Rarible} alt="Not found" />
            </div>
            <div className={classes.partner}>
              <img src={Alchemy} alt="Not found" />
            </div>
            <div className={classes.partner}>
              <img src={Polygon} alt="Not found" />
            </div>
            <div className={classes.partner}>
              <img src={Ethereum} alt="Not found" />
            </div>
            <div className={classes.partner}>
              <img src={Stripe} alt="Not found" />
            </div>
          </div>
        </Box>
      </Container>
    </section>
  );
};

export default Partners;

import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button as MuiButton, Box, MenuItem, Select, OutlinedInput, CircularProgress } from '@material-ui/core';
import { generateValidationToken, addWallet, removeWallet, fetchUserCredits, getWallets } from 'Services/api';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';

import * as actions from 'Store/actions';
import firebaseService from 'Services/firebaseService';
import Container from 'Components/Container';
import SwitchButton from 'Components/SwitchButton';
import Input from 'Shared/Input';
import Button from 'Shared/Button';
import Modal from 'Shared/Modal';
import history from '../../history';
import EthIcon from './assets/ethIcon.svg';
import { checkCoinBalance } from 'Services/api';
import { formatWithDecimal } from 'Helpers/tools';
import { useStyles } from './style';

const Setting = ({ user, updateUserData, setActiveModal }) => {
  const classess = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteUserModal, setDeleteUserModal] = useState(false);
  const [ethPrivateKey, setEthPrivateKey] = useState();
  const [ethPublicKey, setEthPublicKey] = useState();
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [wallets, setWallets] = useState([]);

  const [addressFormValue, setAddressFormValue] = useState('');
  const [wasUpdateSuccessful, setWasUpdateSuccessful] = useState(false);
  const [isAddressUpdate, setIsAddressUpdate] = useState(false);
  const [ethBalance, setEthBalance] = useState(0);

  const getUserWallets = useCallback(async () => {
    const wallets = await getWallets();

    setWallets(wallets);
  }, []);

  const handleSwitch = isOn => {
    updateUserData({
      userId: user.uid,
      updateData: { isNftMailOff: isOn },
    });
  };

  const handleAddWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        const from = accounts[0];

        console.log('connected wallet is :: ', from);

        let validationToken = await generateValidationToken({
          address: from,
        });

        const string = `Ownerfy: ${from.toLowerCase()} ${validationToken}`;

        const msg = `0x${Buffer.from(string, 'utf8').toString('hex')}`;
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [msg, from],
        });

        let added = await addWallet({
          address: from,
          signature,
        });

        getUserWallets();
      } catch (err) {
        alert('connection failed');
      }
    } else {
      alert('Metamask is not installed. Please install the Metmask plugin or the credit card option to purchase');
    }
  };

  const handleRemoveWallet = async address => {
    try {
      let removed = await removeWallet({ address });
      getUserWallets();
    } catch (err) {
      console.warn(err);
      alert('Error removing wallet');
    }
  };

  const settingScheme = Yup.object().shape({
    userName: Yup.string().required('Provide your user name'),
    phoneNumber: Yup.string().required('Provide your phone number'),
  });

  const restFormScheme = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Provide your email'),
  });

  const addressFormScheme = Yup.object().shape(
    {
      addressLine1: addressFormValue ? Yup.string().required('Provide your address') : Yup.string(),
      addressLine2: Yup.string(),
      country: Yup.string().when('addressLine1', {
        is: addressLine1 => addressLine1,
        then: Yup.string().required('Please select country'),
      }),
      city: Yup.string().when('addressLine1', {
        is: addressLine1 => addressLine1,
        then: Yup.string().required('Provide your city'),
      }),
      zipCode: Yup.string().when('addressLine1', {
        is: addressLine1 => addressLine1,
        then: Yup.string().required('Provide your zip code'),
      }),
      state: Yup.string().when('addressLine1', {
        is: addressLine1 => addressLine1,
        then: Yup.string().required('Provide your state'),
      }),
    },
    ['addressLine1', 'zipCode'],
  );

  useEffect(() => {
    const getKeyPhrase = async () => {
      const { ethPrivKey } = await firebaseService.getKeyPhrase();
      setEthPrivateKey(ethPrivKey);
    };

    const getUserCredits = async () => {
      const credits = await fetchUserCredits();
      setUserCredits(credits.nftCredits);
    };

    const getBalance = async () => {
      const balance = await checkCoinBalance();
      setEthBalance(Number(balance));
    };

    if (user) {
      getBalance();
      getKeyPhrase();
      getUserCredits();
      getUserWallets();

      const pub = get(user, 'wallets[0].pub');
      setEthPublicKey(pub);
    }
  }, [user, getUserWallets]);

  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;

    return (
      <Select
        name={name}
        value={value}
        input={<OutlinedInput className={classess.input} />}
        fullWidth
        renderValue={value =>
          value !== '' ? value : <span className={classess.placeHolder}>Please select country</span>
        }
        displayEmpty
        onChange={e => {
          setFieldValue(name, e.target.value);
        }}
      >
        {children}
      </Select>
    );
  };

  const handleDeleteUser = () => {
    firebaseService.deleteUser(user.uid).then(() => {
      history.push('/');
    });
  };

  return (
    <div className={classess.settingContainer}>
      {showModal && (
        <Modal open={showModal} heading="Reset Password" onClose={() => setShowModal(false)}>
          <p className={classess.modalDesc}>
            If you want to change your password, we'll send you a link to reset your password.
          </p>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={restFormScheme}
            onSubmit={(values, { resetForm }) => {
              resetForm();
              console.log(values);
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <Input name="email" label="Email" touched={touched.email} error={errors.email} value={values.email} />
                <div className={classess.btnContainer}>
                  <Button btnLabel="Reset Password" background />
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
      {user ? (
        <Container>
          <div className={classess.setting}>
            <div className={`${classess.flex48} ${classess.settingInputCard}`}>
              <div className={classess.settingHeader}>
                <h1 className={classess.profileTitle}>Account Information</h1>
                <p className={classess.profileDesc}>Update your account Information</p>
                <p className={classess.profileDesc}>Email: {user && user.email}</p>
              </div>
              <div className={classess.settingContent}>
                <Formik
                  initialValues={{
                    userName: user.username,
                    phoneNumber: user.phoneNumber,
                  }}
                  validationSchema={settingScheme}
                  onSubmit={async values => {
                    try {
                      let _data = {
                        username: values.userName,
                        phoneNumber: values.phoneNumber,
                      };
                      updateUserData({
                        userId: user.uid,
                        updateData: _data,
                      });
                      setWasUpdateSuccessful(true);
                    } catch (error) {
                      setWasUpdateSuccessful(false);
                      console.warn('User update error: ', error);
                      alert('There was an error updating your user information');
                      return;
                    }
                  }}
                >
                  {({ errors, touched, values }) => (
                    <Form>
                      <div className={classess.contentWrapper}>
                        <div>
                          <Input
                            name="userName"
                            label="Username"
                            touched={touched.userName}
                            error={errors.userName}
                            value={values.userName}
                          />
                          <Input
                            name="phoneNumber"
                            label="Phone Number"
                            touched={touched.phoneNumber}
                            error={errors.phoneNumber}
                            value={values.phoneNumber}
                          />
                        </div>
                        <div className={classess.btnContainer}>
                          <Button btnLabel="Update" background />
                          {wasUpdateSuccessful && <span className={classess.successMsg}>Updated successfuly!</span>}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className={`${classess.flex48} ${classess.settingInputCard}`}>
              <div className={classess.settingHeader}>
                <h1 className={classess.profileTitle}>Address</h1>
                <p className={classess.profileDesc}>
                  Service is available only for<b> US residents</b> for now
                </p>
              </div>
              <div className={classess.settingContent}>
                <Formik
                  initialValues={{
                    addressLine1: user.address1?.addressLine1 || '',
                    addressLine2: user.address1?.addressLine2 || '',
                    country: 'United States',
                    city: user.address1?.city || '',
                    zipCode: user.address1?.zipcode || '',
                    state: user.address1?.state || '',
                  }}
                  validationSchema={addressFormScheme}
                  onSubmit={async values => {
                    try {
                      let _data = {
                        'address1.addressLine1': values.addressLine1,
                        'address1.addressLine2': values.addressLine2,
                        'address1.city': values.city,
                        'address1.zipcode': values.zipCode,
                        'address1.state': values.state,
                      };
                      updateUserData({
                        userId: user.uid,
                        updateData: _data,
                      });
                      setIsAddressUpdate(true);
                    } catch (error) {
                      setWasUpdateSuccessful(false);
                      console.warn('User update error: ', error);
                      alert('There was an error updating your user information');
                      return;
                    }
                  }}
                >
                  {({ errors, touched, values }) => {
                    setAddressFormValue(values.addressLine2 || values.city || values.zipCode || values.state);
                    return (
                      <Form>
                        <div className={classess.contentWrapper}>
                          <div>
                            <Input
                              name="addressLine1"
                              label="Address Line1"
                              touched={touched.addressLine1}
                              error={errors.addressLine1}
                              value={values.addressLine1}
                            />
                            <Input
                              name="addressLine2"
                              label="AddressLine 2"
                              touched={touched.addressLine2}
                              error={errors.addressLine2}
                              value={values.addressLine2}
                            />
                            <div className="inputContainer">
                              <Field name="country" component={CustomizedSelectForFormik}>
                                <MenuItem value="United States">United States</MenuItem>
                              </Field>
                              {errors.country && touched.country && <p className="error">{errors.country}</p>}
                            </div>
                            <Input
                              name="city"
                              label="City"
                              touched={touched.city}
                              error={errors.city}
                              value={values.city}
                            />
                            <div className={classess.contentLine}>
                              <div className={classess.flex48}>
                                <Input
                                  name="zipCode"
                                  label="Zip Code"
                                  touched={touched.zipCode}
                                  error={errors.zipCode}
                                  value={values.zipCode}
                                />
                              </div>
                              <div className={classess.flex48}>
                                <Input
                                  name="state"
                                  label="State"
                                  touched={touched.state}
                                  error={errors.state}
                                  value={values.state}
                                />
                              </div>
                            </div>
                          </div>
                          <div className={classess.btnContainer}>
                            <Button btnLabel="Update" background />
                            {isAddressUpdate && <span className={classess.successMsg}>Updated successfuly!</span>}
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className={classess.settingCardContainer}>
            <div className={classess.settingCard}>
              <h4 className={classess.cardTitle}>Password</h4>
              <p className={classess.cardDesc}>
                If you want to change your password, we'll send you a link to reset your password
              </p>
              <div className={classess.cardLink} onClick={() => setShowModal(true)}>
                Reset Password
              </div>
            </div>
            <div className={classess.settingCard}>
              <h4 className={classess.cardTitle}>Wallet Public Address</h4>
              <p className={classess.cardDesc}>{ethPublicKey}</p>
            </div>
            <div className={classess.settingCard}>
              <h4 className={classess.cardTitle}>Wallet Private Key</h4>
              <p className={classess.cardDesc}>
                Don't share this private key with anybody. Whoever has access to this key can do anything with your
                NFTs.
              </p>
              <div className={classess.cardItem}>
                <OutlinedInput
                  type={showPrivateKey ? 'text' : 'password'}
                  value={ethPrivateKey}
                  className={classess.cardInput}
                />
                <p onClick={() => setShowPrivateKey(prev => !prev)} className={classess.cardLink}>
                  {showPrivateKey ? 'Hide' : 'Show'}
                </p>
              </div>
            </div>
          </div>
          <div className={classess.setting}>
            <div
              className={`${classess.flex48} ${classess.settingInputCard} ${classess.nftMailCard} ${classess.padding35}`}
            >
              <div className={classess.balanceContainer}>
                <h4 className={classess.cardTitle}>NFT Mail</h4>

                <SwitchButton
                  className={classess.switchButton}
                  handler={handleSwitch}
                  startValue={!user.isNftMailOff}
                />
              </div>
              <p className={classess.cardDesc}>
                Turning NFT mail off will prevent all verifications and communications via NFTs. Adding new addresses
                will allow you to receive email and verifications for ethereum and polygon NFTs owned by that address.
                You must have the new address active on the Metamask extension to add it.
              </p>
              {wallets.length ? (
                wallets.map(wallet => (
                  <div className={classess.addresses}>
                    <p className={classess.balance}>
                      <span className={classess.nftMailValue}>{wallet.address}</span>
                    </p>
                    {wallet.primary !== true && (
                      <MuiButton
                        onClick={() => {
                          handleRemoveWallet(wallet.address);
                        }}
                        fullWidth
                        variant="contained"
                        className={classess.removeButton}
                      >
                        Remove
                      </MuiButton>
                    )}
                  </div>
                ))
              ) : (
                <div>Loading...</div>
              )}
              <MuiButton onClick={handleAddWallet} fullWidth variant="contained" className={classess.addButton}>
                Add
              </MuiButton>
            </div>

            <div className={`${classess.flex48} ${classess.settingInputCard} ${classess.padding35}`}>
              <h4 className={classess.cardTitle}>Your NFT Credits</h4>
              <div className={classess.balanceContainer} style={{ borderBottom: 'none' }}>
                <p className={classess.balance}>
                  <span className={classess.balanceValue} style={{ color: '#21D7BB' }}>
                    {userCredits}
                  </span>
                  Credits
                </p>
              </div>
              <div className={classess.balanceContainer} style={{ borderBottom: 'none' }}>
                <div className={classess.btnCretits}>
                  <Button
                    handleClick={() => (window.location.href = './buy-credits')}
                    btnLabel="Buy Credits"
                    background
                  />
                </div>
              </div>
            </div>

            <div className={`${classess.flex48} ${classess.settingInputCard} ${classess.padding35}`}>
              <h4 className={classess.cardTitle}>Your Balance</h4>
              <div className={classess.balanceContainer}>
                <div className={classess.balanceInline}>
                  <img src={EthIcon} alt="ETH" />
                  <p className={classess.balance}>
                    <span className={classess.balanceValue}>{formatWithDecimal(ethBalance)}</span>ETH
                  </p>
                </div>
                <div>
                  <Button
                    btnLabel="Transfer"
                    background
                    handleClick={() => {
                      setActiveModal('sendEthereum');
                    }}
                  />
                </div>
              </div>
              {/* <div className={classess.balanceContainer} style={{ borderBottom: 'none' }}>
                <div className={classess.balanceInline}>
                  <img src={MaticIcon} alt="MaticIcon" />
                  <p className={classess.balance}>
                    <span className={classess.balanceValue}>214</span>MATIC
                  </p>
                </div>
                <div>
                  <Button btnLabel="Transfer" background />
                </div>
              </div> */}
            </div>

            <Modal
              open={showDeleteUserModal}
              heading="Delete User"
              onClose={() => setDeleteUserModal(false)}
              data-cy="delete-modal"
            >
              <p className={classess.modalDesc} align="center">
                Are you sure?
              </p>
              <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <Button
                  btnLabel="Delete"
                  small
                  background
                  handleClick={handleDeleteUser}
                  data-cy="delete-submit-button"
                />
              </Box>
            </Modal>
          </div>
          <div className={classess.btnDelete} onClick={() => setDeleteUserModal(true)} data-cy="delete-modal-button">
            Delete Account
          </div>
        </Container>
      ) : (
        <div className={classess.circularProgress}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};
// export default Setting;

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setActiveModal: actions.setActiveModal,
      updateUserData: actions.updateUserData,
      passwordReset: actions.passwordReset,
    },
    dispatch,
  );
}

function mapStateToProps({ user }) {
  return {
    user,
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Setting));

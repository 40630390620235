/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

export const InfoArea = ({ title, children, className, hasSeparator = true, rightContent }) => {
  const theme = useTheme();

  return (
    <div className={className}>
      <div
        css={css`
          border-bottom: 1px solid #d8d8d8;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 10px;
          ${theme.breakpoints.up('sm')} {
            padding-bottom: 12px;
          }
        `}
      >
        <Typography
          variant="body1"
          component="h2"
          css={css`
            color: #4d5153;
            font-family: Montserrat;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.11px;
            line-height: 18px;
            ${theme.breakpoints.up('sm')} {
              font-size: 18px;
              letter-spacing: 0.14px;
              line-height: 22px;
            }
          `}
        >
          {title}
        </Typography>
        {rightContent}
      </div>
      <div
        css={css`
          margin-top: 12px;
          padding-bottom: 14px;
          border-bottom: ${hasSeparator ? '1' : '0'}px solid #ededed;
          padding-left: 5px;
          padding-right: 5px;
          ${theme.breakpoints.up('sm')} {
            padding-left: 10px;
            padding-right: 10px;
          }
        `}
      >
        {children}
      </div>
    </div>
  );
};

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useStyles } from './style';

const navigateTo = href => () => {
  window.location.href = href;
};

const DropdownMenuItem = ({ label, href, type, name, orgUrl }) => {
  const classes = useStyles();

  if (type === 'external') {
    return (
      <div className={classes.menuItem} onClick={navigateTo(href)}>
        {label}
      </div>
    );
  }

  return (
    <RouterLink to={href} className={classes.menuItem}>
      {label}
    </RouterLink>
  );
};

export default DropdownMenuItem;

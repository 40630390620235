import React, { useEffect, useState } from 'react';
import { Paper, Button, Box, CircularProgress, Link, Popover } from '@material-ui/core';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import Container from 'Components/Container';
import { fetchAPIUsage, refreshAPIKey, createStripeCustomerPortalSession } from 'Services/api';
import { useStyles } from './style';
import EthereumIcon from './assets/ethereum.svg';
import PolygonIcon from './assets/polygon.svg';
import Copy from './assets/copy.svg';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ApiUsage = () => {
  const classess = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(state => state.user);
  const [usages, setUsages] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const getUsages = async () => {
    const data = await fetchAPIUsage();
    setUsages(data);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefreshKey = async () => {
    setRefreshing(true);
    const updated = await refreshAPIKey();
    if (updated) await getUsages();
    setRefreshing(false);
  };

  const handleManageBilling = async () => {
    const portalOptions = await createStripeCustomerPortalSession();
    window.open(portalOptions.url);
  };

  const handleChangePlan = () => {
    history.push('/api-signup');
  };

  useEffect(() => {
    if (user) {
      getUsages();
    }
  }, [user]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container>
          <div className={classess.sectionHeader}>
            <h1 className={classess.heading}>Your API’s Usage</h1>
            <Box display="flex" justifyContent="center">
              <Button variant="text" onClick={handleChangePlan}>
                Change Plan
              </Button>
              {/* <Button variant="text" onClick={handleManageBilling}>
                See payment history
              </Button> */}
            </Box>

            <p>
              <a href="https://api.ownerfy.com" target="new">
                Ownerfy API Docs
              </a>
            </p>

            <p>
              <a href="https://api.ownerfy.com" target="new">
                Ownerfy Readme
              </a>
            </p>

            <p>For support reach out at support@ownerfy.com</p>
          </div>

          <div className={classess.tableContainer}>
            <div className={classess.table}>
              <div className={classess.TableHeaderRow}>
                <span className={classess.tableCell}>Network</span>
                <span className={classess.tableCell}>NFTs Minted</span>
                <span className={classess.tableCell}>NFTs Transferred</span>
                <span className={classess.tableCell}>Month / Year</span>
                <span className={classess.tableCell}>Wallets Created</span>
                <span className={classess.tableCell}>Plan</span>

                <span className={classess.tableCell}>API Key</span>
              </div>

              {usages.map((item, index) => {
                let network_icon = EthereumIcon;
                if (item.network === 'polygon') {
                  network_icon = PolygonIcon;
                }

                return (
                  <div key={index} className={classess.row}>
                    <span className={classess.tableCell}>
                      <img className={classess.networkIcon} src={network_icon} alt="Etherium or Polygon" />
                      <span>{item.network}</span>
                    </span>
                    <span className={classess.tableCell}>
                      {item.nftsMinted}/{item.maxMints}
                    </span>
                    <span className={classess.tableCell}>
                      {item.nftsTransferred}/{item.maxTransfers}
                    </span>
                    <span className={classess.tableCell}>
                      {item.month}/{item.year}
                    </span>
                    <span className={classess.tableCell}>
                      {item.walletsCreated}/{item.maxWallets}
                    </span>
                    <span className={classess.tableCell}>{item.planName}</span>

                    <span className={classess.tableCell}>
                      <Link
                        aria-describedby={id}
                        onClick={handleClick}
                        className={classess.linkWithBorder}
                        underline="none"
                        data-cy="view-api-key-button"
                      >
                        View Key
                      </Link>
                    </span>
                  </div>
                );
              })}

              {!!usages[0]?.apiKey && (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <Paper elevation={10} className={classess.apiKeyBox}>
                    <h6 className={classess.apiKeyBoxLabel}>API Key</h6>
                    {refreshing ? (
                      <Box style={{ width: 666 }} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <div className={classess.apiKeyContainer}>
                          <span className={classess.apiKeyValue} data-cy="api-key-span">
                            {usages[0].apiKey}
                          </span>
                          <div
                            className={classess.apiKeyCopyClipboard}
                            onClick={() => navigator.clipboard.writeText(usages[0].apiKey)}
                          >
                            <img src={Copy} alt="Copy" />
                            <span>Copy</span>
                          </div>
                        </div>
                        <div className={classess.actions}>
                          <Button onClick={handleRefreshKey} variant="contained">
                            Refresh
                          </Button>
                        </div>
                      </>
                    )}
                  </Paper>
                </Popover>
              )}
            </div>
          </div>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
};

export default ApiUsage;

import config from 'Config/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import uuid from 'uuid';
import axios from './axios';

const firebaseService = {
  firebaseService: {},

  init() {
    if (firebase.apps.length) {
      return;
    }

    firebase.initializeApp(config);
    firebaseService.db = firebase.firestore();
    firebaseService.auth = firebase.auth();
    // set this only if env REACT_APP_EMULATOR_AUTH is true
    if (process.env.REACT_APP_EMULATOR_AUTH === 'true') {
      firebaseService.auth.useEmulator('http://127.0.0.1:9099');
    }

    firebaseService.rtdb = firebase.database();
  },

  getUserData: async uid => {
    if (!uid || !firebase.apps.length) {
      return;
    }

    try {
      const userSnap = await firebaseService.db.collection('users').doc(uid).get();
      const user = userSnap.data();
      return user;
    } catch (err) {
      throw new Error('Error getting user data in: ', err);
    }
  },

  // This will now only work from an owner
  getItem: async id => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const snapshotData = await firebaseService.db.collection('items').doc(id).get();
      return snapshotData.data();
    } catch (err) {
      throw new Error('Error getting product from firebase: ', err);
    }
  },

  updateItem: async item => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const updateRes = await firebaseService.db.collection('items').doc(item.itemId).update(item);
      return updateRes;
    } catch (err) {
      console.warn(err);
      throw new Error(err);
    }
  },

  registerProduct: async regData => {
    const _regData = {
      ...regData,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    try {
      await firebaseService.db.collection('registrations').add(_regData);
      return true;
    } catch (err) {
      throw new Error('Error registering product in database: ', err);
    }
  },

  getKeyPhrase: async () => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const response = await axios.post('/getKeyPhrase');
      return response.data;
    } catch (error) {
      console.warn('Error on server getting keys: ', error);
      throw Error(error);
    }
  },

  getRegistrationCode: async regCodeId => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const response = await axios.post('/getRegCode', { regCodeId });
      return response.data;
    } catch (error) {
      console.warn('Error on server getting reg code: ', error);
      throw Error(error);
    }
  },

  getCustomerData: async (regCodeId, page, limit) => {
    if (!firebase.apps.length) {
      return;
    }
    let snapshotData;
    // console.log(
    //   'page and limit are :: ',
    //   page,
    //   limit,
    //   (page - 1) * limit,
    //   firebaseService.auth.currentUser.uid,
    //   regCodeId,
    // );
    try {
      // Todo add filters
      if (regCodeId) {
        snapshotData = await firebaseService.db
          .collection('registrations')
          .where('formOwner', '==', firebaseService.auth.currentUser.uid)
          .where('regCodeId', '==', regCodeId)
          .get();
      } else {
        snapshotData = await firebaseService.db
          .collection('registrations')
          .where('formOwner', '==', firebaseService.auth.currentUser.uid)
          .get();
      }

      return snapshotData.docs.map(doc => doc.data());
    } catch (err) {
      throw new Error('Error getting customer registrations: ', err);
    }
  },

  getUserRegCodes: async regCodeId => {
    if (!firebase.apps.length) {
      return;
    }
    try {
      const snapshotData = await firebaseService.db
        .collection('regCodes')
        .where('ownerId', '==', firebaseService.auth.currentUser.uid)
        .get();
      return snapshotData.docs.map(doc => doc.data());
    } catch (err) {
      throw new Error('Error getting user reg codes: ', err);
    }
  },

  createRegistrationCode: async regCodeData => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const response = await axios.post('/createRegCode', regCodeData);
      return response.data;
    } catch (error) {
      console.warn('Error on server getting reg code: ', error);
      throw Error(error);
    }
  },

  updateRegistrationCode: async formData => {
    if (!firebase.apps.length) {
      return;
    }

    let _strippedInfo = Object.assign({}, formData);
    // add created at
    delete _strippedInfo.template;

    try {
      let updateRes = await firebaseService.db
        .collection('regCodes')
        .doc(formData.regCodeId)
        .update(
          {
            ...formData,
          },
          { merge: true },
        );
      return updateRes.regCodeId;
    } catch (err) {
      throw new Error('Error updating registration code in database: ', err);
    }
  },

  getUserItems: async () => {
    if (!firebase.apps.length || !firebaseService?.auth?.currentUser?.uid) {
      return;
    }

    try {
      const snapshotData = await firebaseService.db
        .collection('items')
        .where('ownerId', '==', firebaseService.auth.currentUser.uid)
        .get();
      const itemsArray = snapshotData.docs.map(doc => doc.data());

      // this is so people can see their freshly claimed items right away
      let claimsArray = [];
      try {
        const claimSnapshotData = await firebaseService.db
          .collection('items')
          .where('claimerId', '==', firebaseService.auth.currentUser.uid)
          .get();
        claimsArray = claimSnapshotData.docs.map(doc => doc.data());
      } catch (err) {
        console.warn('Error getting claims people can see right away:: ', err);
      }

      const combinedItemsArray = [...itemsArray, ...claimsArray];

      // Get template IDs from items
      let templateIds = new Set();
      for (const item of combinedItemsArray) {
        if (item.templateId) templateIds.add(item.templateId);
      }

      // fetch template IDs and set default to itemsArray
      let completeItemsArray = combinedItemsArray;

      if (templateIds.size > 0) {
        let templateObjects = {};
        for (const templateId of templateIds) {
          const objectSnap = await firebaseService.db.collection('itemTemplates').doc(templateId).get();
          templateObjects[templateId] = objectSnap.data();
        }

        // Stitch in missing template data
        completeItemsArray = combinedItemsArray.map(item => {
          let _item = item;
          if (item.templateId) _item = { ...templateObjects[item.templateId], ...item };
          return _item;
        });
      }

      return completeItemsArray;
    } catch (err) {
      throw new Error('Error getting user items from firebase: ', err);
    }
  },

  getUserTransactions: async () => {
    if (!firebase.apps.length || !firebaseService?.auth?.currentUser?.uid) {
      return;
    }

    try {
      const snapshotData = await firebaseService.db
        .collection('transactions')
        .where('ownerId', '==', firebaseService.auth.currentUser.uid)
        .get();
      const transactionsArr = snapshotData.docs.map(doc => doc.data());

      return transactionsArr;
    } catch (err) {
      throw new Error('Error getting user items from firebase: ', err);
    }
  },

  // currently this is just getting all the products
  // we'll probably want to do some kind of filter in the
  // future
  getProductData: async () => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const snapshotData = await firebaseService.db.collection('products').get();
      return snapshotData.docs.map(doc => doc.data());
    } catch (err) {
      throw new Error('Error getting products from firebase: ', err);
    }
  },

  getPlanData: async () => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const snapshotData = await firebaseService.db.collection('plans').where('type', '==', 'registration').get();

      return snapshotData.docs.map(doc => doc.data());
    } catch (err) {
      console.warn('Error getting plans from firebase: ', err);
    }
  },

  createUserData: async user => {
    try {
      const response = await axios.post('/newUser', user);
      return response.data;
    } catch (error) {
      console.warn('Error on server getting reg code: ', error);
      throw Error(error);
    }
  },

  deleteUser: async userId => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const deletedAt = firebase.firestore.FieldValue.serverTimestamp();
      await firebaseService.updateUserData({ userId, updateData: { deletedAt } });

      await firebase.auth.currentUser.delete();
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  },

  isUsernameDuplicated: async username => {
    try {
      const response = await axios.get(`/checkUsernameDuplication/${username}`);
      const { duplicated } = response.data;

      return duplicated;
    } catch (error) {
      console.warn('Error on checking the username duplication');
      throw Error(error);
    }
  },

  isOwnerValid: async ethAddr => {
    try {
      const response = await axios.get(`/checkOwnerValid/${ethAddr}`);
      return response;
    } catch (error) {
      console.warn('Error on checking the owner valid');
      throw Error(error);
    }
  },

  sendMessageToOwner: async params => {
    try {
      const response = await axios.post(`/sendMessageToOwner`, params);
      return response;
    } catch (error) {
      console.warn('Error on sending a message to the Owner');
      throw Error(error);
    }
  },

  updateUserData: async ({ userId, updateData }) => {
    if (!firebase.apps.length) {
      return;
    }

    try {
      const updateRes = await firebaseService.db.collection('users').doc(userId).update(updateData);

      return updateRes;
    } catch (err) {
      console.warm(err);
      throw new Error(err);
    }
  },

  onAuthStateChanged: callback => {
    if (!firebaseService.auth) {
      return;
    }
    firebaseService.auth.onAuthStateChanged(callback);
  },

  signOut: async () => {
    if (!firebaseService.auth) {
      return;
    }
    firebaseService.auth.signOut();
  },

  resetPassword: async email => {
    return firebaseService.auth.sendPasswordResetEmail(email);
  },

  uploadImages: async ({ images, userId, isThumbs }) => {
    if (!firebase.apps.length) {
      return;
    }
    const storageRef = firebase.storage().ref();
    const promisesArr = [];
    const downloadLinks = [];

    images.forEach(img => {
      let imageId = isThumbs ? `${uuid.v4()}_thumb` : uuid.v4();
      let imageRef = storageRef.child(`/images/${userId}/${imageId}`);
      promisesArr.push(
        imageRef
          .putString(img, 'data_url')
          .then(imgRes => {
            return imgRes.ref.getDownloadURL();
          })
          .then(downloadURL => {
            downloadLinks.push(downloadURL);
          }),
      );
    });

    return Promise.all(promisesArr).then(promisesRes => {
      // promisesRes alwars returns an array of null
      return downloadLinks;
    });
  },
};

firebaseService.init();

export default firebaseService;

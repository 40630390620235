import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '50px',
    maxWidth: '150px',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      border: 'none',
    },
    '& .MuiFormLabel-root': {
      color: '#323441',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: 'bold',
      paddingLeft: '15px',
      marginTop: '4px',
    },
    '& .MuiInputLabel-formControl': {
      transform: 'translate(0, 28px) scale(1)',
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: 'red !important',
    },
    '& .MuiSelect-selectMenu': {
      paddingLeft: '60px',
      fontSize: '14px',
      color: '#748896',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
      display: 'none',
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none',
    },
  },

  arrow: {
    position: 'absolute',
    top: '20%',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
};

export { useStyles, MenuProps };

import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import BestBuddiesImg from '../assets/BestBuddies.png';
import useStyles from './style';

const BestBuddiesSection = () => {
  const classes = useStyles();

  return (
    <Box px={2} className={classes.bestBuddiesSection}>
      <Container>
        <Grid container display="flex" alignItems="center" justifyContent="center" spacing={8}>
          <Grid item xs={12} md={5}>
            <img src={BestBuddiesImg} width="100%" alt="Best Buddies" />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box mb={2} className={classes.bestBuddiesTitle}>
              We are donating to A Global BEST BUDDIES international
            </Box>
            <Box mb={2} className={classes.bestBuddiesDescription}>
              Founded in 1989 by Anthony K. Shriver, Best Buddies is a vibrant organization that has grown from one
              original chapter to nearly 3,000 chapters worldwide, positively impacting the lives of over 1.3 million
              people with and without IDD. Best Buddies programs engage participants in each of the 50 states and in 56
              countries and territories around the world
            </Box>
            <Box className={classes.bestBuddiesLearn}>
              <a target="new" href="https://www.bestbuddies.org/">
                Best Buddies
              </a>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BestBuddiesSection;

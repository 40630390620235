import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(4, 0, 4),
  },
  toolBar: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  formField: {
    marginBottom: theme.spacing(0),
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
});

function Modal({
  classes,
  title,
  onFormSubmit,
  formContents,
  footerContents,
  buttonText,
  containerMaxWidth = 'xs',
  IconComponent = LockOutlinedIcon,
  isButtonDisabled = false,
}) {
  return (
    <Container component="main" maxWidth={containerMaxWidth} disableGutters>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <IconComponent />
        </Avatar>

        <Typography component="h1" variant="h5" className={classes.title}>
          {title}
        </Typography>

        <form onSubmit={onFormSubmit} className={classes.form}>
          {formContents}
          {buttonText && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isButtonDisabled}
            >
              {buttonText}
            </Button>
          )}
        </form>

        {footerContents && <div className={classes.modalFooter}>{footerContents}</div>}
      </div>
    </Container>
  );
}

export default withStyles(styles)(Modal);

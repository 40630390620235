/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { Menu } from 'Components/Menu';
import { shareFacebook } from 'Helpers/share/facebook';
import { shareTwitter } from 'Helpers/share/twitter';
import { shareEmail } from 'Helpers/share/email';
import { copyUrl } from 'Helpers/share/copy-url';
import { useTheme } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import LaunchIcon from '@material-ui/icons/Launch';

export const SocialShareButton = ({ socialShareMessage, nftPageUrl }) => {
  const theme = useTheme();

  return (
    <Menu
      menuItems={[
        {
          icon: (
            <FacebookIcon
              css={css`
                color: #626262;
                font-size: 25px;
              `}
            />
          ),
          key: 1,
          text: 'Share on Facebook',
          onClick: () => {
            console.log('Launch Facebook Share Dialog');
            shareFacebook({
              socialShareType: 'share',
              socialShareUrl: nftPageUrl,
              socialShareVia: '688129461275981',
            });
          },
        },
        {
          icon: (
            <TwitterIcon
              css={css`
                color: #626262;
                font-size: 25px;
              `}
            />
          ),
          key: 2,
          text: 'Share on Twitter',
          onClick: () => {
            console.log('Launch Twitter Share Dialog');
            shareTwitter({
              socialShareText: socialShareMessage,
            });
          },
        },
        {
          icon: (
            <EmailIcon
              css={css`
                color: #626262;
                font-size: 25px;
              `}
            />
          ),
          text: 'Share on Email',
          key: 3,
          onClick: () => {
            console.log('Launch Email Share Dialog');
            shareEmail({
              socialShareSubject: socialShareMessage,
              socialShareBody: window.location.href,
            });
          },
        },
        {
          icon: (
            <LaunchIcon
              css={css`
                color: #626262;
                font-size: 25px;
              `}
            />
          ),
          key: 4,
          text: 'Copy Link',
          onClick: ({ closeMenu }) => {
            console.log('Copy URL to clipboard');
            copyUrl(nftPageUrl);
            closeMenu();
          },
        },
      ]}
    >
      <img
        src="/img/share.png"
        alt="more button"
        css={css`
          width: 20px;
          ${theme.breakpoints.up('sm')} {
            width: 27px;
          }
        `}
      />
    </Menu>
  );
};

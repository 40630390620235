import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { ReactComponent as ProductBackgroundIcon } from '../assets/Product.svg';
import { ReactComponent as LogoIcon } from '../assets/LogoVertical.svg';
import useStyles from './style';

const ProductSection = () => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="center" style={{ position: 'relative', overflow: 'hidden' }}>
      <Box className={classes.inProductBg}>
        <ProductBackgroundIcon />
      </Box>

      <Box className={classes.inProductSection} px={2} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <Container>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={12} md={4}>
              <Box mb={1.5} className={classes.inProductionTitle}>
                In Production With
              </Box>
              <Box mb={1.5} className={classes.inProductionLogoWrapper}>
                <LogoIcon />
              </Box>
              <Box className={classes.inProductionCotent}>
                Ownerfy is an early innovator in NFT technology that helps artists and businesses create higher quality NFTs and smart contracts that meet emerging standards and the demands of enterprise business.
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default ProductSection;

import React, { forwardRef } from 'react';
import classNames from 'classnames';
import './style.scss';

const GlobalButton = (
  { btnLabel, background, small, className: wrapperStyle, handleClick, disabled, href, ...rest },
  ref,
) => {
  return (
    <button
      ref={ref}
      type="submit"
      disabled={disabled || false}
      className={classNames(
        {
          'button-background': !disabled && background,
          'button-gredient': !disabled && !background,
          'button-small': small,
          'button-default': !small,
          'button-disabled': disabled,
        },
        wrapperStyle,
      )}
      onClick={handleClick ? () => handleClick() : null}
      href={href}
      {...rest}
    >
      {btnLabel}
    </button>
  );
};

export default forwardRef(GlobalButton);

import * as types from '../actions/types';

const INITIAL_STATE = {
  complete: false,
  processing: false,
  newItems: [{
    name: '',
    images: [],
    thumbs: [],
    orderId: '',
  }]
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CHECKOUT_PROCESSING:
      return {
        ...state,
        processing: action.payload,
      };
    case types.CHECKOUT_COMPLETE:
      return {
        orderId: action.payload.orderId,
        newItems: action.payload.newItems,
        complete: true,
        processing: false,
      };
    case types.CHECKOUT_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

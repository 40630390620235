import React from 'react';
import Container from '@material-ui/core/Container';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import LoginV2 from 'Pages/Login-v2';
import PageLayout from 'Layout/Layout';

const styles = theme => ({
  container: {
    marginTop: 30,
  },
  button: {
    color: '#fff',
    display: 'block',
    textAlign: 'center',
    width: 150,
  },
  link: {
    marginTop: 20,
  },
});

function Login({ classes, registerWithFirebase }) {
  return (
    <PageLayout hasContainer={false}>
      <Container className={classes.container} component="main" maxWidth="md">
        <LoginV2 title="Login" embedded={true} />
      </Container>
    </PageLayout>
  );
}

export default withRouter(withStyles(styles)(Login));

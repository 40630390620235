import React from 'react';
import Slider from 'react-slick';
import { Box, Container, Grid } from '@material-ui/core';
import BackgroundImg from '../assets/bg-2.png';
import Img1 from '../assets/1.png';
import Img2 from '../assets/2.png';
import Img3 from '../assets/3.png';
import Img4 from '../assets/4.png';
import Img5 from '../assets/5.png';
import JPieceFace from '../assets/jpierce-face.png';
import useStyles from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const ArtistSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.artistWrapper} pt={25} pb={15}>
      <Container className={classes.artistMainSection}>
        <Box className={classes.artistTitle}>About the artist</Box>

        <Box className={classes.artistAbout}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <Box className={classes.artistDescription1}>
                <div>
                  Popular American modern artist, Justin Pierce has found a passion in fine arts, product design and
                  custom urban murals to touch people and their emotions.
                </div>
                <div>
                  His use of colors and patterns makes his work unique and attractive to both - art collectors, famous
                  clientele like Samuel L. Jackson, LeBron James, L.L. Cool J, Rick Ross, and many charity
                  organizations.
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={2}>
              <Box className={classes.jpieceWrapper} px={4} display="flex" alignItems="center">
                <img src={JPieceFace} alt="JPiece Face" width="100%" />
              </Box>
            </Grid>

            <Grid item xs={12} md={5}>
              <Box className={classes.artistDescription2}>
                <div>
                  J.Pierce is inspired by legendary artists: Warhol, Seuss, haring and Picasso. He’s always discovering
                  new insights and artistic visions from art galleries, books, and pop culture in general.
                </div>
                <div>
                  Justin is known for his many colorful paintings, clothes, shoes, car wraps, and murals. He's been
                  featured in national news, and the Jimmy Kimmel Show.
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid className={classes.hideMobile} container spacing={4}>
          <Grid item xs={12} md={3}>
            <img src={Img2} className={classes.gridImg} width="100%" height="395" alt="" />
          </Grid>

          <Grid item xs={12} md={6}>
            <img src={Img1} className={classes.gridImg} width="100%" height="395" alt="" />
          </Grid>

          <Grid item xs={12} md={3}>
            <img src={Img3} width="100%" height="395" className={classes.gridImg} alt="" />
          </Grid>

          <Grid item xs={12} md={6}>
            <img src={Img4} width="100%" height="395" className={classes.gridImg} alt="" />
          </Grid>

          <Grid item xs={12} md={6}>
            <img src={Img5} width="100%" height="395" className={classes.gridImg} alt="" />
          </Grid>
        </Grid>

        <Box className={classes.hideDesktop} style={{ marginRight: -30, overflow: 'hidden' }}>
          <Box style={{ marginRight: '-25%' }}>
            <Slider {...sliderSettings}>
              <Box pr={3}>
                <img src={Img2} className={classes.gridImg} width="100%" height="306" alt="" />
              </Box>
              <Box pr={3}>
                <img src={Img1} className={classes.gridImg} width="100%" height="306" alt="" />
              </Box>
              <Box pr={3}>
                <img src={Img3} width="100%" height="306" className={classes.gridImg} alt="" />
              </Box>
              <Box pr={3}>
                <img src={Img4} width="100%" height="306" className={classes.gridImg} alt="" />
              </Box>
              <Box pr={3}>
                <img src={Img5} width="100%" height="306" className={classes.gridImg} alt="" />
              </Box>
            </Slider>
          </Box>
        </Box>
      </Container>

      <img src={BackgroundImg} className={classes.artistBackground} alt="Artist Background" />
    </Box>
  );
};

export default ArtistSection;

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  pageWrapper: {
    paddingBottom: 0,
  },
  hideMobile: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  hideDesktop: {
    '@media (min-width: 769px)': {
      display: 'none',
    },
  },
  successContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: '42px',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  navWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
  },
  navBtn: {
    border: 'solid 1px #ffffff',
    height: 45,
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    backgroundColor: '#000',

    '&:first-child': {
      marginRight: 14,
    },
  },
  navBtnLabel: {
    fontSize: 12,
    fontWeight: 500,
    marginRight: 16,
  },
  navLink: {
    color: '#fff',
    textDecoration: 'none',
  },
  heroImgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  heroImg: {
    width: '100%',
    '@media (max-width: 768px)': {
      height: 700,
      width: 'auto',
      objectFit: 'cover',
    },
  },
  heroTitle: {
    fontSize: 80,
    lineHeight: '65px',
    fontFamily: 'Intro_Rust',
    textAlign: 'center',
    color: '#ffffff',
    position: 'absolute',
  },
  heroDescriptionWrapper: {
    position: 'absolute',
    bottom: 40,
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      bottom: 0,
    },
  },
  heroDescription: {
    fontSize: 36,
    lineHeight: '36px',
    fontWeight: 700,
    fontFamily: 'Intro_Book_Caps',
    marginBottom: 12,
    textAlign: 'center',
    color: '#ffffff',
  },

  heroCaptions: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 500,
    textAlign: 'center',
    color: '#ffffff',
    marginBottom: 32,
  },
  heroActionbtns: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  metamaskBtn: {
    backgroundColor: '#FD2424',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    textTransform: 'capitalize',
    fontWeight: 500,
    height: 56,
    paddingLeft: 56,
    paddingRight: 56,
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FD2424 !important',
    },
  },
  creditcardBtn: {
    backgroundColor: '#FDF516',
    color: '#191919',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    textTransform: 'capitalize',
    fontWeight: 500,
    height: 56,
    paddingLeft: 56,
    paddingRight: 56,
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FDF516 !important',
    },
  },
  checkBtn: {
    backgroundColor: '#FDF516',
    color: '#191919',
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    textTransform: 'capitalize',
    fontWeight: 500,
    height: 56,
    paddingLeft: 56,
    paddingRight: 56,
    marginBottom: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FDF516 !important',
    },
  },
  footerLogo: {
    margin: 20,
  },
  tokens: {
    fontSize: 20,
    lineHeight: '25px',
    fontWeight: 700,
    fontFamily: 'Intro_Book_Caps',
    marginBottom: 12,
    textAlign: 'center',
    color: '#ffffff',
  },
  tokenIds: {
    fontWeight: 'bold',
  },
  tokenUsed: { fontSize: 22 },
  input: {
    display: 'block',
    height: '34px',
    border: '0',
    width: '70px',
    textAlign: 'center',
    outline: 'none',
    backgroundColor: 'white',
    color: '#000',
    fontSize: '16px',
    margin: '20px',
  },
  launchWrapper: {
    position: 'relative',
  },
  qtyContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  qty: {
    color: '#fff',
    paddingTop: '26px',
  },
  launchTitle: {
    fontFamily: 'Intro_Rust',
    fontSize: 48,
    lineHeight: '38px',
    marginBottom: 34,
    color: '#ffffff',
    textAlign: 'center',
  },
  launchTimer: {
    backgroundColor: '#FCF492',
    width: 329,
    paddingTop: 35,
    paddingBottom: 35,
    fontFamily: 'Intro_Rust',
    color: '#191919',
    marginBottom: 12,
    fontSize: 48,
    lineHeight: '38px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  launchSubscribeBtn: {
    backgroundColor: '#191919',
    width: 329,
    textTransform: 'capitalize',
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
  },
  launchBackground: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    height: 675,
    objectFit: 'cover',
  },
  launchMainSection: {
    position: 'relative',
    zIndex: 1,
  },
  artistWrapper: {
    position: 'relative',
    marginTop: -100,
    '@media (max-width: 768px)': {
      marginTop: -180,
    },
  },
  artistMainSection: {
    position: 'relative',
    zIndex: 1,
    paddingLeft: 30,
    paddingRight: 30,
  },
  artistBackground: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    height: 700,
    objectFit: 'cover',
  },
  artistAbout: {
    marginBottom: 80,
    '@media (max-width: 768px)': {
      marginBottom: 0,
    },
  },
  artistTitle: {
    fontSize: 48,
    lineHeight: '38px',
    fontFamily: 'Intro_Rust',
    textAlign: 'center',
    marginBottom: 42,
    color: '#ffffff',
  },
  artistDescription1: {
    fontSize: 16,
    lineHeight: '32px',
    textAlign: 'left',
    color: '#ffffff',
  },
  artistDescription2: {
    fontSize: 16,
    lineHeight: '32px',
    textAlign: 'right',
    color: '#ffffff',
    '@media (max-width: 768px)': {
      textAlign: 'left',
    },
  },
  jpieceWrapper: {
    '@media (max-width: 768px)': {
      paddingTop: 80,
      display: 'flex',
      justifyContent: 'center',
      '& img': {
        width: 145,
      },
    },
  },
  gridImg: {
    objectFit: 'cover',
  },
  prizeWrapper: {},
  prizeMainSection: {},
  prizeSliderWrapper: {
    '@media (max-width: 768px)': {
      marginRight: '-45%',
    },
  },
  prizeTimeline: {
    fontSize: 32,
    lineHeight: '25px',
    fontFamily: 'Intro_Rust',
    marginBottom: 16,
    textAlign: 'center',
    color: '#FD2424',
  },
  prizeNftsSold: {
    fontSize: 48,
    lineHeight: '38px',
    fontFamily: 'Intro_Rust',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  freePriceContent: {
    color: '#FFFFFF',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    display: 'inline-block',
  },
  freePriceImage: {
    margin: '-20px auto 20px',
  },
  oneEthPriceBar: {
    width: 7,
    borderRadius: 20,
    backgroundColor: '#3CFD15',
    marginRight: 32,
  },
  oneEthPriceContent: {
    color: '#FFFFFF',
  },
  oneEthPriceTitle: {
    fontSize: 16,
    lineHeight: '32px',
    fontFamily: 'Intro_Rust',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  oneEthDescription: {
    fontSize: 16,
    lineHeight: '22px',
  },
  threeEthPriceContent: {
    color: '#FFFFFF',
  },
  threeEthPriceBar: {
    width: 7,
    borderRadius: 20,
    backgroundColor: '#FC0522',
    marginRight: 32,
  },
  threeEthPriceTitle: {
    fontSize: 16,
    lineHeight: '32px',
    fontFamily: 'Intro_Rust',
    marginBottom: 16,
  },
  threeEthDescription: {
    fontSize: 16,
    lineHeight: '22px',
  },
  closingContainer: {
    position: 'relative',
  },
  closingBackground: {},
  closingDescription: {
    fontSize: 16,
    lineHeight: '20px',
    color: '#FFFFFF',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    botom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  timelineImage: {
    marginBottom: 48,
    '@media (max-width: 768px)': {
      marginBottom: 0,
    },
  },
  scrollContainer: {
    width: '100%',
    '@media (max-width: 768px)': {
      width: '200%',
    },
    '@media (max-width: 425px)': {
      width: '300%',
    },
  },
  memberShipWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  membershipTitle: {
    fontSize: 36,
    lineHeight: '36px',
    fontFamily: 'Intro_Rust',
    color: '#FCF492',
    textAlign: 'center',
  },
  membershipDescription: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#E5E5E5',
    textAlign: 'center',
  },
  commercialWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  commercialTitle: {
    fontSize: 36,
    lineHeight: '36px',
    fontFamily: 'Intro_Rust',
    color: '#F9C4AA',
    textAlign: 'center',
  },
  commercialDescription: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#E5E5E5',
    textAlign: 'center',
  },
  ownerDtoWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  ownerDtoTitle: {
    fontSize: 36,
    lineHeight: '36px',
    fontFamily: 'Intro_Rust',
    color: '#E5E5E5',
    textAlign: 'center',
  },
  ownerDtoDescription: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#E5E5E5',
    textAlign: 'center',
  },
  privateClubWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  privateClubTitle: {
    fontSize: 36,
    lineHeight: '36px',
    fontFamily: 'Intro_Rust',
    color: '#E2263C',
    textAlign: 'center',
  },
  privateClubDescription: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#E5E5E5',
    textAlign: 'center',
  },
  nftTraitsWrapper: {
    position: 'relative',
    zIndex: 1,
  },
  nftTraitsTitle: {
    fontSize: 36,
    lineHeight: '36px',
    fontFamily: 'Intro_Rust',
    color: '#E2263C',
    textAlign: 'center',
  },
  nftTraitsDescription: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#E5E5E5',
    textAlign: 'center',
  },
  mapBackground: {
    position: 'absolute',
    top: 48,
    left: 0,
    right: 0,
    width: '100%',
    '@media (max-width: 768px)': {
      width: '150%',
      left: '-25%',
      right: '-25%',
    },
  },
  zombieWrapper: {
    paddingTop: 104,
    paddingBottom: 104,
    backgroundColor: '#FD2424',
    '@media (max-width: 768px)': {
      paddingTop: 24,
      paddingBottom: 24,
    },
  },
  zombieGrid: {
    flexWrap: 'nowrap',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  broughtWrapper: {
    paddingBottom: 100,
    paddingTop: 100,
    '@media (max-width: 768px)': {
      paddingTop: 70,
      paddingBottom: 70,
    },
  },
  broughtTitle: {
    fontSize: 48,
    lineHeight: '38px',
    fontFamily: 'Intro_Rust',
    color: 'white',
    textAlign: 'center',
  },
  broughtDescription: {
    fontSize: 32,
    lineHeight: '36px',
    fontFamily: 'Lato',
    textAlign: 'center',
    color: '#7CB774',
  },
  broughtImages: {
    flexWrap: 'nowrap',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
      '& > div:last-child': {
        display: 'none',
      },
    },
  },
  madeWrapper: {
    paddingTop: 68,
    paddingBottom: 80,
    backgroundColor: '#7CB774',
  },
  madeTitle: {
    fontFamily: 'Intro_Rust',
    color: '#FD2424',
    fontSize: 48,
    lineHeight: '38px',
  },
  madeDescription: {
    fontSize: 18,
    lineHeight: '34px',
    color: 'white',
    paddingRight: 120,
    '@media (max-width: 768px)': {
      paddingRight: 0,
    },
  },
  bestBuddiesSection: {
    backgroundColor: 'white',
    paddingTop: 85,
    paddingBottom: 94,
    '@media (max-width: 768px)': {
      paddingTop: 36,
      paddingBottom: 32,
    },
  },
  bestBuddiesTitle: {
    fontFamily: 'Intro_Rust',
    fontSize: 48,
    lineHeight: '38px',
    color: '#050403',
  },
  bestBuddiesDescription: {
    fontSize: 16,
    lineHeight: '30px',
    color: '#383738',
  },
  bestBuddiesLearn: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '28px',
    color: '#ED1C24',
    textDecoration: 'underline',
  },
  inProductSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    paddingTop: 60,
    '@media (max-width: 768px)': {
      paddingTop: 20,
    },
  },
  inProductBg: {
    width: '100%',
    '& svg': {
      width: '100%',
      height: '100%',
    },
    '@media (max-width: 768px)': {
      marginLeft: '-200%',
      marginRight: '-40%',
      width: '340%',
      paddingTop: 150,
    },
  },
  inProductionTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    color: '#FD2424',
    textAlign: 'center',
  },
  inProductionLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  inProductionCotent: {
    color: 'white',
    fontSize: 12,
    lineHeight: '20px',
    textAlign: 'center',
  },
  certifiedWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 768px)': {
      justifyContent: 'center',
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
  smartContractWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  smartContractTitle: {
    fontSize: 12,
    lineHeight: '16px',
    color: '#3CBAE8',
  },
  smartContractAddress: {
    fontSize: 12,
    lineHeight: '16px',
  },
  socialWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 768px)': {
      justifyContent: 'center',
    },
  },
  socialIcons: {
    width: 300,
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
      width: '100%',
    },
    '& svg': {
      width: 20,
      height: 15,
      '@media (max-width: 768px)': {
        width: 48,
        height: 40,
      },
    },
  },
}));

export default useStyles;

/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import Button from '@material-ui/core/Button';
import Page from 'Components/Page';
import Link from '@material-ui/core/Link';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import useStyles from './style';
import Container from 'Components/Container';

function Success(props) {
  const classes = useStyles();

  return (
    <PageLayout wrapperStyles={classes.pageWrapper} hasContainer={false}>
      <SectionLayout hasContainer={true}>
        <Container>
          <div style={{ marginTop: 50, fontFamily: 'Intro_Rust' }}>
            <h1>Congratulations!</h1>
            <p>You obtained a J. Pierce &amp; Zombie Friend(s)</p>
            <p>Your J. Pierce &amp; Zombie Friends(s) are being minted</p>
            <p>Visit the dashboard to view your tokens information.</p>
            <p>
              Minting may take up to <b>3 minutes</b>. After your friend is minted it may take a while before 3rd
              parties like OpenSea pick it up in their system.
            </p>
            <p>
              Visit the FAQ to <a href="/faq">learn more</a> about how to connect your account to marketplaces and
              DAPPs.{' '}
            </p>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              component={Link}
              href="/my-collection"
              css={css`
                margin-top: 30px;
                color: #ffffff;
                &:hover {
                  text-decoration: none;
                }
              `}
            >
              Proceed to your collection
            </Button>
            {/* <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            component={Link}
            href="/chicadees/mint"
            css={css`
              margin-top: 30px;
              color: #ffffff;
              &:hover {
                text-decoration: none;
              }
            `}
          >
            Mint Another Chicadee
          </Button>
        </div> */}
          </div>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
}

export default Success;
